import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import { Container, Row, Col, Button, Card, CardTitle, CardBody, CardText } from 'reactstrap';
import axios from 'axios';
import './WeddingReservationDetail.style.css';
import { webserviceurl, activelanguage } from '../../../Config';
import SubHeader from '../../Component/SubHeader/SubHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {convertToRupiah} from '../../../Global';
import Collapsible from 'react-collapsible';
import { confirmAlert } from 'react-confirm-alert'; 

class WeddingReservationDetail extends Component {

    constructor(props) {
        super(props);
        this.language = getLanguage(activelanguage, 'weddingreservationdetail');
        this.globallang = getLanguage(activelanguage, 'global');

        this.state = {
            page: "",
            redirect: false,
            weddingreservationid: props.match.params.weddingreservationid,
            title: this.language.title,
            data: {},
            detail: [],
            weddingid:0,
            dataWedding:{},
            weddingcategoryid:0,
            totalremaining:'',
            totalpayment:'',
            totaldownpayment:''
        }
        
    }

    componentDidMount=()=>{
        this.waitForBridge();
        this.loadWeddingReservation();
    }

    loadWeddingid =()=>{
        axios.post(webserviceurl + '/app_get_wedding.php', {
            weddingid: this.state.weddingid
        },
        {headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {
                let result = response.data;
                console.log(result);

                if (result.status === "OK") {
                    this.setState({ 
                        dataWedding: result.records,
                        weddingcategoryid: result.records.weddingcategoryid
                    });

                    
                }
                console.log(this.state.dataWedding);
                console.log(this.state.weddingcategoryid);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    loadWeddingReservation = () => {
        let totaldownpayment    = 0;
        let totalpayment        = 0;
        let totalremaining      = 0;

        axios.post(webserviceurl + '/app_load_weddingreservationdetail.php', {
            weddingreservationid: this.state.weddingreservationid
        },
        {headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {

                let result = response.data;
                console.log(result);
                if (result.status === "OK") {
                    this.setState({ data: result.record });
                    this.setState({ detail: result.record.info });
                    this.setState({ weddingid : result.record.weddingid});
                    this.setState({ totaldownpayment : result.record.downpayment})
                    this.setState({ totalpayment : result.record.totalpayment})

                    totalpayment     = this.state.totalpayment
                    totaldownpayment = this.state.totaldownpayment
                    totalremaining   = (totalpayment - totaldownpayment);
                
                    this.setState({totalremaining : totalremaining});
                }

                console.log(this.state.totalremaining);
                console.log(this.state.weddingid);

                this.loadWeddingid();
                
            })
            .catch((error) => {
                console.log(error);
            });
    }

    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
            window.postMessage(param, "*");
        }
    }

    createMarkup = (content) => {
        return { __html: content };
    }

    renderStatus =()=>{
        if(this.state.data.status === 0){
            return (
                <span className="pending">Not Yet Ordered</span>
            )
        }
		else if (this.state.data.status === 1){
            return (
                <span className="paid">Already Booked</span>
            )
        }
		else if (this.state.data.status === 2){
            return (
                <span className="cancelled">Cancelled</span>
            )
        }
    }


    _doModifyRescheduleReservation =()=>{
        this.props.history.push('/reschedulereservation/'+this.state.weddingcategoryid+'/'+this.state.weddingreservationid);
    }

    edit = () =>{
        // alert("oke edit");
        confirmAlert({
			customUI: ({ onClose }) => {
                return (
                    <div>
                        <Card className="card-alert">
                            <CardTitle className="card-title"  >
                                <span>Information!</span>
                            </CardTitle>
                            <CardBody className="card-body">
                                <CardText className="card-text" >{this.language.areyousureedit}</CardText>
                                    <table>
                                        <tr style={{color:'#fff', textAlign:'center'}} >
                                            <td>
                                                <a className="link-button" onClick={() => {this._doModifyRescheduleReservation(); onClose();}}>{this.globallang.submit}</a>
                                            </td>
                                            <td>
                                                <a className="link-button" onClick={onClose}>{this.globallang.cancel}</a>
                                            </td>
                                        </tr>
                                    </table>
                            </CardBody>
                        </Card>
                    </div>
				)
			}
		})
    }



    render() {
        return (
            <div className="main-container">
                <SubHeader history={this.props.history} hideSearch={true} title={this.state.title} />
                <div className="room-reservation-detail">
                    <table className="room-reservation-info">
                        <tbody>
                            <tr>
                                <td className="col1">
                                    Name
                                </td>
                                <td className="col2">
                                    {this.state.data.name}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
                                    Date Check In
                                </td>
                                <td className="col2">
                                    {moment(this.state.data.checkin).format('lll')}
                                </td>
                            </tr>
							<tr>
                                <td className="col1">
                                    Date Check Out
                                </td>
                                <td className="col2">
                                    {moment(this.state.data.checkout).format('lll')}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
                                    Facilities Name
                                </td>
                                <td className="col2">
                                    {this.state.data.weddingname}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
                                    Duration
                                </td>
                                <td className="col2">
                                    {this.state.data.duration} Jam
                                </td>
                            </tr>
							<tr>
                                <td className="col1">
                                    Estimated Price
                                </td>
                                <td className="col2">
                                    {convertToRupiah(this.state.data.weddingprice)}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
                                    Deposit
                                </td>
                                <td className="col2">
                                    {convertToRupiah(this.state.data.deposit)}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
                                    Total Payment
                                </td>
                                <td className="col2">
                                    {convertToRupiah(this.state.data.totalpayment)}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
                                    Down Payment
                                </td>
                                <td className="col2">
                                    <span style={{color:'#00d02c'}}>{convertToRupiah(this.state.data.downpayment)}</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
                                    Remaining Payment
                                </td>
                                <td className="col2">
                                    <span style={{color:'rgb(255,0,0)'}}>{convertToRupiah(this.state.totalremaining)}</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
                                    Status
                                </td>
                                <td className="col2">
                                    {this.renderStatus()}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
                                    Invoice Number
                                </td>
                                <td className="col2">
                                    {this.state.data.external_id}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
                                    Transaksi Date
                                </td>
                                <td className="col2">
                                {moment(this.state.data.transaksi_date).format('lll')}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
                                    Paid Amount
                                </td>
                                <td className="col2">
                                    <span className="paid">{convertToRupiah(this.state.data.paid_amount)}</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
                                    Payment Method
                                </td>
                                <td className="col2">
                                    {this.state.data.payment_method}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
                                    Payment Channel
                                </td>
                                <td className="col2">
                                    {this.state.data.payment_channel}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
                                    Status Down Payment
                                </td>
                                <td className="col2">
                                    <span className="paid">{this.state.data.status_payment}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div style={{marginTop:20, marginBottom:20, textAlign:'center' }} >
                        <Button  onClick={() => this.edit()} style={{backgroundColor:'rgb(1, 113, 226)', color:'#fff', textAlign:'center', width:'80%' }} >
                            <span> {this.language.reschedule} </span>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default WeddingReservationDetail;