import React, { Component } from "react";
import { getLanguage } from "../../../Languages";
import axios from "axios";
import "./BanquetFacilitiesPage";
import { Container, Row, Col } from "reactstrap";
import { webserviceurl, activelanguage } from "../../../Config";
import SubHeader from "../../Component/SubHeader/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import more from "../../../images/marketplace/more.png";

class BanquetFacilitiesPage extends Component {
  constructor(props) {
    super(props);

    this.language = getLanguage(activelanguage, "banquetfacilities");
    this.globallang = getLanguage(activelanguage, "global");

    let curr = localStorage.getItem("bookingfrom");
    if (curr === null || curr === undefined || curr === "") curr = 0;

    this.state = {
      currentTab: parseInt(curr),
      page: "",
      redirect: false,
      weddings: [],
      weddingcategories: [],
      currentWeddingCategory: 0,
      weddingstart: 0,
      loadWeddingFinished: false,
      nonweddings: [],
      nonweddingcategories: [],
      currentNonWeddingCategory: 0,
      nonweddingstart: 0,
      loadNonWeddingFinished: false,
    };
    this.loginInfo = {
      phoneno: "",
      name: "",
    };

    this.listel = null;
  }

  componentDidMount = () => {
    // this.loadUser();
    this.waitForBridge();
    this.loadWeddingCategory();
    this.loadNonWeddingCategory();

    let curr = localStorage.getItem("bookingfrom");
    if (curr === null || curr === undefined || curr === "") curr = 0;
    this.setState({ currentTab: parseInt(curr) });

    this.listel = document.getElementById("list-booking");
    this.listel.addEventListener("scroll", (e) => {
      this.scrollWeddingCheck();
      this.scrollNonWeddingCheck();
    });
  };

  componentWillUnmount = () => {
    this.listel.removeEventListener("scroll", (e) => {
      this.scrollWeddingCheck();
      this.scrollNonWeddingCheck();
    });
  };

  scrollWeddingCheck = () => {
    if (
      this.listel.scrollTop + this.listel.clientHeight >=
      this.listel.scrollHeight
    ) {
      if (this.state.loadWeddingFinished == false) {
        this.loadWeddingReservationByCategory(
          this.state.currentWeddingCategory,
          this.state.weddingstart
        );
      }
    }
  };

  scrollNonWeddingCheck = () => {
    if (
      this.listel.scrollTop + this.listel.clientHeight >=
      this.listel.scrollHeight
    ) {
      if (this.state.loadNonWeddingFinished == false) {
        this.loadNonWeddingReservationByCategory(
          this.state.currentNonWeddingCategory,
          this.state.nonweddingstart
        );
      }
    }
  };

  loadWedding = (id) => {
    this.setState({ currentWeddingCategory: id });
    this.loadWeddingReservationByCategory(id, 0);
    this.setState({ weddingstart: 0 });
    this.setState({ weddings: [] });
  };

  loadNonWedding = (id) => {
    this.setState({ currentNonWeddingCategory: id });
    this.loadNonWeddingReservationByCategory(id, 0);
    this.setState({ nonweddingstart: 0 });
    this.setState({ nonweddings: [] });
  };

  loadWeddingCategory = (id) => {
    axios
      .get(webserviceurl + "/app_load_weddingcategory.php")
      .then((response) => {
        let result = response.data;
        if (result.status === "OK") {
          this.loadWeddingReservationByCategory(
            this.state.currentWeddingCategory,
            0
          );
          this.setState({ weddingcategories: result.records });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadNonWeddingCategory = (id) => {
    axios
      .get(webserviceurl + "/app_load_weddingcategory.php")
      .then((response) => {
        let result = response.data;
        if (result.status === "OK") {
          this.loadNonWeddingReservationByCategory(
            this.state.currentNonWeddingCategory,
            0
          );
          this.setState({ nonweddingcategories: result.records });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadWeddingReservationByCategory = (id, weddingstart) => {
    axios
      .get(
        webserviceurl +
          "/app_load_weddingreservation.php?id=" +
          id +
          "&weddingstart=" +
          weddingstart +
          "&userid=" +
          this.loginInfo.phoneno
      )
      .then((response) => {
        let result = response.data;
        let limitafterload = this.state.weddingstart + 10;
        if (result.status === "OK") {
          if (result.records.length == 0) {
            this.setState({ loadWeddingFinished: true });
          }

          let tmp = this.state.weddings;
          for (var i = 0; i < result.records.length; i++) {
            tmp.push(result.records[i]);
          }

          this.setState({ weddings: tmp });
          this.setState({ weddingstart: limitafterload });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadNonWeddingReservationByCategory = (id, nonweddingstart) => {
    axios
      .get(
        webserviceurl +
          "/app_load_weddingnonreservation.php?id=" +
          id +
          "&nonweddingstart=" +
          nonweddingstart +
          "&userid=" +
          this.loginInfo.phoneno
      )
      .then((response) => {
        let result = response.data;
        let limitafterload = this.state.nonweddingstart + 10;
        if (result.status === "OK") {
          if (result.records.length == 0) {
            this.setState({ loadNonWeddingFinished: true });
          }

          let tmp = this.state.nonweddings;
          for (var i = 0; i < result.records.length; i++) {
            tmp.push(result.records[i]);
          }

          this.setState({ nonweddings: tmp });
          this.setState({ nonweddingstart: limitafterload });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadUser = () => {
    let tmp = localStorage.getItem("smart-app-id-login");
    if (
      tmp == undefined ||
      tmp == null ||
      tmp == "null" ||
      tmp == "" ||
      tmp == "undefined"
    ) {
      let param = '{"code":"need-login"}';
      this.sendPostMessage(param);
    } else {
      tmp = JSON.parse(tmp);
      this.loginInfo.phoneno = tmp.phonenumber;
      this.loginInfo.name = tmp.name;
    }
  };

  sendPostMessage = (param) => {
    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.sendPostMessage(param);
        }.bind(this),
        200
      );
    } else {
      window.postMessage(param, "*");
    }
  };

  onMessage = (data) => {
    //alert(data.data);
    let messContent = null;
    if (data.data) messContent = JSON.parse(data.data);

    if (messContent) {
      if (messContent.code === "login") {
        localStorage.setItem(
          "smart-app-id-login",
          JSON.stringify(messContent.param)
        );
        //alert(JSON.stringify(messContent.param));
        window.postMessage('{"code":"receivelogin"}', "*");
      } else if (messContent.code === "logout") {
        localStorage.removeItem("smart-app-id-login");
      }
    }
  };

  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let tmp = localStorage.getItem("smart-app-id-login");
      let needLogin = false;
      // let tmp2 = localStorage.getItem('smart-app-id-binding');
      // let needBinding = false;

      if (
        tmp == undefined ||
        tmp == null ||
        tmp == "null" ||
        tmp == "" ||
        tmp == "undefined"
      )
        needLogin = true;
      // else if(tmp2 == undefined || tmp2 == null || tmp2 == 'null' || tmp2 == '' ||tmp2 == 'undefined' ) needBinding = true;
      else {
        tmp = JSON.parse(tmp);
        this.loginInfo.phoneno = tmp.phonenumber;
        this.loginInfo.name = tmp.name;
      }

      let param =
        '{"title":"' +
        this.language.title +
        '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true, "needLogin":' +
        (needLogin ? "true" : "false") +
        "}";
      window.postMessage(param, "*");
    }
  }

  createMarkup = (content) => {
    return { __html: content };
  };

  goBack = () => {
    this.props.history.goBack();
  };

  goToWeddingDetail = (wedding) => {
    this.props.history.push(
      "/weddingreservationdetail/" + wedding.weddingreservationid
    );
  };
  goToNonWeddingDetail = (nonwedding) => {
    this.props.history.push(
      "/nonweddingreservationdetail/" + nonwedding.nonweddingreservationid
    );
  };

  add = () => {
    if (this.state.currentTab == 0) {
      localStorage.setItem("bookingfrom", "0");
      this.props.history.push("/weddingcategoryselect");
    } else {
      localStorage.setItem("bookingfrom", "1");
      this.props.history.push("/nonweddingcategoryselect");
    }
  };

  currentActiveTab(idx) {
    if (idx === this.state.currentTab) return "tab-item active-tab";
    else return "tab-item";
  }

  changeTab(idx) {
    this.setState({ currentTab: idx, currentIndex: 0 });
    if (idx == 0) {
      this.renderWeddingReservation();
    } else {
      this.renderNonWeddingReservation();
    }
  }

  renderDot = (item) => {
    if (item.status === 0) {
      return (
        <span className="pending">
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (item.status === 1) {
      return (
        <span className="paid">
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (item.status === 2) {
      return (
        <span className="cancelled">
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    }
  };

  renderStatus = (item) => {
    if (item.status === 0) {
      return <span className="pending">Not Yet Ordered</span>;
    } else if (item.status === 1) {
      return <span className="paid">Already Booked</span>;
    } else if (item.status === 2) {
      return <span className="cancelled">{this.language.cancelled}</span>;
    }
  };

  renderWeddingReservationBody = () => {
    if (this.state.weddings.length > 0) {
      return (
        <div className="body-section rooms-scroll-view">
          <table>
            <tbody>
              {this.state.weddings.map((item, i) => (
                <tr
                  key={i}
                  className="room-list"
                  onClick={() => this.goToWeddingDetail(item)}
                >
                  <td className="room-list-col-dot">{this.renderDot(item)}</td>
                  <td className="rooms-list-col1">
                    <div className="room-name">{item.weddingname}</div>
                    <div className="room-checkin">
                      Check In: {moment(item.checkin).format("lll")}
                    </div>
                    <div className="room-checkout">
                      Check Out: {moment(item.checkout).format("lll")}
                    </div>
                    {/*<div className="room-desc">Rp. {item.price}</div>*/}
                  </td>
                  <td className="rooms-list-col-qty">
                    <div className="room-status-label">Status</div>
                    <div className="room-status-value">
                      {this.renderStatus(item)}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className="body-section rooms-scroll-view">
          <div className="no-data">{this.globallang.nodata}</div>
        </div>
      );
    }
  };

  renderNonWeddingReservationBody = () => {
    if (this.state.nonweddings.length > 0) {
      return (
        <div className="body-section activities-scroll-view">
          <table>
            <tbody>
              {this.state.nonweddings.map((item, i) => (
                <tr
                  key={i}
                  className="activity-list"
                  onClick={() => this.goToNonWeddingDetail(item)}
                >
                  <td className="activity-list-col-dot">
                    {this.renderDot(item)}
                  </td>
                  <td className="activities-list-col1">
                    <div className="activity-name">{item.nonweddingname}</div>
                    <div className="activity-checkin">
                      Check In: {moment(item.checkin).format("lll")}
                    </div>
                    <div className="activity-checkout">
                      Check Out: {moment(item.checkout).format("lll")}
                    </div>
                  </td>
                  <td className="activities-list-col-qty">
                    <div className="activity-status-label">Status</div>
                    <div className="activity-status-value">
                      {this.renderStatus(item)}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className="body-section rooms-scroll-view">
          <div className="no-data">{this.globallang.nodata}</div>
        </div>
      );
    }
  };

  renderWeddingCategory = (category) => {
    if (category.weddingcategoryid === this.state.currentWeddingCategory) {
      return (
        <div
          className="room-link-label room-link-active"
          onClick={() => this.loadWedding(category.weddingcategoryid)}
        >
          <img src={category.icon} />
          <br />
          {category.weddingcategoryname}{" "}
        </div>
      );
    } else {
      return (
        <div
          className="room-link-label"
          onClick={() => this.loadWedding(category.weddingcategoryid)}
        >
          <img src={category.icon} />
          <br />
          {category.weddingcategoryname}{" "}
        </div>
      );
    }
  };

  renderNonWeddingCategory = (category) => {
    if (
      category.nonweddingcategoryid === this.state.currentNonWeddingCategory
    ) {
      return (
        <div
          className="activity-link-label activity-link-active"
          onClick={() => this.loadNonWedding(category.weddingcategoryid)}
        >
          <img src={category.icon} />
          <br />
          {category.weddingcategoryname}{" "}
        </div>
      );
    } else {
      return (
        <div
          className="activity-link-label"
          onClick={() => this.loadNonWedding(category.weddingcategoryid)}
        >
          <img src={category.icon} />
          <br />
          {category.weddingcategoryname}{" "}
        </div>
      );
    }
  };

  renderWeddingReservation = () => {
    const all = {
      weddingcategoryid: 0,
      weddingcategoryname: this.globallang.all,
      icon: more,
    };

    return (
      <div>
        <div className="room-icons">
          <div className="room-icons-container">
            <table className="table-icon">
              <tbody>
                <tr>
                  <td>
                    <div className="room-icon-column">
                      {this.renderWeddingCategory(all)}
                    </div>
                  </td>
                  {this.state.weddingcategories.map((category, i) => (
                    <td>
                      <div key={i} className="room-icon-column">
                        {this.renderWeddingCategory(category)}
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {this.renderWeddingReservationBody()}
      </div>
    );
  };

  renderNonWeddingReservation = () => {
    const all = {
      weddingcategoryid: 0,
      weddingcategoryname: this.globallang.all,
      icon: more,
    };

    return (
      <div>
        <div className="activity-icons">
          <div className="activity-icons-container">
            <table className="table-icon">
              <tbody>
                <tr>
                  <td>
                    <div className="activity-icon-column">
                      {this.renderNonWeddingCategory(all)}
                    </div>
                  </td>
                  {this.state.nonweddingcategories.map((category, i) => (
                    <td>
                      <div key={i} className="activity-icon-column">
                        {this.renderNonWeddingCategory(category)}
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {this.renderNonWeddingReservationBody()}
      </div>
    );
  };

  renderWhat = () => {
    if (this.state.currentTab == 0) {
      return <div>{this.renderWeddingReservation()}</div>;
    } else {
      return <div>{this.renderNonWeddingReservation()}</div>;
    }
  };

  render() {
    return (
      <div>
        <SubHeader
          history={this.props.history}
          hideSearch={true}
          title={
            this.state.currentTab == 0
              ? this.language.title
              : this.language.title
          }
          showAddButton={true}
          add={this.add}
        />
        <div id="list-booking">
          <div className="tab-section">
            <Container>
              <Row>
                <Col
                  md="6"
                  sm="6"
                  xs="6"
                  className={this.currentActiveTab(0)}
                  onClick={() => this.changeTab(0)}
                >
                  For Weddding
                </Col>
                <Col
                  md="6"
                  sm="6"
                  xs="6"
                  className={this.currentActiveTab(1)}
                  onClick={() => this.changeTab(1)}
                >
                  Non Wedding
                </Col>
              </Row>
            </Container>
          </div>
          {this.renderWhat()}
        </div>
      </div>
    );
  }
}
export default BanquetFacilitiesPage;
