import React, { Component } from 'react';
//import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import axios from 'axios';
import './HealthFormFestivalSpring.style.css';
import { webserviceurl, activelanguage, appId } from '../../../Config';
import SubHeader from '../../Component/SubHeader/SubHeader';
import {
    Card, CardImg, CardText, CardBody, CardLink,
    CardTitle, CardSubtitle,Button, Container, Row, Col, Input
} from 'reactstrap';
// import Logo from '../../../images/white.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; 

class HealthFormFestivalSpring extends Component {

    constructor(props) {
        super(props);
        this.language = getLanguage(activelanguage, 'returnfrommudik');
        this.globallang = getLanguage(activelanguage, 'global');

        this.state = {
			phonenumber:props.match.params.phonenumber,
            date:'',
            name:'',
            cluster:'',
            blok:'',
            office:'',
            cityvisited:'',
            combo1:'O',
            combo2:'O',
            combo3:'O',
            combo4:'O',
            combo5:'O',
            note:'',
            page: "",
            redirect: false,
            title: this.language.title,
            radioSelectOption:1,
        }   
    }

    componentDidMount=()=>{
        this.waitForBridge();
        this.loadUser();
        this.loadDataByPhone();
    }

    componentWillUnmount=()=>{
        localStorage.setItem('health', '2');
    }
    
    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":true, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
            window.postMessage(param, "*");
        }
    }

    createMarkup = (content) => {
        return { __html: content };
    }

    loadUser = () => {
        let info = localStorage.getItem('smart-app-id-login');
         if(info != null && info !== undefined && info != 'null' && info != '' && info != 'undefined'){
         	info = JSON.parse(info);
         	this.setState({phonenumber: info.phonenumber, name: info.name});
         }
    }

    loadDataByPhone = () => {
        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

        axios.post(webserviceurl + '/app_load_springfestival_today2.php', {
            phonenumber: this.state.phonenumber,
            today:date
        },
        {headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {
                console.log(response);
                let result = response.data;
                console.log(result);
                if (result.status === "OK") {

                    this.setState({ 
                        phonenumber: result.records.phonenumber, 
                        name: result.records.name,
                        cluster: result.records.cluster,
                        blok: result.records.blok,
                        office: result.records.office,
                        cityvisited: result.records.cityvisited,
                        combo1: result.records.combo1,
                        combo2: result.records.combo2,
                        combo3: result.records.combo3,
                        combo4: result.records.combo4,
                        combo5: result.records.combo5,
                        note: result.records.note,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }




    handleOptionRadioChange1(e){
		this.setState({
			combo1: e.target.value
		})

		console.log(this.state.combo1);
    }

    handleOptionRadioChange2(e){
		this.setState({
			combo2: e.target.value
		})

		console.log(this.state.combo2);
    }

    handleOptionRadioChange3(e){
		this.setState({
			combo3: e.target.value
		})

		console.log(this.state.combo3);
    }

    handleOptionRadioChange4(e){
		this.setState({
			combo4: e.target.value
		})

		console.log(this.state.combo4);
    }

    handleOptionRadioChange5(e){
		this.setState({
			combo5: e.target.value
		})

		console.log(this.state.combo5);
    }


    
    goToHealthDeclaration=()=>{
        this.props.history.push('/healthdeclaration');
    }

    checkData = () => {
		const {phonenumber, name, cluster, blok,  office, cityvisited, combo1, combo2, combo3, combo4, combo5, note } = this.state;
		
		if(office == null || office  == '' || cluster  == '' || blok  == '' || cityvisited == null || cityvisited == '' || combo1 == 'O' || combo2 == 'O' || combo3 == 'O' || combo4 == 'O' || combo5 == 'O' ){
            
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div>
                            <Card className="card-alert">
                                    <CardTitle className="card-title"  >
                                        <span>Information!</span>
                                    </CardTitle>
                                    <CardBody className="card-body">
                                        <CardText className="card-text" >Silakan isi formulir kosong terlebih dahulu!</CardText>
                                            <table>
                                                <tr style={{color:'#fff', textAlign:'right'}} >
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <a className="link-button" onClick={onClose}>OK</a>
                                                    </td>
                                                </tr>
                                            </table>
                                    </CardBody>
                                </Card>
                        </div>
                    )
                }
            })
			return false;
		}
			
		else{
			this.doSubmit();
		}
    }

    doSubmit=()=>{

        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

        let params = {
            phonenumber:this.state.phonenumber,
            date:date,
            name:this.state.name,
            cluster:this.state.cluster,
            blok:this.state.blok,
            office:this.state.office,
            cityvisited:this.state.cityvisited,
            combo1:this.state.combo1,
            combo2:this.state.combo2,
            combo3:this.state.combo3,
            combo4:this.state.combo4,
            combo5:this.state.combo5,
            note:this.state.note
        }

        console.log(params);

        axios.post(webserviceurl + '/app_insert_springfestival2.php', params,
        {headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {
                let result = response.data;
                console.log(result);
                if (result.status === "ok") {
                  confirmAlert({
                      customUI: ({ onClose }) => {
                          return (
                              <div>
                                  <Card className="card-alert">
                                    <CardTitle className="card-title"  >
                                        <span>Information!</span>
                                    </CardTitle>
                                    <CardBody className="card-body">
                                        <CardText className="card-text" >{this.language.success}</CardText>
                                            <table>
                                                <tr style={{color:'#fff', textAlign:'right'}} >
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <a className="link-button" onClick={onClose}>OK</a>
                                                    </td>
                                                </tr>
                                            </table>
                                    </CardBody>
                                    </Card>
                              </div>
                          )
                      }
                  })
                      this.props.history.push('/healthdeclaration');
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }

    goBack=()=>{
        this.props.history.push('/healthdeclaration')
    }


    render() {
        return(
            <div className="main-container">
                <SubHeader history={this.props.history} hideSearch={true} title={this.state.title} goBack={this.goBack} />
                <div className="trip-center-form">
                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.name} <span style={{color:'red'}} >*</span> </div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}}  value={this.state.name} readOnly/>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">Cluster: <span style={{color:'red'}} >*</span> </div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} placeholder="Silahkan isi" value={this.state.cluster} onChange={(e)=>this.setState({cluster:e.target.value})}/>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">Blok rumah detail: <span style={{color:'red'}} >*</span> </div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} placeholder="Silahkan isi" value={this.state.blok} onChange={(e)=>this.setState({blok:e.target.value})}/>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.phone} <span style={{color:'red'}} >*</span></div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} value={this.state.phonenumber} readOnly/>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.office} <span style={{color:'red'}} >*</span></div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} value={this.state.office} placeholder="Silahkan isi" onChange={(e)=>this.setState({office:e.target.value})} />
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.visitedduring} <span style={{color:'red'}} >*</span></div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} value={this.state.cityvisited} placeholder="Silahkan isi" onChange={(e)=>this.setState({cityvisited:e.target.value})} />
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.approached} <span style={{color:'red'}} >*</span></div>
                            <div style={{backgroundColor:'rgb(250, 250, 250)', padding:4}}>
                                <div className="label-radiobutton" >
                                    <input type="radio" value='Y' id='Y1' checked={this.state.combo1 === 'Y'} onChange={this.handleOptionRadioChange1.bind(this)} />
                                    <label style={{fontSize:13}}  for='Y1'>Ya</label>
                                </div>
                                <div className="label-radiobutton" >
                                    <input type="radio" value='N' id='N1' checked={this.state.combo1 === 'N'} onChange={this.handleOptionRadioChange1.bind(this)} />
                                    <label style={{fontSize:13}} for='N1'>Tidak</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.contactedpeople} <span style={{color:'red'}} >*</span></div>
                            <div style={{backgroundColor:'rgb(250, 250, 250)', padding:4}}>
                                <div className="label-radiobutton" >
                                    <input type="radio" value='Y' id='Y2' checked={this.state.combo2 === 'Y'} onChange={this.handleOptionRadioChange2.bind(this)} />
                                    <label style={{fontSize:13}}  for='Y2'>Ya</label>
                                </div>
                                <div className="label-radiobutton" >
                                    <input type="radio" value='N' id='N2' checked={this.state.combo2 === 'N'} onChange={this.handleOptionRadioChange2.bind(this)} />
                                    <label style={{fontSize:13}} for='N2'>Tidak</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.havefever} <span style={{color:'red'}} >*</span></div>
                            <div style={{backgroundColor:'rgb(250, 250, 250)', padding:4}}>
                                <div className="label-radiobutton" >
                                    <input type="radio" value='Y' id='Y3' checked={this.state.combo3 === 'Y'} onChange={this.handleOptionRadioChange3.bind(this)}/>
                                    <label style={{fontSize:13}}  for='Y3'>Ya</label>
                                </div>
                                <div className="label-radiobutton" >
                                    <input type="radio" value='N' id='N3' checked={this.state.combo3 === 'N'} onChange={this.handleOptionRadioChange3.bind(this)}/>
                                    <label style={{fontSize:13}} for='N3'>Tidak</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.hasrelative} <span style={{color:'red'}} >*</span></div>
                            <div style={{backgroundColor:'rgb(250, 250, 250)', padding:4}}>
                                <div className="label-radiobutton" >
                                    <input type="radio" value='Y' id='Y4' checked={this.state.combo4 === 'Y'} onChange={this.handleOptionRadioChange4.bind(this)}/>
                                    <label style={{fontSize:13}}  for='Y4'>Ya</label>
                                </div>
                                <div className="label-radiobutton" >
                                    <input type="radio" value='N' id='N4' checked={this.state.combo4 === 'N'} onChange={this.handleOptionRadioChange4.bind(this)}/>
                                    <label style={{fontSize:13}} for='N4'>Tidak</label>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.medical} <span style={{color:'red'}} >*</span></div>
                            <div style={{backgroundColor:'rgb(250, 250, 250)', padding:4}}>
                                <div className="label-radiobutton" >
                                    <input type="radio" value='Y' id='Y5' checked={this.state.combo5 === 'Y'} onChange={this.handleOptionRadioChange5.bind(this)}/>
                                    <label style={{fontSize:13}}  for='Y5'>Ya</label>
                                </div>
                                <div className="label-radiobutton" >
                                    <input type="radio" value='N' id='N5' checked={this.state.combo5 === 'N'} onChange={this.handleOptionRadioChange5.bind(this)}/>
                                    <label style={{fontSize:13}} for='N5'>Tidak</label>
                                </div>
                            </div>
                        </div>
                    </div> 
 
                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.specify}</div>
                            <div className="field-value">
                                <textarea value={this.state.note} style={{fontSize:13}} onChange={(e)=>this.setState({note:e.target.value})} rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                    <Container style={{marginTop:20, marginBottom:40}} >
                        <Row>
                            <Col xs="6">
                                <Button  onClick={() => this.goToHealthDeclaration()} style={{backgroundColor:'#d0d1d3', color:'#000', textAlign:'center', width:'100%', fontSize:13 }} >
                                    <span>{this.language.cancel}</span>
                                </Button>
                            </Col>
                            <Col xs="6">
                                <Button  onClick={() => this.checkData()} style={{backgroundColor:'rgb(117, 15, 233)', textAlign:'center', width:'100%', fontSize:13 }} >
                                    <span>{this.language.determine}</span>
                                </Button>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </div>
        )
    }

}
export default HealthFormFestivalSpring;