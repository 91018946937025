import React, { Component } from 'react';
import { getLanguage } from '../../../Languages';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button, Jumbotron, Container 
} from 'reactstrap';
import comingsoon from '../../../images/comingsoon.jpg';
import axios from 'axios';
import './style.css';
import { webserviceurl, activelanguage } from '../../../Config';
import SubHeader from '../../Component/SubHeader/SubHeader';
import "../../../../node_modules/video-react/dist/video-react.css";
import { Player } from 'video-react';
import LocationPicker from 'react-location-picker';
import Geosuggest from 'react-geosuggest';
import { GoogleComponent } from 'react-google-location';


/* Default position */
const defaultPosition = {
    lat: -6.1703683,
    lng: 106.9509767
};


const API_KEY = "AIzaSyCSV1AmlA-ArhLJz9u0TXS8zQEXt5wmdAU";



class TrafficCamera extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: "",
            redirect: false,
            community: props.match.params.communityid,
            position: {},
            addressdetail:'',
            defaultPosition: defaultPosition,
            address:'Jl. Raya Cakung Cilincing KM 0, RT.8/RW.5, Cakung Tim., Kec. Cakung, Jakarta, Daerah Khusus Ibukota Jakarta 13910',
            place: null,

        }

        this.language = getLanguage(activelanguage, 'trafficcamera');
        this.handleLocationChange = this.handleLocationChange.bind(this);
        
    }

    handleLocationChange ({ position, address }) {
        // Set new location
        this.setState({ position, address });
      }


    componentDidMount=()=>{
        this.waitForBridge();

        navigator && navigator.geolocation.getCurrentPosition(position => {
            const { latitude, longitude } = position.coords;
            this.setState({
              defaultPosition: {
                lat: latitude,
                lng: longitude
              }
            });

            console.log(defaultPosition.lat);
            console.log(defaultPosition.lng);
          });


    }

   


    waitForBridge() {

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
            window.postMessage(param, "*");
        }
    }


    onSuggestSelect(suggest) {
        console.log(suggest);
      }

      onSuggestNoResults(userInput) {
        console.log('onSuggestNoResults for :' + userInput);
      }




    
    render() {
          
        return (
            <div>
                <SubHeader history={this.props.history} hideSearch={true} title={this.language.title}/>
                
                {/* <div className="service-center-form">
                <div className="field-container">
                    <div className="field-label">Set Location</div>
                    <div>
                    <GoogleComponent
                        apiKey={API_KEY}
                        language={'en'}
                        country={'country:in|country:us'}
                        coordinates={true}
                        placeholder={'Start typing location'}
                        // locationBoxStyle={'custom-style'}
                        // locationListStyle={'custom-style-list'}
                        onChange={(e) => { this.setState({ place: e }) }} />
                    </div>
                </div>
                <div className="field-container">
                        <div className="field-label">Set Map Location</div>
                            <div className="field-value">
                                <LocationPicker
                                    containerElement={ <div style={ {height: '100%'} } /> }
                                    mapElement={ <div style={ {height: '250px'} } /> }
                                    defaultPosition={this.state.defaultPosition}
                                    radius={-1}
                                    onChange={this.handleLocationChange}
                                />
                            </div>
                        </div>
                        <div className="field-container">
                        <div className="field-label">Location</div>
                            <div className="field-value">
                                <textarea rows="4" placeholder={this.language.inputaddress} value={this.state.address} disabled="disabled" onChange={(event)=>this.setState({address: event.target.value})}></textarea>
                            </div>
                        </div>
                        <div className="field-container">
                        <div className="field-label">Addres Detail</div>
                            <div className="field-value">
                                <textarea rows="4" placeholder="Please input your detail address" value={this.state.addressdetail}  onChange={(event)=>this.setState({addressdetail: event.target.value})}></textarea>
                            </div>
                        </div>
                </div> */}


                <div style={{marginTop:150}}>
                    <h1 style={{textAlign:'center', color:'#1273de', fontWeight:'bold', fontfamily: 'sans-serif' }} className="display-7">COMING SOON!</h1>
                </div>
            </div>
        );
    }
}

export default TrafficCamera;