import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './FoodGardenListItem.style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLanguage } from '../../../Languages';
import { activelanguage } from '../../../Config';

class FoodGardenListItem extends Component {

    constructor(props) {
        super(props);
        let monthName = '';

        this.globallang = getLanguage(activelanguage, 'global');
        this.language = getLanguage(activelanguage, 'foodgardencenterlist');

        this.state = { 
            name : props.name, 
            shortdesc: props.shortdesc,
            tags: props.tags === undefined ? [] : props.tags,   //array
            location: props.location,
            phonenumber: props.phonenumber,
            picture: props.picture
        };
    }

    componentDidMount=()=>{
		
    }

    componentWillReceiveProps=(props)=>{
        this.setState({
			name : props.name, 
            shortdesc: props.shortdesc,
            tags: props.tags === undefined ? [] : props.tags,   //array
            location: props.location,
            phonenumber: props.phonenumber,
            picture: props.picture
			});
    }


    renderCategory=()=>{
        if(this.state.category !== undefined && this.state.category !== ''){
            return (
                <div className="category">{this.state.category}</div>
            )
        }
    }

    renderPicture=()=>{
        if(this.state.picture !== ''){
            return (
                <div className="imgitem-container">
                    <img src={this.state.picture} alt={'imgpic'}/>
                </div>
            )
        }else{
            return (
                <div className="imgitem-container">
                    <img src={require('../../../images/default_food.png')} alt={'imgpic'} className="imgdefault"/>
                </div>
            )
        }
    }
    render() {
        return(
            <div className="list-item-food">
                <div className="relative-container">
                    {this.renderPicture()}
                    <div className="data-container">
                        <div className="name-container" style={{color:'#000', fontWeight:'bold', fontSize:16}} > {this.state.name}</div>
                        <div className="shortdesc-container" style={{fontSize:13}} dangerouslySetInnerHTML={{__html: this.state.location}}></div>
                        <div className="tags-container" >
                            {
                                this.state.tags.map((tag,i)=>{
                                    if(tag !== '')
                                        return <div key={i} className="tag" style={{color:'#000'}} >{tag}</div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FoodGardenListItem;