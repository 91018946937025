import React, { Component } from 'react';
import { getLanguage } from '../../../../Languages';
import { webserviceurl, activelanguage } from '../../../../Config';
import Iframe from 'react-iframe';
import SubHeader from '../../../Component/SubHeader/SubHeader';

class PaymentForm extends Component{

    constructor(props) {
        super(props);
        this.state = {
            page: "",
            urlXendit: decodeURIComponent(props.match.params.xenditId),
            redirect: false,
			list: [],
            icons: []
        }

        this.language = getLanguage(activelanguage, 'selectpayment');
        this.listel = null;
    }

    componentDidMount=()=>{
        this.waitForBridge();
    }

    componentWillUnmount=()=>{
        this.listel.removeEventListener('scroll', (e)=>{
            this.scrollCheck();
        });
    }
	
	scrollCheck = ()=>{
        if(this.listel.scrollTop + this.listel.clientHeight >= this.listel.scrollHeight){
            if(this.state.loadPaymentFinished == false){
				this.loadPayment(this.state.currentCategory, this.state.start);
			}
        }
    }

    createMarkup = (content) => {
        return { __html: content };
    }

    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            // let param = '{"title":"' + this.language.title + '","canGoBack":false}';
            let param = '{"title":"' + this.language.title + '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
            window.postMessage(param, "*");
        }
    }

    goBack=()=>{

        let param = '{"code":"need-home"}';
        this.sendPostMessage(param);
    }

    sendPostMessage=(param)=>{
        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.sendPostMessage(param);
            }.bind(this), 200);
        }
        else {
            window.postMessage(param, "*");
        }
    }

    

    render(){
        if (this.state.redirect) {
            /*if (this.state.page == "newspage") {
                return <Redirect to='/news' />;
            }*/
        }

        // const idUrl = "https://invoice-staging.xendit.co/web/invoices/"+this.state.urlXendit;
        
        const idUrl = "https://invoice.xendit.co/web/invoices/"+this.state.urlXendit;
        return (
            <div className="main-container">
                <SubHeader history={this.props.history} hideSearch={true} title={"Payment Method"} goBack={this.goBack}/>
                <div className="iframe-container" >
                    {/* <iframe src="https://invoice-staging.xendit.co/web/invoices/5d6cba7de2506b074026a2ad"></iframe> */}
                    <Iframe height="100%" width="100%" position="absolute" url={idUrl}/>
                </div>
            </div>
        );
    }
}
export default PaymentForm; 