import React, { Component } from 'react';
import {Button, Form, FormGroup, Label, Text, Input, Container, Row, Col } from 'reactstrap';
import ReactModal from 'react-modal';

class ListDebtor extends Component{
    constructor(props){
        super(props)
        this.state ={
            
        }
    }


    render(){
        return(
            <ReactModal>
            </ReactModal>
        )
    }

}
export default ListDebtor; 