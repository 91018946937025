import React, { Component } from "react";
//import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from "../../../Languages";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Card,
  CardTitle,
  CardBody,
  CardText,
} from "reactstrap";
import axios from "axios";
import "./ServiceCenterForm.style.css";
import { webserviceurl, activelanguage } from "../../../Config";
import SubHeader from "../../Component/SubHeader/SubHeader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { convertToRupiah, makeid } from "../../../Global";
import ServiceCenterList from "../../Component/ServiceCenterList/ServiceCenterList";
import { confirmAlert } from "react-confirm-alert";
import LocationPicker from "react-location-picker";
import { GoogleComponent } from "react-google-location";

/* Default position */
const defaultPosition = {
  lat: -6.1703683,
  lng: 106.9509767,
};

const API_KEY = "AIzaSyCSV1AmlA-ArhLJz9u0TXS8zQEXt5wmdAU";

class ServiceCenterForm extends Component {
  constructor(props) {
    super(props);
    this.language = getLanguage(activelanguage, "servicecenterform");
    this.globallang = getLanguage(activelanguage, "global");
    this.handleLocationChange = this.handleLocationChange.bind(this);

    this.state = {
      page: "",
      redirect: false,
      servicecentercategoryid: props.match.params.servicecentercategoryid,
      servicecenter: [],
      servicecenterid: "",
      servicecentername: "",
      title: "New Service Request",
      userserviceid: 0,
      status: 0,
      statusservice: 0,
      userservicedetailid: 0,
      address: "",
      counterprice: 0,
      requestdate: new Date(),
      hourin: 9,
      minutein: 0,
      hourout: 9,
      minuteout: 0,
      note: "",
      communityid: 22,
      phoneno: "",
      name: "",
      email: "",
      CompanyCode: "MDL",
      currentPage: 0,
      dataXendit: [],
      position: {},
      addressdetail: "",
      defaultPosition: defaultPosition,
      modalIsOpen: false,
      paymentmethod: "CASH",
      qty: 0,
      servicecenterListItem: [],
    };

    this.paymentList = [{ value: "CASH", display: "Cash" }];
  }

  componentDidMount = () => {
    this.waitForBridge();
    this.loadServiceCenter();
    this.loadUser();

    navigator &&
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        this.setState({
          defaultPosition: {
            lat: latitude,
            lng: longitude,
          },
        });

        console.log(defaultPosition.lat);
        console.log(defaultPosition.lng);
      });
  };

  loadServiceCenter = () => {
    axios
      .post(
        webserviceurl + "/app_load_servicecenterlist.php",
        {
          servicecentercategoryid: this.state.servicecentercategoryid,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((response) => {
        let result = response.data;

        if (result.status === "OK") {
          this.setState({ servicecenter: result.records });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let param =
        '{"title":"' +
        this.language.title +
        '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
      window.postMessage(param, "*");
    }
  }

  loadUser = () => {
    if (
      localStorage.getItem("smart-app-id-login") != undefined &&
      localStorage.getItem("smart-app-id-login") != "undefined"
    ) {
      var com = window.localStorage.getItem("smart-app-id-login");
      var login = JSON.parse(com);

      this.setState({
        phoneno: login.phonenumber === undefined ? "" : login.phonenumber,
      });
      this.setState({ name: login.name === undefined ? "" : login.name });
      this.setState({ email: login.email === undefined ? "" : login.email });
    }
  };

  createMarkup = (content) => {
    return { __html: content };
  };

  updateQty = (newValue, servicecenterid) => {
    let servicecenter = this.state.servicecenter;
    let theaddon = {};
    let tempcounter = 0;
    let tempprice = 0;
    let price = 0;
    for (let i = 0; i < servicecenter.length; i++) {
      if (servicecenter[i].servicecenterid === servicecenterid) {
        theaddon = servicecenter[i];
        servicecenter[i].qty = newValue;
      }
      tempcounter += servicecenter[i].qty;

      tempprice += servicecenter[i].qty * servicecenter[i].price;
    }

    let servicecenterListItem = [];
    var i = 0;
    servicecenter.map((idx, val) => {
      if (idx.qty > 0) {
        servicecenterListItem.push({
          servicecenterid: idx.servicecenterid,
          servicecentername: idx.servicecentername,
          price: idx.price,
          qty: idx.qty,
        });
      }
      i++;
    });

    console.log(servicecenterListItem);
    this.setState({ servicecenterListItem: servicecenterListItem });
    this.setState({ servicecenter: servicecenter });
    this.setState({ qty: tempcounter });
    this.setState({ counterprice: tempprice });
  };

  updateDate = (date) => {
    this.setState({ requestdate: date });
  };

  handleHourInChange(e) {
    this.setState({
      hourin: e.target.value,
    });
  }

  handleHourOutChange(e) {
    this.setState({
      hourout: e.target.value,
    });
  }

  handleMinuteInChange(e) {
    this.setState({
      minutein: e.target.value,
    });
  }

  handleMinuteOutChange(e) {
    this.setState({
      minuteout: e.target.value,
    });
  }

  handleLocationChange({ position, address }) {
    // Set new location
    this.setState({ position, address });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  paymentMethodChange(e) {
    this.setState({
      paymentmethod: e.target.value,
    });
  }

  doSubmit = () => {
    if (this.state.qty === 0) {
      return confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div>
              <Card className="card-alert">
                <CardTitle className="card-title">
                  <span>Information!</span>
                </CardTitle>
                <CardBody className="card-body">
                  <CardText className="card-text">
                    Please choose our service menu.
                  </CardText>
                  <table>
                    <tr style={{ color: "#fff", textAlign: "right" }}>
                      <td></td>
                      <td>
                        <a className="link-button" onClick={onClose}>
                          OK
                        </a>
                      </td>
                    </tr>
                  </table>
                </CardBody>
              </Card>
            </div>
          );
        },
      });
    }

    if (this.state.qty !== 0) {
      return confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div>
              <Card className="card-alert">
                <CardTitle className="card-title">
                  <span>Confirmation!</span>
                </CardTitle>
                <CardBody className="card-body">
                  <CardText className="card-text">
                    {this.language.areyousure}
                  </CardText>
                  <table>
                    <tr style={{ color: "#fff", textAlign: "center" }}>
                      <td>
                        <a
                          className="link-button"
                          onClick={() => {
                            this.handleClickOk();
                            onClose();
                          }}
                        >
                          {this.globallang.submit}
                        </a>
                      </td>
                      <td>
                        <a className="link-button" onClick={onClose}>
                          {this.globallang.cancel}
                        </a>
                      </td>
                    </tr>
                  </table>
                </CardBody>
              </Card>
            </div>
          );
        },
      });
    }
  };

  handleClickOk = () => {
    var request_et = "";

    var i = new Date(this.state.requestdate);
    var yearin = i.getFullYear();
    var monthin = i.getMonth();
    var datein = i.getDate();

    request_et =
      yearin +
      "-" +
      (monthin + 1) +
      "-" +
      datein +
      " " +
      this.state.hourin +
      ":" +
      this.state.minutein +
      ":00";

    let body = {
      userserviceid: this.state.userserviceid,
      phoneno: this.state.phoneno,
      note: this.state.note,
      status: this.state.status,
      statusservice: this.state.statusservice,
      communityid: this.state.communityid,
      requestdate: request_et,
      price: this.state.counterprice,
      servicecentercategoryid: this.state.servicecentercategoryid,
      servicecenter: this.state.servicecenterListItem,
      external_id: "INV/SC/" + makeid(10),
      amount: this.state.counterprice,
      payer_email: this.state.email,
      description: "Invoice Service Center",
      address: this.state.address,
      // address: this.state.address.place,
      // coordinates_lat: this.state.place.coordinates.lat,
      // coordinates_long: this.state.place.coordinates.lng,
      addressdetail: this.state.addressdetail,
      payment_method: this.state.paymentmethod,
      payment_channel: this.state.paymentmethod,
    };

    console.log(body);
    axios
      .post(webserviceurl + "/app_insert_servicecenter_request.php", body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => {
        console.log(response);
        let result = response.data;
        if (result.status === "OK") {
          this.props.history.push("/servicecenter");
        } else {
          alert(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  doNext = (toIdx, currIdx) => {
    if (toIdx < 2) {
      if (currIdx === 0) {
        // if(this.state.address === ''){
        //     confirmAlert({
        //         customUI: ({ onClose }) => {
        //             return (
        //                 <div>
        //                    <Card className="card-alert">
        //                         <CardTitle className="card-title"  >
        //                             <span>Information!</span>
        //                         </CardTitle>
        //                         <CardBody className="card-body">
        //                             <CardText className="card-text" >Please set your location</CardText>
        //                                 <table>
        //                                     <tr style={{color:'#fff', textAlign:'right'}} >
        //                                         <td>
        //                                         </td>
        //                                         <td>
        //                                             <a className="link-button" onClick={onClose}>OK</a>
        //                                         </td>
        //                                     </tr>
        //                                 </table>
        //                         </CardBody>
        //                     </Card>
        //                 </div>
        //             )
        //         }
        //     })
        //     return false;
        // }

        if (this.state.addressdetail === "") {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div>
                  <Card className="card-alert">
                    <CardTitle className="card-title">
                      <span>Information!</span>
                    </CardTitle>
                    <CardBody className="card-body">
                      <CardText className="card-text">
                        Please input your detail address
                      </CardText>
                      <table>
                        <tr style={{ color: "#fff", textAlign: "right" }}>
                          <td></td>
                          <td>
                            <a className="link-button" onClick={onClose}>
                              OK
                            </a>
                          </td>
                        </tr>
                      </table>
                    </CardBody>
                  </Card>
                </div>
              );
            },
          });
          return false;
        }
      }
      this.setState({ currentPage: toIdx });
    }
  };

  renderPage = () => {
    if (this.state.currentPage === 0) {
      return (
        <div className="service-center-form1">
          <div className="field-container">
            <div className="field-label">{this.language.user}</div>
            <div className="field-value">
              <Input type="text" value={this.state.name} disabled="disabled" />
            </div>
          </div>
          <div className="field-container">
            <div className="field-label">{this.language.requestdate}</div>
            <div className="field-value">
              {/* <DatePicker selected={this.state.requestdate} onChange={this.updateDate} withPortal disabledKeyboardNavigation={true} className="date-picker" /> */}
              <DatePicker
                selected={this.state.requestdate}
                onChange={this.updateDate}
                dateFormat="dd/MM/yyyy"
                locale="enUS"
                withPortal
                popperClassName="some-custom-class"
                popperPlacement="bottom-end"
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: "5px, 0px",
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: "viewport",
                  },
                }}
                className="date-picker"
              />
            </div>
          </div>
          <div className="field-container">
            <div className="field-label">{this.language.timecheckin}</div>
            <div className="field-value">
              <div className="time">
                <table>
                  <tr>
                    <td>
                      <select
                        onChange={this.handleHourInChange.bind(this)}
                        value={this.state.hourin}
                      >
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                      </select>
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                    <td>
                      <select
                        onChange={this.handleMinuteInChange.bind(this)}
                        value={this.state.minutein}
                      >
                        <option value="00">00</option>
                      </select>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="field-container">
            <div className="field-label">Set Your Location</div>
            <div style={{ marginTop: 10 }}>
              <GoogleComponent
                apiKey={API_KEY}
                language={"id"}
                country={"country:ID"}
                coordinates={true}
                placeholder={"Deliver to"}
                onChange={(e) => {
                  this.setState({ address: e });
                }}
              />
            </div>
          </div>

          <div className="field-container">
            <div className="field-label">Addres Detail</div>
            <div className="field-value">
              <textarea
                rows="3"
                placeholder="Please input your detail address"
                value={this.state.addressdetail}
                onChange={(event) =>
                  this.setState({ addressdetail: event.target.value })
                }
              ></textarea>
            </div>
          </div>
          <div
            className="next-button-service"
            onClick={() =>
              this.doNext(this.state.currentPage + 1, this.state.currentPage)
            }
          >
            Next
          </div>
        </div>
      );
    } else if (this.state.currentPage === 1) {
      return (
        <div className="service-center-form">
          <div className="form-container">
            <div className="continer-request-service">
              <div className="payment-method">
                <span>Service List</span>
              </div>
              <div className="field-container">
                <div className="field-value">
                  {this.state.servicecenter.map((item, i) => (
                    <ServiceCenterList
                      key={i}
                      servicecenterid={item.servicecenterid}
                      servicecentername={item.servicecentername}
                      price={item.price}
                      qty={item.qty}
                      updateQty={this.updateQty}
                    />
                  ))}
                </div>
              </div>
              <div className="field-container">
                <div className="field-label">{this.language.note}</div>
                <div className="field-value">
                  <textarea
                    rows="3"
                    placeholder={this.language.inputnote}
                    value={this.state.note}
                    onChange={(event) =>
                      this.setState({ note: event.target.value })
                    }
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="continer-payment-method-service">
              <div className="payment-method">
                <span>Payment Method</span>
              </div>
              <div className="payment-select-method">
                <select
                  onChange={(e) =>
                    this.setState({ paymentmethod: e.target.value })
                  }
                  value={this.state.paymentmethod}
                >
                  {this.paymentList.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.display}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  renderNext = () => {
    if (this.state.currentPage < 1) {
      return null;
    } else {
      return (
        <div className="submit-button">
          <table>
            <tr>
              <td>
                <div className="field-label">Price Estimate</div>
              </td>
              <td>&nbsp;&nbsp;</td>
              <td></td>
            </tr>
            <tr>
              <td>
                <Input
                  style={{ color: "#02ae41", fontSize: 16, fontWeight: "bold" }}
                  disabled="disabled"
                  value={convertToRupiah(this.state.counterprice)}
                />
              </td>
              <td>&nbsp;&nbsp;</td>
              <td>
                <Button color="success" onClick={() => this.doSubmit()} block>
                  Submit
                </Button>
              </td>
            </tr>
            <tr>
              <text></text>
            </tr>
          </table>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="main-container">
        <SubHeader
          history={this.props.history}
          hideSearch={true}
          title={this.state.title}
        />
        {this.renderPage()}
        {this.renderNext()}
      </div>
    );
  }
}

export default ServiceCenterForm;
