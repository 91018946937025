import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getLanguage } from "../../../Languages";
import SubHeader from "../../Component/SubHeader/SubHeader";
import "./index.style.css";
import { webserviceurl, activelanguage } from "../../../Config";
import axios from "axios";
import LoadingScreen from "../../Component/LoadingScreen/LoadingScreen";
import SwipeableViews from "react-swipeable-views";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { id, enUS } from "date-fns/esm/locale";

class MerchantRegistrationScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Merchant Registration",
      phonenumber: "",
      name: "",
      tempat_lahir: "",
      datecheckin: new Date(),
      genders: [
        { id: 1, display: "Male" },
        { id: 2, display: "Female" },
      ],
      gender: "Male",
      ktp: "",
      nama_toko: "",
      jenis_usaha: "",
      alamat_usaha: "",
      npwp: "",
      numberphone: "",
      email: "",
      nomor_rekening: "",
      nama_bank: "",
      atas_namarekening: "",
      jam_oprational: "",
      delivers: [
        { id: 1, display: "By KOSMO" },
        { id: 2, display: "By Merchant" },
      ],
      pengaturan_delivery: "By KOSMO",
      showLoading: false,
      currentPage: 0,
    };
  }

  componentDidMount() {
    this.waitForBridge();
    this.loadUser();
  }

  componentWillUnmount = () => {};

  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let tmp = localStorage.getItem("smart-app-id-login");
      let needLogin = false;
      // let tmp2 = localStorage.getItem('smart-app-id-binding');
      // let needBinding = false;

      if (
        tmp == undefined ||
        tmp == null ||
        tmp == "null" ||
        tmp == "" ||
        tmp == "undefined"
      )
        needLogin = true;
      // else if(tmp2 == undefined || tmp2 == null || tmp2 == 'null' || tmp2 == '' ||tmp2 == 'undefined' ) needBinding = true;
      else {
        tmp = JSON.parse(tmp);
      }
      let param =
        '{"title":"' +
        this.state.title +
        '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true, "needLogin":' +
        (needLogin ? "true" : "false") +
        "}";
      window.postMessage(param, "*");
    }
  }

  loadUser = () => {
    let info = localStorage.getItem("smart-app-id-login");
    if (
      info != null &&
      info !== undefined &&
      info != "null" &&
      info != "" &&
      info != "undefined"
    ) {
      info = JSON.parse(info);
      this.setState({
        phonenumber: info.phonenumber,
      });
    } else {
      // this.setState({
      //   phonenumber: "082216825612",
      // });
    }
  };

  doCancel = () => {
    this.props.history.push("/");
  };

  updateCheckInDate = (date) => {
    this.setState({ datecheckin: date });
  };

  changeGender = (event) => {
    let genderData = this.state.genders.filter(
      (gender) => gender.display == event.target.value
    );
    this.setState({
      gender: event.target.value,
    });
  };

  changeDelivery = (event) => {
    this.setState({
      pengaturan_delivery: event.target.value,
    });
  };

  doCheckData = () => {
    const {
      name,
      tempat_lahir,
      ktp,
      nama_toko,
      jenis_usaha,
      alamat_usaha,
      numberphone,
      email,
      nomor_rekening,
      nama_bank,
      atas_namarekening,
      jam_oprational,
    } = this.state;

    if (
      name === "" ||
      tempat_lahir === "" ||
      ktp === "" ||
      nama_toko === "" ||
      jenis_usaha === "" ||
      alamat_usaha === "" ||
      numberphone === "" ||
      email === "" ||
      nomor_rekening === "" ||
      nama_bank === "" ||
      atas_namarekening === "" ||
      jam_oprational === ""
    ) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div>
              <Card className="card-alert">
                <CardTitle className="card-title">
                  <span>Information!</span>
                </CardTitle>
                <CardBody className="card-body">
                  <CardText className="card-text">
                    Please fill in all fields.
                  </CardText>
                  <table>
                    <tr style={{ color: "#fff", textAlign: "right" }}>
                      <td></td>
                      <td>
                        <a className="link-button" onClick={onClose}>
                          OK
                        </a>
                      </td>
                    </tr>
                  </table>
                </CardBody>
              </Card>
            </div>
          );
        },
      });
      return false;
    } else {
      this.doSubmit();
    }
  };

  doValidate = () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    let param = {
      phonenumber: this.state.phonenumber,
      date: date,
    };
    axios
      .post(webserviceurl + "/app_validation_questionnaire.php", param, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => {
        let result = response.data;
        if (result.status === "OK") {
          return confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div>
                  <Card className="card-alert">
                    <CardTitle className="card-title">
                      <span>Warning</span>
                    </CardTitle>
                    <CardBody className="card-body">
                      <CardText className="card-text">
                        You have filled out the questionnaire for today. You can
                        take the questionnaire again the next day. Thank you.
                      </CardText>
                      <table>
                        <tr style={{ color: "#fff", textAlign: "right" }}>
                          <td>
                            <a className="link-button" onClick={onClose}>
                              OK
                            </a>
                          </td>
                        </tr>
                      </table>
                    </CardBody>
                  </Card>
                </div>
              );
            },
          });
        } else {
          this.doSubmit();
        }
      })
      .catch((error) => {
        // this.setState({showLoading:false});
        console.log(error);
      });
  };

  doSubmit = () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var curentDate = new Date();
    var dateCurrent =
      curentDate.getFullYear() +
      "-" +
      (curentDate.getMonth() + 1) +
      "-" +
      curentDate.getDate() +
      " " +
      curentDate.getHours() +
      ":" +
      curentDate.getMinutes() +
      ":" +
      curentDate.getSeconds();

    let params = {
      phonenumber: this.state.phonenumber,
      dateCurrent: dateCurrent,
      name: this.state.name,
      tempat_lahir: this.state.tempat_lahir,
      datecheckin: this.state.datecheckin,
      gender: this.state.gender,
      ktp: this.state.ktp,
      nama_toko: this.state.nama_toko,
      jenis_usaha: this.state.jenis_usaha,
      alamat_usaha: this.state.alamat_usaha,
      npwp: this.state.npwp,
      numberphone: this.state.numberphone,
      email: this.state.email,
      nomor_rekening: this.state.nomor_rekening,
      nama_bank: this.state.nama_bank,
      atas_namarekening: this.state.atas_namarekening,
      jam_oprational: this.state.jam_oprational,
      pengaturan_delivery: this.state.pengaturan_delivery,
    };
    console.log(params);

    this.setState({ showLoading: true });
    axios
      .post(webserviceurl + "/app_insert_merchant_register.php", params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => {
        let result = response.data;
        console.log(result);
        if (result.status === "ok") {
          this.setState({ showLoading: false });
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div>
                  <Card className="card-alert">
                    <CardTitle className="card-title">
                      <span>Information!</span>
                    </CardTitle>
                    <CardBody className="card-body">
                      <CardText className="card-text">
                        Thank you for registering your merchant, then it will be
                        processed by customer service.
                      </CardText>
                      <table>
                        <tr style={{ color: "#fff", textAlign: "right" }}>
                          <td></td>
                          <td>
                            <a className="link-button" onClick={onClose}>
                              OK
                            </a>
                          </td>
                        </tr>
                      </table>
                    </CardBody>
                  </Card>
                </div>
              );
            },
          });
          this.props.history.push("/");
        }
      })
      .catch((error) => {
        this.setState({ showLoading: false });
        console.log(error);
      });
  };

  renderLoading = () => {
    if (this.state.showLoading) {
      return <LoadingScreen></LoadingScreen>;
    }
  };

  doNext = (toIdx, currIdx) => {
    if (toIdx < 2) {
      if (currIdx === 0) {
      }
      this.setState({ currentPage: toIdx });
    }
  };

  renderPage = () => {
    if (this.state.currentPage === 0) {
      return (
        <div className="directory-all-list-section-data">
          <div className="form-container">
            <div className="field-container">
              <div
                style={{
                  fontSize: 13,
                  justifyContent: "center",
                  textAlign: "justify",
                }}
              >
                Punya bisnis usaha di wilayah JGC? Bingung mempromosikannya
                secara online sehingga tepat sasaran? Bergabunglah bersama KOSMO
                Sebagai salah satu bagian Merchant tanpa ragu -ragu. Bersama
                KOSMO bikin omsetmu meningkat karena kemudahan dan kenyamanan
                bisnismu menjadi prioritas kami. Kami siap membantu, daftar
                sekarang dan team kami akan menghubungi Anda.
              </div>
            </div>
          </div>
          <ul
            style={{
              listStyleType: "square",
              fontSize: 13,
            }}
          >
            <li>Daftar GRATIS</li>
            <li>
              FREE PROMO IKLAN di seluruh platfom App KOSMO selama 3 bulan
            </li>
            <li>FREE TRIAL 3 bulan tanpa pemotongan komisi</li>
            <li>
              Potongan komisi relatif lebih kecil 15% setelah masa FREE TRIAL 3
              bulan
            </li>
            <li>Market apa aja (Food, Service Ac, Laundri, Jasa dll )</li>
            <li>Terima orderan melalui WA</li>
          </ul>
          <div
            className="next-button-service"
            onClick={() =>
              this.doNext(this.state.currentPage + 1, this.state.currentPage)
            }
          >
            Next
          </div>
        </div>
      );
    } else if (this.state.currentPage === 1) {
      return (
        <div className="directory-all-list-section-data">
          <div className="form-container">
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Name:
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  placeholder="Please fill in"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: -20 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Place of birth (Tempat lahir):
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  placeholder="Please fill in"
                  value={this.state.tempat_lahir}
                  onChange={(e) =>
                    this.setState({ tempat_lahir: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: -20 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Date of Birth & Year (Tanggal Lahir & Tahun):
              </div>
              <div className="field-value">
                <DatePicker
                  selected={this.state.datecheckin}
                  onChange={this.updateCheckInDate}
                  dateFormat="dd/MM/yyyy"
                  locale="id"
                />
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: -20 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Gender (Jenis Kelamin):
              </div>
              <div className="field-value">
                <select value={this.state.gender} onChange={this.changeGender}>
                  {this.state.genders.map((genders, i) => (
                    <option value={genders.display}>{genders.display}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: 0 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                No KTP:
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  placeholder="Please fill in"
                  value={this.state.ktp}
                  onChange={(e) => this.setState({ ktp: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: -20 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Name of business / shop (Nama Usaha / Toko ):
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  placeholder="Please fill in"
                  value={this.state.nama_toko}
                  onChange={(e) => this.setState({ nama_toko: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: -20 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Type of Business (Jenis Usaha):
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  placeholder="Please fill in"
                  value={this.state.jenis_usaha}
                  onChange={(e) =>
                    this.setState({ jenis_usaha: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: -20 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Address of Business Place (Alamat Tempat Usaha):
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  placeholder="Please fill in"
                  value={this.state.alamat_usaha}
                  onChange={(e) =>
                    this.setState({ alamat_usaha: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: -20 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                NPWP:
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  placeholder="Please fill in"
                  value={this.state.npwp}
                  onChange={(e) => this.setState({ npwp: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: -20 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Phone / Whatsapp Number:
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  placeholder="Please fill in"
                  value={this.state.numberphone}
                  onChange={(e) =>
                    this.setState({ numberphone: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: -20 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Email:
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  placeholder="Please fill in"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: -20 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Disbursement No (No Rek Pencairan):
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  placeholder="Please fill in"
                  value={this.state.nomor_rekening}
                  onChange={(e) =>
                    this.setState({ nomor_rekening: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: -20 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Bank Name (Nama Bank):
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  placeholder="Please fill in"
                  value={this.state.nama_bank}
                  onChange={(e) => this.setState({ nama_bank: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: -20 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Name of Account (Nama Rekening):
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  placeholder="Please fill in"
                  value={this.state.atas_namarekening}
                  onChange={(e) =>
                    this.setState({ atas_namarekening: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: -20 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Operational hour (Jam operasional):
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  placeholder="Please fill in"
                  value={this.state.jam_oprational}
                  onChange={(e) =>
                    this.setState({ jam_oprational: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: -20 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Delivery settings (Pengaturan Delivery):
              </div>
              <div className="field-value">
                <select
                  value={this.state.pengaturan_delivery}
                  onChange={this.changeDelivery}
                >
                  {this.state.delivers.map((del, i) => (
                    <option value={del.display}>{del.display}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <Container style={{ marginTop: 20, marginBottom: 40 }}>
            <Row>
              <Col xs="6">
                <Button
                  onClick={() => this.doCancel()}
                  style={{
                    backgroundColor: "#607D8B",
                    color: "#fff",
                    textAlign: "center",
                    width: "100%",
                    fontSize: 13,
                  }}
                >
                  <span>Cancel</span>
                </Button>
              </Col>
              <Col xs="6">
                <Button
                  onClick={() => this.doCheckData()}
                  style={{
                    backgroundColor: "#2196F3",
                    textAlign: "center",
                    width: "100%",
                    fontSize: 13,
                  }}
                >
                  <span>Submit</span>
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        <SubHeader
          history={this.props.history}
          hideSearch={true}
          title={this.state.title}
        />
        {this.renderPage()}
        {this.renderLoading()}
      </div>
    );
  }
}
export default MerchantRegistrationScreen;
