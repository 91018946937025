import React, { Component } from 'react';
//import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import axios from 'axios';
import './HealthFormDailyDeclaration.style.css';
import { webserviceurl, activelanguage } from '../../../Config';
import SubHeader from '../../Component/SubHeader/SubHeader';
import {
    Card, CardImg, CardText, CardBody, CardLink,
    CardTitle, CardSubtitle,Button, Container, Row, Col, Input
} from 'reactstrap';
// import Logo from '../../../images/white.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; 


class HealthFormDailyDeclaration extends Component {

    constructor(props) {
        super(props);
        this.language = getLanguage(activelanguage, 'dailydeclaration');
        this.globallang = getLanguage(activelanguage, 'global');

        this.state = {
			phonenumber:props.match.params.phonenumber,
            date:'',
            name:'',
            company:'',
            cluster:'',
            blok:'',
            city:'',
            combo1:0,
            combo2:0,
            combo3:0,
            combo4:0,
            note:'',
            page: "",
            redirect: false,
            title: this.language.title,
            radioSelectOption:1,
            DAILYCOMBO1:[],
            DAILYCOMBO2:[],
            DAILYCOMBO3:[],
            DAILYCOMBO4:[]
        }   
        
    }

    componentDidMount=()=>{
        this.waitForBridge();
        this.loadUser();
        this.loadDataByPhone();
        this.DAILYCOMBO1();
        this.DAILYCOMBO2();
        this.DAILYCOMBO3();
        this.DAILYCOMBO4();
    }

    componentWillUnmount=()=>{
        localStorage.setItem('health', '0');
    }
    
    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":true, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
            window.postMessage(param, "*");
        }
    }

    createMarkup = (content) => {
        return { __html: content };
    }

    loadUser = () => {
        let info = localStorage.getItem('smart-app-id-login');
         if(info != null && info !== undefined && info != 'null' && info != '' && info != 'undefined'){
         	info = JSON.parse(info);
         	this.setState({phonenumber: info.phonenumber, name: info.name});
         }


    }

    DAILYCOMBO1 = () => {
        axios.get(webserviceurl + '/app_get_combooptions.php?group=DAILYCOMBO1')
        .then((response) => {
            let result = response.data;
            console.log(result);
            if (result.status === "OK") {
                this.setState({ DAILYCOMBO1: result.records });
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }


    DAILYCOMBO2 = () => {
        axios.get(webserviceurl + '/app_get_combooptions.php?group=DAILYCOMBO2')
        .then((response) => {
            let result = response.data;
            console.log(result);
            if (result.status === "OK") {
                this.setState({ DAILYCOMBO2: result.records });
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    DAILYCOMBO3 = () => {
        axios.get(webserviceurl + '/app_get_combooptions.php?group=DAILYCOMBO3')
        .then((response) => {
            let result = response.data;
            console.log(result);
            if (result.status === "OK") {
                this.setState({ DAILYCOMBO3: result.records });
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    DAILYCOMBO4 = () => {
        axios.get(webserviceurl + '/app_get_combooptions.php?group=DAILYCOMBO4')
        .then((response) => {
            let result = response.data;
            console.log(result);
            if (result.status === "OK") {
                this.setState({ DAILYCOMBO4: result.records });
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    loadDataByPhone = () => {
        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

        axios.post(webserviceurl + '/app_load_dailydeclaration_today2.php', {
            phonenumber: this.state.phonenumber,
            today:date
        },
        {headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {
                console.log(response);
                let result = response.data;
                console.log(result);
                if (result.status === "OK") {
                    this.setState({ 
                        phonenumber: result.records.phonenumber, 
                        name: result.records.name,
                        cluster: result.records.cluster,
                        blok: result.records.blok,
                        city: result.records.city,
                        combo1: result.records.combo1,
                        combo2: result.records.combo2,
                        combo3: result.records.combo3,
                        combo4: result.records.combo4,
                        note: result.records.note,

                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }



    handleOptionRadioChange1(i){
		this.setState({
			combo1: i
		})

		console.log(this.state.combo1);
    }

    handleOptionRadioChange2(i){
		this.setState({
			combo2: i
		})

		console.log(this.state.combo2);
    }

    handleOptionRadioChange3(i){
		this.setState({
			combo3: i
		})

		console.log(this.state.combo3);
    }

    handleOptionRadioChange4(i){
		this.setState({
			combo4: i
		})

		console.log(this.state.combo4);
    }
    
    goToHealthDeclaration=()=>{
        this.props.history.push('/healthdeclaration');
    }

    checkData = () => {
		const {phonenumber, name, cluster, blok, combo1, combo2, combo3, combo4, city, } = this.state;
		
		if(city == null || city  == '' || cluster  == '' || blok  == '' || combo1 == 0 || combo2 == 0 || combo3 == 0 || combo4 == 0 ){
            // alert("please fill the description");
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div>
                            <Card className="card-alert">
                                <CardTitle className="card-title"  >
                                    <span>Information!</span>
                                </CardTitle>
                                <CardBody className="card-body">
                                    <CardText className="card-text" >Silahkan isi the blank form first!</CardText>
                                        <table>
                                            <tr style={{color:'#fff', textAlign:'right'}} >
                                                <td>
                                                </td>
                                                <td>
                                                    <a className="link-button" onClick={onClose}>OK</a>
                                                </td>
                                            </tr>
                                        </table>
                                </CardBody>
                            </Card>
                        </div>
                    )
                }
            })
			return false;
		}
			
		else{
			this.doSubmit();
		}
    }
    


    doSubmit=()=>{

        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

        let params = {
            phonenumber:this.state.phonenumber,
            date: date,
            name:this.state.name,
            cluster:this.state.cluster,
            blok:this.state.blok,
            city:this.state.city,
            combo1:this.state.combo1,
            combo2:this.state.combo2,
            combo3:this.state.combo3,
            combo4:this.state.combo4,
            note:this.state.note

        }

        console.log(params);

        axios.post(webserviceurl + '/app_insert_dailydeclaration2.php', params,
        {headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {
                let result = response.data;
                console.log(result);
                if (result.status === "ok") {
                  confirmAlert({
                      customUI: ({ onClose }) => {
                          return (
                              <div>
                                <Card className="card-alert">
                                    <CardTitle className="card-title"  >
                                        <span>Information!</span>
                                    </CardTitle>
                                    <CardBody className="card-body">
                                        <CardText className="card-text" >{this.language.success}</CardText>
                                            <table>
                                                <tr style={{color:'#fff', textAlign:'right'}} >
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <a className="link-button" onClick={onClose}>OK</a>
                                                    </td>
                                                </tr>
                                            </table>
                                    </CardBody>
                                </Card>
                              </div>
                          )
                      }
                  })
                      this.props.history.push('/healthdeclaration');
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }

    goBack=()=>{
        this.props.history.push('/healthdeclaration')
    }

    render() {
        return(
            <div className="main-container">
                <SubHeader history={this.props.history} hideSearch={true} title={this.state.title} goBack={this.goBack} />
                <div className="daily-center-form">
                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.name} <span style={{color:'red'}} >*</span> </div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} value={this.state.name} readOnly/>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">Cluster: <span style={{color:'red'}} >*</span> </div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} placeholder="Silahkan isi" value={this.state.cluster} onChange={(e)=>this.setState({cluster:e.target.value})}/>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">Blok rumah detail: <span style={{color:'red'}} >*</span> </div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} placeholder="Silahkan isi" value={this.state.blok} onChange={(e)=>this.setState({blok:e.target.value})}/>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.cityoftheday} <span style={{color:'red'}} >*</span></div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} value={this.state.city} placeholder="Silahkan isi" onChange={(e)=>this.setState({city:e.target.value})} />
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.thefollowing} <span style={{color:'red'}} >*</span></div>
                            <div style={{backgroundColor:'rgb(250, 250, 250)', padding:4}}>
                                {this.state.DAILYCOMBO1.map((option,i)=>{
                                    return(
                                    <div className="label-radiobutton" >
                                            <input 
                                                type="radio" 
                                                checked={this.state.combo1 == option.id? true: false}
                                                id={option.id}
                                                key={option.id}
                                                onChange={this.handleOptionRadioChange1.bind(this,option.id)} 
                                                value={this.state.combo1 == option.id} 
                                            />
                                            <label style={{fontSize:13}} key={i} for={option.id}>{option.description}</label>
                                    </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.coughing} <span style={{color:'red'}} >*</span></div>
                            <div style={{backgroundColor:'rgb(250, 250, 250)', padding:4}}>
                                {this.state.DAILYCOMBO2.map((option,i)=>{
                                    return(
                                    <div className="label-radiobutton" >
                                            <input 
                                                type="radio" 
                                                checked={this.state.combo2 == option.id? true: false}
                                                key={option.id}
                                                id={option.id}
                                                onChange={this.handleOptionRadioChange2.bind(this,option.id)} 
                                                value={this.state.combo2 == option.id} 
                                            />
                                            <label style={{fontSize:13}} key={i} for={option.id}>{option.description}</label>
                                    </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.contactfriends} <span style={{color:'red'}} >*</span></div>
                            <div style={{backgroundColor:'rgb(250, 250, 250)', padding:4}}>
                                {this.state.DAILYCOMBO3.map((option,i)=>{
                                    return(
                                    <div className="label-radiobutton" >
                                            <input 
                                                type="radio" 
                                                checked={this.state.combo3 == option.id? true: false}
                                                key={option.id}
                                                onChange={this.handleOptionRadioChange3.bind(this,option.id)} 
                                                value={this.state.combo3 == option.id}
                                                id={option.id}
                                            />
                                            <label style={{fontSize:13}} key={i} for={option.id}>{option.description}</label>
                                    </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.havedcontacted} <span style={{color:'red'}} >*</span></div>
                            <div style={{backgroundColor:'rgb(250, 250, 250)', padding:4}}>
                                {this.state.DAILYCOMBO4.map((option,i)=>{
                                    return(
                                        <div className="label-radiobutton" >
                                                <input 
                                                    type="radio" 
                                                    checked={this.state.combo4 == option.id? true: false}
                                                    key={option.id}
                                                    onChange={this.handleOptionRadioChange4.bind(this,option.id)} 
                                                    value={this.state.combo4 == option.id}
                                                    id={option.id}
                                                />
                                                <label style={{fontSize:13}} key={i} for={option.id}>{option.description}</label>
                                        </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.note}</div>
                            <div className="field-value">
                                <textarea value={this.state.note} style={{fontSize:13}} onChange={(e)=>this.setState({note:e.target.value})} rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                    <Container style={{marginTop:20, marginBottom:40}} >
                        <Row>
                            <Col xs="6">
                                <Button  onClick={() => this.goToHealthDeclaration()} style={{backgroundColor:'#d0d1d3', color:'#000', textAlign:'center', width:'100%', fontSize:13 }} >
                                    <span>Cancel</span>
                                </Button>
                            </Col>
                            <Col xs="6">
                                <Button  onClick={() => this.checkData()} style={{backgroundColor:'rgb(117, 15, 233)', textAlign:'center', width:'100%', fontSize:13 }} >
                                    <span>Submit</span>
                                </Button>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </div>
        )
    }

}
export default HealthFormDailyDeclaration;