import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getLanguage } from "../../../Languages";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Container,
  Col,
  Row,
  Card,
  CardTitle,
  CardBody,
  CardText,
} from "reactstrap";
import axios from "axios";
import "./HistoryFoodOrderDetail.style.css";
import { webserviceurl, activelanguage } from "../../../Config";
import { convertToRupiah, makeid } from "../../../Global";
import SubHeader from "../../Component/SubHeader/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import LoadingScreen from "../../Component/LoadingScreen/LoadingScreen";

class HistoryFoodOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.language = getLanguage(activelanguage, "historyfoodorderdetail");
    this.globallang = getLanguage(activelanguage, "global");

    this.state = {
      page: "",
      redirect: false,
      orderid: props.match.params.orderid,
      data: {},
      dataOrderFood: [],
      dataOrderDrink: [],
      dataOrderOther: [],
      showLoading: false,
      calladmin: "+62 838-7059-7388",
    };
  }

  componentDidMount = () => {
    this.waitForBridge();
    this.loadHistoryFoodOrderDetail();
    // this.loadServiceCenterDetailList();
  };

  loadHistoryFoodOrderDetail = () => {
    this.setState({ showLoading: true });
    axios
      .post(
        webserviceurl + "/app_load_history_orderfooddetail.php",
        {
          orderid: this.state.orderid,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((response) => {
        let result = response.data;

        console.log(response);
        console.log(result);
        if (result.status === "OK") {
          let bln = "";

          switch (result.record.requestbln) {
            case 1:
              bln = this.globallang.january;
              break;
            case 2:
              bln = this.globallang.february;
              break;
            case 3:
              bln = this.globallang.march;
              break;
            case 4:
              bln = this.globallang.april;
              break;
            case 5:
              bln = this.globallang.may;
              break;
            case 6:
              bln = this.globallang.june;
              break;
            case 7:
              bln = this.globallang.july;
              break;
            case 8:
              bln = this.globallang.august;
              break;
            case 9:
              bln = this.globallang.september;
              break;
            case 10:
              bln = this.globallang.october;
              break;
            case 11:
              bln = this.globallang.november;
              break;
            case 12:
              bln = this.globallang.december;
              break;
          }
          result.record.monthname = bln;

          this.setState({ data: result.record });
          this.setState({ dataOrderFood: result.record.OrderFood });

          setTimeout(() => {
            this.setState({ showLoading: false });
          }, 500);
        }

        console.log(this.state.dataOrderFood);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let param =
        '{"title":"' +
        this.language.title +
        '","canGoBack":true, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
      window.postMessage(param, "*");
    }
  }

  createMarkup = (content) => {
    return { __html: content };
  };

  renderStatusDelivery = () => {
    if (this.state.data.deliveryById === 1) {
      return this.renderStatusOrderByZoom();
    } else {
      return this.renderStatusOrderByMerchant();
    }
  };

  renderStatusOrderByMerchant = () => {
    if (this.state.data.paid_amount === "0") {
      return (
        <span style={{ fontSize: 14, color: "#ff0018", fontWeight: "bold" }}>
          Waiting for payment
        </span>
      );
    } else if (this.state.data.paid_amount !== this.state.data.totalPayment) {
      return (
        <span style={{ fontSize: 14, color: "#6c757d", fontWeight: "bold" }}>
          Incomplete payment
        </span>
      );
    } else if (
      this.state.data.status_zoom === "" &&
      this.state.data.status_payment !== 0 &&
      this.state.data.paid_amount !== "0"
    ) {
      return (
        <span style={{ fontSize: 14, color: "#28a745", fontWeight: "bold" }}>
          on Process
        </span>
      );
    } else {
      return (
        <span style={{ fontSize: 14, color: "#007bff", fontWeight: "bold" }}>
          Done
        </span>
      );
    }
  };

  renderStatusOrderByZoom = () => {
    if (
      this.state.data.status_zoom === "" &&
      this.state.data.statuspayment === 0 &&
      this.state.data.paid_amount === "0"
    ) {
      return (
        <span style={{ fontSize: 14, color: "#ff0018", fontWeight: "bold" }}>
          Waiting for Payment
        </span>
      );
    } else if (
      this.state.data.status_zoom === "" &&
      this.state.data.statuspayment !== 0 &&
      this.state.data.paid_amount !== "0"
    ) {
      return (
        <span style={{ fontSize: 14, color: "#ff9933", fontWeight: "bold" }}>
          Finding Driver
        </span>
      );
    } else if (this.state.data.status_zoom === "finding driver") {
      return (
        <span style={{ fontSize: 14, color: "#ff9933", fontWeight: "bold" }}>
          Finding Driver
        </span>
      );
    } else if (this.state.data.status_zoom === "Available") {
      return (
        <span style={{ fontSize: 14, color: "#ff9933", fontWeight: "bold" }}>
          Finding Driver
        </span>
      );
    } else if (this.state.data.status_zoom === "On Delivery") {
      return (
        <span style={{ fontSize: 14, color: "#28a745", fontWeight: "bold" }}>
          Delivering
        </span>
      );
    } else if (this.state.data.status_zoom === "Offline") {
      return (
        <span style={{ fontSize: 14, color: "#6c757d", fontWeight: "bold" }}>
          Driver is offline
        </span>
      );
    } else if (this.state.data.status_zoom === "On Road") {
      return (
        <span style={{ fontSize: 14, color: "#ff9933", fontWeight: "bold" }}>
          On the way
        </span>
      );
    } else if (this.state.data.status_zoom === "Arriving") {
      return (
        <span style={{ fontSize: 14, color: "#ff9933", fontWeight: "bold" }}>
          The order arrived soon
        </span>
      );
    } else if (this.state.data.status_zoom === "Pickup") {
      return (
        <span style={{ fontSize: 14, color: "#28a745", fontWeight: "bold" }}>
          Pick Up Order
        </span>
      );
    } else if (this.state.data.status_zoom === "Receiving") {
      return (
        <span style={{ fontSize: 14, color: "#ff9933", fontWeight: "bold" }}>
          Receive orders
        </span>
      );
    } else if (this.state.data.status_zoom === "Canceled") {
      return (
        <span style={{ fontSize: 14, color: "#6c757d", fontWeight: "bold" }}>
          The order was not taken by drivers
        </span>
      );
    } else if (this.state.data.status_zoom === "Expired") {
      return (
        <span style={{ fontSize: 14, color: "#ff0018", fontWeight: "bold" }}>
          Expired order
        </span>
      );
    } else if (this.state.data.status_zoom === "Unavailable") {
      return (
        <span style={{ fontSize: 14, color: "#ff0018", fontWeight: "bold" }}>
          Unavailable
        </span>
      );
    } else if (this.state.data.status_zoom === "Unsuccessful") {
      return (
        <span style={{ fontSize: 14, color: "#ff0018", fontWeight: "bold" }}>
          Unsuccessful
        </span>
      );
    } else {
      return <span className="paid">Delivered</span>;
    }
  };

  renderStatusPayment = () => {
    if (
      this.state.data.statuspayment === 0 &&
      this.state.data.paid_amount === "0"
    ) {
      return (
        <span style={{ fontSize: 14, color: "#ff0018" }}>
          Waiting for payment
        </span>
      );
    } else if (this.state.data.paid_amount !== this.state.data.totalPayment) {
      return (
        <span style={{ fontSize: 14, color: "#6c757d" }}>
          Incomplete payment
        </span>
      );
    } else if (this.state.data.statuspayment === 3) {
      return (
        <span style={{ fontSize: 14, color: "#007bff" }}>
          Paid (Payment Refund)
        </span>
      );
    } else {
      return <span className="paid">Paid</span>;
    }
  };

  renderLoading = () => {
    if (this.state.showLoading) {
      return <LoadingScreen></LoadingScreen>;
    }
  };

  renderPrice = (item) => {
    if (item.commodityIsDiscountId === 1) {
      return (
        <td>
          <span>
            {convertToRupiah(
              item.price * ((100 - item.priceDiscountId.replace("%", "")) / 100)
            )}
          </span>
          <br></br>
          <span
            style={{
              fontFamily: "monospace",
              textDecoration: "line-through",
              color: "#808080",
            }}
          >
            {convertToRupiah(item.price)}{" "}
          </span>{" "}
        </td>
      );
    } else {
      return (
        <td>
          <span>{convertToRupiah(item.price)}</span>
        </td>
      );
    }
  };

  renderPromo = (item) => {
    if (item.commodityIsDiscountId === 1) {
      return <div className="promo">Promo Disc {item.priceDiscountId}</div>;
    } else if (item.promo1buyget1Id === 1) {
      return <div className="promo">Promo Buy 1 Get 1</div>;
    }
  };

  renderTax = () => {
    if (this.state.data.tax !== 0) {
      return (
        <tr>
          <td className="col1">Tax&nbsp;{this.state.data.tax}% </td>
          <td className="col2">
            {convertToRupiah(
              this.state.data.totalhargaNormal * (this.state.data.tax / 100)
            )}
          </td>
        </tr>
      );
    }
  };

  deliveryPromo = () => {
    if (this.state.data.status_diskon_bykosmo === 1) {
      return (
        <td className="col2">
          <span>
            {convertToRupiah(
              this.state.data.fee_by_kosmo *
                ((100 - this.state.data.feeDiskonByKOSMOID.replace("%", "")) /
                  100)
            )}
          </span>{" "}
          &nbsp;{" "}
          <span
            style={{
              fontFamily: "monospace",
              textDecoration: "line-through",
              color: "#808080",
            }}
          >
            {convertToRupiah(this.state.data.fee_by_kosmo)}
          </span>
        </td>
      );
    } else {
      return (
        <td className="col2">
          {convertToRupiah(this.state.data.fee_by_kosmo)}
        </td>
      );
    }
  };

  renderDelivery = () => {
    if (this.state.data.deliveryById === 1) {
      return (
        <tr>
          <td className="col1">Delivery</td>
          {this.deliveryPromo()}
        </tr>
      );
    } else {
      return (
        <tr>
          <td className="col1">Delivery</td>
          <td className="col2">
            {convertToRupiah(this.state.data.fee_by_merchant)}
          </td>
        </tr>
      );
    }
  };

  renderItemPaymentMethod() {
    if (this.state.data.paymentmethod === "OVO") {
      return (
        <tr>
          <td className="col1">{this.language.paymentmethod}</td>
          <td className="col2">
            <span
              style={{
                fontFamily: "auto",
                fontWeight: "bold",
                color: "#140a8f",
              }}
            >
              {this.state.data.paymentmethod}
            </span>
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td className="col1">{this.language.paymentmethod}</td>
          <td className="col2">
            <span
              style={{
                fontFamily: "auto",
                fontWeight: "bold",
                color: "#ee4e2e",
              }}
            >
              {this.state.data.paymentmethod}
            </span>
          </td>
        </tr>
      );
    }
  }

  renderHargaTotalWithVoucher = () => {
    if (this.state.data.checkedVoucher === "1") {
      return (
        <tr>
          <td className="col1">Sub Total</td>
          <td className="col2">
            {" "}
            <span
              style={{
                fontFamily: "monospace",
                textDecoration: "line-through",
                color: "#808080",
              }}
            >
              {convertToRupiah(this.state.data.totalhargaNormal)}
            </span>
            &nbsp;
            {convertToRupiah(
              parseInt(this.state.data.totalhargaNormal) -
                parseInt(this.state.data.useVoucherKosmo)
            )}
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td className="col1">Sub Total</td>
          <td className="col2">
            {convertToRupiah(this.state.data.totalhargaNormal)}
          </td>
        </tr>
      );
    }
  };

  render() {
    return (
      <div className="main-container">
        <SubHeader
          history={this.props.history}
          hideSearch={true}
          title={this.language.title}
        />
        <div className="history-order-food-detail">
          <table className="history-order-food-info">
            <tbody>
              <tr>
                <td className="col1">Date order</td>
                <td className="col2">{this.state.data.currentdate}</td>
              </tr>
              <tr>
                <td className="col1">{this.language.name}</td>
                <td className="col2">{this.state.data.name}</td>
              </tr>
              <tr>
                <td className="col3" colSpan="2">
                  <div>{this.language.address}</div>
                  <div className="textarea-note">
                    {this.state.data.deliverto}
                    <br></br>
                    {this.state.data.locationdetail}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col1">Merchant Name</td>
                <td className="col2" style={{ fontSize: 14, color: "#000" }}>
                  {this.state.data.merchantname}
                </td>
              </tr>
              <tr>
                <td className="col3" colSpan="2">
                  <div>{this.language.note}</div>
                  <div className="textarea-note">{this.state.data.note}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="history-order-food-info">
            <tbody>
              <tr>
                <th style={{ paddingLeft: 10 }}>Order List </th>
                <th>Price </th>
                <th style={{ paddingRight: 10 }}>Qty </th>
              </tr>
              {this.state.dataOrderFood.map((item) => (
                <tr>
                  <td style={{ paddingLeft: 10 }}>
                    {item.foodname} <br></br> {this.renderPromo(item)}
                  </td>
                  {this.renderPrice(item)}
                  <td style={{ paddingRight: 10 }}>{item.qty}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <table className="history-order-food-info">
            <tbody>
              {this.renderHargaTotalWithVoucher()}
              {this.renderTax()}
              {this.renderDelivery()}
              <tr>
                <td className="col1">Total Payment</td>
                <td className="col2">
                  {convertToRupiah(this.state.data.totalPayment)}
                </td>
              </tr>
              <tr>
                <td className="col1">Paid amount </td>
                <td className="col2">
                  {convertToRupiah(this.state.data.paid_amount)}
                </td>
              </tr>
              {this.renderItemPaymentMethod()}
              <tr>
                <td className="col1">Status Payment</td>
                <td className="col2">{this.renderStatusPayment()}</td>
              </tr>
              <tr>
                <td className="col1">{this.language.status_services}</td>
                <td className="col2">{this.renderStatusDelivery()}</td>
              </tr>

              <tr>
                <td className="col3" colSpan="2">
                  <span>Call Admin</span>
                  <a href={"tel:" + this.state.calladmin + ""}>
                    <img
                      src={require("../../../images/btn_phone_n@2x.png")}
                      style={{ width: 25, height: 25, marginLeft: 8 }}
                    />
                    <span>+62 838-7059-7388</span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          {this.renderLoading()}
        </div>
        {/*<div className="next-button" onClick={()=>this.goNext()}>
                    {this.globallang.next}
                </div>*/}
      </div>
    );
  }
}

export default HistoryFoodOrderDetail;
