import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getLanguage } from "../../../Languages";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import "./PaymentCenterDetailPage.style.css";
import {
  webserviceurl,
  activelanguage,
  PAYMENT_METHODS,
  NO_PAYMENT_METHOD,
} from "../../../Config";
import SubHeader from "../../Component/SubHeader/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import ReactModal from "react-modal";
/* import 'react-confirm-alert/src/react-confirm-alert.css'; */
import Popup from "reactjs-popup";
import { convertToRupiah, makeid, numberFormat } from "../../../Global";
import PaymentForm from "./PaymentForm/PaymentForm";
import PaymentFormBCA from "./PaymentForm/PaymentFormBCA";
//import Modal from '../../Component/Modal/Modal';
// import Modal from 'react-bootstrap/Modal';
import "../../Component/Modal/Modal.css";
import moment from "moment";
import PaymentMethod from "./PaymentMethod";
import PaymentMethodErrorBoundary from "./PaymentMethodErrorBoundry";
import LoadingScreen from "../../Component/LoadingScreen/LoadingScreen";

function GetDetailData(item) {
  // alert(JSON.stringify(item));
  console.log(item);
}

class PaymentCenterDetailPage extends Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.language = getLanguage(activelanguage, "paymentcenterdetail");
    this.globallang = getLanguage(activelanguage, "global");
    this.toggle = this.toggle.bind(this);
    this.GetDetailData = GetDetailData.bind(this);
    console.log(this);

    this.state = {
      page: "",
      redirect: false,
      id: props.match.params.paymentid,
      InvNo: props.match.params.invno,
      idx: props.match.params.idx,
      currentTab: 0,
      data: {
        DebtorAcct: props.DebtorAcct,
        InvNo: props.InvNo,
        InvDate: props.InvDate,
        InvAmt: props.InvAmt,
        PayNo: props.PayNo,
        PayDate: props.PayDate,
        Category: props.Category,
        Periode: props.Periode,
      },
      // Debtor:'J5 / 002/O',
      // UnitCode:'J5 / 002',
      // Debtor: props.match.params.debtoracct,
      UnitCode: props.match.params.unitcode,
      CompanyCode: props.match.params.companycode,
      Debtor: "",
      UntiCode: "",
      billingstatus: 0,
      isShowing: false,
      modal: false,
      activeTab: "1",
      list: {},
      listUnitCode: [],
      InvAmtVal: "",
      token: "",
      dataXendit: [],
      email: "",
      voucher: {},
      amountdiscount: "",
      amountdisc: "",
      fixAmount: "",
      tesAmount: "",
      selectedPaymentMethod: NO_PAYMENT_METHOD,
      showLoading: false,
    };
    console.log(this.state.deb);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  loadUser = () => {
    if (
      localStorage.getItem("smart-app-id-login") != undefined &&
      localStorage.getItem("smart-app-id-binding") != "undefined"
    ) {
      var user = localStorage.getItem("smart-app-id-login");
      var userID = JSON.parse(user);

      this.setState({ email: userID.email });

      // alert(this.state.email);
    }
  };

  loadVoucher = () => {
    axios
      .post(webserviceurl + "/app_load_voucher.php")
      .then((response) => {
        console.log(response.data);
        let data = {};
        if (response && response.data.records && response.data.records.length) {
          data = response.data.records[0];
        }
        this.setState({ voucher: data });

        let totalAmount = 0;
        let totalDiscount = 0;
        let totalPrice = 0;
        let totalPriceAll = 0;

        totalAmount = this.state.list.InvAmt;
        totalDiscount = this.state.voucher.voucherdiscount;
        totalPrice = (totalAmount * totalDiscount) / 100;
        totalPriceAll = totalAmount - totalPrice;

        this.setState({ fixAmount: totalPriceAll });

        console.log(totalAmount);
        console.log(totalDiscount);
        console.log(totalPrice);
        console.log(totalPriceAll);
        console.log(this.state.fixAmount);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  doJoinPaymentPaid = (debtor, dataPending) => {
    axios({
      method: "post",
      url: webserviceurl + "/app_payment_debtor.php",
      data: {
        Debtor: debtor,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let records1 = response.data.records;
        let records2 = response.data.records_item;

        var newResultDataPaid = records1.concat(records2);

        let resultLast = [];
        var i = 0;
        dataPending.map((val1, i1) => {
          newResultDataPaid.map((val2, i2) => {
            if (val1.InvNo === val2.v_external_id) {
              val1.status = "PAID";
            }
          });
          resultLast.push({
            DebtorAcct: val1.DebtorAcct,
            InvNo: val1.InvNo,
            InvDate: val1.InvDate,
            InvAmt: val1.InvAmt,
            PayNo: val1.PayNo,
            PayDate: val1.PayDate,
            PayAmt: val1.PayAmt,
            Category: val1.Category,
            Periode: val1.Periode,
            Status: val1.status,
          });
          i++;
        });

        console.log(resultLast);
        this.setState({ list: resultLast[0] });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  loadBillingDetail = (InvNo, Debtor, UnitCode) => {
    let getToken = JSON.parse(localStorage.getItem("smart-app-id-token"));

    if (
      getToken == undefined ||
      getToken == null ||
      getToken == "null" ||
      getToken == "" ||
      getToken == "undefined"
    ) {
      let param = '{"code":"need-binding"}';
      this.sendPostMessage(param);
    }

    var config = {
      headers: { Authorization: "Bearer " + getToken.access_token },
    };

    var BodyData = {
      Debtor: Debtor,
      UnitCode: UnitCode,
      InvNo: InvNo,
      token: getToken.access_token,
    };

    // alert(JSON.stringify(BodyData));

    this.setState({ showLoading: true });
    axios
      .post(webserviceurl + "/api_moderndetail.php", BodyData, config)
      .then((response) => {
        // alert(JSON.stringify(response));
        this.doJoinPaymentPaid(Debtor, response.data.data);
        // console.log(response.data);
        // let data = {};
        // if (response && response.data.data && response.data.data.length) {
        //   data = response.data.data[0];
        // }
        // this.setState({ list: data });
        // this.loadVoucher();
        setTimeout(() => {
          this.setState({ showLoading: false });
        }, 500);
        // this.setState({InvAmtVal : response.data.InvAmt});
        console.log(response);
      })
      .catch((error) => {
        this.setState({ showLoading: false });
        console.log(error);
      });
  };

  componentWillMount = () => {
    this.loadUser();
    // alert(JSON.stringify(this.state.amountdiscount));
    // alert(this.state.CompanyCode);
  };

  componentDidMount = () => {
    this.waitForBridge();
    this.loadBillingDetail(
      decodeURIComponent(this.props.match.params.paymentid),
      decodeURIComponent(this.props.match.params.idx),
      decodeURIComponent(this.props.match.params.unitcode)
    );
    // this.loadVoucher();
  };

  componentWillReceiveProps = (nextprops, nextstate) => {
    // console.log(nextprops);
    // const billing = parseInt(nextprops.billingamount);
    // const total = billing*102.5/100;
    // console.log(total);
    // this.setState({amount:total});
  };
  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let param =
        '{"title":"' +
        this.language.title +
        '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
      window.postMessage(param, "*");
    }
  }

  openModalHandler = () => {
    this.setState({
      isShowing: true,
      modal: false,
    });
  };

  closeModalHandler = () => {
    this.setState({
      isShowing: false,
    });
  };
  openModalBCA = () => {
    this.setState({
      isShowing2: true,
      modal: false,
    });
  };

  closeModalBCA = () => {
    this.setState({
      isShowing2: false,
    });
  };

  //  handleClickPay = () => {
  //         const body = JSON.stringify({
  //             "external_id": this.state.list.InvNo,
  //             "amount": this.state.list.InvAmt ,
  //             "payer_email": this.state.email,
  //             "description": "Invoice "+ this.state.list.Category+"PKL"});
  //         axios.post(webserviceurl+'/apiXendit/create_invoice.php',body)
  //         .then((response) => {
  //             alert(JSON.stringify(response.data));
  //             this.setState({ dataXendit: response.data });
  //             // this.setState
  //             alert(this.state.dataXendit.invoice_url);
  //         });

  //         // this.goToFormXendit();
  //     }

  goFormXendit = () => {
    const body = JSON.stringify({
      debtoracct: this.state.list.DebtorAcct,
      external_id: this.state.list.InvNo,
      amount: this.state.list.InvAmt,
      payer_email: this.state.email.replace(/\s/g, ""),
      companycode: this.state.CompanyCode,
      description: "Invoice " + this.state.list.Category + "PKL",
    });

    // alert(body);

    axios
      .post(webserviceurl + "/apiXendit/create_invoice.php", body)
      .then((response) => {
        // alert(JSON.stringify(response.data));
        this.setState({ dataXendit: response.data });
        // alert(this.state.dataXendit.id);
        this.props.history.push(
          "/paymentfrom/" + encodeURIComponent(this.state.dataXendit.id)
        );
      });
  };

  // goToFormXendit = async () =>{
  //     await this.props.history.push('/PaymentForm/'+this.state.dataXendit.invoice_url)
  // }
  doPay = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <p>Are you sure want to pay this billing?</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                this.handleClickPay();
                onClose();
              }}
            >
              PAY
            </button>
          </div>
        );
      },
    });
  };

  /* doPay = () =>{
		confirmAlert({
		  message: 'Are you sure want to pay this billing?',
		  buttons: [
			{
				label: 'XENDIT',
				onClick: () => {
					//alert('Thank you. Your payment has been saved and will be processed soon.');
                    // this.props.history.go(-1);
                    this.openModalHandler();				
			   }
			},
			{
			  label: 'BCA',
			  onClick: () => {
                  this.openModalBCA();
              }
			}
		  ]
		})
    } */

  shouldRenderPaymentMethod = (paymentMethod) =>
    this.state.selectedPaymentMethod === NO_PAYMENT_METHOD ||
    this.state.selectedPaymentMethod === paymentMethod;

  selectPaymentMethod = (paymentMethod) => {
    this.setState({
      selectedPaymentMethod: paymentMethod,
    });
  };

  cancelPaymentMethod = () => this.selectPaymentMethod(NO_PAYMENT_METHOD);

  renderPayButton = () => {
    const showPaymentMethodForm =
      this.state.selectedPaymentMethod !== NO_PAYMENT_METHOD;

    if (this.state.list.Status !== "PAID") {
      return (
        <div className="payment-button-container">
          <Button
            color="info"
            className="fullbutton"
            onClick={() => this.goFormXendit()}
          >
            {this.language.pay}
          </Button>
        </div>
      );
    } else {
      return (
        <div className="payment-button-container">
          <Button disabled color="info" className="fullbutton">
            {this.language.pay}
          </Button>
        </div>
      );
    }
  };

  renderPaidDate = () => {
    if (this.state.data.billingstatus === 1) {
      return (
        <tr>
          <td className="payment-info-label">{this.language.paiddate} :</td>
          <td className="payment-info-value paid">
            {this.state.data.paiddate}
          </td>
        </tr>
      );
    }
  };

  renderStatus = () => {
    if (this.state.list.PayAmt !== null) {
      return (
        <tr>
          <td className="payment-info-label">{this.language.status} :</td>
          <td className="payment-info-value paid">{this.language.paid}</td>
        </tr>
      );
    } else if (this.state.list.Status === "PAID") {
      return (
        <tr>
          <td className="payment-info-label">{this.language.status} :</td>
          <td className="payment-info-value Received">
            {/* Payment Received <br></br> Pending Clearance */}
            Payment Accepted
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td className="payment-info-label">{this.language.status} :</td>
          <td className="payment-info-value pending">
            {this.language.pending}
          </td>
        </tr>
      );
    }
  };
  renderCategory = () => {
    console.log(this.state.list.Category);
    if (this.state.list.Category === "I") {
      return (
        <tr>
          <td className="payment-info-label">{this.language.category}:</td>
          <td className="payment-info-value pending">{this.language.ipkl}</td>
        </tr>
      );
    }
  };

  renderLoading = () => {
    if (this.state.showLoading) {
      return <LoadingScreen></LoadingScreen>;
    }
  };

  render() {
    if (this.state.redirect) {
      /*if (this.state.page == "newspage") {
                return <Redirect to='/news' />;
            }*/
    }

    return (
      <div className="main-container payment-page">
        <SubHeader
          history={this.props.history}
          hideSearch={true}
          title={this.language.title}
        />
        <div className="park-introduction">
          <div className="payment-title">
            {/* Periode {moment(this.state.list.InvDate).format('MMMM Y')} */}
            {this.state.list.InvNo}
          </div>
          <div className="payment-amount">
            {convertToRupiah(this.state.list.InvAmt)}
            <div className="payment-amount-label">{this.language.amount} :</div>
          </div>
          {/* <div className="payment-amount-diskon">
                        Rp. {numberFormat(this.state.fixAmount)}
                    </div> */}
          {/* <div className="payment-amount-label">{this.language.amount} :</div> */}
          {/* <div className="payment-issuedate">
                        Rp. {numberFormat(this.state.fixAmount)}
                    </div> */}
          <table className="payment-info-container">
            <tbody>
              <tr>
                <td className="payment-info-label">
                  {this.language.periode} :
                </td>
                <td className="payment-info-value">
                  {moment(this.state.list.InvDate).format("MMMM Y")}
                </td>
              </tr>
              <tr>
                <td className="payment-info-label">
                  {this.language.debacct} :
                </td>
                <td className="payment-info-value">
                  {this.state.list.DebtorAcct}
                </td>
              </tr>
              {this.renderPaidDate()}
              {this.renderCategory()}
              {this.renderStatus()}
            </tbody>
          </table>
          {this.renderLoading()}
          {this.renderPayButton()}
        </div>
      </div>
    );
  }
}

export { GetDetailData };
export default PaymentCenterDetailPage;
