export function numberFormat(value, digit = 0, decimalSeparator = ",", thousandSeparator = "."){
    if(isNaN(value)) return 0;
    else{
        var tmp = (value + "").split(".");
        var result = "";

        var idx = 0;
        for(var i=tmp[0].length-1; i>= 0; i--){
            idx++;
            result = tmp[0].substr(i,1) + result;
            if(idx == 3 && i > 0){
                result = thousandSeparator + result;
                idx = 0;
            }
        }
        if(tmp.length > 1){
            result = result + decimalSeparator + tmp[1];
        }
        return result;
    }
    

}

export function convertToRupiah(number) {

    if (number) {
  
      var rupiah = "";
  
      var numberrev = number
  
        .toString()
  
        .split("")
  
        .reverse()
  
        .join("");
  
      for (var i = 0; i < numberrev.length; i++)
  
        if (i % 3 == 0) rupiah += numberrev.substr(i, 3) + ".";
  
      return (
  
        "Rp. " +
  
        rupiah
  
          .split("", rupiah.length - 1)
  
          .reverse()
  
          .join("")
  
      );
  
    } else {
  
      return number;
  
    }
  
  }

  export function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 export function makeidrandom(length) {
  var result           = '';
  var characters       = '0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}