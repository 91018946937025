import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Container,
  Col,
  Row,
  Card,
  CardTitle,
  CardBody,
  CardText,
} from "reactstrap";
import { getLanguage } from "../../../Languages";
import axios from "axios";
import "./MerchantListPage.style.css";
import SubHeaderFood from "../../Component/SubHeaderFood/SubHeaderFood";
import { webserviceurl, activelanguage, limitList } from "../../../Config";
import FoodGardenListItem from "../../Component/FoodGardenListItem/FoodGardenListItem";
import LoadingScreen from "../../Component/LoadingScreen/LoadingScreen";
import noData from "../../../images/kosong2.png";
import { confirmAlert } from "react-confirm-alert";

class MerchantListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      redirect: false,
      merchant: {
        list: [],
        currentIndex: 0,
        showLoadMore: false,
      },
      limitList: limitList,
      onload: false,
      community: props.match.params.communityid,
      category: props.match.params.categoryid,
      list: [],
      start: 0,
      loadDirectoryFinished: false,
      showLoading: false,
      filter: "",
      phonenumber: "",
      name: "",
      email: "",
    };

    this.language = getLanguage(activelanguage, "merchantlist");
  }

  componentDidMount = () => {
    this.waitForBridge();
    let info = localStorage.getItem("smart-app-id-login");
    if (
      info != null &&
      info !== undefined &&
      info != "null" &&
      info != "" &&
      info != "undefined"
    ) {
      info = JSON.parse(info);
      this.setState({
        phonenumber: info.phonenumber,
        name: info.name,
        email: info.email,
      });
      this.doCheckLocationUser(info.phonenumber);
    } else {
      // this.setState({
      //   phonenumber: "082216825612",
      //   name: "Uci Baetulloh",
      //   email: "ucibaetulloh@gmail.com",
      // });
      // this.doCheckLocationUser("082216825612");
    }
    this.loadFoodGarden();
  };

  componentWillUnmount = () => {};

  loadUser = () => {
    console.log("haha");
    let info = localStorage.getItem("smart-app-id-login");
    if (
      info != null &&
      info !== undefined &&
      info != "null" &&
      info != "" &&
      info != "undefined"
    ) {
      info = JSON.parse(info);
      this.setState({
        phonenumber: info.phonenumber,
        name: info.name,
        email: info.email,
      });
      this.doCheckLocationUser(info.phonenumber);
    } else {
      // this.setState({
      //   phonenumber: "082216825612",
      //   name: "Uci Baetulloh",
      //   email: "ucibaetulloh@gmail.com",
      // });
      // this.doCheckLocationUser("082216825612");
    }
  };

  doMaps = () => {
    this.props.history.push("/googlemaps");
  };

  doCheckLocationUser = (phonenumber) => {
    let params = {
      phonenumber: phonenumber,
    };
    axios({
      method: "post",
      url: webserviceurl + "/user_location.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        console.log(result);
        if (result.status === "OK") {
          if (result.records.length === 0) {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div>
                    <Card className="card-alert">
                      <CardTitle className="card-title">
                        <span>Suggestion</span>
                      </CardTitle>
                      <CardBody className="card-body">
                        <CardText className="card-text">
                          Set the address for your location.
                        </CardText>
                        <table>
                          <tr style={{ color: "#fff", textAlign: "right" }}>
                            <td>
                              <a
                                className="link-button"
                                onClick={() => {
                                  this.doMaps();
                                  onClose();
                                }}
                              >
                                OK
                              </a>
                            </td>
                          </tr>
                        </table>
                      </CardBody>
                    </Card>
                  </div>
                );
              },
            });
            return false;
          }
          // this.setState({ addresslist: result.records });
          // this.setState({ address: result.records[0].address });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  onSearch = (query) => {
    axios({
      method: "post",
      url: webserviceurl + "/merchant_food.php",
      data: {
        currentIndex: 0,
        limit: this.state.limitList,
        filter: query,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        this.setState({ onload: false });
        let result = response.data;
        let limitafterload = 10;
        if (result.status === "OK") {
          if (result.records.length == 0) {
            this.setState({ loadFinished: true });
          }

          let list = result.records;

          let next = 0;
          if (result.records.length > 0) next += 1;

          let show = true;
          if (result.records.length < limitList) show = false;

          this.setState({
            merchant: { list: list, currentIndex: next, showLoadMore: show },
          });
          this.setState({ filter: query });
          this.setState({ counter: limitafterload });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadFoodGarden = () => {
    this.state.merchant.showLoadMore = false;
    this.setState({ showLoading: true });
    this.setState({ onload: true });
    axios({
      method: "post",
      url: webserviceurl + "/merchant_food.php",
      data: {
        currentIndex: this.state.merchant.currentIndex,
        limit: this.state.limitList,
        filter: "",
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        this.setState({ onload: false });
        let result = response.data;
        if (result.status === "OK") {
          let list = this.state.merchant.list;
          for (var i = 0; i < result.records.length; i++) {
            result.records[i].shortdesc = decodeURIComponent(
              result.records[i].shortdesc
            );
            list.push(result.records[i]);
          }
          let next = this.state.merchant.currentIndex;
          if (result.records.length > 0) next += 1;

          let show = true;
          if (result.records.length < limitList) show = false;
          this.setState({
            merchant: { list: list, currentIndex: next, showLoadMore: show },
          });
        }
        setTimeout(() => {
          this.setState({ showLoading: false });
        }, 500);
      })
      .catch((error) => {
        this.state.merchant.showLoadMore = false;
        this.setState({ showLoading: false });
        this.setState({ onload: false });
        console.log(error);
      });
  };

  loadMerchantScroll = () => {
    this.state.merchant.showLoadMore = false;
    // this.setState({ showLoading: true });
    this.setState({ onload: true });
    axios({
      method: "post",
      url: webserviceurl + "/merchant_food.php",
      data: {
        currentIndex: this.state.merchant.currentIndex,
        limit: this.state.limitList,
        filter: "",
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        this.setState({ onload: false });
        let result = response.data;
        if (result.status === "OK") {
          let list = this.state.merchant.list;

          for (var i = 0; i < result.records.length; i++) {
            result.records[i].shortdesc = decodeURIComponent(
              result.records[i].shortdesc
            );
            list.push(result.records[i]);
          }
          let next = this.state.merchant.currentIndex;
          if (result.records.length > 0) next += 1;

          let show = true;
          if (result.records.length < limitList) show = false;

          this.setState({
            merchant: { list: list, currentIndex: next, showLoadMore: show },
          });
        }
      })
      .catch((error) => {
        this.state.merchant.showLoadMore = false;
        this.setState({ onload: false });
        console.log(error);
      });
  };

  loadUser = () => {
    let tmp = localStorage.getItem("smart-app-id-login");
    if (
      tmp == undefined ||
      tmp == null ||
      tmp == "null" ||
      tmp == "" ||
      tmp == "undefined"
    ) {
      let param = '{"code":"need-login"}';
      this.sendPostMessage(param);
    } else {
      tmp = JSON.parse(tmp);
      this.loginInfo.phoneno = tmp.phonenumber;
      this.loginInfo.name = tmp.name;

      this.setState({
        phoneno: tmp.phonenumber === undefined ? "" : tmp.phonenumber,
      });
      this.setState({ name: tmp.name === undefined ? "" : tmp.name });
      this.setState({ email: tmp.email === undefined ? "" : tmp.email });
    }
  };

  sendPostMessage = (param) => {
    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.sendPostMessage(param);
        }.bind(this),
        200
      );
    } else {
      window.postMessage(param, "*");
    }
  };

  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let param =
        '{"title":"' +
        this.language.title +
        '","canGoBack":true, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
      window.postMessage(param, "*");
    }
  }

  createMarkup = (content) => {
    return { __html: content };
  };

  goToDetail = (item) => {
    this.props.history.push("/foodgardendetail/" + item.id);
  };

  renderTab = () => {
    if (this.state.merchant.list.length > 0) {
      return (
        <div className="body-section directory-scroll-view">
          {this.state.merchant.list.map((item, i) => (
            <div key={i} onClick={() => this.goToDetail(item)}>
              <FoodGardenListItem
                key={i}
                name={item.name}
                location={item.location}
                phonenumber={item.phonenumber}
                tags={item.tags}
                picture={item.picture}
                onClick={() => this.goToDetail(item)}
              />
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="body-section directory-scroll-view">
          <div
            style={{
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginTop: 80,
            }}
          >
            <img
              src={noData}
              style={{ width: 180, height: 120, marginBottom: -20 }}
            />
          </div>
          <div className="no-data" style={{ marginTop: -30, fontSize: 16 }}>
            Merchant is not available
          </div>
        </div>
      );
    }
  };

  handleScroll = (e) => {
    const bottom =
      Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) -
        e.target.clientHeight <
      50;
    if (bottom) {
      if (this.state.merchant.showLoadMore === true) {
        this.loadMerchantScroll();
      }
    }
  };

  renderLoading = () => {
    if (this.state.showLoading) {
      return <LoadingScreen></LoadingScreen>;
    }
  };

  goToActivityOrder = () => {
    this.props.history.replace("/orderactivity");
  };

  goBack = () => {
    this.props.history.replace("/marketplace");
  };

  render() {
    if (this.state.redirect) {
    }

    return (
      <div>
        <SubHeaderFood
          history={this.props.history}
          hideSearch={true}
          onSearch={this.onSearch}
          showAddButton={true}
          goBack={this.goBack}
          add={this.goToActivityOrder}
        />
        <div className="">
          <div onScroll={this.handleScroll}>{this.renderTab()}</div>
        </div>
        {this.renderLoading()}
      </div>
    );
  }
}

export default MerchantListPage;
