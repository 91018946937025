import React, { Component } from "react";
//import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from "../../../Languages";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CardTitle,
  CardText,
  CardSubtitle,
  CarouselCaption,
  Collapse,
  CardBody,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  Input,
  Button,
} from "reactstrap";
import axios from "axios";
import "./WeddingReservationForm.style.css";
import { webserviceurl, activelanguage } from "../../../Config";
import SubHeader from "../../Component/SubHeader/SubHeader";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RoomAddOn from "../../Component/RoomAddOn/RoomAddOn";
import { convertToRupiah, makeid } from "../../../Global";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { zhCN, enUS } from "date-fns/esm/locale";
registerLocale("enUS", enUS);

class WeddingReservationForm extends Component {
  constructor(props) {
    super(props);
    this.language = getLanguage(activelanguage, "weddingreservationform");
    this.globallang = getLanguage(activelanguage, "global");

    this.state = {
      weddingcategoryid: props.match.params.weddingcategoryid,
      weddings: [],
      weddingid: "",
      weddingname: "",
      duration: "",
      weddingprice: "",
      deposit: "",
      totalpayment: "",
      downpayment: "",
      weddingpic: "",
      user: [],
      phoneno: "",
      name: "",
      email: "",
      datecheckin: new Date(),
      datecheckout: new Date(),
      hourin: 7,
      minutein: 0,
      hourout: 7,
      minuteout: 0,
      page: "",
      redirect: false,
      title: this.language.title,
      weddingreservationid: 0,
      price: 0,
      status: 0,
      communityid: 22,
      addon: [],
      roomaddonid: 0,
      roomaddonname: "",
      counterprice: 0,
      qty: 0,
      description: "",
      attending: 0,
      memberInvited: [],
      dataXendit: [],
      currentPage: 0,
      AdvWedding: [],
      fulldesc: "",
      shortdesc: "",
      keterangan: "",
      kapasitas: "",
      luas: "",
      activeIndex: 0,
      activeIndex2: 0,
      activeIndex3: 0,
      collapse: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount = () => {
    this.waitForBridge();
    this.loadWedding();
    // this.loadWeddingFull();
    this.loadUser();

    console.log(this.state.weddingid);
  };

  loadWedding = () => {
    axios
      .post(
        webserviceurl + "/app_load_wedding.php",
        {
          weddingcategoryid: this.state.weddingcategoryid,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((response) => {
        let result = response.data;

        if (result.status === "OK") {
          this.setState({
            weddings: result.records,
            type: result.records.length > 0 ? result.records[0].weddingid : 0,
          });
          this.setState({ weddingid: result.records[0].weddingid });
          this.setState({ weddingname: result.records[0].weddingname });
          this.setState({ duration: result.records[0].duration });
          this.setState({ weddingprice: result.records[0].weddingprice });
          this.setState({ deposit: result.records[0].deposit });
          this.setState({ totalpayment: result.records[0].totalpayment });
          this.setState({ downpayment: result.records[0].downpayment });
          this.setState({ weddingpic: result.records[0].weddingpic });
          this.setState({ shortdesc: result.records[0].shortdesc });
          this.setState({ fulldesc: result.records[0].fulldesc });
          this.setState({ kapasitas: result.records[0].kapasitas });
          this.setState({ luas: result.records[0].luas });
          this.setState({ keterangan: result.records[0].keterangan });

          axios
            .post(
              webserviceurl + "/app_load_weddingfull.php",
              {
                weddingcategoryid: this.state.weddingcategoryid,
                weddingid: this.state.weddingid,
              },
              {
                headers: {
                  "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
                },
              }
            )
            .then((response) => {
              let result = response.data;

              if (result.status === "OK") {
                let adv = [];
                for (let i = 0; i < result.records.length; i++) {
                  for (
                    let idx = 0;
                    idx < result.records[i].bannerpic.length;
                    idx++
                  ) {
                    adv.push({
                      src: result.records[i].bannerpic[idx].iklan,
                      altText: "slide" + idx,
                      caption: "cap" + idx,
                    });
                  }
                }
                this.setState({ AdvWedding: adv });
                console.log(this.state.AdvWedding);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadUser = () => {
    if (
      localStorage.getItem("smart-app-id-login") != undefined &&
      localStorage.getItem("smart-app-id-login") != "undefined"
    ) {
      var com = window.localStorage.getItem("smart-app-id-login");
      var login = JSON.parse(com);

      this.setState({
        phoneno: login.phonenumber,
        name: login.name,
        email: login.email,
      });
    }
  };

  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let param =
        '{"title":"' +
        this.language.title +
        '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
      window.postMessage(param, "*");
    }
  }

  sendPostMessage = (param) => {
    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.sendPostMessage(param);
        }.bind(this),
        200
      );
    } else {
      window.postMessage(param, "*");
    }
  };

  createMarkup = (content) => {
    return { __html: content };
  };

  updateCheckInDate = (date) => {
    this.setState({ datecheckin: date });
  };

  updateCheckOutDate = (date) => {
    this.setState({ datecheckout: date });
  };

  handleHourInChange(e) {
    this.setState({
      hourin: e.target.value,
    });
  }

  handleHourOutChange(e) {
    this.setState({
      hourout: e.target.value,
    });
  }

  handleMinuteInChange(e) {
    this.setState({
      minutein: e.target.value,
    });
  }

  handleMinuteOutChange(e) {
    this.setState({
      minuteout: e.target.value,
    });
  }

  updateMemberSelected = (list) => {
    this.setState({ memberInvited: list });
  };

  toggle() {
    this.setState((state) => ({ collapse: !state.collapse }));
  }

  next = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.AdvWedding.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.AdvWedding.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  renderAdvWedding = () => {
    if (this.state.AdvWedding.length > 0) {
      const slides = this.state.AdvWedding.map((item) => {
        console.log(item);
        return (
          <CarouselItem
            onExiting={this.onExiting}
            onExited={this.onExited}
            key={item.src}
            className="home-banner-container"
          >
            <img src={item.src} alt={item.altText} className="home-banner" />
            <CarouselCaption
              captionText={item.caption}
              captionHeader={item.caption}
            />
          </CarouselItem>
        );
      });

      return (
        <div className="home-header">
          <Carousel
            activeIndex={this.state.activeIndex}
            next={this.next}
            previous={this.previous}
          >
            {/* <CarouselIndicators items={this.state.AdvWedding} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex} /> */}
            {slides}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={this.previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={this.next}
            />
          </Carousel>
        </div>
      );
    }
  };

  changeWeddingCategory = (event) => {
    let weddingData = this.state.weddings.filter(
      (wedding) => wedding.weddingid == event.target.value
    );
    this.setState({
      weddingid: event.target.value,
      weddingid: weddingData[0].weddingid,
      weddingname: weddingData[0].weddingname,
      duration: weddingData[0].duration,
      weddingprice: weddingData[0].weddingprice,
      deposit: weddingData[0].deposit,
      totalpayment: weddingData[0].totalpayment,
      downpayment: weddingData[0].downpayment,
      weddingpic: weddingData[0].weddingpic,
      fulldesc: weddingData[0].fulldesc,
      shortdesc: weddingData[0].shortdesc,
      kapasitas: weddingData[0].kapasitas,
      luas: weddingData[0].luas,
      keterangan: weddingData[0].keterangan,
    });

    axios
      .post(
        webserviceurl + "/app_load_weddingfull.php",
        {
          weddingcategoryid: this.state.weddingcategoryid,
          weddingid: this.state.weddingid,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((response) => {
        let result = response.data;

        if (result.status === "OK") {
          let adv = [];
          for (let i = 0; i < result.records.length; i++) {
            for (let idx = 0; idx < result.records[i].bannerpic.length; idx++) {
              adv.push({
                src: result.records[i].bannerpic[idx].iklan,
                altText: "slide" + idx,
                caption: "cap" + idx,
              });
            }
          }
          this.setState({ AdvWedding: adv });
          console.log(this.state.AdvWedding);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  validate = () => {
    var checkin = "";
    var checkout = "";

    var i = new Date(this.state.datecheckin);
    var yearin = i.getFullYear();
    var monthin = i.getMonth();
    var datein = i.getDate();

    var o = new Date(this.state.datecheckout);
    var yearout = o.getFullYear();
    var monthout = o.getMonth();
    var dateout = o.getDate();

    checkin = yearin + "-" + monthin + "-" + datein;
    checkout = yearout + "-" + monthout + "-" + dateout;

    if (this.state.datecheckout >= this.state.datecheckin) {
      if (checkin === checkout) {
        if (this.state.hourin > this.state.hourout) {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div>
                  <Card className="card-alert">
                    <CardTitle className="card-title">
                      <span>Information!</span>
                    </CardTitle>
                    <CardBody className="card-body">
                      <CardText className="card-text">
                        {this.language.error}
                      </CardText>
                      <table>
                        <tr style={{ color: "#fff", textAlign: "right" }}>
                          <td>
                            <a className="link-button" onClick={onClose}>
                              {this.globallang.ok}
                            </a>
                          </td>
                        </tr>
                      </table>
                    </CardBody>
                  </Card>
                </div>
              );
            },
          });
          // confirmAlert({
          //     message: this.language.error,
          //     buttons: [{label: "OK"}]
          // });
          return 0;
        } else if (this.state.hourin === this.state.hourout) {
          if (this.state.minutein >= this.state.minuteout) {
            // confirmAlert({
            //     message: this.language.error,
            //     buttons: [{label: "OK"}]
            // });
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div>
                    <Card className="card-alert">
                      <CardTitle className="card-title">
                        <span>Information!</span>
                      </CardTitle>
                      <CardBody className="card-body">
                        <CardText className="card-text">
                          {this.language.error}
                        </CardText>
                        <table>
                          <tr style={{ color: "#fff", textAlign: "right" }}>
                            <td>
                              <a className="link-button" onClick={onClose}>
                                {this.globallang.ok}
                              </a>
                            </td>
                          </tr>
                        </table>
                      </CardBody>
                    </Card>
                  </div>
                );
              },
            });
            return 0;
          } else {
            this.checkIfExist();
          }
        } else {
          this.checkIfExist();
        }
      } else {
        this.checkIfExist();
      }
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div>
              <Card className="card-alert">
                <CardTitle className="card-title">
                  <span>Information!</span>
                </CardTitle>
                <CardBody className="card-body">
                  <CardText className="card-text">
                    {this.language.error}
                  </CardText>
                  <table>
                    <tr style={{ color: "#fff", textAlign: "right" }}>
                      <td>
                        <a className="link-button" onClick={onClose}>
                          {this.globallang.ok}
                        </a>
                      </td>
                    </tr>
                  </table>
                </CardBody>
              </Card>
            </div>
          );
        },
      });
      // confirmAlert({
      //     message: this.language.error,
      //     buttons: [{label: "OK"}]
      // })
    }
  };

  checkIfExist = () => {
    var checkin = "";
    var checkout = "";

    var i = new Date(this.state.datecheckin);
    var yearin = i.getFullYear();
    var monthin = i.getMonth();
    var datein = i.getDate();

    var o = new Date(this.state.datecheckout);
    var yearout = o.getFullYear();
    var monthout = o.getMonth();
    var dateout = o.getDate();

    checkin =
      yearin +
      "-" +
      (monthin + 1 > 9 ? monthin + 1 : "0" + (monthin + 1)) +
      "-" +
      (datein > 9 ? datein : "0" + datein) +
      " " +
      (this.state.hourin > 9 ? this.state.hourin : "0" + this.state.hourin) +
      ":" +
      (this.state.minutein > 9
        ? this.state.minutein
        : "0" + this.state.minutein);
    checkout =
      yearout +
      "-" +
      (monthout + 1 > 9 ? monthout + 1 : "0" + (monthout + 1)) +
      "-" +
      (dateout > 9 ? dateout : "0" + dateout) +
      " " +
      (this.state.hourout > 9 ? this.state.hourout : "0" + this.state.hourout) +
      ":" +
      (this.state.minuteout > 9
        ? this.state.minuteout
        : "0" + this.state.minuteout);

    axios({
      method: "post",
      url: webserviceurl + "/app_check_weddingreservationdate.php",
      data: {
        checkin: checkin,
        checkout: checkout,
        weddingid: this.state.weddingid,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        if (result.records === 1) {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div>
                  <Card className="card-alert">
                    <CardTitle className="card-title">
                      <span>Information!</span>
                    </CardTitle>
                    <CardBody className="card-body">
                      <CardText className="card-text">
                        {this.language.alreadybooked}
                      </CardText>
                      <table>
                        <tr style={{ color: "#fff", textAlign: "right" }}>
                          <td>
                            <a className="link-button" onClick={onClose}>
                              {this.globallang.ok}
                            </a>
                          </td>
                        </tr>
                      </table>
                    </CardBody>
                  </Card>
                </div>
              );
            },
          });
          // confirmAlert({
          //     message: this.language.alreadybooked,
          //     buttons: [{label: "OK"}]
          // })
        } else {
          this.doSubmit();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleClickOk = () => {
    var checkin = "";
    var checkout = "";

    var i = new Date(this.state.datecheckin);
    var yearin = i.getFullYear();
    var monthin = i.getMonth();
    var datein = i.getDate();

    var o = new Date(this.state.datecheckout);
    var yearout = o.getFullYear();
    var monthout = o.getMonth();
    var dateout = o.getDate();

    checkin =
      yearin +
      "-" +
      (monthin + 1) +
      "-" +
      datein +
      " " +
      this.state.hourin +
      ":" +
      this.state.minutein +
      ":00";
    checkout =
      yearout +
      "-" +
      (monthout + 1) +
      "-" +
      dateout +
      " " +
      this.state.hourout +
      ":" +
      this.state.minuteout +
      ":00";

    const body = JSON.stringify({
      weddingreservationid: this.state.weddingreservationid,
      phoneno: this.state.phoneno,
      checkin: checkin,
      checkout: checkout,
      weddingid: this.state.weddingid,
      duration: this.state.duration,
      weddingprice: this.state.weddingprice,
      deposit: this.state.deposit,
      totalpayment: this.state.totalpayment,
      downpayment: this.state.downpayment,
      external_id: "INV/BW/" + makeid(10),
      amount: this.state.downpayment,
      payer_email: this.state.email,
      description: "Invoice Wedding Booking",
      communityid: this.state.communityid,
    });

    // alert(JSON.stringify(body));

    axios
      .post(webserviceurl + "/apiXendit/create_inv_wedding_booking.php", body)
      .then((response) => {
        //   alert(JSON.stringify(response.data));
        this.setState({ dataXendit: response.data });
        //    alert(this.state.dataXendit.id);
        this.props.history.push(
          "/paymentfrom/" + encodeURIComponent(this.state.dataXendit.id)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  doSubmit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div>
            <Card className="card-alert">
              <CardTitle className="card-title">
                <span>Information!</span>
              </CardTitle>
              <CardBody className="card-body">
                <CardText className="card-text">
                  {this.language.areyousure}
                </CardText>
                <table>
                  <tr style={{ color: "#fff", textAlign: "center" }}>
                    <td>
                      <a
                        className="link-button"
                        onClick={() => {
                          this.handleClickOk();
                          onClose();
                        }}
                      >
                        {this.globallang.submit}
                      </a>
                    </td>
                    <td>
                      <a className="link-button" onClick={onClose}>
                        {this.globallang.cancel}
                      </a>
                    </td>
                  </tr>
                </table>
              </CardBody>
            </Card>
          </div>
        );
      },
    });
  };

  renderPicture = () => {
    if (this.state.weddingpic !== "") {
      return (
        <div className="commodity-imgitem-container">
          <img src={this.state.weddingpic} alt={"imgpic"} />
        </div>
      );
    } else {
      return (
        <div className="commodity-imgitem-container">
          <img
            src={require("../../../images/default.png")}
            alt={"imgpic"}
            className="imgdefault"
          />
        </div>
      );
    }
  };

  doNext = (toIdx, currIdx) => {
    if (toIdx < 2) {
      if (currIdx === 0) {
        if (this.state.weddingid === "") {
          return false;
        }
      }
      this.setState({ currentPage: toIdx });
    }
  };

  renderPage = () => {
    if (this.state.currentPage === 0) {
      return (
        <div className="service-center-form">
          <div className="form-container">
            <div className="field-container">
              <div className="field-label">{this.language.banquet}</div>
              <div className="field-value">
                <select
                  value={this.state.weddingid}
                  onChange={this.changeWeddingCategory}
                >
                  {this.state.weddings.map((weddings, i) => (
                    <option value={weddings.weddingid}>
                      {weddings.weddingname}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="field-container">
              <div className="icons">
                <div className="roomcategory-icons-container">
                  <div className="room-category-content">
                    <div className="roomcategory-link-label">
                      {/* <img className="image-250" style={{height: (200) + 'px' }} src={this.state.weddingpic} /> */}
                      <div className="header">{this.renderAdvWedding()}</div>
                      <table>
                        <tbody>
                          <tr className="activity-list">
                            <td></td>
                            <td className="activities-list-col1">
                              <div
                                style={{
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  color: "#000",
                                }}
                              >
                                {this.state.weddingname}
                              </div>
                              <div style={{ fontSize: 13, color: "#8c8c8c" }}>
                                Duration of use: {this.state.duration}-Hour
                              </div>
                              <div style={{ fontSize: 13, color: "#8c8c8c" }}>
                                Capacity: {this.state.kapasitas} Person
                              </div>
                              <div style={{ fontSize: 13, color: "#8c8c8c" }}>
                                Building area: {this.state.luas} m2
                              </div>
                            </td>
                            <td className="activities-list-col1">
                              <div className="activity-status-label">
                                Price Estimate
                              </div>
                              <div className="activity-status-value">
                                <span
                                  style={{
                                    color: "#ff9933",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {convertToRupiah(this.state.weddingprice)}
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="field-container">
              <div className="field-label">Information</div>
              <div className="field-value">
                <textarea
                  rows="2"
                  disabled="disabled"
                  value={this.state.keterangan}
                ></textarea>
              </div>
            </div>
            <div className="field-container">
              <div className="field-label">Description</div>
              <div className="field-value">
                <textarea
                  rows="4"
                  disabled="disabled"
                  value={this.state.fulldesc}
                ></textarea>
              </div>
            </div>
            <div className="field-container">
              <Button
                color="success"
                onClick={this.toggle}
                style={{ marginBottom: "1rem", justifyContent: "center" }}
              >
                More Detail
              </Button>
              <Collapse isOpen={this.state.collapse}>
                <Card>
                  <CardBody>
                    <CardSubtitle style={{ fontSize: 15 }}>
                      1. Price Included
                    </CardSubtitle>
                    <ul style={{ fontSize: 14 }}>
                      <li>Cleaning Service dan Security</li>
                      <li>Listrik 2200 VA</li>
                      <li>10 Parking Lot for committee in the drop off area</li>
                    </ul>
                    <CardSubtitle style={{ fontSize: 15 }}>
                      2. Common Facilities
                    </CardSubtitle>
                    <ul style={{ fontSize: 14 }}>
                      <li>Full AC</li>
                      <li>futura chair + 100 pcs cover</li>
                      <li>1 Make up & wardrobe room</li>
                      <li>Public kitchen</li>
                      <li>Loading prepartion pada H-1</li>
                    </ul>
                    <CardSubtitle style={{ fontSize: 15 }}>
                      3. Terms and Conditions
                    </CardSubtitle>
                    <ul style={{ fontSize: 14 }}>
                      <li>Down payment minimal Rp. 2.000.000,-</li>
                      <li>Repayment a maximum of 1 week before the event</li>
                      <li>There is no refund if a cancellation occurs</li>
                      <li>Deposit Rp.1000.000,-</li>
                      <li>Additional usage time of Rp. 500,000 / hour</li>
                      <li>Price included PPN 10%</li>
                    </ul>
                  </CardBody>
                </Card>
              </Collapse>
            </div>
          </div>
        </div>
      );
    } else if (this.state.currentPage === 1) {
      return (
        <div className="service-center-form">
          <div className="form-container">
            <div className="field-container">
              <div className="field-label">{this.language.user}</div>
              <div className="field-value">
                <Input
                  type="text"
                  value={this.state.name}
                  disabled="disabled"
                />
              </div>
            </div>
            <div className="field-container">
              <div className="field-label">{this.language.datecheckin}</div>
              <div className="field-value">
                {/* <DatePicker selected={this.state.datecheckin} withPortal onChange={this.updateCheckInDate} disabledKeyboardNavigation={true} className="date-picker" /> */}
                <DatePicker
                  selected={this.state.datecheckin}
                  onChange={this.updateCheckInDate}
                  dateFormat="dd/MM/yyyy"
                  locale="enUS"
                  withPortal
                  popperClassName="some-custom-class"
                  popperPlacement="bottom-end"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 0px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                  className="date-picker"
                />
              </div>
            </div>
            <div className="field-container">
              <div className="field-label">{this.language.datecheckout}</div>
              <div className="field-value">
                {/* <DatePicker selected={this.state.datecheckout} withPortal onChange={this.updateCheckOutDate}  disabledKeyboardNavigation={true} className="date-picker" /> */}
                <DatePicker
                  selected={this.state.datecheckout}
                  onChange={this.updateCheckOutDate}
                  dateFormat="dd/MM/yyyy"
                  locale="enUs"
                  withPortal
                  popperClassName="some-custom-class"
                  popperPlacement="bottom-end"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 0px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                  className="date-picker"
                />
              </div>
            </div>
            <div className="field-container">
              <div className="field-label">{this.language.timecheckin}</div>
              <div className="field-value">
                <div className="time">
                  <table>
                    <tr>
                      <td>
                        <select
                          onChange={this.handleHourInChange.bind(this)}
                          value={this.state.hourin}
                        >
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                        </select>
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                      <td>
                        <select
                          onChange={this.handleMinuteInChange.bind(this)}
                          value={this.state.minutein}
                        >
                          <option value="00">00</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="field-container">
              <div className="field-label">{this.language.timecheckout}</div>
              <div className="field-value">
                <div className="time">
                  <table>
                    <tr>
                      <td>
                        <select
                          onChange={this.handleHourOutChange.bind(this)}
                          value={this.state.hourout}
                        >
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                        </select>
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                      <td>
                        <select
                          onChange={this.handleMinuteOutChange.bind(this)}
                          value={this.state.minuteout}
                        >
                          <option value="00">00</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <td>&nbsp;&nbsp;</td>
            <div className="field-container">
              <div className="field-label">{this.language.banquet}</div>
              <div className="field-value">
                <select
                  value={this.state.weddingid}
                  onChange={this.changeWeddingCategory}
                >
                  {this.state.weddings.map((weddings, i) => (
                    <option value={weddings.weddingid}>
                      {weddings.weddingname}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="field-container">
              <div className="field-label">Duration</div>
              <div className="field-value">
                <Input disabled="disabled" value={this.state.duration} />
              </div>
            </div>
            <div className="field-container">
              <div className="field-label">Price Estimate</div>
              <div className="field-value">
                <Input
                  disabled="disabled"
                  value={convertToRupiah(this.state.weddingprice)}
                />
              </div>
            </div>
            <div className="field-container">
              <div className="field-label">Deposit</div>
              <div className="field-value">
                <Input
                  disabled="disabled"
                  value={convertToRupiah(this.state.deposit)}
                />
              </div>
            </div>
            <div className="field-container">
              <div className="field-label">Total Payment</div>
              <div className="field-value">
                <Input
                  disabled="disabled"
                  value={convertToRupiah(this.state.totalpayment)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  renderNext = () => {
    if (this.state.currentPage < 1) {
      return (
        <div className="payment-button-container">
          <Button
            color="info"
            className="fullbutton"
            onClick={() =>
              this.doNext(this.state.currentPage + 1, this.state.currentPage)
            }
          >
            {" "}
            Next{" "}
          </Button>
        </div>
      );
    } else {
      return (
        <div className="submit-button">
          <table>
            <tr>
              <td>
                <div className="field-label">Down Payment Minimum</div>
              </td>
              <td>&nbsp;&nbsp;</td>
              <td></td>
            </tr>
            <tr>
              <td>
                <Input
                  style={{ color: "#02ae41", fontSize: 16, fontWeight: "bold" }}
                  disabled="disabled"
                  value={convertToRupiah(this.state.downpayment)}
                />
              </td>
              <td>&nbsp;&nbsp;</td>
              <td>
                <Button color="success" onClick={() => this.validate()} block>
                  Submit
                </Button>
              </td>
            </tr>
            <tr>
              <text></text>
            </tr>
          </table>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="main-container">
        <SubHeader
          history={this.props.history}
          hideSearch={true}
          title={this.state.title}
        />
        {this.renderPage()}
        {this.renderNext()}
      </div>
    );
  }
}
export default WeddingReservationForm;
