import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getLanguage } from "../../../Languages";
import Picture from "../../Component/pictureGallery";
import SubHeader from "../../Component/SubHeader/SubHeader";
import "./NewsDetailStyle.css";
import { webserviceurl, activelanguage } from "../../../Config";
import axios from "axios";
import LoadingScreen from "../../Component/LoadingScreen/LoadingScreen";
import SwipeableViews from "react-swipeable-views";
import newsdefault from "../../../images/news_default.png";

class NewsDetail extends Component {
  constructor(props) {
    super(props);

    this.language = getLanguage(activelanguage, "newsdetail");
    this.idnews = props.match.params.idnews;

    this.state = {
      navigateTo: "",
      title: "News Detail",
      currentIndex: props.match.params.currentIndex,
      idnews: props.match.params.idnews,
      newsDataList: [],
      news: [
        {
          id: 0,
          title: "",
          fulldesc: "",
          newspic: [],
          showLoading: false,
          time: "",
        },
      ],
    };
  }

  componentDidMount() {
    this.waitForBridge();
    this.loadNews();
  }

  componentWillUnmount = () => {
    localStorage.setItem("covidtabnews", "0");
  };

  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let param =
        '{"title":"' +
        this.language.title +
        '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
      window.postMessage(param, "*");
    }
  }

  loadNews = () => {
    this.setState({ showLoading: true });
    axios({
      method: "post",
      url: webserviceurl + "/app_load_newsdetail2.php",
      data: {
        id: this.state.idnews,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        if (result.status === "OK") {
          this.setState({ news: result.record });

          this.loadNewsDetailList();

          setTimeout(() => {
            this.setState({ showLoading: false });
          }, 500);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  loadNewsDetailList = () => {
    this.setState({ showLoading: true });
    axios({
      method: "post",
      url: webserviceurl + "/app_load_newsdetailList.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        console.log(result);
        if (result.status === "OK") {
          let list = this.state.newsDataList;
          for (var i = 0; i < result.records.length; i++) {
            result.records[i].shortdesc = decodeURIComponent(
              result.records[i].shortdesc
            );
            list.push(result.records[i]);
          }
          this.setIndex(list);
          this.setState({ newsDataList: list });
        }

        setTimeout(() => {
          this.setState({ showLoading: false });
        }, 400);
        console.log(this.state.newsDataList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  setIndex = (list) => {
    var index = list.findIndex((p) => p.id == this.idnews);
    this.setState({
      currentIndex: index,
    });
  };

  renderLoading = () => {
    if (this.state.showLoading) {
      return <LoadingScreen></LoadingScreen>;
    }
  };

  renderThumbnail = (item) => {
    if (item.previewmode === 1) {
      return (
        <div className="tree-mode-image">
          {item.thumbnail.map((img, i) => (
            <div className="center-cropped">
              <img src={img.picture} alt={"imgpic"} />
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="tree-mode-image">
          <div className="center-cropped">
            <img src={newsdefault} alt={"imgpic"} />
          </div>
        </div>
      );
    }
  };

  renderPreviewMode = (item) => {
    if (item.previewmode === 1) {
      return (
        <div className="news-content" key={item.id}>
          <div className="news-title">{item.title}</div>
          <div className="descriptime" style={{ marginBottom: 10 }}>
            <div className="timeupdate">
              <span>{item.time} </span>
            </div>
            <div className="reading">
              <span>{item.reading} Reading</span>
            </div>
          </div>
          <Picture images={item.newspic} theme={"full"} />
          {this.renderThumbnail(item)}
          <div
            className="news-description"
            dangerouslySetInnerHTML={{ __html: item.fulldesc }}
          ></div>
        </div>
      );
    }
    if (item.previewmode === 2 || item.previewmode === 4) {
      return (
        <div className="news-content" key={item.id}>
          <div className="news-title">{item.title}</div>
          <div className="descriptime" style={{ marginBottom: 10 }}>
            <div className="timeupdate">
              <span>{item.time} </span>
            </div>
            <div className="reading">
              <span>{item.reading} Reading</span>
            </div>
          </div>
          <Picture images={item.newspic} theme={"full"} />

          <div
            className="news-description"
            dangerouslySetInnerHTML={{ __html: item.fulldesc }}
          ></div>
        </div>
      );
    }
    if (item.previewmode === 3) {
      //
      return (
        <div className="news-content" key={item.id}>
          <div className="news-title">{item.title}</div>
          <div className="descriptime" style={{ marginBottom: 10 }}>
            <div className="timeupdate">
              <span>{item.time} </span>
            </div>
            <div className="reading">
              <span>{item.reading} Reading</span>
            </div>
          </div>
          {/* <Picture images={item.newspic} theme={"full"} /> */}
          <div
            className="news-description"
            dangerouslySetInnerHTML={{ __html: item.fulldesc }}
          ></div>
        </div>
      );
    }
    if (item.previewmode === 5 || item.previewmode === 6) {
      //tree-image
      return (
        <div className="news-content" key={item.id}>
          <video width="100%" controls style={{ marginTop: 10 }}>
            <source src={item.video_url[0]} type="video/mp4" />
          </video>
          <div className="news-title">{item.title}</div>
          <div className="descriptime" style={{ marginBottom: 10 }}>
            <div className="timeupdate">
              <span>{item.time} </span>
            </div>
            <div className="reading">
              <span>{item.reading} Reading</span>
            </div>
          </div>
          <div
            className="news-description"
            dangerouslySetInnerHTML={{ __html: item.fulldesc }}
          ></div>
        </div>
      );
    }
  };

  renderNewsDataList = () => {
    if (this.state.newsDataList.length > 0) {
      return (
        <SwipeableViews index={this.state.currentIndex}>
          {this.state.newsDataList.map((item) => (
            <div className="main-container whitebg">
              {this.renderPreviewMode(item)}
            </div>
          ))}
        </SwipeableViews>
      );
    } else {
      return <div className="no-data-available"></div>;
    }
  };

  render() {
    if (this.state.navigateTo != "") {
      return <Redirect to={this.state.navigateTo} />;
    }
    return (
      <div>
        <SubHeader
          history={this.props.history}
          hideSearch={true}
          title={this.state.title}
        />
        {this.renderNewsDataList()}
        {this.renderLoading()}
      </div>
    );
  }
}

export default NewsDetail;
