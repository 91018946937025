import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getLanguage } from "../../../Languages";
import { Container, Row, Col, Card, CardText, CardImg } from "reactstrap";
import Picture from "../../Component/pictureGallery";
import LoadingScreen from "../../Component/LoadingScreen/LoadingScreen";
import NewsListItem from "../../Component/NewsListItem/NewsListItem";
import "./News.css";
import { webserviceurl, activelanguage, limitList } from "../../../Config";
import axios from "axios";
import SubHeader from "../../Component/SubHeader/SubHeader";
import SwipeableViews from "react-swipeable-views";
import noData from "../../../images/kosong2.png";
import newsdefault from "../../../images/news_default.png";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import { zhCN } from "date-fns/esm/locale";
import "moment/locale/en-au";
moment.locale("zhCN");
registerLocale("zhCN", zhCN);

class News extends Component {
  constructor(props) {
    super(props);
    this.language = getLanguage(activelanguage, "news");
    this.globalLang = getLanguage(activelanguage, "global");

    this.state = {
      currentTab: parseInt(curr),
      navigateTo: "",
      news: {
        list: [],
        listTop: [],
        currentIndex: 0,
        showLoadMore: false,
      },
      newsFeed: {
        list: [],
      },
      announcement: [],
      event: [],
      redirect: false,
      community: "",
      category: [],
      currentIndex: 0,
      limitList: limitList,
      onload: false,
      showLoadMore: true,
      start: 0,
      loadFinished: false,
      filter: "",
      counter: 0,
      showLoading: false,
      phonenumber: "",
      reading: 0,
    };

    this.listel = null;

    let search = props.location.search.replace("?", "");
    let params = search.split("&");
    for (let i = 0; i < params.length; i++) {
      let param = params[i].split("=");
      if (param[0].toLowerCase() === "page") {
        this.state.navigateTo = param[1];
        this.state.redirect = true;
        break;
      } else if (param[0].toLowerCase() === "community") {
        this.state.community = param[1];
      }
    }

    let curr = localStorage.getItem("covidtabnews");
    if (curr === null || curr === undefined || curr === "") curr = 0;
  }

  componentDidMount = () => {
    this.waitForBridge();
    this.loadNews(this.state.news.currentIndex);
    this.loadNewsFeedGlobal();

    let curr = localStorage.getItem("covidtabnews");
    if (curr === null || curr === undefined || curr === "") curr = 0;
    this.setState({ currentTab: parseInt(curr) });
  };

  componentWillUnmount = () => {
    window.localStorage.removeItem("covidtabnews");
  };

  loadNews = (currentIndex) => {
    this.state.news.showLoadMore = false;
    this.setState({ showLoading: true });
    this.setState({ onload: true });
    axios({
      method: "post",
      url: webserviceurl + "/app_load_news.php",
      data: {
        currentIndex: currentIndex,
        limit: this.state.limitList,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        this.setState({ onload: false });
        let result = response.data;
        console.log(result);
        if (result.status === "OK") {
          let list = this.state.news.list;
          let listTop = this.state.news.listTop;

          for (var i = 0; i < result.records.length; i++) {
            result.records[i].shortdesc = decodeURIComponent(
              result.records[i].shortdesc
            );
            result.records[i].time = moment(result.records[i].time);
            list.push(result.records[i]);
          }

          listTop.push(result.records[0]);

          let next = this.state.news.currentIndex;
          if (result.records.length > 0) next += 1;

          let show = true;
          if (result.records.length < limitList) show = false;

          this.setState({
            news: {
              list: list,
              listTop: listTop,
              currentIndex: next,
              showLoadMore: show,
            },
          });

          console.log(this.state.news);
        }
        setTimeout(() => {
          this.setState({ showLoading: false });
        }, 500);
      })
      .catch((error) => {
        this.state.news.showLoadMore = false;
        this.setState({ onload: false });
        console.log(error);
      });
  };

  loadNewsFeedGlobal = () => {
    this.setState({ showLoading: true });
    let urlBerita =
      "https://newsapi.org/v2/top-headlines?country=id&apiKey=71b0ec76c4c2460d95430ba7bef9c251";
    axios
      .get(urlBerita)
      .then((response) => {
        console.log(response);
        let result = response.data;
        if (result.status === "ok") {
          var tmp = result.articles;
          for (let i = 0; i < tmp.length; i++) {
            tmp[i].id = i;
          }

          this.setState({ newsFeed: { list: tmp } });
          console.log(this.state.newsFeed);

          setTimeout(() => {
            this.setState({ showLoading: false });
          }, 200);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let param =
        '{"title":"' +
        this.language.title +
        '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
      window.postMessage(param, "*");
    }
  }

  currentActiveTab(idx) {
    if (idx === this.state.currentTab) return "tab-item active-tab";
    else return "tab-item";
  }

  changeTab(idx) {
    this.setState({ currentTab: idx });
    if (idx == 0) {
      this.renderList();
    } else if (idx == 1) {
      this.renderNewsFeedBody();
    }
  }

  goToDetail(news) {
    this.countReading(news);
    localStorage.setItem("covidtabnews", "0");
    this.props.history.push("/newsdetail/" + news.id);
  }

  goToFeedDetail(news) {
    localStorage.setItem("covidtabnews", "0");
    this.props.history.push("/newsfeeddetail/" + news.id);
  }

  countReading = (item) => {
    axios
      .post(
        webserviceurl + "/master_save_news_reading.php",
        {
          phoneno: this.state.phonenumber,
          newsid: item.id,
          reading: this.state.reading + 1,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((response) => {
        let result = response.data;
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleScroll = (e) => {
    const bottom =
      Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) -
        e.target.clientHeight <
      50;
    if (bottom) {
      if (this.state.news.showLoadMore === true) {
        this.loadNews(this.state.news.currentIndex);
      }
    }
  };

  renderPreviewMode = (item) => {
    // console.log(item);

    if (item.previewmode === 1) {
      //tree-image
      return (
        <div className="relative-container">
          <div className="tree-image-mode">
            <div className="descriptime">
              <div className="name-container">
                <span className="category-Text">{item.category}</span>
                {item.title}
              </div>
            </div>
            {this.renderThumbnail(item)}
            <div className="descriptime">
              <div className="timeupdate">
                <span>{moment(item.time_ago).fromNow()} </span>
              </div>
              <div className="reading">
                <span>{item.reading} Reading</span>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (item.previewmode === 2) {
      //single-image
      return (
        <div className="relative-container">
          <div className="descript-container-border">
            {this.renderSingleImage(item)}
            <div className="descript-container-small">
              <div className="headline-text-name">
                <span className="category-Text">{item.category}</span>{" "}
                {item.title}
              </div>
              <div
                className="sortdesc-headline-text"
                dangerouslySetInnerHTML={{ __html: item.shortdesc }}
              ></div>
              <div className="descriptime">
                <div className="timeupdate">
                  <span>{moment(item.time_ago).fromNow()} </span>
                </div>
                <div className="reading">
                  <span>{item.reading} Reading</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (item.previewmode === 3) {
      //text-mode
      return (
        <div className="relative-container">
          <div className="text-mode">
            <div className="name-container">
              <span className="category-Text">{item.category}</span>
              {item.title}
            </div>
            <div className="descriptime">
              <div className="timeupdate">
                <span>{moment(item.time_ago).fromNow()} </span>
              </div>
              <div className="reading">
                <span>{item.reading} Reading</span>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (item.previewmode === 4) {
      //BIG Img Mode
      return (
        <div className="relative-container">
          <div className="tree-image-mode">
            {this.renderBigImg(item)}
            <div className="descriptime">
              <div className="name-container">
                <span className="category-Text">{item.category}</span>
                {item.title}
              </div>
            </div>
            <div className="descriptime">
              <div className="timeupdate">
                <span>{moment(item.time_ago).fromNow()} </span>
              </div>
              <div className="reading">
                <span>{item.reading} Reading</span>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (item.previewmode === 5) {
      //large-video-mode
      return (
        <div className="relative-container">
          <div className="tree-image-mode">
            <div className="descript-video">
              <div className="name-container">
                <span className="category-Text">{item.category}</span>
                {item.title}
              </div>
            </div>
            {this.renderVideoLarge(item)}
            <div className="descriptime">
              <div className="timeupdate">
                <span>{moment(item.time_ago).fromNow()} </span>
              </div>
              <div className="reading">
                <span>{item.reading} Reading</span>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (item.previewmode === 6) {
      //large-video-mode
      return (
        <div className="relative-container">
          <div className="descript-container-border">
            {this.renderSmallVideo(item)}
            <div className="descript-container-small">
              <div className="headline-text-name">
                <span className="category-Text">{item.category}</span>{" "}
                {item.title}
              </div>
              <div
                className="sortdesc-headline-text"
                dangerouslySetInnerHTML={{ __html: item.shortdesc }}
              ></div>
              <div className="descriptime">
                <div className="timeupdate">
                  <span>{moment(item.time_ago).fromNow()} </span>
                </div>
                <div className="reading">
                  <span>{item.reading} Reading</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  renderCategory = (item) => {
    if (item.category !== undefined && item.category !== "") {
      return <div className="category">{item.category}</div>;
    }
  };

  renderBigImg = (item) => {
    if (item.newspic[0] !== "" && item.previewmode === 4) {
      return (
        <div className="image-big-mode">
          <img src={item.newspic[0]} alt="" />
        </div>
      );
    } else {
      return (
        <div className="image-big-mode">
          <img src={newsdefault} alt="" />
        </div>
      );
    }
  };

  renderThumbnail = (item) => {
    if (item.previewmode === 1) {
      return (
        <div className="tree-mode-image">
          {item.thumbnail.map((img, i) => (
            <div className="center-cropped">
              <img src={img.picture} alt={"imgpic"} />
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="tree-mode-image">
          <div className="center-cropped">
            <img src={newsdefault} alt={"imgpic"} />
          </div>
        </div>
      );
    }
  };

  renderSingleImage = (item) => {
    if (item.newspic[0] !== "" && item.previewmode === 2) {
      return (
        <div className="img-cropped">
          <img src={item.newspic[0]} alt={"imgpic"} className="img-cropped" />
        </div>
      );
    } else {
      return (
        <div className="img-cropped">
          <img src={newsdefault} alt={"imgpic"} className="img-cropped" />
        </div>
      );
    }
  };

  renderVideoLarge = (item) => {
    if (item.video_url[0] !== "" && item.previewmode === 5) {
      return (
        <video width="100%" controls style={{ marginTop: 10 }}>
          <source src={item.video_url[0]} type="video/mp4" />
        </video>
      );
    } else {
      return (
        <div className="imgitem-container">
          <img src={newsdefault} alt={"imgpic"} className="imgdefault" />
        </div>
      );
    }
  };

  renderSmallVideo = (item) => {
    if (item.video_url[0] !== "" && item.previewmode === 6) {
      return (
        <div className="small-video">
          <video width="100%" height="90%" controls style={{ marginTop: 10 }}>
            <source src={item.video_url[0]} type="video/mp4" />
          </video>
        </div>
      );
    } else {
      return (
        <div className="small-video">
          <img src={newsdefault} alt={"imgpic"} />
        </div>
      );
    }
  };

  renderSingleImageFeed = (item) => {
    if (item.urlToImage !== "" || item.urlToImage !== null) {
      return (
        <div className="img-cropped">
          <img src={item.urlToImage} alt={"imgpic"} className="img-cropped" />
        </div>
      );
    } else {
      return (
        <div className="img-cropped">
          <img src={newsdefault} alt={"imgpic"} className="img-cropped" />
        </div>
      );
    }
  };

  renderList = () => {
    if (this.state.news.list.length > 0) {
      return (
        <div className="news-list-section-data" onScroll={this.handleScroll}>
          <div>
            {this.state.news.list.map((item, i) => (
              <div
                className="list-item"
                key={i}
                onClick={() => this.goToDetail(item)}
              >
                {this.renderPreviewMode(item)}
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div className="news-list-section-data">
          <div
            style={{
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginTop: 80,
            }}
          >
            <img src={noData} style={{ width: 180, height: 120 }} />
          </div>
          <div className="no-data" style={{ marginTop: -20 }}>
            {this.language.nodata}
          </div>
        </div>
      );
    }
  };

  renderNewsFeedBody = () => {
    if (this.state.newsFeed.list.length > 0) {
      return (
        <div className="news-list-section-data">
          <div>
            {this.state.newsFeed.list.map((item, i) => (
              <div
                className="list-item"
                key={i}
                onClick={() => this.goToFeedDetail(item)}
              >
                <div className="relative-container">
                  <div className="descript-container-border">
                    {this.renderSingleImageFeed(item)}
                    <div className="descript-container-small">
                      <div className="headline-text-name"> {item.title}</div>
                      <div
                        className="sortdesc-headline-text"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></div>
                      <div className="descriptime">
                        <div className="timeupdate">
                          <span>{moment(item.publishedAt).fromNow()} </span>
                        </div>
                        {/* <div className="reading" >
                                                <span>{item.reading} Reading</span>
                                            </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div className="news-list-section-data">
          <div
            style={{
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginTop: 80,
            }}
          >
            <img src={noData} style={{ width: 180, height: 120 }} />
          </div>
          <div className="no-data" style={{ marginTop: -20 }}>
            {this.language.nodata}
          </div>
        </div>
      );
    }
  };

  renderWhat = () => {
    if (this.state.currentTab == 0) {
      return <div>{this.renderList()}</div>;
    } else if (this.state.currentTab == 1) {
      return <div>{this.renderNewsFeedBody()}</div>;
    }
  };

  renderLoading = () => {
    if (this.state.showLoading) {
      return <LoadingScreen></LoadingScreen>;
    }
  };

  goBack = () => {
    this.props.history.push("/");
  };

  render() {
    if (this.state.navigateTo != "") {
      return <Redirect to={this.state.navigateTo} />;
    }
    return (
      <div>
        <SubHeader
          history={this.props.history}
          hideSearch={true}
          title={this.language.title}
          goBack={this.goBack}
        />
        <div style={{ backgroundColor: "#fff" }}>
          <div className="tab-section">
            <Container>
              <Row>
                <Col
                  md="6"
                  sm="6"
                  xs="6"
                  className={this.currentActiveTab(0)}
                  onClick={() => this.changeTab(0)}
                >
                  {this.language.dynamax}
                </Col>
                <Col
                  md="6"
                  sm="6"
                  xs="6"
                  className={this.currentActiveTab(1)}
                  onClick={() => this.changeTab(1)}
                >
                  {this.language.newsfeed}
                </Col>
              </Row>
            </Container>
          </div>
          {this.renderWhat()}
          {this.renderLoading()}
        </div>
      </div>
    );
  }
}

export default News;
