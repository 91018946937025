import React, { Component } from "react";
//import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from "../../../Languages";
//import { Container, Row, Col } from 'reactstrap';
import axios from "axios";
import "./OrederFoodActivityPage.style.css";
import { webserviceurl, activelanguage } from "../../../Config";
import { convertToRupiah, makeid } from "../../../Global";
import SubHeaderHistory from "../../Component/SubHeaderHistory/SubHeaderHistory";
import SubHeader from "../../Component/SubHeader/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col } from "reactstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import more from "../../../images/marketplace/more.png";
import LoadingScreen from "../../Component/LoadingScreen/LoadingScreen";
import noData from "../../../images/kosong2.png";
import moment from "moment";
import pic1 from "../../../images/marketplace/12-Online-Store.png";
import pic2 from "../../../images/marketplace/13-Food.png";
import pic3 from "../../../images/marketplace/14-Market.png";
import pic4 from "../../../images/marketplace/15-Laundry.png";
import pic5 from "../../../images/marketplace/16-House-Keeping.png";
import pic6 from "../../../images/marketplace/17-Maintenance.png";
import pic7 from "../../../images/marketplace/18-Moving.png";
import pic8 from "../../../images/marketplace/23-Massage.png";
import { zeroPad } from "react-countdown-now";
moment.locale("id");

class OrderFoodActivityPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      redirect: false,
      community: props.match.params.communityid,
      phoneno: "",
      name: "",
      start: 0,
      loadFinished: false,
      orderFoods: [],
      historystart: 0,
      historyloadFinished: false,
      historyorderFoods: [],
      showLoading: false,
      currentTab: 0,
      currentIndex: 0,
    };

    this.loginInfo = {
      phoneno: "",
      name: "",
    };

    this.language = getLanguage(activelanguage, "orderfoodactivity");
    this.globallang = getLanguage(activelanguage, "global");
  }

  componentDidMount = () => {
    this.waitForBridge();
    this.loadUser();
    // this.loadOrderFoodActivity(this.state.phoneno);
  };

  loadOrderFoodActivity = (phoneno) => {
    this.setState({ showLoading: true });
    axios
      .get(
        webserviceurl +
          "/app_load_orderfood.php?start=" +
          this.state.start +
          "&phoneno=" +
          phoneno
      )
      .then((response) => {
        let result = response.data;
        let limitafterload = this.state.start + 50;

        console.log(response);
        console.log(result);

        if (result.status === "OK") {
          if (result.records.length == 0) {
            this.setState({ loadFinished: true });
          }

          let tmp = this.state.orderFoods;

          for (let i = 0; i < result.records.length; i++) {
            let bln = "";

            switch (result.records[i].orderbln) {
              case 1:
                bln = this.globallang.january;
                break;
              case 2:
                bln = this.globallang.february;
                break;
              case 3:
                bln = this.globallang.march;
                break;
              case 4:
                bln = this.globallang.april;
                break;
              case 5:
                bln = this.globallang.may;
                break;
              case 6:
                bln = this.globallang.june;
                break;
              case 7:
                bln = this.globallang.july;
                break;
              case 8:
                bln = this.globallang.august;
                break;
              case 9:
                bln = this.globallang.september;
                break;
              case 10:
                bln = this.globallang.october;
                break;
              case 11:
                bln = this.globallang.november;
                break;
              case 12:
                bln = this.globallang.december;
                break;
            }
            result.records[i].monthname = bln;

            tmp.push(result.records[i]);
          }

          var now = new Date();
          var dateTime = moment(now).format("YYYY-MM-DD HH:mm:ss");

          function diff_minutes(dt2, dt1) {
            var diff = (dt2.getTime() - dt1.getTime()) / 1000;
            diff /= 60;
            return Math.abs(Math.round(diff));
          }

          result.records.map((v) => {
            if (dateTime > v.max_paiddate) {
              this.doExpiredTime(phoneno, v.orderid);
            }

            // if (
            //   10 < diff_minutes(new Date(dateTime), new Date(v.currentdate)) &&
            //   v.status_payment === 1 &&
            //   v.paid_amount !== "" &&
            //   v.status_zoom === ""
            // ) {
            //   console.log("Lebih dr 10 Menit");
            //   this.StatusDriverOffline(v.orderid, v.ZoomOrderId, phoneno);
            // }

            if (v.ZoomOrderId !== "") {
              this.doCheckCallBack(v.ZoomOrderId);
            }
          });

          this.setState({ orderFoods: tmp });
          this.setState({ start: limitafterload });

          setTimeout(() => {
            this.setState({ showLoading: false });
          }, 500);
        }

        console.log(this.state.orderFoods);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  doCheckCallBack = (ZoomOrderId) => {
    let params = {
      ZoomOrderId: ZoomOrderId,
    };
    axios({
      method: "post",
      url: webserviceurl + "/callback_orderfood.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        var now = new Date();
        var dateTimeNow = moment(now).format("YYYY-MM-DD HH:mm:ss");
        function diff_minutes(dt2, dt1) {
          var diff = (dt2.getTime() - dt1.getTime()) / 1000;
          diff /= 60;
          return Math.abs(Math.round(diff));
        }

        if (result.status === "OK") {
          //Pickup to delivery
          if (
            result.records.status === "Pickup" &&
            result.records.pickup !== "0000-00-00 00:00:00"
          ) {
            if (
              diff_minutes(
                new Date(dateTimeNow),
                new Date(result.records.pickup)
              ) > 1
            ) {
              this.doUpdateStatusOrder(result.records.zoomorderid);
            }
          }

          //Cenceled Order (no take order)
          if (result.records !== "") {
            if (
              result.records.status === "Canceled" &&
              result.records.canceltime !== "0000-00-00 00:00:00"
              // result.records.driver === null
            ) {
              this.doCanceledOrder(result.records.zoomorderid);
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  doCanceledOrder = (zoomorderid) => {
    let params = {
      zoomorderid: zoomorderid,
    };

    axios({
      method: "post",
      url: webserviceurl + "/do_canceled_order_food.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        console.log(result);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  doUpdateStatusOrder = (zoomorderid) => {
    let params = {
      zoomorderid: zoomorderid,
    };

    axios({
      method: "post",
      url: webserviceurl + "/do_update_order_food.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        console.log(result);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  StatusDriverOffline = (orderid, ZoomOrderId, phoneno) => {
    let params = {
      orderid: orderid,
      ZoomOrderId: ZoomOrderId,
      phoneno: phoneno,
    };
    axios({
      method: "post",
      url: webserviceurl + "/driver_food_offline.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        console.log(result);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  doExpiredTime = (phonenumber, id) => {
    let params = {
      phonenumber: phonenumber,
      id: id,
    };
    axios({
      method: "post",
      url: webserviceurl + "/expired_food_order.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        console.log(result);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  loadHistoryFoodOrder = (phoneno) => {
    this.setState({ showLoading: true });
    axios
      .get(
        webserviceurl +
          "/app_load_history_orderfood.php?start=" +
          this.state.historystart +
          "&phoneno=" +
          phoneno
      )
      .then((response) => {
        let result = response.data;
        let limitafterload = this.state.historystart + 10;

        console.log(response);
        console.log(result);

        if (result.status === "OK") {
          if (result.records.length == 0) {
            this.setState({ historyloadFinished: true });
          }

          let tmp = this.state.historyorderFoods;

          for (let i = 0; i < result.records.length; i++) {
            let bln = "";

            switch (result.records[i].orderbln) {
              case 1:
                bln = this.globallang.january;
                break;
              case 2:
                bln = this.globallang.february;
                break;
              case 3:
                bln = this.globallang.march;
                break;
              case 4:
                bln = this.globallang.april;
                break;
              case 5:
                bln = this.globallang.may;
                break;
              case 6:
                bln = this.globallang.june;
                break;
              case 7:
                bln = this.globallang.july;
                break;
              case 8:
                bln = this.globallang.august;
                break;
              case 9:
                bln = this.globallang.september;
                break;
              case 10:
                bln = this.globallang.october;
                break;
              case 11:
                bln = this.globallang.november;
                break;
              case 12:
                bln = this.globallang.december;
                break;
            }
            result.records[i].monthname = bln;

            tmp.push(result.records[i]);
          }

          this.setState({ historyorderFoods: tmp });
          this.setState({ start: limitafterload });

          setTimeout(() => {
            this.setState({ showLoading: false });
          }, 500);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadUser = () => {
    if (
      localStorage.getItem("smart-app-id-login") != undefined &&
      localStorage.getItem("smart-app-id-login") != "undefined"
    ) {
      var com = window.localStorage.getItem("smart-app-id-login");
      var login = JSON.parse(com);

      // alert(JSON.stringify(login));

      this.setState({
        phoneno: login.phonenumber,
        name: login.name,
        email: login.email,
      });

      this.loadOrderFoodActivity(login.phonenumber);
      this.loadHistoryFoodOrder(login.phonenumber);
    } else {
      // this.loadOrderFoodActivity("082216825612");
      // this.loadHistoryFoodOrder("082216825612");
    }
  };

  waitForBridge() {
    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let param =
        '{"title":"' +
        this.language.title +
        '","canGoBack":true, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
      window.postMessage(param, "*");
    }
  }

  goBack = () => {
    // this.props.history.replace("/merchantlist/22/0");
    this.props.history.goBack();
  };

  goToHistoryOrder = () => {
    this.props.history.replace("/historyfoodorder");
  };

  renderDot = (item) => {
    if (item.status === 1) {
      return (
        <span style={{ fontSize: 14, color: "#007bff" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (item.status === 4) {
      return (
        <span style={{ fontSize: 14, color: "#6c757d" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    }
  };

  renderStatus = (item) => {
    if (item.status === 1) {
      return (
        <span style={{ fontSize: 14, color: "#007bff", fontWeight: "bold" }}>
          Delivered
        </span>
      );
    } else if (item.status === 4) {
      return (
        <span style={{ fontSize: 14, color: "#6c757d", fontWeight: "bold" }}>
          Canceled Order
        </span>
      );
    }
  };

  renderImgCategory = (item) => {
    if (item.merchantcategoryname === "Food") {
      return <img src={pic2} style={{ height: 35, width: 35 }} />;
    } else if (item.merchantcategoryname === "Market") {
      return <img src={pic3} style={{ height: 35, width: 35 }} />;
    } else if (item.merchantcategoryname === "Online Store") {
      return <img src={pic1} style={{ height: 35, width: 35 }} />;
    } else if (item.merchantcategoryname === "Laundry") {
      return <img src={pic4} style={{ height: 35, width: 35 }} />;
    } else if (item.merchantcategoryname === "Housekeeping") {
      return <img src={pic5} style={{ height: 35, width: 35 }} />;
    } else if (item.merchantcategoryname === "Maintenance") {
      return <img src={pic6} style={{ height: 35, width: 35 }} />;
    } else if (item.merchantcategoryname === "Moving") {
      return <img src={pic7} style={{ height: 35, width: 35 }} />;
    } else if (item.merchantcategoryname === "Massages") {
      return <img src={pic8} style={{ height: 35, width: 35 }} />;
    }
  };

  renderDotOrder = (item) => {
    if (item.status_payment === 0 && item.paid_amount === "0") {
      return (
        <span style={{ fontSize: 14, color: "#ff0018" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (item.paid_amount !== item.totalPayment) {
      return (
        <span style={{ fontSize: 14, color: "#6c757d" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (
      item.status_zoom === "" &&
      item.status_payment !== 0 &&
      item.paid_amount !== "0"
    ) {
      return (
        <span style={{ fontSize: 14, color: "#ff9933" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (item.status_zoom === "finding driver") {
      return (
        <span style={{ fontSize: 14, color: "#ff9933" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (item.status_zoom === "Available") {
      return (
        <span style={{ fontSize: 14, color: "#ff9933" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (item.status_zoom === "On Delivery") {
      return (
        <span style={{ fontSize: 14, color: "#28a745" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (item.status_zoom === "Offline") {
      return (
        <span style={{ fontSize: 14, color: "#6c757d" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (item.status_zoom === "On Road") {
      return (
        <span style={{ fontSize: 14, color: "#ff9933" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (item.status_zoom === "Arriving") {
      return (
        <span style={{ fontSize: 14, color: "#ff9933" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (item.status_zoom === "Pickup") {
      return (
        <span style={{ fontSize: 14, color: "#28a745" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (item.status_zoom === "Receiving") {
      return (
        <span style={{ fontSize: 14, color: "#ff9933" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (item.status_zoom === "Canceled") {
      return (
        <span style={{ fontSize: 14, color: "#6c757d" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (item.status_zoom === "Expired") {
      return (
        <span style={{ fontSize: 14, color: "#ff0018" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (item.status_zoom === "Unavailable") {
      return (
        <span style={{ fontSize: 14, color: "#ff0018" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else if (item.status_zoom === "Unsuccessful") {
      return (
        <span style={{ fontSize: 14, color: "#ff0018" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    } else {
      return (
        <span style={{ fontSize: 14, color: "#007bff" }}>
          <FontAwesomeIcon icon="circle" />
        </span>
      );
    }
  };

  renderStatusDelivery = (item) => {
    if (item.deliveryById === 1) {
      return this.renderStatusOrderByZoom(item);
    } else {
      return this.renderStatusOrderByMerchant(item);
    }
  };

  renderStatusOrderByMerchant = (item) => {
    if (item.status_payment === 0 && item.paid_amount === "0") {
      return (
        <span style={{ fontSize: 14, color: "#ff0018", fontWeight: "bold" }}>
          Waiting for payment
        </span>
      );
    } else if (item.paid_amount !== item.totalPayment) {
      return (
        <span style={{ fontSize: 14, color: "#6c757d", fontWeight: "bold" }}>
          Incomplete payment
        </span>
      );
    } else if (
      item.status_zoom === "" &&
      item.status_payment !== 0 &&
      item.paid_amount !== "0"
    ) {
      return (
        <span style={{ fontSize: 14, color: "#28a745", fontWeight: "bold" }}>
          on Process
        </span>
      );
    } else {
      return (
        <span style={{ fontSize: 14, color: "#007bff", fontWeight: "bold" }}>
          Done
        </span>
      );
    }
  };

  renderStatusOrderByZoom = (item) => {
    if (item.status_payment === 0 && item.paid_amount === "0") {
      return (
        <span style={{ fontSize: 14, color: "#ff0018", fontWeight: "bold" }}>
          Waiting for payment
        </span>
      );
    } else if (item.paid_amount !== item.totalPayment) {
      return (
        <span style={{ fontSize: 14, color: "#6c757d", fontWeight: "bold" }}>
          Incomplete payment
        </span>
      );
    } else if (
      item.status_zoom === "" &&
      item.status_payment !== 0 &&
      item.paid_amount !== "0"
    ) {
      return (
        <span style={{ fontSize: 14, color: "#ff9933", fontWeight: "bold" }}>
          Finding Driver
        </span>
      );
    } else if (item.status_zoom === "finding driver") {
      return (
        <span style={{ fontSize: 14, color: "#ff9933", fontWeight: "bold" }}>
          Finding Driver
        </span>
      );
    } else if (item.status_zoom === "Available") {
      return (
        <span style={{ fontSize: 14, color: "#ff9933", fontWeight: "bold" }}>
          Finding Driver
        </span>
      );
    } else if (item.status_zoom === "On Delivery") {
      return (
        <span style={{ fontSize: 14, color: "#28a745", fontWeight: "bold" }}>
          Delivering
        </span>
      );
    } else if (item.status_zoom === "Offline") {
      return (
        <span style={{ fontSize: 14, color: "#6c757d", fontWeight: "bold" }}>
          Driver is offline
        </span>
      );
    } else if (item.status_zoom === "On Road") {
      return (
        <span style={{ fontSize: 14, color: "#ff9933", fontWeight: "bold" }}>
          On the way
        </span>
      );
    } else if (item.status_zoom === "Arriving") {
      return (
        <span style={{ fontSize: 14, color: "#ff9933", fontWeight: "bold" }}>
          The order arrived soon
        </span>
      );
    } else if (item.status_zoom === "Pickup") {
      return (
        <span style={{ fontSize: 14, color: "#28a745", fontWeight: "bold" }}>
          Pick Up Order
        </span>
      );
    } else if (item.status_zoom === "Receiving") {
      return (
        <span style={{ fontSize: 14, color: "#ff9933", fontWeight: "bold" }}>
          Receive orders
        </span>
      );
    } else if (item.status_zoom === "Canceled") {
      return (
        <span style={{ fontSize: 14, color: "#6c757d", fontWeight: "bold" }}>
          Canceled order
        </span>
      );
    } else if (item.status_zoom === "Expired") {
      return (
        <span style={{ fontSize: 14, color: "#ff0018", fontWeight: "bold" }}>
          Expired order
        </span>
      );
    } else if (item.status_zoom === "Unavailable") {
      return (
        <span style={{ fontSize: 14, color: "#ff0018", fontWeight: "bold" }}>
          Unavailable
        </span>
      );
    } else if (item.status_zoom === "Unsuccessful") {
      return (
        <span style={{ fontSize: 14, color: "#ff0018", fontWeight: "bold" }}>
          Unsuccessful
        </span>
      );
    } else if (item.status_zoom === "Delivered") {
      return (
        <span style={{ fontSize: 14, color: "#007bff", fontWeight: "bold" }}>
          Delivered
        </span>
      );
    }
  };

  goToDetail = (orderid) => {
    this.props.history.push("/orderactivitydetail/" + orderid.orderid);
  };

  goToDetailHistory = (orderid) => {
    this.props.history.push("/historyfoodorderdetail/" + orderid.orderid);
  };

  renderLoading = () => {
    if (this.state.showLoading) {
      return <LoadingScreen></LoadingScreen>;
    }
  };

  payBefore = (item) => {
    if (item.status_payment === 0 && item.paid_amount === "0") {
      return (
        <div className="orderfoods-name">
          <span>Pay before: {moment(item.max_paiddate).format("ll")}</span>,{" "}
          <span>{item.max_paiddate.substr(11, 5)}</span>
        </div>
      );
    }
  };

  renderBodyOrder = () => {
    if (this.state.orderFoods.length > 0) {
      return (
        <div className="orderfoods-scroll-view">
          <table>
            <tbody>
              {this.state.orderFoods.map((item, i) => (
                <tr
                  key={i}
                  className="orderfood-list"
                  onClick={() => this.goToDetail(item)}
                >
                  <td className="orderfoods-list-col-dot">
                    {/* {this.renderDotOrder(item)} &nbsp; */}
                    {this.renderImgCategory(item)}
                  </td>
                  <td className="orderfoods-list-col1">
                    {/* <div className="orderfoods-name">{item.ordertgl} {item.monthname} {item.orderthn}</div> */}
                    <div className="orderfoods-name">
                      <span>
                        Order date: {moment(item.currentdate).format("ll")}
                      </span>
                      , <span>{item.currentdate.substr(11, 5)}</span>
                    </div>
                    <div className="orderfoods-desc">{item.merchantname}</div>
                    <div className="orderfoods-desc">
                      <span>Pay amount: </span>{" "}
                      <span>{convertToRupiah(item.totalPayment)}</span>
                    </div>
                    {this.payBefore(item)}
                  </td>
                  <td className="orderfoods-list-col-qty">
                    <div className="orderfood-status-label">Status</div>
                    <div className="orderfood-status-value">
                      {this.renderStatusDelivery(item)}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {this.renderLoading()}
        </div>
      );
    } else {
      return (
        <div className="orderfoods-scroll-view">
          <div
            style={{
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginTop: 80,
            }}
          >
            <img
              src={noData}
              style={{ width: 180, height: 120, marginBottom: -20 }}
            />
          </div>
          <div className="no-data" style={{ marginTop: -30, fontSize: 16 }}>
            Order is not available
          </div>
        </div>
      );
    }
  };

  renderBodyHistory = () => {
    if (this.state.historyorderFoods.length > 0) {
      return (
        <div className="orderfoods-scroll-view">
          <table>
            <tbody>
              {this.state.historyorderFoods.map((item, i) => (
                <tr
                  key={i}
                  className="orderfood-list"
                  onClick={() => this.goToDetailHistory(item)}
                >
                  <td className="orderfoods-list-col-dot">
                    {/* {this.renderDot(item)} */}
                    {this.renderImgCategory(item)}
                  </td>
                  <td className="orderfoods-list-col1">
                    {/* <div className="orderfoods-name">{item.ordertgl} {item.monthname} {item.orderthn}</div> */}
                    <div className="orderfoods-name">
                      <span>
                        Order date: {moment(item.currentdate).format("ll")}
                      </span>
                      , <span>{item.currentdate.substr(11, 5)}</span>
                    </div>
                    <div className="orderfoods-desc">{item.merchantname}</div>
                    <div className="orderfoods-desc">
                      <span>Paid amount: </span>{" "}
                      <span>{convertToRupiah(item.totalPayment)}</span>
                    </div>
                  </td>
                  <td className="orderfoods-list-col-qty">
                    <div className="orderfood-status-label">Status</div>
                    <div className="orderfood-status-value">
                      {this.renderStatus(item)}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className="orderfoods-scroll-view">
          <div
            style={{
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginTop: 80,
            }}
          >
            <img
              src={noData}
              style={{ width: 180, height: 120, marginBottom: -20 }}
            />
          </div>
          <div className="no-data" style={{ marginTop: -30, fontSize: 16 }}>
            History is not available
          </div>
        </div>
      );
    }
  };

  currentActiveTab(idx) {
    if (idx === this.state.currentTab) return "tab-item active-tab";
    else return "tab-item";
  }

  changeTab(idx) {
    this.setState({ currentTab: idx, currentIndex: 0 });
    if (idx == 0) {
      this.renderBodyOrder();
    } else {
      this.renderBodyHistory();
    }
  }

  renderWhat = () => {
    if (this.state.currentTab == 0) {
      return <div>{this.renderBodyOrder()}</div>;
    } else {
      return <div>{this.renderBodyHistory()}</div>;
    }
  };

  render() {
    return (
      <div>
        <SubHeaderHistory
          history={this.props.history}
          hideSearch={true}
          title="Order Activity"
          showAddButton={false}
          goBack={this.goBack}
        />
        <div>
          <div className="tab-section">
            <Container>
              <Row>
                <Col
                  md="6"
                  sm="6"
                  xs="6"
                  className={this.currentActiveTab(0)}
                  onClick={() => this.changeTab(0)}
                >
                  Order Progress
                </Col>
                <Col
                  md="6"
                  sm="6"
                  xs="6"
                  className={this.currentActiveTab(1)}
                  onClick={() => this.changeTab(1)}
                >
                  Order History
                </Col>
              </Row>
            </Container>
          </div>
          {this.renderWhat()}
        </div>
      </div>
    );
  }
}
export default OrderFoodActivityPage;
