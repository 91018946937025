import React, { Component } from 'react';
//import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import axios from 'axios';
import './HealthDetailReturnTrip.style.css';
import { webserviceurl, activelanguage, appId } from '../../../Config';
import SubHeader from '../../Component/SubHeader/SubHeader';
import {
    Card, CardImg, CardText, CardBody, CardLink,
    CardTitle, CardSubtitle,Button, Container, Row, Col, Input
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; 
import LoadingScreen from '../../Component/LoadingScreen/LoadingScreen';

class HealthDetailReturnTrip extends Component {

    constructor(props) {
        super(props);
        this.language = getLanguage(activelanguage, 'returntrip');
        this.globallang = getLanguage(activelanguage, 'global');

        this.state = {
            phonenumber:props.match.params.phonenumber,
            date:props.match.params.date,
            name:'',
            cluster:'',
            blok:'',
            office:'',
            backtoofficehours:'',
            startworkinghours:'',
            placeofdeparture:'',
            arrivalcity:'',
            transportation:0,
            flightnumber:'',
            gotohubei:'O',
            note:'',
            page: "",
            redirect: false,
            title: this.language.title,
            showLoading: false,
            RETURNTRIPCOMBO1:[]

        }   
    }

    componentDidMount=()=>{
        this.waitForBridge();
        this.loadUser();
        this.loadDataByPhone();
        this.RETRUNTRIPDAILYCOMBO1();
    }

    componentWillUnmount=()=>{
        localStorage.setItem('health', '1');
    }
    
    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":true, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
            window.postMessage(param, "*");
        }
    }

    createMarkup = (content) => {
        return { __html: content };
    }

    loadUser = () => {
        let info = localStorage.getItem('smart-app-id-login');
         if(info != null && info !== undefined && info != 'null' && info != '' && info != 'undefined'){
         	info = JSON.parse(info);
         	this.setState({phonenumber: info.phonenumber, name: info.name});
         }
    }

    loadDataByPhone = () => {
        this.setState({showLoading:true});
        axios.post(webserviceurl + '/app_load_returntrip_detail2.php', {
            phonenumber: this.state.phonenumber,
            date:this.state.date
        },
        {headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {
                console.log(response);
                let result = response.data;
                console.log(result);
                if (result.status === "OK") {

                    this.setState({ 
                        phonenumber: result.records.phonenumber, 
                        name: result.records.name,
                        company: result.records.company,
                        cluster: result.records.cluster,
                        blok: result.records.blok,
                        office: result.records.office,
                        backtoofficehours: result.records.backtoofficehours,
                        startworkinghours: result.records.startworkinghours,
                        placeofdeparture: result.records.placeofdeparture,
                        arrivalcity: result.records.arrivalcity,
                        transportation: result.records.transportation,
                        flightnumber: result.records.flightnumber,
                        gotohubei: result.records.gotohubei,
                        note: result.records.note,


                    });
                }
                setTimeout(()=>{
                    this.setState({showLoading:false});
                }, 500); 
    
            })
            .catch((error) => {
                console.log(error);
            });
    }

    RETRUNTRIPDAILYCOMBO1 = () => {
        axios.get(webserviceurl + '/app_get_combooptions.php?group=RETURNTRIPCOMBO1')
        .then((response) => {
            let result = response.data;
            console.log(result);
            if (result.status === "OK") {
                this.setState({ RETURNTRIPCOMBO1: result.records });
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }



    onChange(i){
        this.setState({
            transportation : i
        });
        console.log(this.state.transportation);
     }

    handleHubeiChange(e){
		this.setState({
			gotohubei: e.target.value
		})
		console.log(this.state.gotohubei);
    }
    
    goToHealthDeclaration=()=>{
        this.props.history.push('/healthdeclaration');
    }

    

    renderLoading=()=>{
        if(this.state.showLoading){
            return (
                <LoadingScreen></LoadingScreen>
            )
        }
    }



    render() {
        return(
            <div className="main-container">
                <SubHeader history={this.props.history} hideSearch={true} title={this.state.title} />
                <div className="trip-center-form">
                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.name} <span style={{color:'red'}} >*</span></div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} value={this.state.name} readOnly/>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">Cluster: <span style={{color:'red'}} >*</span> </div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} placeholder="Silahkan isi" value={this.state.cluster} onChange={(e)=>this.setState({cluster:e.target.value})}/>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">Blok rumah detail: <span style={{color:'red'}} >*</span> </div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} placeholder="Silahkan isi" value={this.state.blok} onChange={(e)=>this.setState({blok:e.target.value})}/>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.phone} <span style={{color:'red'}} >*</span></div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} value={this.state.phonenumber} readOnly/>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.office} <span style={{color:'red'}} >*</span></div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} value={this.state.office}  readOnly />
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.bacttoofficetime} <span style={{color:'red'}} >*</span></div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} value={this.state.backtoofficehours}  readOnly />
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.startworkinghour} <span style={{color:'red'}} >*</span></div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} value={this.state.startworkinghours}  readOnly />
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.placeofdepature} <span style={{color:'red'}} >*</span></div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} value={this.state.placeofdeparture} readOnly />
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.arrivalcity} <span style={{color:'red'}} >*</span></div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} value={this.state.arrivalcity}  readOnly />
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.returntransfortation} <span style={{color:'red'}} >*</span></div>
                            <div style={{backgroundColor:'rgb(250, 250, 250)', padding:4}}>
                                {this.state.RETURNTRIPCOMBO1.map((option,i)=>{
                                    return(
                                    <div className="label-radiobutton" >
                                            <input 
                                                id={option.id}
                                                readOnly
                                                type="radio" 
                                                checked={this.state.transportation == option.id? true: false}
                                                key={option.id}
                                                onChange={this.onChange.bind(this,option.id)} 
                                                value={this.state.transportation == option.id}
                                            />
                                            <label style={{fontSize:13}} key={i} for={option.id}>{option.description}</label>
                                    </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.licenseplat} <span style={{color:'red'}} >*</span></div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} value={this.state.flightnumber}  readOnly />
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.hubei} <span style={{color:'red'}} >*</span></div>
                            <div style={{backgroundColor:'rgb(250, 250, 250)', padding:4}}>
                                <div className="label-radiobutton" >
                                    <input type="radio" value='Y' id='Y' checked={this.state.gotohubei === 'Y'} readOnly onChange={this.handleHubeiChange.bind(this)} />
                                    <label style={{fontSize:13}}  for='Y'>Yes</label>
                                </div>
                                <div className="label-radiobutton" >
                                    <input type="radio" value='N' id='N' checked={this.state.gotohubei === 'N'} readOnly onChange={this.handleHubeiChange.bind(this)} />
                                    <label style={{fontSize:13}} for='N'>No</label>
                                </div>
                            </div>
                        </div>
                    </div>
 
                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.note}</div>
                            <div className="field-value">
                                <textarea value={this.state.note} style={{fontSize:13}} readOnly rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderLoading()}
            </div>
        )
    }

}
export default HealthDetailReturnTrip;