import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getLanguage } from "../../../Languages";
import SubHeader from "../../Component/SubHeader/SubHeader";
import "./QuestionnairePage.style.css";
import { webserviceurl, activelanguage } from "../../../Config";
import axios from "axios";
import LoadingScreen from "../../Component/LoadingScreen/LoadingScreen";
import SwipeableViews from "react-swipeable-views";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import { confirmAlert } from "react-confirm-alert";

class QuestionnaireScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "JGC Tenant Questionnaire",
      pemilikrumah: "O",
      pencahayaanrumah: "O",
      halamanrumah: "O",
      jarakantarrumah: "O",
      aksesrumah: "O",
      lingkunganAman: "O",
      satpamLingkungan: "O",
      kebersihanLingkungan: "O",
      fasilitasOlahraga: "O",
      fasilitasTamanHijau: "O",
      fasilitasUmum: "O",
      hubunganSosial: "O",
      usahaRumah: "O",
      pengirimanRealTime: "O",
      pekerjaan: "",
      phonenumber: "",
      name: "",
      email: "",
      genderId: 0,
      alamatrumah: "",
      note: "",
      showLoading: false,
    };
  }

  componentDidMount() {
    this.waitForBridge();
    this.loadUser();
  }

  componentWillUnmount = () => {};

  // waitForBridge() {
  //   //the react native postMessage has only 1 parameter
  //   //while the default one has 2, so check the signature
  //   //of the function

  //   if (window.postMessage.length !== 1) {
  //     setTimeout(
  //       function () {
  //         this.waitForBridge();
  //       }.bind(this),
  //       200
  //     );
  //   } else {
  //     let param =
  //       '{"title":"' +
  //       this.state.title +
  //       '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
  //     window.postMessage(param, "*");
  //   }
  // }

  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let tmp = localStorage.getItem("smart-app-id-login");
      let needLogin = false;
      // let tmp2 = localStorage.getItem('smart-app-id-binding');
      // let needBinding = false;

      if (
        tmp == undefined ||
        tmp == null ||
        tmp == "null" ||
        tmp == "" ||
        tmp == "undefined"
      )
        needLogin = true;
      // else if(tmp2 == undefined || tmp2 == null || tmp2 == 'null' || tmp2 == '' ||tmp2 == 'undefined' ) needBinding = true;
      else {
        tmp = JSON.parse(tmp);
      }
      let param =
        '{"title":"' +
        this.state.title +
        '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true, "needLogin":' +
        (needLogin ? "true" : "false") +
        "}";
      window.postMessage(param, "*");
    }
  }

  loadUser = () => {
    let info = localStorage.getItem("smart-app-id-login");
    if (
      info != null &&
      info !== undefined &&
      info != "null" &&
      info != "" &&
      info != "undefined"
    ) {
      info = JSON.parse(info);
      this.setState({
        phonenumber: info.phonenumber,
        name: info.name,
        email: info.email,
        genderId: info.genderId,
      });
    } else {
      // this.setState({
      //   phonenumber: "082216825612",
      //   name: "Uci Baetulloh",
      //   email: "ucibaetulloh@gmail.com",
      //   genderId: "1",
      // });
    }
  };

  doCancel = () => {
    this.props.history.push("/");
  };

  doCheckData = () => {
    const {
      pemilikruma,
      pencahayaanrumah,
      halamanrumah,
      jarakantarrumah,
      aksesrumah,
      lingkunganAman,
      satpamLingkungan,
      kebersihanLingkungan,
      fasilitasOlahraga,
      fasilitasTamanHijau,
      fasilitasUmum,
      hubunganSosial,
      usahaRumah,
      pengirimanRealTime,
      pekerjaan,
      alamatrumah,
    } = this.state;

    if (
      pemilikruma == "O" ||
      pencahayaanrumah == "O" ||
      halamanrumah == "O" ||
      jarakantarrumah == "O" ||
      aksesrumah == "O" ||
      lingkunganAman == "O" ||
      satpamLingkungan == "O" ||
      kebersihanLingkungan == "O" ||
      fasilitasOlahraga == "O" ||
      fasilitasTamanHijau == "O" ||
      fasilitasUmum == "O" ||
      hubunganSosial == "O" ||
      usahaRumah == "O" ||
      pengirimanRealTime == "O" ||
      pekerjaan == "" ||
      alamatrumah == ""
    ) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div>
              <Card className="card-alert">
                <CardTitle className="card-title">
                  <span>Information!</span>
                </CardTitle>
                <CardBody className="card-body">
                  <CardText className="card-text">
                    Silahkan isi semua pertanyaan terlebih dahulu!
                  </CardText>
                  <table>
                    <tr style={{ color: "#fff", textAlign: "right" }}>
                      <td></td>
                      <td>
                        <a className="link-button" onClick={onClose}>
                          OK
                        </a>
                      </td>
                    </tr>
                  </table>
                </CardBody>
              </Card>
            </div>
          );
        },
      });
      return false;
    } else {
      this.doValidate();
    }
  };

  doValidate = () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    let param = {
      phonenumber: this.state.phonenumber,
      date: date,
    };
    axios
      .post(webserviceurl + "/app_validation_questionnaire.php", param, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => {
        let result = response.data;
        if (result.status === "OK") {
          return confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div>
                  <Card className="card-alert">
                    <CardTitle className="card-title">
                      <span>Warning</span>
                    </CardTitle>
                    <CardBody className="card-body">
                      <CardText className="card-text">
                        You have filled out the questionnaire for today. You can
                        take the questionnaire again the next day. Thank you.
                      </CardText>
                      <table>
                        <tr style={{ color: "#fff", textAlign: "right" }}>
                          <td>
                            <a className="link-button" onClick={onClose}>
                              OK
                            </a>
                          </td>
                        </tr>
                      </table>
                    </CardBody>
                  </Card>
                </div>
              );
            },
          });
        } else {
          this.doSubmit();
        }
      })
      .catch((error) => {
        // this.setState({showLoading:false});
        console.log(error);
      });
  };

  doSubmit = () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var curentDate = new Date();
    var dateCurrent =
      curentDate.getFullYear() +
      "-" +
      (curentDate.getMonth() + 1) +
      "-" +
      curentDate.getDate() +
      " " +
      curentDate.getHours() +
      ":" +
      curentDate.getMinutes() +
      ":" +
      curentDate.getSeconds();

    let params = {
      date: date,
      dateCurrent: dateCurrent,
      pemilikrumah: this.state.pemilikrumah,
      pencahayaanrumah: this.state.pencahayaanrumah,
      halamanrumah: this.state.halamanrumah,
      jarakantarrumah: this.state.jarakantarrumah,
      aksesrumah: this.state.aksesrumah,
      lingkunganAman: this.state.lingkunganAman,
      satpamLingkungan: this.state.satpamLingkungan,
      kebersihanLingkungan: this.state.kebersihanLingkungan,
      fasilitasOlahraga: this.state.fasilitasOlahraga,
      fasilitasTamanHijau: this.state.fasilitasTamanHijau,
      fasilitasUmum: this.state.fasilitasUmum,
      hubunganSosial: this.state.hubunganSosial,
      usahaRumah: this.state.usahaRumah,
      pengirimanRealTime: this.state.pengirimanRealTime,
      pekerjaan: this.state.pekerjaan,
      phonenumber: this.state.phonenumber,
      alamatrumah: this.state.alamatrumah,
      note: this.state.note,
    };
    console.log(params);

    this.setState({ showLoading: true });
    axios
      .post(webserviceurl + "/app_insert_questionaire.php", params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => {
        let result = response.data;
        console.log(result);
        if (result.status === "ok") {
          this.setState({ showLoading: false });
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div>
                  <Card className="card-alert">
                    <CardTitle className="card-title">
                      <span>Information!</span>
                    </CardTitle>
                    <CardBody className="card-body">
                      <CardText className="card-text">
                        Thank you for filling out the questionnaire, have a nice
                        day.
                      </CardText>
                      <table>
                        <tr style={{ color: "#fff", textAlign: "right" }}>
                          <td></td>
                          <td>
                            <a className="link-button" onClick={onClose}>
                              OK
                            </a>
                          </td>
                        </tr>
                      </table>
                    </CardBody>
                  </Card>
                </div>
              );
            },
          });
          this.props.history.push("/");
        }
      })
      .catch((error) => {
        this.setState({ showLoading: false });
        console.log(error);
      });
  };

  handlePemilikRumah(e) {
    this.setState({
      pemilikrumah: e.target.value,
    });
  }

  handlePencahayaanRumah(e) {
    this.setState({
      pencahayaanrumah: e.target.value,
    });
  }

  handleHalamanRumah(e) {
    this.setState({
      halamanrumah: e.target.value,
    });
  }

  handleJarakAntarRumah(e) {
    this.setState({
      jarakantarrumah: e.target.value,
    });
  }

  handleAksesRumah(e) {
    this.setState({
      aksesrumah: e.target.value,
    });
  }

  handleLingkunganAman(e) {
    this.setState({
      lingkunganAman: e.target.value,
    });
  }

  handleSatpamLingkungan(e) {
    this.setState({
      satpamLingkungan: e.target.value,
    });
  }

  handleKebersihanLingkungan(e) {
    this.setState({
      kebersihanLingkungan: e.target.value,
    });
  }

  handleFasilitiasOlahraga(e) {
    this.setState({
      fasilitasOlahraga: e.target.value,
    });
  }

  handleFasilitasTamanHijau(e) {
    this.setState({
      fasilitasTamanHijau: e.target.value,
    });
  }

  handleFasilitasUmum(e) {
    this.setState({
      fasilitasUmum: e.target.value,
    });
  }

  handleHubunganSosial(e) {
    this.setState({
      hubunganSosial: e.target.value,
    });
  }

  handleUsahaRumah(e) {
    this.setState({
      usahaRumah: e.target.value,
    });
  }

  handlePengirimanRealTime(e) {
    this.setState({
      pengirimanRealTime: e.target.value,
    });
  }

  renderLoading = () => {
    if (this.state.showLoading) {
      return <LoadingScreen></LoadingScreen>;
    }
  };

  render() {
    return (
      <div>
        <SubHeader
          history={this.props.history}
          hideSearch={true}
          title={this.state.title}
        />
        <div className="directory-all-list-section-data">
          <div className="form-container">
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Nama:
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  value={this.state.name}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: -20 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Pekerjaan:
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  placeholder="Silahkan isi"
                  value={this.state.pekerjaan}
                  onChange={(e) => this.setState({ pekerjaan: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div
            className="form-container"
            style={{ marginTop: -20, marginBottom: 10 }}
          >
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Alamat Rumah:
              </div>
              <div className="field-value">
                <textarea
                  value={this.state.alamatrumah}
                  placeholder="Silahkan isi"
                  style={{ fontSize: 12 }}
                  onChange={(e) =>
                    this.setState({ alamatrumah: e.target.value })
                  }
                  rows="2"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: -20 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Isilah setiap pertanyaan pada salah satu kolom "Ya" atau "Tidak"
                yang sesuai dengan keadaan Anda.
              </div>
            </div>
          </div>
          <ol
            style={{
              marginLeft: 30,
              padding: 10,
              color: "#000",
              fontWeight: "bold",
              fontSize: 13,
            }}
            type="A"
          >
            <li>Perumaahan</li>
            <ol
              style={{
                marginLeft: -30,
                fontSize: 12,
                color: "#4a4a4a",
              }}
              type="1"
            >
              <li>Apakah status kepemilikan rumah Anda milik sendiri?</li>
              <div>
                <div className="label-radiobutton">
                  <input
                    type="radio"
                    value="Y"
                    id="pemilikrumah-Y"
                    checked={this.state.pemilikrumah === "Y"}
                    onChange={this.handlePemilikRumah.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="pemilikrumah-Y">
                    Ya
                  </label>
                </div>
                <div
                  className="label-radiobutton"
                  style={{ marginTop: -18, marginBottom: -10 }}
                >
                  <input
                    type="radio"
                    value="N"
                    id="pemilikrumah-N"
                    checked={this.state.pemilikrumah === "N"}
                    onChange={this.handlePemilikRumah.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="pemilikrumah-N">
                    Tidak
                  </label>
                </div>
              </div>
              <li>Apakah pencahayaan didalam rumah Anda cukup ?</li>
              <div>
                <div className="label-radiobutton">
                  <input
                    type="radio"
                    value="Y"
                    id="pencahayaanrumah-Y"
                    checked={this.state.pencahayaanrumah === "Y"}
                    onChange={this.handlePencahayaanRumah.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="pencahayaanrumah-Y">
                    Ya
                  </label>
                </div>
                <div
                  className="label-radiobutton"
                  style={{ marginTop: -18, marginBottom: -10 }}
                >
                  <input
                    type="radio"
                    value="N"
                    id="pencahayaanrumah-N"
                    checked={this.state.pencahayaanrumah === "N"}
                    onChange={this.handlePencahayaanRumah.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="pencahayaanrumah-N">
                    Tidak
                  </label>
                </div>
              </div>
              <li>Apakah ada halaman disekitar rumah Anda?</li>
              <div>
                <div className="label-radiobutton">
                  <input
                    type="radio"
                    value="Y"
                    id="halamanrumah-Y"
                    checked={this.state.halamanrumah === "Y"}
                    onChange={this.handleHalamanRumah.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="halamanrumah-Y">
                    Ya
                  </label>
                </div>
                <div
                  className="label-radiobutton"
                  style={{ marginTop: -18, marginBottom: -10 }}
                >
                  <input
                    type="radio"
                    value="N"
                    id="halamanrumah-N"
                    checked={this.state.halamanrumah === "N"}
                    onChange={this.handleHalamanRumah.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="halamanrumah-N">
                    Tidak
                  </label>
                </div>
              </div>
              <li>
                Apakah ada jarak antara rumah Anda dengan rumah yang lainnya ?
              </li>
              <div>
                <div className="label-radiobutton">
                  <input
                    type="radio"
                    value="Y"
                    id="jarakantarrumah-Y"
                    checked={this.state.jarakantarrumah === "Y"}
                    onChange={this.handleJarakAntarRumah.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="jarakantarrumah-Y">
                    Ya
                  </label>
                </div>
                <div
                  className="label-radiobutton"
                  style={{ marginTop: -18, marginBottom: -10 }}
                >
                  <input
                    type="radio"
                    value="N"
                    id="jarakantarrumah-N"
                    checked={this.state.jarakantarrumah === "N"}
                    onChange={this.handleJarakAntarRumah.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="jarakantarrumah-N">
                    Tidak
                  </label>
                </div>
              </div>
              <li>Apakah Akses menuju perumahan Anda cukup baik?</li>
              <div>
                <div className="label-radiobutton">
                  <input
                    type="radio"
                    value="Y"
                    id="aksesrumah-Y"
                    checked={this.state.aksesrumah === "Y"}
                    onChange={this.handleAksesRumah.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="aksesrumah-Y">
                    Ya
                  </label>
                </div>
                <div
                  className="label-radiobutton"
                  style={{ marginTop: -18, marginBottom: -10 }}
                >
                  <input
                    type="radio"
                    value="N"
                    id="aksesrumah-N"
                    checked={this.state.aksesrumah === "N"}
                    onChange={this.handleAksesRumah.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="aksesrumah-N">
                    Tidak
                  </label>
                </div>
              </div>
            </ol>
            <li>Keamanan dan Keselamatan</li>
            <ol
              style={{
                marginLeft: -30,
                fontSize: 12,
                color: "#4a4a4a",
              }}
              type="1"
            >
              <li>Apakah lingkungan tempat tinggal Anda aman ?</li>
              <div>
                <div className="label-radiobutton">
                  <input
                    type="radio"
                    value="Y"
                    id="lingkunganAman-Y"
                    checked={this.state.lingkunganAman === "Y"}
                    onChange={this.handleLingkunganAman.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="lingkunganAman-Y">
                    Ya
                  </label>
                </div>
                <div
                  className="label-radiobutton"
                  style={{ marginTop: -18, marginBottom: -10 }}
                >
                  <input
                    type="radio"
                    value="N"
                    id="lingkunganAman-N"
                    checked={this.state.lingkunganAman === "N"}
                    onChange={this.handleLingkunganAman.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="lingkunganAman-N">
                    Tidak
                  </label>
                </div>
              </div>
              <li>
                Apakah ada security / tim keamanan di lingkungan tempat tinggal
                Anda?
              </li>
              <div>
                <div className="label-radiobutton">
                  <input
                    type="radio"
                    value="Y"
                    id="satpamLingkungan-Y"
                    checked={this.state.satpamLingkungan === "Y"}
                    onChange={this.handleSatpamLingkungan.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="satpamLingkungan-Y">
                    Ya
                  </label>
                </div>
                <div
                  className="label-radiobutton"
                  style={{ marginTop: -18, marginBottom: -10 }}
                >
                  <input
                    type="radio"
                    value="N"
                    id="satpamLingkungan-N"
                    checked={this.state.satpamLingkungan === "N"}
                    onChange={this.handleSatpamLingkungan.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="satpamLingkungan-N">
                    Tidak
                  </label>
                </div>
              </div>
            </ol>
            <li>Kebersihan dan Fasilitas</li>
            <ol
              style={{
                marginLeft: -30,
                fontSize: 12,
                color: "#4a4a4a",
              }}
              type="1"
            >
              <li>Apakah Kebersihan di lingkungan Anda sudah Cukup?</li>
              <div>
                <div className="label-radiobutton">
                  <input
                    type="radio"
                    value="Y"
                    id="kebersihanLingkungan-Y"
                    checked={this.state.kebersihanLingkungan === "Y"}
                    onChange={this.handleKebersihanLingkungan.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="kebersihanLingkungan-Y">
                    Ya
                  </label>
                </div>
                <div
                  className="label-radiobutton"
                  style={{ marginTop: -18, marginBottom: -10 }}
                >
                  <input
                    type="radio"
                    value="N"
                    id="kebersihanLingkungan-N"
                    checked={this.state.kebersihanLingkungan === "N"}
                    onChange={this.handleKebersihanLingkungan.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="kebersihanLingkungan-N">
                    Tidak
                  </label>
                </div>
              </div>
              <li>Apakah ada Fasilitas Olah Raga di lingkungan Anda?</li>
              <div>
                <div className="label-radiobutton">
                  <input
                    type="radio"
                    value="Y"
                    id="fasilitasOlahraga-Y"
                    checked={this.state.fasilitasOlahraga === "Y"}
                    onChange={this.handleFasilitiasOlahraga.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="fasilitasOlahraga-Y">
                    Ya
                  </label>
                </div>
                <div
                  className="label-radiobutton"
                  style={{ marginTop: -18, marginBottom: -10 }}
                >
                  <input
                    type="radio"
                    value="N"
                    id="fasilitasOlahraga-N"
                    checked={this.state.fasilitasOlahraga === "N"}
                    onChange={this.handleFasilitiasOlahraga.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="fasilitasOlahraga-N">
                    Tidak
                  </label>
                </div>
              </div>
              <li>Apakah ada Fasilitas Taman Hijau di lingkungan Anda ?</li>
              <div>
                <div className="label-radiobutton">
                  <input
                    type="radio"
                    value="Y"
                    id="fasilitasTamanHijau-Y"
                    checked={this.state.fasilitasTamanHijau === "Y"}
                    onChange={this.handleFasilitasTamanHijau.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="fasilitasTamanHijau-Y">
                    Ya
                  </label>
                </div>
                <div
                  className="label-radiobutton"
                  style={{ marginTop: -18, marginBottom: -10 }}
                >
                  <input
                    type="radio"
                    value="N"
                    id="fasilitasTamanHijau-N"
                    checked={this.state.fasilitasTamanHijau === "N"}
                    onChange={this.handleFasilitasTamanHijau.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="fasilitasTamanHijau-N">
                    Tidak
                  </label>
                </div>
              </div>
              <li>Apakah ada Fasilitas umum di lingkungan Anda?</li>
              <div>
                <div className="label-radiobutton">
                  <input
                    type="radio"
                    value="Y"
                    id="fasilitasUmum-Y"
                    checked={this.state.fasilitasUmum === "Y"}
                    onChange={this.handleFasilitasUmum.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="fasilitasUmum-Y">
                    Ya
                  </label>
                </div>
                <div
                  className="label-radiobutton"
                  style={{ marginTop: -18, marginBottom: -10 }}
                >
                  <input
                    type="radio"
                    value="N"
                    id="fasilitasUmum-N"
                    checked={this.state.fasilitasUmum === "N"}
                    onChange={this.handleFasilitasUmum.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="fasilitasUmum-N">
                    Tidak
                  </label>
                </div>
              </div>
            </ol>
            <li>Sosial dan Ekonomi</li>

            <ol
              style={{
                marginLeft: -30,
                fontSize: 12,
                color: "#4a4a4a",
              }}
              type="1"
            >
              <li>
                Apakah hubungan sosial di lingkungan Anda sudah cukup
                terorganisir?
              </li>
              <div>
                <div className="label-radiobutton">
                  <input
                    type="radio"
                    value="Y"
                    id="hubunganSosial-Y"
                    checked={this.state.hubunganSosial === "Y"}
                    onChange={this.handleHubunganSosial.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="hubunganSosial-Y">
                    Ya
                  </label>
                </div>
                <div
                  className="label-radiobutton"
                  style={{ marginTop: -18, marginBottom: -10 }}
                >
                  <input
                    type="radio"
                    value="N"
                    id="hubunganSosial-N"
                    checked={this.state.hubunganSosial === "N"}
                    onChange={this.handleHubunganSosial.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="hubunganSosial-N">
                    Tidak
                  </label>
                </div>
              </div>
              <li>
                Apakah Anda mempunyai usaha rumahan yang perlu di Promosi?
              </li>
              <div>
                <div className="label-radiobutton">
                  <input
                    type="radio"
                    value="Y"
                    id="usahaRumah-Y"
                    checked={this.state.usahaRumah === "Y"}
                    onChange={this.handleUsahaRumah.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="usahaRumah-Y">
                    Ya
                  </label>
                </div>
                <div
                  className="label-radiobutton"
                  style={{ marginTop: -18, marginBottom: -10 }}
                >
                  <input
                    type="radio"
                    value="N"
                    id="usahaRumah-N"
                    checked={this.state.usahaRumah === "N"}
                    onChange={this.handleUsahaRumah.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="usahaRumah-N">
                    Tidak
                  </label>
                </div>
              </div>
              <li>
                Apakah Anda merasa perlu di pengiriman real time untuk melayani
                kebutuhan Anda di kawasan JGC?
              </li>
              <div>
                <div className="label-radiobutton">
                  <input
                    type="radio"
                    value="Y"
                    id="pengirimanRealTime-Y"
                    checked={this.state.pengirimanRealTime === "Y"}
                    onChange={this.handlePengirimanRealTime.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="pengirimanRealTime-Y">
                    Ya
                  </label>
                </div>
                <div
                  className="label-radiobutton"
                  style={{ marginTop: -18, marginBottom: -10 }}
                >
                  <input
                    type="radio"
                    value="N"
                    id="pengirimanRealTime-N"
                    checked={this.state.pengirimanRealTime === "N"}
                    onChange={this.handlePengirimanRealTime.bind(this)}
                  />
                  <label style={{ fontSize: 12 }} for="pengirimanRealTime-N">
                    Tidak
                  </label>
                </div>
              </div>
            </ol>
          </ol>
          <div className="form-container">
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 13 }}>
                Saran Anda untuk kebutuhan/Fasilitas yang di nilai masih kurang
                dan perlu di kawasan JGC :
              </div>
              <div className="field-value">
                <textarea
                  value={this.state.note}
                  style={{ fontSize: 12 }}
                  onChange={(e) => this.setState({ note: e.target.value })}
                  rows="6"
                ></textarea>
              </div>
            </div>
          </div>
          <Container style={{ marginTop: 20, marginBottom: 40 }}>
            <Row>
              <Col xs="6">
                <Button
                  onClick={() => this.doCancel()}
                  style={{
                    backgroundColor: "#607D8B",
                    color: "#fff",
                    textAlign: "center",
                    width: "100%",
                    fontSize: 13,
                  }}
                >
                  <span>Cancel</span>
                </Button>
              </Col>
              <Col xs="6">
                <Button
                  onClick={() => this.doCheckData()}
                  style={{
                    backgroundColor: "#2196F3",
                    textAlign: "center",
                    width: "100%",
                    fontSize: 13,
                  }}
                >
                  <span>Submit</span>
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        {this.renderLoading()}
      </div>
    );
  }
}
export default QuestionnaireScreen;
