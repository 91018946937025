import React, { Component } from "react";
//import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from "../../../Languages";
import axios from "axios";
import "./HealthformReturnTrip.style.css";
import { webserviceurl, activelanguage, appId } from "../../../Config";
import SubHeader from "../../Component/SubHeader/SubHeader";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardLink,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Row,
  Col,
  Input,
} from "reactstrap";
// import Logo from '../../../images/white.png';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { zhCN, id } from "date-fns/esm/locale";

registerLocale("id", id);

class HealthformReturnTrip extends Component {
  constructor(props) {
    super(props);
    this.language = getLanguage(activelanguage, "returntrip");
    this.globallang = getLanguage(activelanguage, "global");

    this.state = {
      phonenumber: props.match.params.phonenumber,
      date: "",
      name: "",
      cluster: "",
      blok: "",
      office: "",
      backtoofficehours: new Date(),
      startworkinghours: new Date(),
      placeofdeparture: "",
      arrivalcity: "",
      transportation: 0,
      flightnumber: "",
      gotohubei: "O",
      note: "",
      page: "",
      redirect: false,
      title: this.language.title,
      RETURNTRIPCOMBO1: [],
    };
  }

  componentDidMount = () => {
    this.waitForBridge();
    this.loadUser();
    this.loadDataByPhone();
    this.RETRUNTRIPDAILYCOMBO1();
  };

  componentWillUnmount = () => {
    localStorage.setItem("health", "1");
  };

  waitForBridge() {
    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let param =
        '{"title":"' +
        this.language.title +
        '","canGoBack":true, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
      window.postMessage(param, "*");
    }
  }

  createMarkup = (content) => {
    return { __html: content };
  };

  loadUser = () => {
    let info = localStorage.getItem("smart-app-id-login");
    if (
      info != null &&
      info !== undefined &&
      info != "null" &&
      info != "" &&
      info != "undefined"
    ) {
      info = JSON.parse(info);
      this.setState({ phonenumber: info.phonenumber, name: info.name });
    }
  };

  loadDataByPhone = () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    axios
      .post(
        webserviceurl + "/app_load_returntrip_today2.php",
        {
          phonenumber: this.state.phonenumber,
          today: date,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((response) => {
        console.log(response);
        let result = response.data;
        console.log(result);
        if (result.status === "OK") {
          this.setState({
            phonenumber: result.records.phonenumber,
            name: result.records.name,
            cluster: result.records.cluster,
            blok: result.records.blok,
            office: result.records.office,
            backtoofficehours: new Date(result.records.backtoofficehours),
            startworkinghours: new Date(result.records.startworkinghours),
            placeofdeparture: result.records.placeofdeparture,
            arrivalcity: result.records.arrivalcity,
            transportation: result.records.transportation,
            flightnumber: result.records.flightnumber,
            gotohubei: result.records.gotohubei,
            note: result.records.note,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  RETRUNTRIPDAILYCOMBO1 = () => {
    axios
      .get(webserviceurl + "/app_get_combooptions.php?group=RETURNTRIPCOMBO1")
      .then((response) => {
        let result = response.data;
        console.log(result);
        if (result.status === "OK") {
          this.setState({ RETURNTRIPCOMBO1: result.records });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onChange(i) {
    this.setState({
      transportation: i,
    });
    console.log(this.state.transportation);
  }

  handleHubeiChange(e) {
    this.setState({
      gotohubei: e.target.value,
    });
    console.log(this.state.gotohubei);
  }

  goToHealthDeclaration = () => {
    this.props.history.push("/healthdeclaration");
  };

  checkData = () => {
    const {
      phonenumber,
      name,
      cluster,
      blok,
      office,
      backtoofficehours,
      startworkinghours,
      placeofdeparture,
      arrivalcity,
      transportation,
      flightnumber,
      gotohubei,
      note,
    } = this.state;

    if (
      office == null ||
      office == "" ||
      cluster == "" ||
      blok == "" ||
      backtoofficehours == null ||
      backtoofficehours == "" ||
      startworkinghours == null ||
      startworkinghours == "" ||
      placeofdeparture == null ||
      placeofdeparture == "" ||
      arrivalcity == null ||
      arrivalcity == "" ||
      transportation == 0 ||
      flightnumber == null ||
      flightnumber == "" ||
      gotohubei == "O"
    ) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div>
              <Card className="card-alert">
                <CardTitle className="card-title">
                  <span>Information!</span>
                </CardTitle>
                <CardBody className="card-body">
                  <CardText className="card-text">
                    Silahkan isi formulir kosong terlebih dahulu!
                  </CardText>
                  <table>
                    <tr style={{ color: "#fff", textAlign: "right" }}>
                      <td></td>
                      <td>
                        <a className="link-button" onClick={onClose}>
                          OK
                        </a>
                      </td>
                    </tr>
                  </table>
                </CardBody>
              </Card>
            </div>
          );
        },
      });
      return false;
    } else {
      this.doSubmit();
    }
  };

  doSubmit = () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var Backtoofficehours = "";
    var Startworkinghour = "";

    //var i = new Date(this.state.backtoofficehours);
    var i = this.state.backtoofficehours;
    var yearin = i.getFullYear();
    var monthin = i.getMonth();
    var datein = i.getDate();

    //var o = new Date(this.state.startworkinghours);
    var o = this.state.startworkinghours;
    var yearout = o.getFullYear();
    var monthout = o.getMonth();
    var dateout = o.getDate();

    Backtoofficehours = yearin + "-" + (monthin + 1) + "-" + datein;
    Startworkinghour = yearout + "-" + (monthout + 1) + "-" + dateout;

    let params = {
      phonenumber: this.state.phonenumber,
      date: date,
      name: this.state.name,
      cluster: this.state.cluster,
      blok: this.state.blok,
      office: this.state.office,
      backtoofficehours: Backtoofficehours,
      startworkinghours: Startworkinghour,
      placeofdeparture: this.state.placeofdeparture,
      arrivalcity: this.state.arrivalcity,
      transportation: this.state.transportation,
      flightnumber: this.state.flightnumber,
      gotohubei: this.state.gotohubei,
      note: this.state.note,
    };

    console.log(params);

    axios
      .post(webserviceurl + "/app_insert_returntrip2.php", params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => {
        let result = response.data;
        console.log(result);
        if (result.status === "ok") {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div>
                  <Card className="card-alert">
                    <CardTitle className="card-title">
                      <span>Information!</span>
                    </CardTitle>
                    <CardBody className="card-body">
                      <CardText className="card-text">
                        {this.language.success}
                      </CardText>
                      <table>
                        <tr style={{ color: "#fff", textAlign: "right" }}>
                          <td></td>
                          <td>
                            <a className="link-button" onClick={onClose}>
                              OK
                            </a>
                          </td>
                        </tr>
                      </table>
                    </CardBody>
                  </Card>
                </div>
              );
            },
          });
          this.props.history.push("/healthdeclaration");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateCheckInDate = (date) => {
    this.setState({ backtoofficehours: date });
    console.log(this.state.backtoofficehours);
  };

  updateCheckOutDate = (date) => {
    this.setState({ startworkinghours: date });
    console.log(this.state.startworkinghours);
  };

  handleSelect(date) {
    console.log(date); // native Date object
  }

  goBack = () => {
    this.props.history.push("/healthdeclaration");
  };

  render() {
    return (
      <div className="main-container">
        <SubHeader
          history={this.props.history}
          hideSearch={true}
          title={this.state.title}
          goBack={this.goBack}
        />
        <div className="trip-center-form">
          <div className="form-container">
            <div className="field-container">
              <div className="field-label">
                {this.language.name} <span style={{ color: "red" }}>*</span>
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  value={this.state.name}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="form-container">
            <div className="field-container">
              <div className="field-label">
                Cluster: <span style={{ color: "red" }}>*</span>{" "}
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  placeholder="Silahkan isi"
                  value={this.state.cluster}
                  onChange={(e) => this.setState({ cluster: e.target.value })}
                />
              </div>
            </div>
          </div>

          <div className="form-container">
            <div className="field-container">
              <div className="field-label">
                Blok rumah detail: <span style={{ color: "red" }}>*</span>{" "}
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  placeholder="Silahkan isi"
                  value={this.state.blok}
                  onChange={(e) => this.setState({ blok: e.target.value })}
                />
              </div>
            </div>
          </div>

          <div className="form-container">
            <div className="field-container">
              <div className="field-label">
                {this.language.phone} <span style={{ color: "red" }}>*</span>
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  value={this.state.phonenumber}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="form-container">
            <div className="field-container">
              <div className="field-label">
                {this.language.office} <span style={{ color: "red" }}>*</span>
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  value={this.state.office}
                  placeholder="Silahkan isi"
                  onChange={(e) => this.setState({ office: e.target.value })}
                />
              </div>
            </div>
          </div>

          <div className="form-container">
            <div className="field-container">
              <div className="field-label">
                {this.language.bacttoofficetime}{" "}
                <span style={{ color: "red" }}>*</span>
              </div>
              <div className="field-value">
                <DatePicker
                  selected={this.state.backtoofficehours}
                  onChange={this.updateCheckInDate}
                  dateFormat="dd/MM/yyyy"
                  locale="id"
                  withPortal
                  popperClassName="some-custom-class"
                  popperPlacement="bottom-end"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 0px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                  className="date-picker"
                />
              </div>
            </div>
          </div>

          <div className="form-container">
            <div className="field-container">
              <div className="field-label">
                {this.language.startworkinghour}{" "}
                <span style={{ color: "red" }}>*</span>
              </div>
              <div className="field-value">
                <DatePicker
                  selected={this.state.startworkinghours}
                  onChange={this.updateCheckOutDate}
                  dateFormat="dd/MM/yyyy"
                  locale="id"
                  withPortal
                  popperClassName="some-custom-class"
                  popperPlacement="bottom-end"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 0px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                  className="date-picker"
                />
              </div>
            </div>
          </div>

          <div className="form-container">
            <div className="field-container">
              <div className="field-label">
                {this.language.placeofdepature}{" "}
                <span style={{ color: "red" }}>*</span>
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  value={this.state.placeofdeparture}
                  placeholder="Silahkan isi"
                  onChange={(e) =>
                    this.setState({ placeofdeparture: e.target.value })
                  }
                />
              </div>
            </div>
          </div>

          <div className="form-container">
            <div className="field-container">
              <div className="field-label">
                {this.language.arrivalcity}{" "}
                <span style={{ color: "red" }}>*</span>
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  value={this.state.arrivalcity}
                  placeholder="Silahkan isi"
                  onChange={(e) =>
                    this.setState({ arrivalcity: e.target.value })
                  }
                />
              </div>
            </div>
          </div>

          <div className="form-container">
            <div className="field-container">
              <div className="field-label">
                {this.language.returntransfortation}{" "}
                <span style={{ color: "red" }}>*</span>
              </div>
              <div
                style={{ backgroundColor: "rgb(250, 250, 250)", padding: 4 }}
              >
                {this.state.RETURNTRIPCOMBO1.map((option, i) => {
                  return (
                    <div className="label-radiobutton">
                      <input
                        id={option.id}
                        type="radio"
                        checked={
                          this.state.transportation == option.id ? true : false
                        }
                        key={option.id}
                        onChange={this.onChange.bind(this, option.id)}
                        value={this.state.transportation == option.id}
                      />
                      <label style={{ fontSize: 13 }} key={i} for={option.id}>
                        {option.description}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="form-container">
            <div className="field-container">
              <div className="field-label">
                {this.language.licenseplat}{" "}
                <span style={{ color: "red" }}>*</span>
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 13 }}
                  value={this.state.flightnumber}
                  placeholder="Silahkan isi"
                  onChange={(e) =>
                    this.setState({ flightnumber: e.target.value })
                  }
                />
              </div>
            </div>
          </div>

          <div className="form-container">
            <div className="field-container">
              <div className="field-label">
                {this.language.hubei} <span style={{ color: "red" }}>*</span>
              </div>
              <div
                style={{ backgroundColor: "rgb(250, 250, 250)", padding: 4 }}
              >
                <div className="label-radiobutton">
                  <input
                    type="radio"
                    value="Y"
                    id="Y"
                    checked={this.state.gotohubei === "Y"}
                    onChange={this.handleHubeiChange.bind(this)}
                  />
                  <label style={{ fontSize: 13 }} for="Y">
                    Ya
                  </label>
                </div>
                <div className="label-radiobutton">
                  <input
                    type="radio"
                    value="N"
                    id="N"
                    checked={this.state.gotohubei === "N"}
                    onChange={this.handleHubeiChange.bind(this)}
                  />
                  <label style={{ fontSize: 13 }} for="N">
                    Tidak
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="form-container">
            <div className="field-container">
              <div className="field-label">{this.language.note}</div>
              <div className="field-value">
                <textarea
                  value={this.state.note}
                  style={{ fontSize: 13 }}
                  onChange={(e) => this.setState({ note: e.target.value })}
                  rows="3"
                ></textarea>
              </div>
            </div>
          </div>
          <Container style={{ marginTop: 20, marginBottom: 40 }}>
            <Row>
              <Col xs="6">
                <Button
                  onClick={() => this.goToHealthDeclaration()}
                  style={{
                    backgroundColor: "#d0d1d3",
                    color: "#000",
                    textAlign: "center",
                    width: "100%",
                    fontSize: 13,
                  }}
                >
                  <span>{this.language.cancel}</span>
                </Button>
              </Col>
              <Col xs="6">
                <Button
                  onClick={() => this.checkData()}
                  style={{
                    backgroundColor: "rgb(117, 15, 233)",
                    textAlign: "center",
                    width: "100%",
                    fontSize: 13,
                  }}
                >
                  <span>{this.language.determine}</span>
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
export default HealthformReturnTrip;
