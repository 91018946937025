import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getLanguage } from "../../../Languages";
import { Button, Container, Row, Col } from "reactstrap";
import axios from "axios";
import "./style.css";
import LazyLoad from "react-lazy-load";
import ImageLoader from "../../Component/ImageLoader";
import PictureUploader from "../../Component/PictureUploader/PictureUploader";
//import Picture from '../../Component/pictureGallery';
import noData from "../../../images/kosong2.png";
import MomentListItem from "../../Component/MomentListItem/MomentListItem";
import { webserviceurl, activelanguage } from "../../../Config";
import SubHeaderMoment from "../../Component/SubHeaderMoment/SubHeaderMoment";
import PictureGallery from "../../Component/pictureGallery";
import LoadingScreen from "../../Component/LoadingScreen/LoadingScreen";

class Moments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      redirect: false,
      start: 0,
      counter: 0,
      filter: "",
      loadMomentFinished: false,
      moment: {
        list: [],
        currentIndex: 0,
      },
      momentid: 0,
      phoneno: "",
      name: "",
      profilepic: [],
      desc: "",
      gallery: [],
      showLoading: false,
      communityid: 22,
    };

    this.language = getLanguage(activelanguage, "moments");
    this.listel = null;
  }

  componentDidMount = () => {
    this.loadMoment();
    this.loadUser();
    document.addEventListener("message", this.onMessage);
    this.waitForBridge();
    this.listel = document.getElementById("list-moment");
    this.listel.addEventListener("scroll", (e) => {
      this.scrollCheck();
    });
  };

  componentWillUnmount = () => {
    document.removeEventListener("message", this.onMessage);
    this.listel.removeEventListener("scroll", (e) => {
      this.scrollCheck();
    });
  };

  onUploadGallery = (result) => {
    this.setState({ gallery: result });
  };

  scrollCheck = () => {
    if (
      this.listel.scrollTop + this.listel.clientHeight >=
      this.listel.scrollHeight
    ) {
      if (this.state.loadMomentFinished == false) {
        this.loadMoment(this.state.start);
      }
    }
  };

  loadMoment = (start) => {
    this.setState({ showLoading: true });
    axios({
      method: "post",
      url: webserviceurl + "/app_load_moments.php",
      data: {
        currentIndex: this.state.moment.currentIndex,
        community: 0,
        start: this.state.start,
        filter: this.state.filter,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        let limitafterload = this.state.start + 10;
        if (result.status === "OK") {
          if (result.records.length == 0) {
            this.setState({ loadMomentFinished: true });
          }

          let tmp = this.state.moment.list;

          for (var i = 0; i < result.records.length; i++) {
            tmp.push(result.records[i]);
          }

          for (var i = 0; i < result.records.length; i++) {
            result.records[i].desc = decodeURIComponent(result.records[i].desc);
          }

          this.setState({ moment: { list: tmp } });
          this.setState({ start: limitafterload });

          setTimeout(() => {
            this.setState({ showLoading: false });
          }, 500);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  loadSession = () => {
    let tmp = localStorage.getItem("smart-app-id-login");
    if (
      tmp == undefined ||
      tmp == null ||
      tmp == "null" ||
      tmp == "" ||
      tmp == "undefined"
    ) {
      let param = '{"code":"need-login"}';
      this.sendPostMessage(param);
    } else {
    }
  };

  goToHome = () => {
    // this.props.history.push('/');
    let param = '{"code":"need-moment"}';
    this.sendPostMessage(param);
  };

  sendPostMessage = (param) => {
    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.sendPostMessage(param);
        }.bind(this),
        200
      );
    } else {
      window.postMessage(param, "*");
    }
  };

  loadUser = () => {
    if (
      localStorage.getItem("smart-app-id-login") != undefined &&
      localStorage.getItem("smart-app-id-login") != "undefined"
    ) {
      var com = localStorage.getItem("smart-app-id-login");
      var login = JSON.parse(com);

      this.state.phoneno = login.phonenumber;
      this.state.name = login.name;
      this.state.profilepic = login.profilepic;
      //this.setState({phoneno : login.phonenumber===undefined?"":login.phonenumber, name : login.name===undefined?"":login.name, profilepic : login.profilepic===undefined?"":login.profilepic});
    }
  };

  addMoment = () => {
    const { desc } = this.state;
    const { gallery } = this.state;

    if (desc == null || desc == "" || gallery == [] || gallery == null) {
      alert("Please fill the input!");
      return false;
    } else {
      this.onSubmit();
    }
  };

  onSubmit = () => {
    axios({
      method: "post",
      url: webserviceurl + "/app_insert_moment.php",
      data: {
        momentid: this.state.momentid,
        phoneno: this.state.phoneno,
        desc: this.state.desc,
        communityid: this.state.communityid,
        gallery: this.state.gallery,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        alert("Moment added");
        this.setState({ desc: "" });
        this.setState({ gallery: [] });
        this.loadMoment();
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  };

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {
      console.log("Geolocation is not supported by this browser");
    }
  };

  showPosition = (position) => {
    console.log(position.coords.latitude);
    console.log(position.coords.longitude);
  };

  onSearch = (query) => {
    this.setState({ showLoading: true });
    axios({
      method: "post",
      url: webserviceurl + "/app_load_moments.php",
      data: {
        currentIndex: this.state.moment.currentIndex,
        community: 0,
        start: 0,
        filter: query,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        let limitafterload = 10;

        if (result.status === "OK") {
          if (result.records.length == 0) {
            this.setState({ loadMomentFinished: true });
          }

          let tmp = result.records;

          for (var i = 0; i < result.records.length; i++) {
            result.records[i].desc = decodeURIComponent(result.records[i].desc);
          }

          this.setState({ moment: { list: tmp } });
          this.setState({ filter: query });
          this.setState({ counter: limitafterload });

          setTimeout(() => {
            this.setState({ showLoading: false });
          }, 500);
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  };

  goToDetail = (moment) => {
    //this.props.history.push('/momentprofile/'+moment.id);
  };

  onMessage = (data) => {
    //alert(data.data);
    let messContent = null;
    if (data.data) messContent = JSON.parse(data.data);

    if (messContent) {
      if (messContent.code === "login") {
        localStorage.setItem(
          "smart-app-id-login",
          JSON.stringify(messContent.param)
        );
        //alert(JSON.stringify(messContent.param));
        window.postMessage('{"code":"receivelogin"}', "*");
      } else if (messContent.code === "logout") {
        localStorage.removeItem("smart-app-id-login");
      }
    }
  };

  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let tmp = localStorage.getItem("smart-app-id-login");
      let needLogin = false;
      // let tmp2 = localStorage.getItem('smart-app-id-binding');
      // let needBinding = false;

      if (
        tmp == undefined ||
        tmp == null ||
        tmp == "null" ||
        tmp == "" ||
        tmp == "undefined"
      )
        needLogin = true;
      // else if(tmp2 == undefined || tmp2 == null || tmp2 == 'null' || tmp2 == '' ||tmp2 == 'undefined' ) needBinding = true;
      else {
        tmp = JSON.parse(tmp);
        this.state.phoneno = tmp.phonenumber;
        this.state.name = tmp.name;
        this.state.profilepic = tmp.profilepic;
      }

      let param =
        '{"title":"' +
        this.language.moments +
        '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true, "needLogin":' +
        (needLogin ? "true" : "false") +
        "}";
      window.postMessage(param, "*");
    }
  }

  createMarkup = (content) => {
    return { __html: content };
  };

  renderList = () => {
    if (this.state.moment.list.length > 0) {
      return (
        <div className="news-list-section" style={{ marginTop: -20 }}>
          {this.state.moment.list.map((item, i) => (
            <div key={i} onClick={() => this.goToDetail(item)}>
              <MomentListItem
                id={item.id}
                pic={item.pic}
                name={item.name}
                momentpic={item.momentpic}
                desc={item.desc}
                gallery={item.gallery}
                date={item.date}
                comment={item.comment}
                count={item.count}
                username={item.username}
                history={this.props.history}
              />
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="news-list-section" id="list-news">
          <div
            style={{
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginTop: 80,
            }}
          >
            <img src={noData} style={{ width: 180, height: 120 }} />
          </div>
          <div className="no-data" style={{ marginTop: -20 }}>
            No data available
          </div>
        </div>
      );
    }
  };

  renderPicture = () => {
    if (this.state.profilepic !== "") {
      return (
        <div className="imgitem-container4">
          <LazyLoad>
            <img src={this.state.profilepic} alt={"imgpic"} />
          </LazyLoad>
        </div>
      );
    } else {
      return (
        <div className="imgitem-container4">
          <ImageLoader
            src={require("../../../images/default.png")}
            alt={"imgpic"}
            className="imgdefault"
          />
        </div>
      );
    }
  };

  renderAdd = () => {
    return (
      <div className="body-section">
        <div className="list-item">
          <div className="relative-container">
            {this.renderPicture()}
            <div className="data-container7">
              <div className="name-container moments-name">
                {this.state.name}
              </div>
              <div align="right" className="addcomment-container">
                <Button color="success" onClick={() => this.goToHome()}>
                  Add Moment
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderLoading = () => {
    if (this.state.showLoading) {
      return <LoadingScreen></LoadingScreen>;
    }
  };

  render() {
    if (this.state.redirect) {
      /*if (this.state.page == "newspage") {
                return <Redirect to='/news' />;
            }*/
    }

    return (
      <div>
        <SubHeaderMoment
          history={this.props.history}
          hideSearch={true}
          onSearch={this.onSearch}
          showAddButton={true}
          add={this.goToHome}
        />
        {/* <SubHeader history={this.props.history} onSearch={this.onSearch}/> */}
        <div id="list-moment">
          {/* {this.renderAdd()} */}
          {this.renderList()}
          {this.renderLoading()}
        </div>
      </div>
    );
  }
}

export default Moments;
