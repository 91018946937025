import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getLanguage } from "../../../Languages";
import { Container, Row, Col, Button } from "reactstrap";
import axios from "axios";
import "./PaymentCenterMultiple.css";
import { webserviceurl, activelanguage } from "../../../Config";
import SubHeader from "../../Component/SubHeader/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetDetailData } from "../PaymentCenterDetailPage/PaymentCenterDetailPage";
import more from "../../../images/marketplace/more.png";
import moment from "moment";
import { convertToRupiah, makeid, numberFormat } from "../../../Global";
import { confirmAlert } from "react-confirm-alert";
import PaymentMultipleListItem from "../../Component/PaymentMultipleListItem/PaymentMultipleListItem";
import LoadingScreen from "../../Component/LoadingScreen/LoadingScreen";
import { da } from "date-fns/locale";

class PaymentCenterMultiple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      redirect: false,
      //list:[{name:'PLN Billing', category:'', tags:[], pic:"bulp", shortdesc:"October pln billing payment"},
      //	  {name:'Water Billing', category:'', tags:[], pic:"tint", shortdesc:"October water billing payment"}],
      listData: [
        {
          DebtorAcct: "J5 / 002/O",
          InvNo: "JC/IS/18/10/00983   ",
          InvDate: "2018-10-01T00:00:00",
          InvAmt: 590700.0,
          PayNo: null,
          PayDate: null,
          PayAmt: null,
          Category: "I",
          Periode: "201810",
        },
        {
          DebtorAcct: "J5 / 002/O",
          InvNo: "JC/IS/18/11/01080   ",
          InvDate: "2018-11-01T00:00:00",
          InvAmt: 590700.0,
          PayNo: null,
          PayDate: null,
          PayAmt: null,
          Category: "I",
          Periode: "201811",
        },
        {
          DebtorAcct: "J5 / 002/O",
          InvNo: "JC/IS/18/12/01052   ",
          InvDate: "2018-12-01T00:00:00",
          InvAmt: 590700.0,
          PayNo: null,
          PayDate: null,
          PayAmt: null,
          Category: "I",
          Periode: "201812",
        },
        {
          DebtorAcct: "J5 / 002/O",
          InvNo: "JC/IS/19/01/01829   ",
          InvDate: "2019-01-01T00:00:00",
          InvAmt: 590700.0,
          PayNo: null,
          PayDate: null,
          PayAmt: null,
          Category: "I",
          Periode: "201901",
        },
        {
          DebtorAcct: "J5 / 002/O",
          InvNo: "JC/IS/19/02/01040   ",
          InvDate: "2019-02-01T00:00:00",
          InvAmt: 590700.0,
          PayNo: null,
          PayDate: null,
          PayAmt: null,
          Category: "I",
          Periode: "201902",
        },
        {
          DebtorAcct: "J5 / 002/O",
          InvNo: "JC/IS/19/03/01094   ",
          InvDate: "2019-03-01T00:00:00",
          InvAmt: 590700.0,
          PayNo: null,
          PayDate: null,
          PayAmt: null,
          Category: "I",
          Periode: "201903",
        },
        {
          DebtorAcct: "J5 / 002/O",
          InvNo: "JC/IS/19/04/01124   ",
          InvDate: "2019-04-01T00:00:00",
          InvAmt: 590700.0,
          PayNo: null,
          PayDate: null,
          PayAmt: null,
          Category: "I",
          Periode: "201904",
        },
        {
          DebtorAcct: "J5 / 002/O",
          InvNo: "JC/IS/19050634",
          InvDate: "2019-05-01T00:00:00",
          InvAmt: 590700.0,
          PayNo: null,
          PayDate: null,
          PayAmt: null,
          Category: "I",
          Periode: "201905",
        },
        {
          DebtorAcct: "J5 / 002/O",
          InvNo: "JC/IS/19060754",
          InvDate: "2019-06-01T00:00:00",
          InvAmt: 590700.0,
          PayNo: null,
          PayDate: null,
          PayAmt: null,
          Category: "I",
          Periode: "201906",
        },
        {
          DebtorAcct: "J5 / 002/O",
          InvNo: "JC/IS/19070924",
          InvDate: "2019-07-01T00:00:00",
          InvAmt: 590700.0,
          PayNo: null,
          PayDate: null,
          PayAmt: null,
          Category: "I",
          Periode: "201907",
        },
        {
          DebtorAcct: "J5 / 002/O",
          InvNo: "JC/IS/19080846",
          InvDate: "2019-08-01T00:00:00",
          InvAmt: 590700.0,
          PayNo: null,
          PayDate: null,
          PayAmt: null,
          Category: "I",
          Periode: "201908",
        },
        {
          DebtorAcct: "J5 / 002/O",
          InvNo: "JC/IS/19090722",
          InvDate: "2019-09-01T00:00:00",
          InvAmt: 590700.0,
          PayNo: null,
          PayDate: null,
          PayAmt: null,
          Category: "I",
          Periode: "201909",
        },
        {
          DebtorAcct: "J5 / 002/O",
          InvNo: "JC/IS/19100812",
          InvDate: "2019-10-01T00:00:00",
          InvAmt: 590700.0,
          PayNo: null,
          PayDate: null,
          PayAmt: null,
          Category: "I",
          Periode: "201910",
        },
        {
          DebtorAcct: "J5 / 002/O",
          InvNo: "JC/IS/19111028",
          InvDate: "2019-11-01T00:00:00",
          InvAmt: 590700.0,
          PayNo: null,
          PayDate: null,
          PayAmt: null,
          Category: "I",
          Periode: "201911",
        },
        {
          DebtorAcct: "J5 / 002/O",
          InvNo: "JC/IS/19121089",
          InvDate: "2019-12-01T00:00:00",
          InvAmt: 590700.0,
          PayNo: null,
          PayDate: null,
          PayAmt: null,
          Category: "I",
          Periode: "201912",
        },
      ],
      list: [],
      listDataOutstanding: [],
      listMultiPay: [],
      icons: [],
      all: [{ id: 0, image: more, label: "All" }],
      currentCategory: 0,
      start: 0,
      Debtor: "",
      UnitCode: "",
      loadPaymentFinished: false,
      listUnitCode: [],
      currentUnitCode: "",
      currentLotNo: "",
      LotNo: "",
      token: "",
      sub_total: "",
      selected: {},
      selectAll: 0,
      listMulti: [],
      email: "",
      dataXendit: [],
      DebtorAcct: "",
      CompanyCode: "",
      ProjectCode: "",
      checked: 0,
      voucher: {},
      amountdiscount: "",
      amountdisc: "",
      fixAmount: "",
      tesAmount: "",
      showLoading: false,
      dataDebtor: [
        {
          IsValid: true,
          Message: "Succeed.",
          DebtorAcct: "J5 / 002/O",
          LotNo: "J5 / 002",
          BisnisId: "JC/CI000580/O",
          ClusterName: "Thames",
          CompanyCode: "MDL",
        },
        {
          IsValid: true,
          Message: "Succeed.",
          DebtorAcct: "J6 / 001/O",
          LotNo: "J6 / 001",
          BisnisId: "JC/CI000582/O",
          ClusterName: "Thames",
          CompanyCode: "MDL",
        },
        {
          IsValid: true,
          Message: "Succeed.",
          DebtorAcct: "J7 / 017/O",
          LotNo: "J7 / 017",
          BisnisId: "JC/CI000598/O",
          ClusterName: "Thames",
          CompanyCode: "MDL",
        },
      ],
      aksesToken:
        "hi45yTGMbJ11N80A98qxxgjOOEswvZxkYmDVhTRlYuNz55HvJ1p40cyMPL-rIAWYGQry8LM88UFN00418GvOJWdimbuPl96YYF7DHOVzlbs9-oPG5KtrinhAGISft2XXSpWs-_0X_-Wz-gUn-F9WNxKVg_qeSuXps4E15EGdCWYSN41wHPThRfwEq-NKyyugHuJ1GRac_WlWzzGts0U_gA",
    };
    this.loginInfo = {
      phoneno: "",
      name: "",
    };
    this.loginBinding = {
      Debtor: "",
      UnitCode: "",
    };
    this.language = getLanguage(activelanguage, "paymentmultiple");
    this.toggleRow = this.toggleRow.bind(this);
    this.listel = null;

    // alert(this.state.list);
  }

  doJoinPaymentPaid = (debtor, dataPending) => {
    axios({
      method: "post",
      url: webserviceurl + "/app_payment_debtor.php",
      data: {
        Debtor: debtor,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let records1 = response.data.records;
        let records2 = response.data.records_item;

        var newResultDataPaid = records1.concat(records2);

        let resultLast = [];
        var i = 0;
        dataPending.map((val1, i1) => {
          newResultDataPaid.map((val2, i2) => {
            if (val1.InvNo === val2.v_external_id) {
              val1.status = "PAID";
            }
          });
          resultLast.push({
            DebtorAcct: val1.DebtorAcct,
            InvNo: val1.InvNo,
            InvDate: val1.InvDate,
            InvAmt: val1.InvAmt,
            PayNo: val1.PayNo,
            PayDate: val1.PayDate,
            PayAmt: val1.PayAmt,
            Category: val1.Category,
            Periode: val1.Periode,
            Status: val1.status,
          });
          i++;
        });

        console.log(resultLast);
        console.log(newResultDataPaid);

        var tmpData = resultLast;

        for (let i = 0; i < tmpData.length; i++) {
          tmpData[i].isChecked = false;
        }

        let listDataOutstanding = tmpData.filter((v) => v.Status !== "PAID");
        console.log(listDataOutstanding);

        this.setState({ listDataOutstanding: listDataOutstanding });
        this.setState({ list: tmpData });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  loadPayment = (debtor, unitcode, companycode, projectCode) => {
    let getToken = JSON.parse(localStorage.getItem("smart-app-id-token"));
    if (
      getToken == undefined ||
      getToken == null ||
      getToken == "null" ||
      getToken == "" ||
      getToken == "undefined"
    ) {
      let param = '{"code":"need-binding"}';
      this.sendPostMessage(param);
    }

    // this.setState({ token: getToken.access_token});
    // this.state.token = getToken.access_token;

    var config = {
      headers: { Authorization: "Bearer " + getToken.access_token },
    };

    this.setState({ showLoading: true });
    axios
      .post(
        webserviceurl + "/api_modernland.php",
        {
          Debtor: debtor,
          UnitCode: unitcode,
          ProjectCode: projectCode,
          token: getToken.access_token,
        },
        config
      )
      .then((response) => {
        // alert(JSON.stringify(response));
        if (response.data.status === "OK") {
          // var tmp = response.data.data;

          // for (let i = 0; i < tmp.length; i++) {
          //   tmp[i].isChecked = false;
          // }

          // this.setState({ list: tmp });

          this.doJoinPaymentPaid(debtor, response.data.data);
          // let sum = a => a.reduce((x, y) => x + y);
          // let totalAmount = sum(this.state.list.map(x => Number(x.InvAmt)));
          // this.setState({sub_total: totalAmount})
        }
        if (response.data.status === 401) {
          confirmAlert({
            message: response.data.message,
            buttons: [{ label: "OK" }],
          });
          // alert(response.data.message);
          // localStorage.setItem('smart-app-id-binding', null);
          // localStorage.removeItem('smart-app-id-binding');
          // localStorage.setItem('Modernland-Account', null);
          // localStorage.removeItem('Modernland-Account');
          // localStorage.setItem('smart-app-id-token', null);
          // localStorage.removeItem('smart-app-id-token');
          let param = '{"code":"need-binding"}';
          this.sendPostMessage(param);
        }

        setTimeout(() => {
          this.setState({ showLoading: false });
        }, 500);
      })
      .catch((error) => {
        this.setState({ showLoading: false });
        console.log(error);
      });
  };

  loadDataPayment = () => {
    var tmp = this.state.listData;

    for (let i = 0; i < tmp.length; i++) {
      tmp[i].isChecked = false;
    }
    this.setState({ list: tmp });
  };

  loadDebtor = async () => {
    let tmp = this.state.dataDebtor;
    let currentLotNo = "";
    let current = "";

    if (tmp.length > 0) {
      currentLotNo = tmp[0].LotNo;
      current = tmp[0].DebtorAcct + "|" + tmp[0].LotNo;
      await this.changeUnitCode(
        tmp[0].DebtorAcct +
          "|" +
          tmp[0].LotNo +
          "|" +
          tmp[0].CompanyCode +
          "|" +
          tmp[0].ProjectCode
      );
      // this.loadPayment(tmp[0].DebtorAcct, tmp[0].LotNo, tmp[0].CompanyCode);
      console.log(this.state.CompanyCode);
    }

    this.setState({
      listUnitCode: tmp,
      currentUnitCode: current,
      currentLotNo: currentLotNo,
    });
  };

  componentWillMount = () => {
    this.loadToken();
    this.loadAkun();

    console.log(this.state.aksesToken);
    console.log(this.state.dataDebtor);
  };

  componentDidMount = () => {
    this.waitForBridge();
    this.loadToken();
    this.loadBinding();
    // this.doJoinPaymentPaid("DC000206.00001/O", [
    //   {
    //     DebtorAcct: "DC000206.00001/O",
    //     InvNo: "JC1/IS/20020463",
    //     InvDate: "2020-02-01T00:00:00",
    //     InvAmt: 10000.0,
    //     PayNo: null,
    //     PayDate: null,
    //     PayAmt: null,
    //     Category: "I",
    //     Periode: "202002",
    //   },
    //   {
    //     DebtorAcct: "DC000206.00001/O",
    //     InvNo: "JC1/IS/20030489",
    //     InvDate: "2020-03-01T00:00:00",
    //     InvAmt: 10000.0,
    //     PayNo: null,
    //     PayDate: null,
    //     PayAmt: null,
    //     Category: "I",
    //     Periode: "202003",
    //   },
    //   {
    //     DebtorAcct: "DC000206.00001/O",
    //     InvNo: "JC1/IS/20040452",
    //     InvDate: "2020-04-01T00:00:00",
    //     InvAmt: 10000.0,
    //     PayNo: null,
    //     PayDate: null,
    //     PayAmt: null,
    //     Category: "I",
    //     Periode: "202004",
    //   },
    //   {
    //     DebtorAcct: "DC000206.00001/O",
    //     InvNo: "JC1/IS/20050456",
    //     InvDate: "2020-05-01T00:00:00",
    //     InvAmt: 10000.0,
    //     PayNo: null,
    //     PayDate: null,
    //     PayAmt: null,
    //     Category: "I",
    //     Periode: "202005",
    //   },
    //   {
    //     DebtorAcct: "DC000206.00001/O",
    //     InvNo: "JC1/IS/20060457",
    //     InvDate: "2020-06-01T00:00:00",
    //     InvAmt: 10000.0,
    //     PayNo: null,
    //     PayDate: null,
    //     PayAmt: null,
    //     Category: "I",
    //     Periode: "202006",
    //   },
    //   {
    //     DebtorAcct: "DC000206.00001/O",
    //     InvNo: "JC1/IS/20070471",
    //     InvDate: "2020-07-01T00:00:00",
    //     InvAmt: 10000.0,
    //     PayNo: null,
    //     PayDate: null,
    //     PayAmt: null,
    //     Category: "I",
    //     Periode: "202007",
    //   },
    //   {
    //     DebtorAcct: "DC000206.00001/O",
    //     InvNo: "JC1/IS/20080460",
    //     InvDate: "2020-08-01T00:00:00",
    //     InvAmt: 10000.0,
    //     PayNo: null,
    //     PayDate: null,
    //     PayAmt: null,
    //     Category: "I",
    //     Periode: "202008",
    //   },
    // ]);

    //this.loadPayment();
    // this.loadDebtor();
    // this.loadDataPayment();
    // this.listel = document.getElementById('list-payment');
    // this.listel.addEventListener('scroll', (e)=>{
    //     this.scrollCheck();
    // });
  };

  loadUser = () => {
    let tmp = localStorage.getItem("smart-app-id-login");
    if (
      tmp == undefined ||
      tmp == null ||
      tmp == "null" ||
      tmp == "" ||
      tmp == "undefined"
    ) {
      let param = '{"code":"need-login"}';
      this.sendPostMessage(param);
    } else {
      tmp = JSON.parse(tmp);
      this.loginInfo.phoneno = tmp.phonenumber;
      this.loginInfo.name = tmp.name;
    }
  };

  loadAkun = () => {
    if (
      localStorage.getItem("smart-app-id-binding") != undefined &&
      localStorage.getItem("smart-app-id-binding") != "undefined"
    ) {
      var com = localStorage.getItem("smart-app-id-binding");
      var binding = JSON.parse(com);

      // alert(JSON.stringify(binding));

      // this.setState({Debtor : binding.DebtorAcct === undefined?"":binding.DebtorAcct, UnitCode : binding.LotNo === undefined?"":binding.LotNo});
      //this.setState({phoneno : login.phonenumber===undefined?"":login.phonenumber, name : login.name===undefined?"":login.name, profilepic : login.profilepic===undefined?"":login.profilepic});
    }

    if (
      localStorage.getItem("smart-app-id-login") != undefined &&
      localStorage.getItem("smart-app-id-binding") != "undefined"
    ) {
      var user = localStorage.getItem("smart-app-id-login");
      var userID = JSON.parse(user);

      this.setState({ email: userID.email });

      // alert(this.state.email);
    }
  };

  loadToken = () => {
    let tkn = localStorage.getItem("smart-app-id-token");
    if (
      tkn == undefined ||
      tkn == null ||
      tkn == "null" ||
      tkn == "" ||
      tkn == "undefined"
    ) {
      let param = '{"code":"need-binding"}';
      this.sendPostMessage(param);
    } else {
      tkn = JSON.parse(tkn);
      // alert(JSON.stringify(tkn.access_token));
      this.setState({ token: JSON.stringify(tkn.access_token) });
    }
  };

  loadBinding = async () => {
    let tmp = localStorage.getItem("smart-app-id-binding");
    let tmp2 = localStorage.getItem("Modernland-Account");
    if (
      tmp2 == undefined ||
      tmp2 == null ||
      tmp2 == "null" ||
      tmp2 == "" ||
      tmp2 == "undefined"
    ) {
      let param = '{"code":"need-binding"}';
      this.sendPostMessage(param);
    }
    if (
      tmp == undefined ||
      tmp == null ||
      tmp == "null" ||
      tmp == "" ||
      tmp == "undefined"
    ) {
      let param = '{"code":"need-binding"}';
      this.sendPostMessage(param);
    } else {
      tmp = JSON.parse(tmp);
      let currentLotNo = "";
      let current = "";
      if (tmp.length > 0) {
        currentLotNo = tmp[0].LotNo;
        current = tmp[0].DebtorAcct + "|" + tmp[0].LotNo;
        await this.changeUnitCode(
          tmp[0].DebtorAcct +
            "|" +
            tmp[0].LotNo +
            "|" +
            tmp[0].CompanyCode +
            "|" +
            tmp[0].ProjectCode
        );
        this.loadPayment(
          tmp[0].DebtorAcct,
          tmp[0].LotNo,
          tmp[0].CompanyCode,
          tmp[0].ProjectCode
        );

        console.log(this.state.CompanyCode);

        // item.DebtorAcct+'|'+item.LotNo
      }

      this.setState({
        listUnitCode: tmp,
        currentUnitCode: current,
        currentLotNo: currentLotNo,
      });
      //this.state.Debtor = tmp.DebtorAcct;
      //this.state.UnitCode = tmp.LotNo;
    }
  };

  sendPostMessage = (param) => {
    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.sendPostMessage(param);
        }.bind(this),
        200
      );
    } else {
      window.postMessage(param, "*");
    }
  };

  componentWillUnmount = () => {
    // this.listel.removeEventListener('scroll', (e)=>{
    //     this.scrollCheck();
    // });
  };

  scrollCheck = () => {
    if (
      this.listel.scrollTop + this.listel.clientHeight >=
      this.listel.scrollHeight
    ) {
      if (this.state.loadPaymentFinished == false) {
        this.loadPayment(this.state.currentCategory, this.state.start);
      }
    }
  };

  loadVoucher = () => {
    axios
      .post(webserviceurl + "/app_load_voucher.php")
      .then((response) => {
        console.log(response.data);
        let data = {};
        if (response && response.data.records && response.data.records.length) {
          data = response.data.records[0];
        }
        this.setState({ voucher: data });

        let totalAmount = 0;
        let totalDiscount = 0;
        let totalPrice = 0;
        let totalPriceAll = 0;

        totalAmount = this.state.sub_total;
        totalDiscount = this.state.voucher.voucherdiscount;
        totalPrice = (totalAmount * totalDiscount) / 100;
        totalPriceAll = totalAmount - totalPrice;

        this.setState({ fixAmount: totalPriceAll });
        console.log(totalAmount);
        console.log(totalDiscount);
        console.log(totalPrice);
        console.log(totalPriceAll);
        console.log(this.state.fixAmount);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let tmp = localStorage.getItem("smart-app-id-login");
      let needLogin = false;
      // let tmp2 = localStorage.getItem('smart-app-id-binding');
      // let needBinding = false;

      if (
        tmp == undefined ||
        tmp == null ||
        tmp == "null" ||
        tmp == "" ||
        tmp == "undefined"
      )
        needLogin = true;
      // else if(tmp2 == undefined || tmp2 == null || tmp2 == 'null' || tmp2 == '' ||tmp2 == 'undefined' ) needBinding = true;
      else {
        tmp = JSON.parse(tmp);
        this.loginInfo.phoneno = tmp.phonenumber;
        this.loginInfo.name = tmp.name;
      }

      let param =
        '{"title":"' +
        this.language.title +
        '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true, "needLogin":' +
        (needLogin ? "true" : "false") +
        "}";
      window.postMessage(param, "*");
    }
  }

  createMarkup = (content) => {
    return { __html: content };
  };

  doMultiplePay = () => {
    this.props.history.push("/paymentmultipledetail");
  };

  toggleRow(firstName) {
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[firstName] = !this.state.selected[firstName];
    this.setState({
      selected: newSelected,
      selectAll: 2,
    });
  }

  toggleSelectAll(e) {
    let tmp = this.state.listDataOutstanding;
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].isChecked = e.target.checked;
    }
    console.log(tmp);
    this.setState({ listDataOutstanding: tmp, selectAll: e.target.checked });

    // alert(JSON.stringify(this.state.list));

    // let sum = a => a.reduce((x, y) => x + y);
    // let totalAmount = sum(this.state.list.map(x => Number(x.InvAmt)));
    // this.setState({sub_total: totalAmount})

    let multiPay = [];
    var i = 0;
    var tmp_total = 0;
    tmp.map((idx, val) => {
      if (idx.isChecked === true) {
        multiPay.push({ name: idx.InvNo, quantity: 1, price: idx.InvAmt });
        this.setState({ DebtorAcct: idx.DebtorAcct });
        tmp_total += idx.InvAmt;
      }
      i++;
    });

    this.setState({ listMulti: multiPay, sub_total: tmp_total });
    console.log(JSON.stringify(multiPay));
    // alert(JSON.stringify(multiPay));

    // this.loadVoucher();
  }

  handleCheck(e, i) {
    // let tmp = this.state.list;
    // console.log(tmp);
    // for (let j = 0; j < tmp.length; j++) {
    //   if (j <= i) {
    //     tmp[j].isChecked = e.target.checked;
    //   } else {
    //     tmp[j].isChecked = false;
    //   }
    // }
    // this.setState({ list: tmp, checked: e.target.checked });

    let tmp = this.state.list;
    tmp[i].isChecked = e.target.checked;
    console.log(tmp);
    this.setState({ list: tmp });
    this.setState({ checked: tmp[i].isChecked });

    let multiPay = [];
    var i = 0;
    var tmp_total = 0;
    tmp.map((idx, val) => {
      if (idx.isChecked === true) {
        multiPay.push({ name: idx.InvNo, quantity: 1, price: idx.InvAmt });
        this.setState({ DebtorAcct: idx.DebtorAcct });
        tmp_total += idx.InvAmt;
      }
      i++;
    });

    this.setState({ listMulti: multiPay, sub_total: tmp_total });
    console.log(JSON.stringify(multiPay));
  }

  goFormXendit = () => {
    const body = JSON.stringify({
      debtoracct: this.state.DebtorAcct,
      companycode: this.state.CompanyCode,
      external_id: "ID/MP/" + makeid(10),
      amount: this.state.sub_total,
      // "amount": this.state.fixAmount,
      payer_email: this.state.email.replace(/\s/g, ""),
      description: "Multi Payment Invoice IPKL",
      items: this.state.listMulti,
    });

    // this.state.sub_total

    // alert(JSON.stringify(body));

    axios
      .post(webserviceurl + "/apiXendit/create_invoice_multi.php", body)
      .then((response) => {
        // alert(JSON.stringify(response.data));
        this.setState({ dataXendit: response.data });
        // alert(this.state.dataXendit.id);
        this.props.history.push(
          "/paymentfrom/" + encodeURIComponent(this.state.dataXendit.id)
        );
      })
      .catch(function (error) {
        console.log(error);
        // alert(JSON.stringify(error));
      });
  };

  renderLoading = () => {
    if (this.state.showLoading) {
      return <LoadingScreen></LoadingScreen>;
    }
  };

  renderCheckStatus = (data, i) => {
    if (data.Status === "PAID") {
      return (
        <td>
          <input
            disabled
            type="checkbox"
            name={data.InvAmt}
            checked={data.isChecked}
            onChange={(e) => this.handleCheck(e, i)}
          />
        </td>
      );
    } else {
      return (
        <td>
          <input
            type="checkbox"
            name={data.InvAmt}
            checked={data.isChecked}
            onChange={(e) => this.handleCheck(e, i)}
          />
        </td>
      );
    }
  };

  renderTab = () => {
    return (
      <div className="body-section payment-scroll-view-multiple">
        <div className="payment-select-unit">
          <select
            className="payment-select-unit"
            value={this.state.currentUnitCode}
            onChange={(e) => this.changeUnitCode(e.target.value)}
          >
            {this.state.listUnitCode.map((item, i) => (
              <option
                value={
                  item.DebtorAcct +
                  "|" +
                  item.LotNo +
                  "|" +
                  item.CompanyCode +
                  "|" +
                  item.ProjectCode
                }
              >
                Unit Code: {item.LotNo} - Cluster: {item.ClusterName}{" "}
              </option>
            ))}
          </select>
        </div>
        <table>
          <tbody>
            <tr>
              <td style={{ textAlign: "left", paddingLeft: 6 }}>
                <label>
                  <text>Total Amount:</text>
                  <div>{convertToRupiah(this.state.sub_total)}</div>
                  {/* <div style={{color:'#e60000', textDecoration:'line-through' }} >{convertToRupiah(this.state.sub_total)}</div> */}
                  {/* <text>Rp. {numberFormat(this.state.fixAmount)}</text> */}
                </label>
              </td>
              <td style={{ textAlign: "right", paddingRight: 6 }}>
                <label>
                  <input
                    type="checkbox"
                    checked={this.state.selectAll}
                    onChange={(e) => this.toggleSelectAll(e)}
                  />
                  Select all
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        {this.state.list.map((data, i) => (
          <div className="payment-list-item">
            <table>
              <tr key={i}>
                {this.renderCheckStatus(data, i)}
                <td>
                  <div className="name-container">{data.InvNo}</div>
                  <div className="category-container">
                    Periode {moment(data.InvDate).format("MMMM Y")}
                  </div>
                </td>
                <td style={{ width: "150px" }}>
                  <div className="amount-container">
                    {convertToRupiah(data.InvAmt)}
                  </div>
                  {this.renderPayAmt(data)}
                </td>
              </tr>
            </table>
          </div>
        ))}
        {/* <div>
                        <Button color="success" onClick={() => this.goFormXendit()} block>PAY MULTIPLE</Button>
                    </div> */}
      </div>
    );
  };

  // renderPayAmt = () => {
  //   if (this.state.PayAmt != null) {
  //     return (
  //       <div className="paiddate-container">
  //         {this.language.paid}: {this.state.paiddate}
  //       </div>
  //     );
  //   } else {
  //     return <div className="unpaid-container">{this.language.pending}</div>;
  //   }
  // };

  renderPayAmt = (data) => {
    if (data.PayAmt != null) {
      return (
        <div className="paiddate-container">
          {this.language.paid}: {data.paiddate}
        </div>
      );
    } else if (data.Status === "PAID") {
      return (
        <div className="Received-container">
          {/* Payment Received <br></br> Pending Clearance */}
          Payment Accepted
        </div>
      );
    } else {
      return <div className="unpaid-container">{this.language.pending}</div>;
    }
  };

  renderLink = (icon) => {
    if (icon.id === this.state.currentCategory) {
      return (
        <div
          className="payment-link-label payment-link-active"
          onClick={() => this.loadPayment(icon.id)}
        >
          <img src={icon.image} />
          <br />
          {icon.label}{" "}
        </div>
      );
    } else {
      return (
        <div
          className="payment-link-label"
          onClick={() => this.loadPayment(icon.id)}
        >
          <img src={icon.image} />
          <br />
          {icon.label}{" "}
        </div>
      );
    }
  };

  changeUnitCode = (value) => {
    let arr = value.split("|");
    this.setState({ LotNo: arr[1] });
    this.setState({ CompanyCode: arr[2] });
    this.setState({ ProjectCode: arr[3] });
    this.loadPayment(arr[0], arr[1], arr[2], arr[3]);
    this.setState({ currentUnitCode: value });

    setTimeout(() => {
      // alert(this.state.LotNo + '///////' + 'CompanyCode' + this.state.CompanyCode + '......' + this.state.currentUnitCode);
    }, 3000);
  };

  canBeSubmitted() {
    const { checked, selectAll } = this.state;
    return checked || selectAll;
  }

  render() {
    if (this.state.redirect) {
      /*if (this.state.page == "newspage") {
                return <Redirect to='/news' />;
            }*/
    }

    const isEnabled = this.canBeSubmitted();

    return (
      <div className="main-container">
        <SubHeader
          history={this.props.history}
          hideSearch={true}
          title={this.language.title}
        />
        {this.renderTab()}
        {this.renderLoading()}
        <div className="payment-button-container">
          <Button
            color="info"
            className="fullbuttonMultiple"
            disabled={!isEnabled}
            onClick={() => this.goFormXendit()}
          >
            PAY MULTIPLE
          </Button>
        </div>
      </div>
    );
  }
}

export { GetDetailData };
export default PaymentCenterMultiple;
