var Languages = {
    "eng": {
        /**
         * GLOBAL
         */
        "global":{
            'loadmore': 'Load More',
            'yes': 'Yes',
            'no': 'No',
            'ok': 'OK',
            'cancel':'Cancel',
            'submit':'Submit',
            'next': 'Next',
            'submit': 'Submit',
            'january': 'January',
            'february': 'February',
            'march': 'March',
            'april': 'April',
            'may': 'May',
            'june': 'June',
            'july': 'July',
            'august': 'August',
            'september': 'September',
            'october': 'October',
            'november': 'Novmeber',
            'december': 'December',
            'all': 'All',
            'pay': 'Pay',
            'nodata': 'No data available'
        },
        /**
         * COMPONENT
         */
        "subheader":{
            'back': 'Back',
            'search': 'Search'
        },
		'PictureUploader':{
            'placeholder': 'Drag your file here or click "Choose file" button',
            'choosefile': 'Choose File',
            'maxfile': 'Max File',
            'pictures': 'Picture(s)',
            'reachlimit': 'Already reach limit file'
        },
        /**
         * PAGE
         */
        "home": {
            "title": 'Home',
            "news": 'News',
            "inbox": 'Inbox',
            "moments": 'Moments',
            "emergency": 'Emergency',
            "trafficcamera": 'Traffic Camera',
            "visitoraccess": 'Visitor Access',
            "smartparking": 'Smart Parking',
            "callcenter": 'Call Center',
            "servicecenter": 'Service Center',
            "mybills": 'My Bills',
            "more": 'More',
            "room": 'Bookings',
            "customercare": 'Customer Care'
        },
        "homebinding":{
            "title": 'Home Binding'
        },
		"activityreservationconfirmation":{
            "title": 'Confirmation',
        },
        "activityreservationform":{
            "title": 'New Activity Reservation',
            "activity": 'Select Activity',
            "user": 'User',
            "datecheckin": 'Check In Date',
			"datecheckout": 'Check Out Date',
			"timecheckin": 'Check In Time',
			"timecheckout": 'Check Out Time',
			"addon": 'Add-Ons',
			"error": 'Please input a valid date!',
			"alreadybooked": 'The time has been booked by another user, please book another time',
            "areyousure": 'Are you sure want to submit new request?',
            "success": 'Your request has been saved. Thank you.'
        },
		"activitycategoryselect":{
            "title": 'Select Activity Category',
        },
		"activityreservationdetail":{
            "title": 'Activity Reservation',
            "status": 'Status',
            "pending": 'Pending',
            "completed": 'Completed',
			"cancelled": 'Cancelled'
        },
		"comingsoonpage": {
            "title": 'Coming Soon',
            "label": 'Coming Soon'
        },
        "companypage": {
            "company": 'Company',
        },
        "companyprofilepage": {
            "company": 'Company',
            "information": 'Information',
            "service": 'Service',
            "product": 'Product',
            "like": 'Like',
            "follower": 'Follower',
            "description": 'Description',
            "about": 'About Us'
        },
		"coorporatecontact":{
            "title": 'Call Service',
        },
		"directory":{
            "title": 'Directory',
        },


        "directorydetail": {
            "back": 'Back',
            "title": "Directory",
			"name": "Name",
			"address": "Address",
			"phone": "Phone Number",
			"website": "Website"
        },
		"directorycategoryselect":{
            "title": 'Directory',
        },

        "foodgarden":{
            "title": 'Food Garden',
        },

        "foodgardenprofile":{
            "title": 'Food garden Detail',
            "nodata":'No Data',
            "yes": 'Yes',
            "no": 'No',
            "confirmation": 'Confirmation',
            "areyousure": 'You already have product in cart, are you sure want to cancel?',
            "cart": 'Cart',
            "sold": 'Sold',
            "units": 'Units',
            "menu": 'Menu',
            "comments": 'Comments',
            "info": 'Info',
            "nocomment":'No Comment Available',
            "noinfo": 'No Info Available',
			"pay": 'Pay',
			"deliver": "Deliver to",
			"address": "Address",
			"success": 'We will process your order, wait a few minutes.',
            "validation": 'Add item to cart first!',
            "areyoupay":'Are you sure want to pay this cart?'
        },

        "orderfoodactivity":{
            "title":'Order Food Activity',
            "proses": 'On Delivery',
            "completed": 'Completed',
        },

        "orderfoodactivitydetail":{
            "title":'Order Food Detail',
            "proses": 'On Delivery',
            "completed": 'Completed',
            "price": 'Total Payment',
            "name": 'Name',
            "request": 'Request',
            "requestdate": 'Request Date',
            "status": 'Status Payment',
            "status_services": 'Status Order',
            "address": 'Deliver to',
            "note": 'Note',
            "pending": 'Pending',
            "process": 'In Process',
            "done": 'Done',
            "ordernumber": 'Order Number',
            "item":'Total Item',
            "paymentmethod":'Payment Method',
            "locationdetail":'Location Detail'
        },

        "historyfoodorder":{
            "title":'History Food Order',
            "proses": 'in Delivery',
            "completed": 'COMPLETED',
            "done": 'DONE'
        },

        "historyfoodorderdetail":{
            "title":'Transaction Detail',
            "proses": 'in Delivery',
            "completed": 'COMPLETED',
            "done":'DONE',
            "delivered":'Delivered',
            "price": 'Total Payment',
            "name": 'Name',
            "request": 'Request',
            "requestdate": 'Request Date',
            "status": 'Status Payment',
            "status_services": 'Status Order',
            "address": 'Deliver to',
            "note": 'Note',
            "pending": 'Pending',
            "process": 'In Process',
            "paymentsuccess":'Payment Success',
            "done": 'Done',
            "ordernumber": 'Order Number',
            "item":'Total Item',
            "paymentmethod":'Payment Method',
            "locationdetail":'Location Detail'
        },

		"entertainmentpage": {
            "title": 'Entertainment',
            "errorOnMessage": 'Failed to open browser'
        },
        "investmentpage": {
            "investment": 'Investment',
            "nodata":'没有可用数据'
        },
        "investmentdetailpage": {
            "title": 'Investment',
            "information": 'Information',
            "description": 'Description',
        },
        "marketplace":{
            "title": 'Marketplace',
        },
        "statuscallback":{
            "title": 'Status Payment',
            "success": 'You Successfully for payment',
            "thnk": 'Thank You'
        },
        "merchantlist":{
            "title": 'Merchant',
            "nodata":'No Data'
        },
        "merchantprofile":{
            "title": 'Merchant',
            "nodata":'No Data',
            "yes": 'Yes',
            "no": 'No',
            "confirmation": 'Confirmation',
            "areyousure": 'You already have product in cart, are you sure want to cancel?',
            "cart": 'Cart',
            "sold": 'Sold',
            "units": 'Units',
            "menu": 'Menu',
            "comments": 'Comments',
            "info": 'Info',
            "nocomment":'No Comment Available',
            "noinfo": 'No Info Available',
			"pay": 'Pay',
			"deliver": "Deliver to",
			"address": "Address",
			"success": 'Order Success',
			"validation": 'Add item to cart first'
        },
		"moments": {
            "moments": 'Moments',
			"nodata":'No Data',
			"description": 'Description',
			"placeholder": 'Describe your moment here',
			"upload": 'Upload Picture'
        },
		"news": {
            "title": 'News',
            "current": 'Current',
            "history": 'History',
            "nodata": 'No data available',
            "community": 'Community',
            "dynamax": 'Jakarta Garden City',
            "newsfeed": 'Newsfeed'
        },
        "newsdetail": {
            "back": 'Back',
            "title": "News"
        },
		"onlinestore": {
            "title": 'Online Store',
			"nodata":'No Data'
        },
		"parkinformation": {
            "parkinformation": 'Park Information',
            "parkintroduction": 'Introduction',
            "servicephilosophy": 'Service',
            "parkpositioning": 'Positioning',
        },
        "parkenterprise": {
            "parkenterprise": 'Park Enterprise',
            "companyintroduction": 'Introduction',
            "businesscase": 'Business',
            "corporatecontact": 'Contact',
        },
        "paymentcenter":{
            "title": 'Payment Center',
        },
        "paymentmultiple":{
            "title": 'Payment Multiple Center',
            "paid": 'Paid',
            "pending": 'Pending Payment'
        },
        "selectpayment":{
            "title": 'Method Payment',
        },
        "statuscallback":{
            "title": 'Status Payment',
        },
        "paymentcenterdetail":{
            "title": 'Payment Center Detail',
            "periode": 'Periode',
            "amount": 'Amount',
            "periode": 'Periode',
            "type": 'Type',
            "name": 'Name',
            "phone": 'Phone',
            "address": 'Address',
            "status": 'Status',
            "pending": 'Pending Payment',
            "paid": 'Paid',
            "paiddate": 'Paid Date',
            "pay": 'PAY',
            "debacct": 'Debtor Account',
            "invno": 'Invoice Number',
            "payno": 'Payment Number',
            "payamt": 'Paid Amount',
            "paydate": 'Paid Date',
            "ipkl"  : 'IPKL',
            "category" :'Category'

        },
        "paymentmultipledetail":{
            "title": 'Payment Multiple Detail',
            "periode": 'Periode',
            "amount": 'Total Amount',
            "type": 'Type',
            "name": 'Name',
            "phone": 'Phone',
            "address": 'Address',
            "status": 'Status',
            "pending": 'Pending Payment',
            "paid": 'Paid',
            "paiddate": 'Paid Date',
            "pay": 'PAY',
            "debacct": 'Debtor Account',
            "invno": 'Invoice Number',
            "invomulti": 'Multiple Invoice',
            "payno": 'Payment Number',
            "payamt": 'Paid Amount',
            "paydate": 'Paid Date',
            "ipkl"  : 'IPKL',
            "category" :'Category'

        },
        "paymentcenterlist":{
            "paid": 'Paid',
            "pending": 'Pending Payment'
        },
		"productpage": {
            "product": 'Product',
        },
        "projectpage": {
            "product": 'Product',
        },
        "projectpage": {
            "project": 'Project',
            "nodata":'No Data Available'
        },
        "projectdetailpage": {
            "title": '项目',
            "information": '信息',
            "description": '描述',
        },
		"roomreservationdetail":{
            "title": 'Room Reservation',
            "status": 'Status',
            "pending": 'Pending',
            "completed": 'Completed',
			"cancelled": 'Cancelled'
        },
        "weddingreservationdetail":{
            "title": 'Wedding Reservation',
            "status": 'Status',
            "pending": 'Pending',
            "completed": 'Completed',
            "cancelled": 'Cancelled',
            "reschedule": 'Reschedule',
            "areyousureedit":'Are you sure you want to reschedule booked?'
        },
        "nonweddingreservationdetail":{
            "title": 'Facilities Reservation',
            "status": 'Status',
            "pending": 'Pending',
            "completed": 'Completed',
            "cancelled": 'Cancelled',
            "reschedule": 'Reschedule',
            "areyousureedit":'Are you sure you want to reschedule booked?'
        },
        "roomreservationconfirmation":{
            "title": 'Confirmation',
        },
        "roomreservationform":{
            "title": 'New Room Reservation',
            "room": 'Select Room',
            "user": 'User',
            "price": 'Total Price',
			"datecheckin": 'Check In Date',
			"datecheckout": 'Check Out Date',
			"timecheckin": 'Check In Time',
			"timecheckout": 'Check Out Time',
			"addon": 'Add-Ons',
			"error": 'Please input a valid date!',
			"alreadybooked": 'The time has been booked by another user, please book another time',
            "areyousure": 'Are you sure want to submit new request?',
            "success": 'Your request has been saved. Thank you.'
        },
        "weddingreservationform":{
            "title": 'Wedding Reservation',
            "banquet": 'Select Banquet Facilities',
            "user": 'User',
            "price": 'Total Price',
			"datecheckin": 'Check In Date',
			"datecheckout": 'Check Out Date',
			"timecheckin": 'Check In Time',
			"timecheckout": 'Check Out Time',
			"addon": 'Add-Ons',
			"error": 'Please input a valid date!',
			"alreadybooked": 'The time has been booked by another user, please book another time',
            "areyousure": 'Are you sure want to submit new request?',
            "success": 'Your request has been saved. Thank you.'
        },
        "nonweddingreservationform":{
            "title": 'Facilities Reservation',
            "banquet": 'Select Banquet Facilities',
            "user": 'User',
            "price": 'Total Price',
			"datecheckin": 'Check In Date',
			"datecheckout": 'Check Out Date',
			"timecheckin": 'Check In Time',
			"timecheckout": 'Check Out Time',
			"addon": 'Add-Ons',
			"error": 'Please input a valid date!',
			"alreadybooked": 'The time has been booked by another user, please book another time',
            "areyousure": 'Are you sure want to submit new request?',
            "success": 'Your request has been saved. Thank you.'
        },
		"roomcategoryselect":{
            "title": 'Select Room Category',
        },
        "weddingcategoryselect":{
            "title": 'Select Facilities Category',
        },
        "nonweddingcategoryselect":{
            "title": 'Select Facilities Category',
        },
        "servicecenter":{
            "title": 'Service Center',
            "pleaseaddqty": 'Please add quantity to proccess',
            "areyousure": 'Are you sure want to cancel?',
            "pending": 'Pending',
            "completed": 'Completed',
            "proccess": 'On Process',
            "done": 'Done',
            "status": 'Status'
        },
        "servicecenterdetail":{
            "title": 'User Service Detail',
            "price": 'Total Payment',
            "name": 'Name',
            "request": 'Request',
            "requestdate": 'Request Date',
            "status": 'Status Payment',
            "status_services": 'Status Services',
            "address": 'Address',
            "note": 'Note',
            "pending": 'Pending',
            "process": 'On Process',
            "done": 'Done',
            "completed": 'Completed'
        },
        "servicecenterconfirmation":{
            "title": 'Confirmation',
        },
		"servicecentercategoryselect":{
            "title": 'Service Center Category',
        },
        "servicecenterform":{
            "title_service": 'New Service Request',
            "user": 'User Request',
            "address": 'Address',
            "service": 'Service',
            "type" : 'Select Services',
			"price": 'Total Price',
            "requestdate": 'Request Date',
            "timecheckin": 'Request Time',
            "note": 'Note',
            "inputnote": 'Input note here',
            "inputaddress": 'Enter full address',
            "areyousure": 'Are you sure want to submit new request?',
            "success": 'Your request has been saved. Thank you.'
        },
        
		"servicepage": {
            "service": 'Service',
        },
		"spacebookings":{
            "title": 'Space Bookings',
			"roombooking": 'Room Bookings',
            "activitybooking": 'Activity Bookings',
            "pleaseaddqty": 'Please add quantity to proccess',
            "areyousure": 'Are you sure want to cancel?',
            "pending": 'Pending',
            "completed": 'Completed',
			"cancelled": 'Cancelled',
            "status": 'Status'
        },

        "historyservicecenterorder":{
            "title": ' History Service Center',
            "pleaseaddqty": 'Please add quantity to proccess',
            "areyousure": 'Are you sure want to cancel?',
            "pending": 'Pending',
            "completed": 'Completed',
            "proccess": 'On Process',
            "done": 'Done',
            "status": 'Status'
        },
        "historyservicecenterorderdetail":{
            "title": 'User Service Detail',
            "price": 'Total Payment',
            "name": 'Name',
            "request": 'Request',
            "requestdate": 'Request Date',
            "status": 'Status Payment',
            "status_services": 'Status Services',
            "address": 'Address',
            "note": 'Note',
            "pending": 'Pending',
            "process": 'On Process',
            "done": 'Done',
            "completed": 'Completed'
        },


        "banquetfacilities":{
            "title": 'Space Bookings',
			"roombooking": 'Banquet Facilities',
            "activitybooking": 'Banquet Facilities',
            "pleaseaddqty": 'Please add quantity to proccess',
            "areyousure": 'Are you sure want to cancel?',
            "pending": 'Pending',
            "completed": 'Completed',
			"cancelled": 'Cancelled',
            "status": 'Status'
        },
		"talentpage": {
            "talent": 'Talent',
            "nodata":'No Data Available'
        },
        "talentdetailpage": {
            "title": 'Talent',
            "information": 'Information',
            "description": 'Description',
        },
        "trafficcamera":{
            "title": 'Traffic Camera',
        },

        "market":{
            "title": 'Market',
        },

        "food":{
            "title": 'Food'
        },

        "laundry":{
            "title": 'Laundry'
        },

        "housekeeping":{
            "title":'House Keeping'
        },
        
        "maintenance":{
            "title":'Maintenance'
        }, 

        "moving":{
            "title":'Moving'
        }, 

        "massage":{
            "title":'Massages'
        },

        "customercare":{
            "title": 'Complaint',
            "fieldusername": 'User Complain',
            "fieldIncidentDate": 'Incident Date',
            "fieldComplainDate": 'Complain Date',
            "placeholderdesc":'Description...',
            "debtoracct":'Debtor Account',
            "fieldconfirm": 'Please enter your complain description!',
            "success":'Your complaint will be processed. thank you',
            "submit": 'Submit'

        },

		"visitoraccess":{
            "title": 'Visitor Access',
        },
        "visitoraccessdetail":{
            "title": 'Visitor Access Detail',
            "period": 'Period',
            "identity": 'Identity',
            "name": 'Name',
            "phone": 'Phone',
            "address": 'Address'
        },
		"visitoraccesslist":{
            
        },
		"merchantpayment":{
            "title": 'Cart',
            "nodata":'No Data',
            "yes": 'Yes',
            "no": 'No',
            "confirmation": 'Confirmation',
            "areyousure": 'You already have product in cart, are you sure want to cancel?',
            "cart": 'Cart',
            "sold": 'Sold',
            "units": 'Units',
            "menu": 'Menu',
            "comments": 'Comments',
            "info": 'Info',
            "nocomment":'No Comment Available',
            "noinfo": 'No Info Available',
			"pay": 'Pay',
			"success": 'Order Success'
        },
        "reschedulereservation":{
            "title":'Reschedule Reservation',
            "banquet": 'Select Banquet Facilities',
            "user": 'User',
            "price": 'Total Price',
			"datecheckin": 'Check In Date',
			"datecheckout": 'Check Out Date',
			"timecheckin": 'Check In Time',
			"timecheckout": 'Check Out Time',
			"addon": 'Add-Ons',
			"error": 'Please input a valid date!',
			"alreadybooked": 'The time has been booked by another user, please book another time',
            "areyousure": 'Are you sure want to submit new request?',
            "success": 'Your request has been saved. Thank you.',
            "submit": 'Submit'
        },
        "feedback":{
            "title": 'Feedback',
        },
        'healthpage':{
            "title":'Contact Tracing',
            "dailydeclaration":'Daily Declaration',
            "returntrip":'Return Trip',
            "springfestivaltravel":'Spring Festival travel',
            "gotodeclare":'Go to declare',
            "renewal":'Renewal',
            "today":'Today',
            "undeclared":'Undeclared',

        },

        // 'dailydeclaration':{
        //     "title":'Daily Declaration',
        //     "name":'Name',
        //     "company":'Company Name',
        //     "cityoftheday":'City Where',
        //     "thefollowing":'Whether I am the following group?',
        //     "coughing":'Was there a cough, chest tightness, fever, fatigue, runny nose, diarrhea that day?',
        //     "contactfriends":'Are there any symptoms in the previous article when contacting relatives or friends?',
        //     "havedcontacted":'Have you contacted the following persons on that day? ',
        //     "note":'If you have other questions, please note',
        //     "success":'Your form data has been saved. Thank you'
        // },

        'dailydeclaration':{
            "title":'Daily Declaration',
            "name":'Nama:',
            "company":'Nama perusahaan anda:',
            "cityoftheday":'Kota tempat tinggal anda saat ini:',
            "thefollowing":'Apakah Anda adalah termasuk dalam kelompok berikut?',
            "coughing":'Adakah anda batuk, sesak dada, demam, lelah, pilek, diare?',
            "contactfriends":'Jika anda mempunyai gejala pada pertanyaan no 2 diatas, apakah anda sebelumnya bertemu saudara atau teman?',
            "havedcontacted":'Sudahkah Anda menghubungi orang-orang berikut pada hari itu? ',
            "note":'Jika Anda memiliki pertanyaan lain, harap dicatat',
            "success":'Your form data has been saved. Thank you.'
        },


        // 'returntrip':{
        //     "title":'Return Trip Declaration',
        //     "name":'Name',
        //     "company":'Company Name',
        //     "phone":'Cellphone Number',
        //     "office":'Office Location',
        //     "bacttoofficetime":'Back to Office Time',
        //     "startworkinghour":'Start Working Hours (Including Remote Office)',
        //     "placeofdepature":'Departure Starting Point',
        //     "arrivalcity":'Arrival Point',
        //     "returntransfortation":'Return transportation?',
        //     "licenseplat":'Please fill in the flight number or license plate number of public transportation',
        //     "hubei":'Whether to pass through PSBB area?',
        //     "note":'If you have other questions, please note',
        //     "cancel":'Cancel',
        //     "determine":'Submit',
        //     "success":'Your form data has been saved. Thank you'
        // },

        'returntrip':{
            "title":'Return Trip Declaration',
            "name":'Nama:',
            "company":'Nama perusahaan anda:',
            "phone":'Nomor Hp aktif:',
            "office":'Lokasi kantor anda:',
            "bacttoofficetime":'Waktu kembali ke kantor:',
            "startworkinghour":'Mulai Jam Kerja (Termasuk Remote Office)',
            "placeofdepature":'Titik Awal Keberangkatan:',
            "arrivalcity":'Titik Kedatangan',
            "returntransfortation":'Transportasi yang anda gunakan?',
            "licenseplat":'Silakan isi nomor penerbangan atau nomor plat transportasi umum',
            "hubei":'Apakah anda melewati area PSBB?',
            "note":'Jika Anda memiliki pertanyaan lain, harap dicatat',
            "cancel":'Cancel',
            "determine":'Submit',
            "success":'Your form data has been saved. Thank you.'
        },

        // 'returnfrommudik':{
        //     "title":'Return From Mudik',
        //     "name":'Name',
        //     "company":'Company Name',
        //     "phone":'Cellphone Number',
        //     "office":'Office Location',
        //     "visitedduring":'City Travelled',
        //     "approached":'Have you been to or through PSBB area this two weeks?',
        //     "contactedpeople":'Have you contacted people who have visited or passed through PSBB area in the past two weeks?',
        //     "havefever":'Whether I have fever, fatigue, runny nose, diarrhea, cough, chest tightness and other symptoms in the past two weeks?',
        //     "hasrelative":'Whether relatives or friends have fever, fatigue, runny nose, diarrhea, cough, chest tightness, etc. in the past two weeks',
        //     "medical":'Have you seen a doctor in the past two weeks',
        //     "specify":'If there is medical treatment',
        //     "note":'Please explain in details',
        //     "cancel":'cancel',
        //     "determine":'Submit',
        //     "success":'Your form data has been saved. Thank you'
        // },

        'returnfrommudik':{
            "title":'Return From Mudik',
            "name":'Nama:',
            "company":'Nama perusahaan anda:',
            "phone":'Nomor Hp. Anda:',
            "office":'Lokasi kantor anda:',
            "visitedduring":'Kota yang dikunjungi:',
            "approached":'Apakah Anda pernah ke atau melalui area PSBB selama dua minggu ini?',
            "contactedpeople":'Sudahkah Anda menghubungi orang-orang yang telah mengunjungi atau melewati area PSBB dalam dua minggu terakhir?',
            "havefever":'Apakah anda mengalami demam, kelelahan, pilek, diare, batuk, sesak dada dan gejala lainnya dalam dua minggu terakhir?',
            "hasrelative":'Apakah kerabat atau teman mengalami demam, kelelahan, pilek, diare, batuk, sesak dada, dalam dua minggu terakhir?',
            "medical":'Pernahkah Anda mengunjungi dokter dalam dua minggu terakhir?',
            "specify":'Jika Anda memiliki pertanyaan lain, harap dicatat',
            "note":'Jika Anda memiliki pertanyaan lain, harap dicatat',
            "cancel":'cancel',
            "determine":'Submit',
            "success":'Your form data has been saved. Thank you'
        },

        // Personnel returning from or around the epidemic area
        // Persons with confirmed or suspected new coronary pneumonia
        // Home health isolation
        // Persons without the above
        // No physical discomfort
        // Have one or more symptoms
        // No physical discomfort
        // Have one or more symptoms
        // Contact with people who have been diagnosed or suspected
        // No previous contact with people history
        // Airplanes
        // Trains, High-speed rail
        // Cars, Buses, Public Buses, Self-Driving
        // Others

        "covid19": {
            "title": 'COVID-19',
            "current": 'Current',
            "history": 'History',
            "nodata": 'No data available',
            "newsfeed": 'Newsfeed',
            "callcenter": 'Call Center',
            "formcovid": 'Request Form',
            "success":'Your compliant form data has been saved. Thank you'
        },
        "newsfeeddetail": {
            "back": 'Back',
            "title": "Newsfeed Detail"
        },

        "compliantcovidhistory":{
            "title":'History Request Form'
        },
        "wfh":{
            "title":"WFH Information"
        },

        "wfhdetail":{
            "title":"WFH Information Detail"
        }


    },
    "chn":{
        /**
         * GLOBAL
         */
        "global":{
            'loadmore': '装载更多',
            'yes': '是',
            'no': '不是',
            'next': '下一个',
            'submit': '提交',
            'january': '一月',
            'february': '二月',
            'march': '三月',
            'april': '四月',
            'may': '五月',
            'june': '六月',
            'july': '七月',
            'august': '八月',
            'september': '九月',
            'october': '十月',
            'november': '十一月',
            'december': '十二月',
            'all': '所有',
            'nodata': '没有可用数据'
        },
        /**
         * COMPONENT
         */
        "subheader":{
            'back': '返回',
            'search': '搜索'
        },
		'PictureUploader':{
            'placeholder': '在此拖动文件或单击“选择文件”按钮',
            'choosefile': '选择文件',
            'maxfile': '最大文件',
            'pictures': '图片',
            'reachlimit': '已达到限制文件'
        },
        /**
         * PAGE
         */
        "home": {
            "title": '主页',
            "news": '新闻',
            "inbox": '收件箱',
            "moments": '瞬间',
            "emergency": '急',
            "trafficcamera": '交通摄像头',
            "visitoraccess": '访客访问',
            "smartparking": '智能停车',
            "callcenter": '呼叫中心',
            "servicecenter": '服务中心',
            "mybills": '我的账单',
            "more": '更多',
            "room": '房间'
        },
		"activityreservationconfirmation":{
            "title": '服务细节',
        },
        "activityreservationform":{
            "title": '新活动预订',
            "activity": '选择活动',
            "user": '用户',
			"datecheckin": '入住日期',
			"datecheckout": '离开日期',
			"timecheckin": '登记时间',
			"timecheckout": '退房时间',
			"addon": '加载项',
			"error": '请输入有效日期！',
			"alreadybooked": '该时间已被其他用户预订，请再次预订',
            "areyousure": '您确定要提交新请求吗？',
            "success": '您的请求已保存。 谢谢。'
        },
		"activitycategoryselect":{
            "title": '选择活动类别',
        },
		"activityreservationdetail":{
            "title": '活动预约',
            "status": '状态',
            "note": '注意',
            "pending": '有待',
            "completed": '完成',
			"cancelled": '取消'
        },
        "companypage": {
            "company": '公司',
            "nodata":'没有可用数据'
        },
        "companyprofilepage": {
            "title": '公司',
            "information": '信息',
            "service": '服务',
            "product": '产品',
            "like": '喜欢',
            "follower": '信徒',
            "description": '描述',
            "about": '左右'
        },
		"comingsoonpage": {
            "title": '数据未找到',
            "label": '数据未找到'
        },
		"coorporatecontact":{
            "title": '服务热线',
        },
		"directory":{
            "title": '目录',
        },
        "directorydetail": {
            "back": '背部',
            "title": "目录",
			"name": "名称",
			"address": "地址",
			"phone": "电话号码",
			"website": "网站"
        },
		"directorycategoryselect":{
            "title": 'Select Directory Category',
        },
		"entertainmentpage": {
            "title": '娱乐',
            "errorOnMessage": '无法打开浏览器'
        },
        "investmentpage": {
            "investment": '投资',
            "nodata":'没有可用数据'
        },
        "investmentdetailpage": {
            "title": '投资',
            "information": '信息',
            "description": '描述',
        },
        "marketplace":{
            "title": '市井',
        },
        "merchantlist":{
            "title": '商人',
            "nodata":'没有可用数据'
        },
        "merchantprofile":{
            "title": '商人',
            "nodata":'没有可用数据',
            "yes": '是',
            "no": '没有',
            "confirmation": '确认',
            "areyousure": '你有购物车中的商品，确定要取消吗？',
            "cart": '购物车',
            "sold": '售出',
            "units": '个单位',
            "menu": '菜单',
            "comments": '评论',
            "info": '信息',
            "nocomment":'没有评论',
            "noinfo": '没有可用信息',
			"pay": '工资',
			"deliver": "交付给",
			"address": "地址",
			"success": '订单成功',
			"validation": '首先将项目添加到购物车'
        },
		"moments": {
            "moments": '瞬间',
			"nodata":'没有可用数据',
			"description": '描述',
			"placeholder": '在这里描述你的时刻',
			"upload": '上传图片'
        },
		"news": {
            "title": '公告',
            "current": '当前',
            "history": '历史',
            "nodata":'没有可用数据',
            "community": '社区',
            "dynamax": 'Dynamax',
            "newsfeed": '新闻源'
        },
        "newsdetail": {
            "back": '回去',
            "title": "公告"
        },
		"onlinestore": {
            "title": '网上商城',
			"nodata":'没有可用数据'
        },
		"parkinformation": {
            "parkinformation": '园区介绍',
            "parkintroduction": '园区介绍',
            "servicephilosophy": '服务理念',
            "parkpositioning": '园区定位',
        },
        "parkenterprise": {
            "parkenterprise": '园区企业',
            "companyintroduction": '企业介绍',
            "businesscase": '企业案例',
            "corporatecontact": '企业联系人',
        },
        "paymentcenter":{
            "title": '付款中心',
        },
        "paymentcenterdetail":{
            "title": '付款细节',
            "amount": '金额付款',
            "periode": '期间',
            "type": '类型',
            "name": '名称',
            "phone": '电话号码',
            "address": '地址',
            "status": '状态',
            "pending": '待付款',
            "paid": '支付',
            "paiddate": '缴纳日期',
            "pay": '工资'
        },
        "paymentcenterlist":{
            "paid": '支付',
            "pending": '待付款'
        },
		"productpage": {
            "product": '产品',
            "nodata":'没有可用数据'
        },
        "productdetailpage": {
            "title": '产品',
            "information": '信息',
            "description": '描述',
        },
        "projectpage": {
            "project": '项目',
            "nodata":'没有可用数据'
        },
        "projectdetailpage": {
            "title": '项目',
            "information": '信息',
            "description": '描述',
        },
		"roomreservationdetail":{
            "title": '预订房间',
            "status": '状态',
            "note": '注意',
            "pending": '有待',
            "completed": '完成',
			"cancelled": '取消'
        },
        "roomreservationconfirmation":{
            "title": '服务细节',
        },
        "roomreservationform":{
            "title": '新房间预订',
            "room": '选择房间',
            "user": '用户',
            "checkin": '入住日期',
			"checkout": '离开日期',
			"addon": '加载项',
			"error": '请输入有效日期！',
			"alreadybooked": '该时间已被其他用户预订，请再次预订',
            "areyousure": '您确定要提交新请求吗？',
            "success": '您的请求已保存。 谢谢。'
        },
		"roomcategoryselect":{
            "title": '选择房间类别',
        },
        "servicecenter":{
            "title": '服务中心',
            "pleaseaddqty": '请为服务添加数量',
            "areyousure": '你确定要取消吗？',
            "pending": '有待',
            "completed": '完成',
            "status": '状态'
        },
		"servicecenterdetail":{
            "title": '用户服务明细',
            "price": '总价',
            "request": '请求',
            "requestdate": '查询日期',
            "status": '状态',
            "note": '注意',
            "pending": '有待',
            "completed": '完成'
        },
        "servicecenterconfirmation":{
            "title": '服务细节',
        },
		"servicecentercategoryselect":{
            "title": '选择服务中心类别',
        },
        "servicecenterform":{
            "title": '新服务请求',
            "service": '服务',
			"price": '价钱',
            "requestdate": '查询日期',
            "note": '注意',
            "inputnote": '输入注释在这里',
            "areyousure": '您确定要提交新请求吗？',
            "success": '您的请求已保存。 谢谢。'
        },
		"servicepage": {
            "service": '服务',
            "nodata":'没有可用数据'
        },
        "servicedetailpage": {
            "title": '服务',
            "information": '信息',
            "description": '描述',
        },
		"spacebookings":{
            "title": '预订房间',
			"roombooking": '预订房间',
            "activitybooking": '活动预订',
            "pleaseaddqty": '请为服务添加数量',
            "areyousure": '你确定要取消吗？',
            "pending": '有待',
            "completed": '完成',
			"cancelled": '取消',
            "status": '状态'
        },
		"talentpage": {
            "talent": '人才',
            "nodata":'没有可用数据'
        },
        "talentdetailpage": {
            "title": '人才',
            "information": '信息',
            "description": '描述',
        },
        "trafficcamera":{
            "title": '交通摄像头',
        },
		"visitoraccess":{
            "title": '访客访问',
        },
        "visitoraccessdetail":{
            "title": '访客访问细节',
            "period": '期',
            "identity": '身分',
            "name": '名称',
            "phone": '电话',
            "address": '地址'
        },
		"visitoraccesslist":{
            
        },
		"merchantpayment":{
            "title": '大车',
            "nodata":'没有可用数据',
            "yes": '是',
            "no": '没有',
            "confirmation": '确认',
            "areyousure": '你有购物车中的商品，确定要取消吗？',
            "cart": '购物车',
            "sold": '售出',
            "units": '个单位',
            "menu": '菜单',
            "comments": '评论',
            "info": '信息',
            "nocomment":'没有评论',
            "noinfo": '没有可用信息',
			"pay": '工资',
			"success": '订单成功'
        }
    }
}

export function getLanguage(country, page) {
    return Languages[country][page];
}