import React, { Component } from 'react';
import { Ring, Roller } from 'react-awesome-spinners'
import './LoadingScreen.style.css';
import { getLanguage } from '../../../Languages';
import { webserviceurl, activelanguage } from '../../../Config';

class LoadingScreen extends Component{

    constructor(props) {
        super(props);
        this.state={
            loading: true
        }
    }

    componentWillReceiveProps=(props)=>{
        
    }

    render() {
        return (
            <div style={{position: 'absolute',top:0,bottom:0,left:0,right:0,flex:1,backgroundColor:'rgba(0,0,0,.2)',alignItems: 'center',alignSelf: 'center',justifyContent: 'center',padding:100,elevation: 6,}}>
                <div style={{marginTop:100, alignContent:'center', justifySelf:'center', alignItems:'center',textAlign:'center'}} >
                    {/* <Roller
                        color={'#00bfff'}
                        size= {100}
                        color= {'#00bfff'}
                        sizeUnit= {'90px'}
                    /> */}
                    <img src={require('../../../images/loading2.gif')} style={{height:100, width:100}} alt="loading" />
                </div>
            </div>
        )
    }
}

export default LoadingScreen;