import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import Picture from '../../Component/pictureGallery';
import './CovidNewsDetail.style.css';
import { webserviceurl, activelanguage } from '../../../Config';
import axios from 'axios';
import SubHeader from '../../Component/SubHeader/SubHeader';
import SwipeableViews from 'react-swipeable-views';
import LoadingScreen from '../../Component/LoadingScreen/LoadingScreen';
import newsdefault from '../../../images/news_default.png';

class NewsCovidDetail extends Component{
    constructor(props) {
        super(props);

        this.language = getLanguage(activelanguage, 'newsfeeddetail');
        
        this.state = {
            navigateTo: '',
            newscovid: {},
            idNewsCovid: props.match.params.idnewscovid,
            categoryid:props.match.params.cat,
            currentIndex:props.match.params.currentIndex,
            listID:[],
            idx:0,
            left:false,
            right:false,
            onload:false,
            NewsCovidDataList:[],
            community:'',
            id:'',
            type:'',
            showLoading: false
        }
    }
    
    componentDidMount(){
        this.waitForBridge();
        this.loadNewsCovidDetail();
    }

    componentWillUnmount=()=>{
        localStorage.setItem('covidtab', '0');
    }
    

    setIndex=(list)=>{
        var index = list.findIndex(p => p.id == this.state.idNewsCovid)
        console.log(index);
        this.setState({
            currentIndex : index
        })
    }

    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }.bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":true, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
            window.postMessage(param, "*");
        }
    }

    loadNewsCovidDetail= () => {
        this.setState({showLoading:true});
        axios({
            method: 'post',
            url: webserviceurl + '/app_load_newsdetail_covid19.php',
            data: {
                id: this.state.idNewsCovid,
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
        })
            .then((response) => {
                let result = response.data;
                console.log(result);
                if (result.status === "OK") {
                    
                    this.setState({ newscovid: result.record });
                    this.setState({ community: result.record.communityid});
                    this.setState({ id: result.record.id});
                    this.setState({ type: result.record.categoryid});
                }

                this.loadNewsCovidDetailList();

                setTimeout(()=>{
                    this.setState({showLoading:false});
                }, 400); 

                
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    loadNewsCovidDetailList=()=>{
        this.setState({showLoading:true});
        axios({
            method: 'post',
            url: webserviceurl + '/app_load_newsdetailList_covid19.php',
            data: {},
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
        })
        .then((response) => {
            console.log(response);
            let result = response.data;
            console.log(result);
            if (result.status === "OK") {
                let list = this.state.NewsCovidDataList;
                for (var i = 0; i < result.records.length; i++) {
                    result.records[i].shortdesc = decodeURIComponent(result.records[i].shortdesc);
                    list.push(result.records[i]);

                }
                this.setIndex(list);
                this.setState({ NewsCovidDataList: list});
            }

            setTimeout(()=>{
                this.setState({showLoading:false});
            }, 400); 
            console.log(this.state.NewsCovidDataList);

        })
        .catch(function (error) {
            console.log(error);
        });
    }


    renderLoading=()=>{
        if(this.state.showLoading){
            return (
                <LoadingScreen></LoadingScreen>
            )
        }
    }


    renderThumbnail=(item)=>{
        if(item.previewmode === 1){
            return (
                <div className="tree-mode-image">
                    {item.thumbnail.map((img, i) =>
                        <div className="center-cropped">
                            <img src={img.picture} alt={'imgpic'}/>
                        </div>
                    )}
                </div>
            )
        }else{
            return (
                <div className="tree-mode-image">
                    <div className="center-cropped">
                        <img src={newsdefault} alt={'imgpic'}/>
                    </div>
                </div>
            )
        }
    }

    renderPreviewMode=(item)=>{
        if(item.previewmode === 1){
            return(
                <div className="news-content" key={item.id} >
                    <div className="news-title">{item.title}</div>
                        <div className="descriptime" style={{marginBottom:10}} >
                            <div className="timeupdate" >
                                <span>{item.time} </span>
                            </div>
                            <div className="reading" >
                                <span>{item.reading} Reading</span>
                            </div>
                        </div>
                        <Picture images={item.newspic} theme={"full"} />
                        {this.renderThumbnail(item)}
                        <div className="news-description" dangerouslySetInnerHTML={{__html: item.fulldesc}}></div>
                </div>
            )
        }
        if(item.previewmode === 2 || item.previewmode === 4){
            return(
                <div className="news-content" key={item.id} >
                    <div className="news-title">{item.title}</div>
                        <div className="descriptime" style={{marginBottom:10}} >
                            <div className="timeupdate" >
                                <span>{item.time} </span>
                            </div>
                            <div className="reading" >
                                <span>{item.reading} Reading</span>
                            </div>
                        </div>
                        <Picture images={item.newspic} theme={"full"} />
                        
                        <div className="news-description" dangerouslySetInnerHTML={{__html: item.fulldesc}}></div>
                </div>
            )
        }
        if(item.previewmode === 3){ //
            return(
                <div className="news-content" key={item.id} >
                    <div className="news-title">{item.title}</div>
                        <div className="descriptime" style={{marginBottom:10}} >
                            <div className="timeupdate" >
                                <span>{item.time} </span>
                            </div>
                            <div className="reading" >
                                <span>{item.reading} Reading</span>
                            </div>
                        </div>
                        {/* <Picture images={item.newspic} theme={"full"} /> */}
                        <div className="news-description" dangerouslySetInnerHTML={{__html: item.fulldesc}}></div>
                </div>
            )
        }
        if(item.previewmode === 5 || item.previewmode === 6 ){ //tree-image
            return(
                <div className="news-content" key={item.id} >
                    <video width="100%" controls style={{marginTop:10}} >
                        <source src={item.video_url[0]} type="video/mp4" />
                    </video>
                    <div className="news-title">{item.title}</div>
                        <div className="descriptime" style={{marginBottom:10}} >
                            <div className="timeupdate" >
                                <span>{item.time} </span>
                            </div>
                            <div className="reading" >
                                <span>{item.reading} Reading</span>
                            </div>
                        </div>
                        <div className="news-description" dangerouslySetInnerHTML={{__html: item.fulldesc}}></div>
                </div>
            )
        }
    }
    
    renderNewsCovidDataList=()=>{
        if (this.state.NewsCovidDataList.length > 0) {
            return (
                <SwipeableViews index={this.state.currentIndex}>
                {this.state.NewsCovidDataList.map((item) =>
                    <div className="main-container whitebg">
                        {this.renderPreviewMode(item)}
                    </div>
                )}
            </SwipeableViews>
            )

        } else {
            return (
                <div className="no-data-available"></div>
            )
        }
    }

    render() {
        if (this.state.navigateTo != '') {
            return <Redirect to={this.state.navigateTo} />;
        }
        return (
        
            <div>
            <SubHeader history={this.props.history} hideSearch={true} title={this.language.title} showAddButton={false} goBack={this.goBack}/>
               {this.renderNewsCovidDataList()}
               {this.renderLoading()}
            </div>
        )
    }
}

export default NewsCovidDetail;