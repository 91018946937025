import React, { Component } from 'react';
//import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import axios from 'axios';
import './HealthDetailDailyDeclaration.css';
import { webserviceurl, activelanguage, appId } from '../../../Config';
import SubHeader from '../../Component/SubHeader/SubHeader';
import {
    Card, CardImg, CardText, CardBody, CardLink,
    CardTitle, CardSubtitle,Button, Container, Row, Col, Input
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; 
import LoadingScreen from '../../Component/LoadingScreen/LoadingScreen';

class HealthDetailDailyDeclaration extends Component {

    constructor(props) {
        super(props);
        this.language = getLanguage(activelanguage, 'dailydeclaration');
        this.globallang = getLanguage(activelanguage, 'global');

        this.state = {
			phonenumber:props.match.params.phonenumber,
            date:props.match.params.date,
            name:'',
            cluster:'',
            blok:'',
            city:'',
            combo1:0,
            combo2:0,
            combo3:0,
            combo4:0,
            note:'',
            page: "",
            redirect: false,
            title: this.language.title,
            radioSelectOption:1,
            DAILYCOMBO1:[],
            DAILYCOMBO2:[],
            DAILYCOMBO3:[],
            DAILYCOMBO4:[],
            showLoading: false
        }   
        
    }

    componentDidMount=()=>{
        this.waitForBridge();
        this.loadUser();
        this.loadDataByPhone();
        this.DAILYCOMBO1();
        this.DAILYCOMBO2();
        this.DAILYCOMBO3();
        this.DAILYCOMBO4();
    }

    componentWillUnmount=()=>{
        localStorage.setItem('health', '0');
    }
    
    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":true, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
            window.postMessage(param, "*");
        }
    }

    createMarkup = (content) => {
        return { __html: content };
    }

    loadUser = () => {
        let info = localStorage.getItem('smart-app-id-login');
         if(info != null && info !== undefined && info != 'null' && info != '' && info != 'undefined'){
         	info = JSON.parse(info);
         	this.setState({phonenumber: info.phonenumber, name: info.name});
         }
    }

    DAILYCOMBO1 = () => {
        axios.get(webserviceurl + '/app_get_combooptions.php?group=DAILYCOMBO1')
        .then((response) => {
            let result = response.data;
            console.log(result);
            if (result.status === "OK") {
                this.setState({ DAILYCOMBO1: result.records });
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }


    DAILYCOMBO2 = () => {
        axios.get(webserviceurl + '/app_get_combooptions.php?group=DAILYCOMBO2')
        .then((response) => {
            let result = response.data;
            console.log(result);
            if (result.status === "OK") {
                this.setState({ DAILYCOMBO2: result.records });
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    DAILYCOMBO3 = () => {
        axios.get(webserviceurl + '/app_get_combooptions.php?group=DAILYCOMBO3')
        .then((response) => {
            let result = response.data;
            console.log(result);
            if (result.status === "OK") {
                this.setState({ DAILYCOMBO3: result.records });
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    DAILYCOMBO4 = () => {
        axios.get(webserviceurl + '/app_get_combooptions.php?group=DAILYCOMBO4')
        .then((response) => {
            let result = response.data;
            console.log(result);
            if (result.status === "OK") {
                this.setState({ DAILYCOMBO4: result.records });
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    loadDataByPhone = () => {
        this.setState({showLoading:true});
        axios.post(webserviceurl + '/app_load_dailydeclaration_detail2.php', {
            phonenumber: this.state.phonenumber,
            date:this.state.date
        },
        {headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {
                console.log(response);
                let result = response.data;
                console.log(result);
                if (result.status === "OK") {
                    this.setState({ 
                        phonenumber: result.records.phonenumber, 
                        name: result.records.name,
                        cluster: result.records.cluster,
                        blok: result.records.blok,
                        city: result.records.city,
                        combo1: result.records.combo1,
                        combo2: result.records.combo2,
                        combo3: result.records.combo3,
                        combo4: result.records.combo4,
                        note: result.records.note,

                    });
                }
                setTimeout(()=>{
                    this.setState({showLoading:false});
                }, 500); 
            })
            .catch((error) => {
                console.log(error);
            });
    }



    handleOptionRadioChange1(i){
		this.setState({
			combo1: i
		})

		console.log(this.state.combo1);
    }

    handleOptionRadioChange2(i){
		this.setState({
			combo2: i
		})

		console.log(this.state.combo2);
    }

    handleOptionRadioChange3(i){
		this.setState({
			combo3: i
		})

		console.log(this.state.combo3);
    }

    handleOptionRadioChange4(i){
		this.setState({
			combo4: i
		})

		console.log(this.state.combo4);
    }
    
    goToHealthDeclaration=()=>{
        this.props.history.push('/healthdeclaration');
    }

    

    renderLoading=()=>{
        if(this.state.showLoading){
            return (
                <LoadingScreen></LoadingScreen>
            )
        }
    }


    render() {
        return(
            <div className="main-container">
                <SubHeader history={this.props.history} hideSearch={true} title={this.state.title} />
                <div className="daily-center-form">
                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.name} <span style={{color:'red'}} >*</span> </div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} value={this.state.name} readOnly/>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">Cluster: <span style={{color:'red'}} >*</span> </div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} placeholder="Silahkan isi" value={this.state.cluster} onChange={(e)=>this.setState({cluster:e.target.value})}/>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">Blok rumah detail: <span style={{color:'red'}} >*</span> </div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} placeholder="Silahkan isi" value={this.state.blok} onChange={(e)=>this.setState({blok:e.target.value})}/>
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.cityoftheday} <span style={{color:'red'}} >*</span></div>
                            <div className="field-value">
                                <input type="text" style={{fontSize:13}} value={this.state.city} placeholder="Please fill in" readOnly />
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.thefollowing} <span style={{color:'red'}} >*</span></div>
                            <div style={{backgroundColor:'rgb(250, 250, 250)', padding:4}}>
                                {this.state.DAILYCOMBO1.map((option,i)=>{
                                    return(
                                    <div className="label-radiobutton" >
                                            <input 
                                                type="radio" 
                                                readOnly
                                                checked={this.state.combo1 == option.id? true: false}
                                                id={option.id}
                                                key={option.id}
                                                onChange={this.handleOptionRadioChange1.bind(this,option.id)} 
                                                value={this.state.combo1 == option.id} 
                                            />
                                            <label style={{fontSize:13}} key={i} for={option.id}>{option.description}</label>
                                    </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.coughing} <span style={{color:'red'}} >*</span></div>
                            <div style={{backgroundColor:'rgb(250, 250, 250)', padding:4}}>
                                {this.state.DAILYCOMBO2.map((option,i)=>{
                                    return(
                                    <div className="label-radiobutton" >
                                            <input 
                                                type="radio" 
                                                readOnly
                                                checked={this.state.combo2 == option.id? true: false}
                                                key={option.id}
                                                id={option.id}
                                                onChange={this.handleOptionRadioChange2.bind(this,option.id)} 
                                                value={this.state.combo2 == option.id} 
                                            />
                                            <label style={{fontSize:13}} key={i} for={option.id}>{option.description}</label>
                                    </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.contactfriends} <span style={{color:'red'}} >*</span></div>
                            <div style={{backgroundColor:'rgb(250, 250, 250)', padding:4}}>
                                {this.state.DAILYCOMBO3.map((option,i)=>{
                                    return(
                                    <div className="label-radiobutton" >
                                            <input 
                                                type="radio" 
                                                readOnly
                                                checked={this.state.combo3 == option.id? true: false}
                                                key={option.id}
                                                onChange={this.handleOptionRadioChange3.bind(this,option.id)} 
                                                value={this.state.combo3 == option.id}
                                                id={option.id}
                                            />
                                            <label style={{fontSize:13}} key={i} for={option.id}>{option.description}</label>
                                    </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.havedcontacted} <span style={{color:'red'}} >*</span></div>
                            <div style={{backgroundColor:'rgb(250, 250, 250)', padding:4}}>
                                {this.state.DAILYCOMBO4.map((option,i)=>{
                                    return(
                                        <div className="label-radiobutton" >
                                                <input 
                                                    type="radio" 
                                                    readOnly
                                                    checked={this.state.combo4 == option.id? true: false}
                                                    key={option.id}
                                                    onChange={this.handleOptionRadioChange4.bind(this,option.id)} 
                                                    value={this.state.combo4 == option.id}
                                                    id={option.id}
                                                />
                                                <label style={{fontSize:13}} key={i} for={option.id}>{option.description}</label>
                                        </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.note}</div>
                            <div className="field-value">
                                <textarea value={this.state.note} style={{fontSize:13}} readOnly rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderLoading()}
            </div>
        )
    }

}
export default HealthDetailDailyDeclaration;