import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import { Container, Row, Col } from 'reactstrap';
import LoadingScreen from '../../Component/LoadingScreen/LoadingScreen';
import axios from 'axios';
import './FoodGardenpage.style.css';
import { webserviceurl, activelanguage } from '../../../Config';
import SubHeader from '../../Component/SubHeader/SubHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import more from '../../../images/marketplace/more.png';
import FoodGardenListItem from '../../Component/FoodGardenListItem/FoodGardenListItem';
import banner from '../../../images/onlinestore.jpg';

class FoodGardenPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: "",
            redirect: false,
			list: [],
			start: 0,	
            loadDirectoryFinished: false,
            showLoading: false
        }

        this.language = getLanguage(activelanguage, 'foodgarden');
		this.listel = null;
    }

	loadFoodGarden = () =>{
        this.setState({showLoading:true});
		axios.post(webserviceurl + '/app_load_foodgarden2.php',{

        },
		{headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {
                let result = response.data;
                console.log(result);
                if (result.status === "OK") {
					
					let tmp = this.state.list;
					
					for(var i=0; i<result.records.length;i++){
						tmp.push(result.records[i]);
					}
					
					this.setState({ list: tmp });
                }

                console.log(this.state.list);
                
                setTimeout(()=>{
                    this.setState({showLoading:false});
                }, 500); 

				
            })
            .catch((error) => {
                console.log(error);
            });
	}

	componentDidMount = () => {
        this.waitForBridge();
		this.loadFoodGarden();
		this.listel = document.getElementById('list-directory');
        this.listel.addEventListener('scroll', (e)=>{
            this.scrollCheck();
        });
	}
	
	componentWillUnmount=()=>{
        this.listel.removeEventListener('scroll', (e)=>{
            this.scrollCheck();
        });
    }
	
	scrollCheck = ()=>{	
        if(this.listel.scrollTop + this.listel.clientHeight >= this.listel.scrollHeight){
            if(this.state.loadDirectoryFinished == false){
				this.loadDirectory();
			}
        }
    }

    loadUser=()=>{
        let tmp = localStorage.getItem('smart-app-id-login');
        if(tmp == undefined || tmp == null || tmp == 'null' || tmp == '' || tmp == 'undefined' ){
            let param = '{"code":"need-login"}';
            this.sendPostMessage(param);

        }else{
            tmp = JSON.parse(tmp);
            this.loginInfo.phoneno = tmp.phonenumber;
            this.loginInfo.name = tmp.name;
        }
    }

    sendPostMessage=(param)=>{
        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.sendPostMessage(param);
            }.bind(this), 200);
        }
        else {
            window.postMessage(param, "*");
        }
    }


    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":false, "showCommunityName":false, "hideTopbar":true, "hideFooterMenu":true}';           
            window.postMessage(param, "*");
        }
    }

    createMarkup = (content) => {
        return { __html: content };
    }

    goToDetail=(item)=>{
        this.props.history.push('/foodgardendetail/'+item.id);
    }

    

    renderTab = () => {
        return (
                <div className="body-section directory-scroll-view">
                    {this.state.list.map((item, i) =>
                        <div key={i} onClick={()=>this.goToDetail(item)}>
                            <FoodGardenListItem key={i}  name={item.name}   location={item.location} phonenumber={item.phonenumber} tags={item.tags}  picture={item.picture} onClick={()=>this.goToDetail(item)}/>
                        </div>
                    )}
                </div>
            )
    }

    renderLoading=()=>{
        if(this.state.showLoading){
            return (
                <LoadingScreen></LoadingScreen>
            )
        }
    }

    render() {
        if (this.state.redirect) {
            /*if (this.state.page == "newspage") {
                return <Redirect to='/news' />;
            }*/
        }

        return (
            <div className="main-container" >
                <SubHeader history={this.props.history} hideSearch={true} title={this.language.title}/>
                <div id="list-directory">
                   
					{this.renderTab()}
                    {this.renderLoading()}
                </div>
            </div>
        );
    }
}

export default FoodGardenPage;