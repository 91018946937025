import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getLanguage } from "../../../Languages";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
//import { Container, Row, Col } from 'reactstrap';
import axios from "axios";
import "./StatusPaymentToEmail.style.css";

import { webserviceurl, activelanguage } from "../../../Config";
import success from "../../../images/payment/payment-succes-3.jpg";
import SubHeader from "../../Component/SubHeader/SubHeader";
import LoadingScreen from "../../Component/LoadingScreen/LoadingScreen";

class StatusPaymentToEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      redirect: false,
      email: "",
      token: "",
      phonenumber: "",
      wetesid: 0,
      name: "",
      phone: "",
      showLoading: false,
      dataPaymentStatus: [],
    };

    this.trySendMessage = 0;
    this.language = getLanguage(activelanguage, "entertainmentpage");
  }

  loadUser = () => {
    if (
      localStorage.getItem("smart-app-id-login") != undefined &&
      localStorage.getItem("smart-app-id-login") != "undefined"
    ) {
      var user = localStorage.getItem("smart-app-id-login");
      var userID = JSON.parse(user);

      this.setState({ email: userID.email });

      // alert(this.state.email);
      // alert(JSON.stringify(userID.email));
    }

    // let getToken = JSON.parse(localStorage.getItem('smart-app-id-token'));
    // this.setState({...this.state,
    //                 token: getToken.access_token});
    // alert(this.state.token);
  };

  getStatusPayment = () => {
    axios
      .get(webserviceurl + "/get_paymentStatus.php?email=" + this.state.email)
      .then((response) => {
        let result = response.data.data;
        this.setState({ dataPaymentStatus: result });
      })
      .catch(function (error) {
        console.log(error);
        alert(JSON.stringify(error));
      });
  };

  goPaymentToModern = () => {
    axios
      .post(
        webserviceurl + "/pushemail.php",
        {
          email: this.state.email,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((response) => {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  goPaymentToModern = () => {
    this.setState({ showLoading: true });
    let param = {
      wetesid: this.state.wetesid,
      phone: this.state.phone,
      name: this.state.name,
    };

    console.log(param);

    axios
      .post(webserviceurl + "/app_insert_wetes.php", param, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => {
        if (response.data.status === "OK") {
          this.props.history.push("/historyserviceorder");
        }

        axios
          .post(
            webserviceurl + "/pushemail.php",
            {
              email: this.state.email,
            },
            {
              headers: {
                "Content-Type":
                  "application/x-www-form-urlencoded;charset=UTF-8",
              },
            }
          )
          .then((response) => {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });

        setTimeout(() => {
          this.setState({ showLoading: false });
        }, 500);
      })
      .catch(function (error) {
        // console.log(error);
        alert(JSON.stringify(error));
      });
  };

  renderLoading = () => {
    if (this.state.showLoading) {
      return <LoadingScreen></LoadingScreen>;
    }
  };

  componentWillMount = () => {
    this.loadUser();
  };

  componentDidMount = () => {
    this.waitForBridge();
    // this.getStatusPayment();
  };

  openWindow = () => {
    //window.open('https://www.netflix.com/id-en/', "_blank");
  };

  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let param =
        '{"title":"' +
        this.language.title +
        '","canGoBack":false, "showCommunityName":true, "hideTopbar":false, "hideFooterMenu":false}';
      window.postMessage(param, "*");
    }
  }

  // goToHome=()=>{
  //     let param = '{"code":"need-home"}';
  //     this.sendPostMessage(param);
  // }

  sendPostMessage = (param) => {
    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.sendPostMessage(param);
        }.bind(this),
        200
      );
    } else {
      window.postMessage(param, "*");
    }
  };

  sendMessage = (url) => {
    this.trySendMessage += 1;
    if (window.postMessage.length !== 1) {
      if (this.trySendMessage < 30) {
        setTimeout(
          function () {
            this.sendMessage();
          }.bind(this),
          200
        );
      } else {
        alert(this.language.errorOnMessage);
        this.trySendMessage = 0;
      }
    } else {
      this.trySendMessage = 0;
      let param = '{"code":"openbrowser","url":"' + url + '"}';
      window.postMessage(param, "*");
    }
  };

  createMarkup = (content) => {
    return { __html: content };
  };

  render() {
    const { showLoading } = this.state;

    return (
      <div className="main-container payment-page">
        <div className="park-introduction">
          <Card body className="text-center">
            <CardImg top width="100%" src={success} alt="Success" />
            <CardBody>
              <CardTitle style={{ backgroundColor: "#ffffff", color: "#000" }}>
                THANK YOU!
              </CardTitle>
              <CardSubtitle>Your payment is successfully done.</CardSubtitle>
              <CardText></CardText>
              <Button
                color="success"
                className="fullbutton"
                onClick={() => this.goPaymentToModern()}
                disabled={showLoading}
              >
                {showLoading && (
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: "5px" }}
                  />
                )}
                {showLoading && <span>PLEASE WAIT...</span>}
                {!showLoading && <span>CLICK DONE</span>}
              </Button>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default StatusPaymentToEmail;
