import React, { Component } from 'react';
//import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import { Carousel,CarouselItem, CarouselControl, CarouselIndicators,CardTitle,CardText, CardSubtitle, CarouselCaption, Collapse, CardBody, Card, Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Input, Button } from 'reactstrap';
import axios from 'axios';
import './RescheduleReservation.style.css';
import { webserviceurl, activelanguage } from '../../../Config';
import SubHeader from '../../Component/SubHeader/SubHeader';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {convertToRupiah, makeid} from '../../../Global';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import RoomAddOn from '../../Component/RoomAddOn/RoomAddOn';
import { confirmAlert } from 'react-confirm-alert'; 

class RescheduleReservation extends Component{

    constructor(props) {
        super(props);
        this.language = getLanguage(activelanguage, 'reschedulereservation');
        this.globallang = getLanguage(activelanguage, 'global');

        this.state ={
            weddingreservationid: props.match.params.weddingreservationid,
            weddingcategoryid: props.match.params.weddingcategoryid,
            phoneno: '',
            name: '',
            email:'',
            startdate: moment(),
			enddate: moment(),
			datecheckin: moment(),
			datecheckout: moment(),
			hourin: 0,
			minutein: 0,
			hourout: 0,
			minuteout: 0,
            page: "",
            redirect: false,
            data:{},
            weddingid:''
        }
    }

    componentDidMount=()=>{
        this.waitForBridge();
        this.loadUser();
        this.loadWeddingReservation();
    }

    loadWeddingReservation = () => {
        var starttime;
		var start;
		var endtime; 
        var end;
        
        axios.post(webserviceurl + '/app_load_weddingreservationdetail.php', {
            weddingreservationid: this.state.weddingreservationid
        },
        {headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {

                let result = response.data;
                console.log(result);
                if (result.status === "OK") {
                    this.setState({ data: result.record });
                    this.setState({ detail: result.record.info });
                    this.setState({ weddingid : result.record.weddingid})

                    this.setState({datecheckin : result.record.checkin});
                    this.setState({datecheckout : result.record.checkout});

                    starttime = this.state.datecheckin.split(" ");
                    start = starttime[1].split(":");
                    
                    this.setState({hourin: start[0]});
                    this.setState({minutein: start[1]});
                    
                    endtime = this.state.datecheckout.split(" ");
                    end = endtime[1].split(":");
                    
                    this.setState({hourout: end[0]});
                    this.setState({minuteout: end[1]});
                
                }

                console.log(this.state.weddingid);
                
            })
            .catch((error) => {
                console.log(error);
            });
    }

    loadUser=()=>{

		if(localStorage.getItem('smart-app-id-login') != undefined && localStorage.getItem('smart-app-id-login') != 'undefined'){
			var com = window.localStorage.getItem('smart-app-id-login');
			var login = JSON.parse(com);

			this.setState({phoneno: login.phonenumber, name: login.name, email: login.email});			
		}
    }

    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
            window.postMessage(param, "*");
        }
    }

    updateCheckInDate = (datecheckin) => {
        this.setState({ datecheckin: datecheckin });
        this.setState({ datechanged: true });
    }
	
	updateCheckOutDate = (datecheckout) => {
        this.setState({ datecheckout: datecheckout });
        this.setState({ datechanged: true });
    }
	
	handleHourInChange(e){
		this.setState({
		  hourin: e.target.value
		})
	}
	
	handleHourOutChange(e){
		this.setState({
		  hourout: e.target.value
		})
	}
	
	handleMinuteInChange(e){
		this.setState({
		  minutein: e.target.value
		})
	}
	
	handleMinuteOutChange(e){
		this.setState({
		  minuteout: e.target.value
		})
    }
    
    validate=()=>{
		var checkin = "";
		var checkout = "";
		
		var i = new Date(this.state.datecheckin);
		var yearin = i.getFullYear();
		var monthin = i.getMonth();
		var datein = i.getDate();
		
		var o = new Date(this.state.datecheckout);
		var yearout = o.getFullYear();
		var monthout = o.getMonth();
		var dateout = o.getDate();
		
		checkin = yearin+'-'+(monthin+1 > 9 ? monthin+1 : '0'+(monthin+1))+'-'+(datein > 9 ? datein : '0'+datein);
        checkout = yearout+'-'+(monthout+1 > 9 ? monthout+1 : '0'+(monthout+1))+'-'+(dateout > 9 ? dateout : '0'+dateout);

        console.log(this.state.hourin);
        console.log(this.state.hourout);
        console.log(this.state.minutein);
        console.log(this.state.minuteout);

        
        console.log(checkin);
        console.log(checkout);
		
		if(this.state.datecheckout >= this.state.datecheckin){
			if(checkin === checkout){
				if(this.state.hourin>this.state.hourout){
                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <div>
                                <Card className="card-alert">
                                    <CardTitle className="card-title"  >
                                        <span>Information!</span>
                                    </CardTitle>
                                    <CardBody className="card-body">
                                        <CardText className="card-text" >{this.language.error}</CardText> 
                                            <table>
                                                <tr style={{color:'#fff', textAlign:'right'}} >
                                                    <td>
                                                        <a className="link-button" onClick={onClose}>{this.globallang.ok}</a>
                                                    </td>
                                                </tr>
                                            </table>
                                    </CardBody>
                                </Card>
                            </div>
                            )
                        }
                    })
					// confirmAlert({
                    //     message: this.language.error,
                    //     buttons: [{label: "OK"}]
                    // });
					return 0;
				}else if(this.state.hourin === this.state.hourout){
					if(this.state.minutein >= this.state.minuteout){
						// confirmAlert({
                        //     message: this.language.error,
                        //     buttons: [{label: "OK"}]
                        // });
                        confirmAlert({
                            customUI: ({ onClose }) => {
                                return (
                                    <div>
                                    <Card className="card-alert">
                                        <CardTitle className="card-title"  >
                                            <span>Information!</span>
                                        </CardTitle>
                                        <CardBody className="card-body">
                                            <CardText className="card-text" >{this.language.error}</CardText> 
                                                <table>
                                                    <tr style={{color:'#fff', textAlign:'right'}} >
                                                        <td>
                                                            <a className="link-button" onClick={onClose}>{this.globallang.ok}</a>
                                                        </td>
                                                    </tr>
                                                </table>
                                        </CardBody>
                                    </Card>
                                </div>
                                )
                            }
                        })
						return 0;
					}else{
						this.checkIfExist();			
					}
				}else{
					this.checkIfExist();
				}
			}else{
				this.checkIfExist();
			}
		}else{
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div>
                        <Card className="card-alert">
                            <CardTitle className="card-title"  >
                                <span>Information!</span>
                            </CardTitle>
                            <CardBody className="card-body">
                                <CardText className="card-text" >{this.language.error}</CardText> 
                                    <table>
                                        <tr style={{color:'#fff', textAlign:'right'}} >
                                            <td>
                                                <a className="link-button" onClick={onClose}>{this.globallang.ok}</a>
                                            </td>
                                        </tr>
                                    </table>
                            </CardBody>
                        </Card>
                    </div>
                    )
                }
            })
            // confirmAlert({
            //     message: this.language.error,
            //     buttons: [{label: "OK"}]
            // })
		}
    }

    checkIfExist=()=>{
		var checkin = "";
		var checkout = "";
		
		var i = new Date(this.state.datecheckin);
		var yearin = i.getFullYear();
		var monthin = i.getMonth();
		var datein = i.getDate();
		
		var o = new Date(this.state.datecheckout);
		var yearout = o.getFullYear();
		var monthout = o.getMonth();
		var dateout = o.getDate();

		checkin     = yearin+'-'+(monthin+1 > 9 ? monthin+1 : '0'+(monthin+1))+'-'+(datein > 9 ? datein : '0'+datein)+" "+this.state.hourin+':'+this.state.minutein+":00";
		checkout    = yearout+'-'+(monthout+1 > 9 ? monthout+1 : '0'+(monthout+1))+'-'+(dateout > 9 ? dateout : '0'+dateout)+" "+this.state.hourout+':'+this.state.minuteout+":00";
        
        
        let params ={
            checkin: checkin,
            checkout: checkout,
            weddingid: this.state.weddingid
        }

        console.log(params);
        // alert(JSON.stringify(params));


        axios({
            method: 'post',
            url: webserviceurl + '/app_check_weddingreservationdate.php',
            data: {
                checkin: checkin,
				checkout: checkout,
				weddingid: this.state.weddingid
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
        })
            .then((response) => {
                let result = response.data;
                // alert(JSON.stringify(result));
                if (result.records === 1) {
                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <div>
                                <Card className="card-alert">
                                    <CardTitle className="card-title"  >
                                        <span>Information!</span>
                                    </CardTitle>
                                    <CardBody className="card-body">
                                        <CardText className="card-text" >{this.language.alreadybooked}</CardText> 
                                            <table>
                                                <tr style={{color:'#fff', textAlign:'right'}} >
                                                    <td>
                                                        <a className="link-button" onClick={onClose}>{this.globallang.ok}</a>
                                                    </td>
                                                </tr>
                                            </table>
                                    </CardBody>
                                </Card>
                            </div>
                            )
                        }
                    })
                }
				
				else{
					this.doSubmit();
				}
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleClickOk=()=>{
        var checkin = "";
		var checkout = "";
		
		var i = new Date(this.state.datecheckin);
		var yearin = i.getFullYear();
		var monthin = i.getMonth();
		var datein = i.getDate();
		
		var o = new Date(this.state.datecheckout);
		var yearout = o.getFullYear();
		var monthout = o.getMonth();
		var dateout = o.getDate();
		
		checkin = yearin+"-"+(monthin+1)+"-"+datein+" "+this.state.hourin+':'+this.state.minutein+":00";
        checkout = yearout+"-"+(monthout+1)+"-"+dateout+" "+this.state.hourout+':'+this.state.minuteout+":00";
        
        // const body = {
        //     weddingreservationid: this.state.weddingreservationid,
        //     phoneno: this.state.phoneno,
        //     checkin: checkin,
        //     checkout: checkout       
        // }

        // alert(JSON.stringify(body));
        // console.log(body);

        axios({
            method: 'post',
            url: webserviceurl + '/app_update_weddingreservation.php',
            data: {
                weddingreservationid: this.state.weddingreservationid,
                phoneno: this.state.phoneno,
                checkin: checkin,
                checkout: checkout 
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
        })
        .then((response) => {
            let result = response.data;
            if (result.status === "OK") {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div>
                            <Card className="card-alert">
                                <CardTitle className="card-title"  >
                                    <span>Information!</span>
                                </CardTitle>
                                <CardBody className="card-body">
                                    <CardText className="card-text" >{this.language.success}</CardText> 
                                        <table>
                                            <tr style={{color:'#fff', textAlign:'right'}} >
                                                <td>
                                                    <a className="link-button" onClick={onClose}>{this.globallang.ok}</a>
                                                </td>
                                            </tr>
                                        </table>
                                </CardBody>
                            </Card>
                        </div>
                        )
                    }
                })

                this.props.history.push('/banquetfacilities');
            }
            // alert(JSON.stringify(result));
            

        })
        .catch(function (error) {
            console.log(error);
        });

    }

    doSubmit=()=>{
		confirmAlert({
			customUI: ({ onClose }) => {
                return (
                    <div>
                       <Card className="card-alert">
                            <CardTitle className="card-title"  >
                                <span>Information!</span>
                            </CardTitle>
                            <CardBody className="card-body">
                                <CardText className="card-text" >{this.language.areyousure}</CardText>
                                    <table>
                                        <tr style={{color:'#fff', textAlign:'center'}} >
                                            <td>
                                                <a className="link-button" onClick={() => {this.handleClickOk(); onClose();}}>{this.globallang.submit}</a>
                                            </td>
                                            <td>
                                                <a className="link-button" onClick={onClose}>{this.globallang.cancel}</a>
                                            </td>
                                        </tr>
                                    </table>
                            </CardBody>
                        </Card>
                    </div>
				)
			}
		})
    }

    

    render(){
        return(
            <div className="main-container">
            <SubHeader history={this.props.history} hideSearch={true} title={this.language.title} />
            <div className="reschedule-center-form">
                    <div className="form-container">
                        <div className="field-container">
                            <div className="field-label">{this.language.user}</div>
                            <div className="field-value">
                                <Input type="text"  value={this.state.name} disabled="disabled"/>
                            </div>
                        </div>
						<div className="field-container">
                            <div className="field-label">{this.language.datecheckin}</div>
                            <div className="field-value">
                                <DatePicker  selected={moment.utc(this.state.datecheckin)} withPortal onChange={this.updateCheckInDate} disabledKeyboardNavigation={true} className="date-picker" />
                            </div>
                        </div>
                        <div className="field-container">
                            <div className="field-label">{this.language.datecheckout}</div>
                            <div className="field-value">
                                <DatePicker selected={moment.utc(this.state.datecheckout)}  withPortal onChange={this.updateCheckOutDate}  disabledKeyboardNavigation={true} className="date-picker" />
                            </div>
                        </div>
						<div className="field-container">
                            <div className="field-label">{this.language.timecheckin}</div>
                            <div className="field-value">
                                <div className="time">
									<table>
										<tr>
											<td>
												<select onChange = {this.handleHourInChange.bind(this)} value={this.state.hourin}>
                                                    <option value="08">08</option>
                                                    <option value="09">09</option>
													<option value="10">10</option>
													<option value="11">11</option>
													<option value="12">12</option>
													<option value="13">13</option>
													<option value="14">14</option>
													<option value="15">15</option>
													<option value="16">16</option>
													<option value="17">17</option>
												</select>
											</td>
											<td>
												&nbsp;&nbsp;:&nbsp;&nbsp;
											</td>
											<td>
												<select onChange = {this.handleMinuteInChange.bind(this)} value={this.state.minutein}>
													<option value="00">00</option>
												</select>
											</td>
										</tr>
									</table>
								</div>
                            </div>
                        </div>
						<div className="field-container">
                            <div className="field-label">{this.language.timecheckout}</div>
                            <div className="field-value">
                                <div className="time">
									<table>
										<tr>
											<td>
												<select onChange = {this.handleHourOutChange.bind(this)} value={this.state.hourout}>
                                                    <option value="08">08</option>
                                                    <option value="09">09</option>
													<option value="10">10</option>
													<option value="11">11</option>
                                                    <option value="12">12</option>
													<option value="13">13</option>
													<option value="14">14</option>
													<option value="15">15</option>
													<option value="16">16</option>
													<option value="17">17</option>
												</select>
											</td>
											<td>
												&nbsp;&nbsp;:&nbsp;&nbsp;
											</td>
											<td>
												<select onChange = {this.handleMinuteOutChange.bind(this)} value={this.state.minuteout}>
													<option value="00">00</option>
												</select>
											</td>
										</tr>
									</table>
								</div>
                            </div>
                        </div>
                        <Container style={{marginBottom:20, marginTop:20}} >
                        <Button  onClick={()=>this.validate()} style={{backgroundColor:'rgb(1, 113, 226)'}} className="button-bottom" >
                            <span> {this.language.submit} </span>
                        </Button>
                    </Container>
                </div>
            </div>
        </div>
        )
    }

}
export default RescheduleReservation;