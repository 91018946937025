import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './PaymentMultipleListItem.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLanguage } from '../../../Languages';
import { activelanguage } from '../../../Config';
import {convertToRupiah} from '../../../Global';
import moment from 'moment';

class PaymentMultipleListItem extends Component {

    constructor(props) {
        super(props);
        let monthName = '';

        this.globallang = getLanguage(activelanguage, 'global');
        this.language = getLanguage(activelanguage, 'paymentcenterlist');

        switch(parseInt(props.periodmonth)){
            case 1: monthName = this.globallang.january; break;
            case 2: monthName = this.globallang.february; break;
            case 3: monthName = this.globallang.march; break;
            case 4: monthName = this.globallang.april; break;
            case 5: monthName = this.globallang.may; break;
            case 6: monthName = this.globallang.june; break;
            case 7: monthName = this.globallang.july; break;
            case 8: monthName = this.globallang.august; break;
            case 9: monthName = this.globallang.september; break;
            case 10: monthName = this.globallang.october; break;
            case 11: monthName = this.globallang.november; break;
            case 12: monthName = this.globallang.december; break;
        }

        this.state = { 
            name : props.name, 
            InvNo: props.InvNo,
            shortDesc: props.shortdesc,
            InvAmt: props.InvAmt,
            pic: props.pic,
            InvDate: props.InvDate,
            year: props.periodyear,
            monthname : monthName,
            DebtorAcct: props.DebtorAcct,
			billingstatus: props.PayAmt,
			billingcategoryid: props.billingcategoryid,
            paiddate: props.paiddate,
            address: props.address,
            PayAmt: props.PayAmt,
            Periode:props.Periode,
            Category:props.Category
        };
        
    }

    componentDidMount=()=>{
		
    }

    componentWillReceiveProps=(props)=>{
        this.setState({
			name : props.name, 
            InvNo: props.InvNo,
            shortDesc: props.shortdesc,
            InvAmt: props.InvAmt,
            pic: props.pic,
            InvDate: props.InvDate,
            year: props.periodyear,
            //monthname : monthName,
            DebtorAcct: props.DebtorAcct,
			billingstatus: props.PayAmt,
			billingcategoryid: props.billingcategoryid,
            paiddate: props.paiddate,
            address: props.address,
            PayAmt: props.PayAmt,
            Periode:props.Periode,
            Category:props.Category
    
			});
    }


    //billingstatus = PayAmt - InvAmt;
    renderBillingStatus=()=>{
		if(this.state.billingstatus == 1){
            return (
                <div className="paiddate-container">{this.language.paid}: {this.state.paiddate}</div>
            )
        }else{
            return (
                <div className="unpaid-container">{this.language.pending}</div>
            )
        }
    }
	
	renderBulletStatus=()=>{
        console.log(this.state.PayAmt);
		if(this.state.PayAmt != null){
            return (
                <span className="paid">●&nbsp;&nbsp;&nbsp;</span>
            )
        }
		
		else if(this.state.PayAmt == null){
            return (
                <span className="pending">●&nbsp;&nbsp;&nbsp;</span>
            )
        }
    }
    renderPayAmt=()=>{
        if (this.state.PayAmt != null){
            return (
                <div className="paiddate-container">{this.language.paid}: {this.state.paiddate}</div>
            )
        }else{
            return (
                <div className="unpaid-container">{this.language.pending}</div>
            )
        }
    }
    renderPicture=()=>{
        let icon;
        if(this.state.category === "PLN")
            icon = <FontAwesomeIcon icon="lightbulb" className="list-icon" />;
        else if(this.state.category === "Water")
            icon = <FontAwesomeIcon icon="tint" className="list-icon" />;
        else if(this.state.category === "IPKL")
            icon = <FontAwesomeIcon icon="business-time" className="list-icon" />;
        if(this.state.category !== ''){
            return (
                <div className="imgitem-container">
                    {icon}
                </div>
            )
        }else{
            return (
                <div className="imgitem-container">
                    <img src={require('../../../images/default.png')} alt={'imgpic'} className="imgdefault"/>
                </div>
            )
        }
    }

    render() {
        return(
            <div className="list-item">
                <div className="relative-container">
                    {/*<div className="imgitem-container">
                        <img src={this.state.pic} alt={'imgpic'}/>
                    </div>*/}
                    {/*this.renderPicture()*/}
                    <div className="payment-list-item">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                    <label>
                                        <input
                                        type="checkbox"/>
                                    </label>
                                    </td>
                                    <td>
                                        <div className="name-container">Inv/{this.state.InvNo}</div>
                                        <div className="category-container">Periode {moment(this.state.InvDate).format('MMMM Y')}</div>
                                        {/*<div className="customerid-container">{this.state.customerid}</div>*/}
                                    </td>
                                    <td style={{width:'150px'}}>
                                        {/* <div className="amount-container">{this.state.InvNo}</div> */}
                                        <div className="amount-container">{convertToRupiah(this.state.InvAmt)}</div>
                                        {this.renderPayAmt()}
                                    </td>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default PaymentMultipleListItem;