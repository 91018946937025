import React, { Component } from 'react';
import { getLanguage } from '../../../Languages';
import { Container, Row, Col, Input,Button, Card, CardBody, Collapse, CardSubtitle } from 'reactstrap';
import axios from 'axios';
import './CustomerCareHistory.style.css';
import { webserviceurl, activelanguage } from '../../../Config';
import SubHeader from '../../Component/SubHeader/SubHeader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {convertToRupiah, makeid} from '../../../Global';
import ServiceCenterList from '../../Component/ServiceCenterList/ServiceCenterList';
import { confirmAlert } from 'react-confirm-alert'; 
import LocationPicker from 'react-location-picker';
import noData from '../../../images/kosong2.png'
import LoadingScreen from '../../Component/LoadingScreen/LoadingScreen';


class CustomerCareHistoryPage extends Component{
    constructor(props){
        super(props);
        this.language = getLanguage(activelanguage, 'feedback');
        this.globallang = getLanguage(activelanguage, 'global');

        this.state={
            page:"",
            redirect: false,
            collapse: false,
            indexCollapse:null,
            showLoading: false,
            phoneno:'',
            name:'',
            email:'',
            currentUnitCode:'',
            list:[],
            DataKeluhan:[
                {
                    "CsNo": "JC1/CS/20010001",
                    "TglKeluhan": "2020-01-08T11:04:14",
                    "Description": "Mengajukan Penghapusan Denda Keterlambatan Pembayaran Angsuran / KPR",
                    "TglRespon": "2020-01-08T17:20:08.703",
                    "Status": 3,
                    "KeluhanMayor": "N",
                    "Catatan": "",
                    "TglCatatan": "2020-01-08T17:42:00",
                    "DetailList": [
                        {
                            "CategoryDetail": "Penghapusan Denda",
                            "Tindakan": "PEMBUATAN IOM",
                            "TglJadwal": "2020-01-08T17:19:05",
                            "TglTarget": null
                        }
                    ]
                },
                {
                    "CsNo": "JC1/CS/20010002",
                    "TglKeluhan": "2020-01-08T11:39:16",
                    "Description": "Mengajukan penghapusan denda keterlambatan pembayaran angsuran /KPR",
                    "TglRespon": "2020-01-08T17:21:28.207",
                    "Status": 5,
                    "KeluhanMayor": "N",
                    "Catatan": "",
                    "TglCatatan": "2020-01-23T11:03:00",
                    "DetailList": [
                        {
                            "CategoryDetail": "Penghapusan Denda",
                            "Tindakan": "PEMBUATAN IOM",
                            "TglJadwal": "2020-01-08T17:20:20",
                            "TglTarget": "2020-01-16T16:30:00"
                        }
                    ]
                }
            ]
        }

        this.loginInfo = {
            phoneno: '',
            name: ''
        }

        // this.toggle = this.toggle.bind(this);
    }

    componentDidMount=()=>{
        this.waitForBridge();
        this.loadUser();
        this.loadToken();
        this.loadBinding();
    }

    waitForBridge() {

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {

            let tmp = localStorage.getItem('smart-app-id-login');
            let needLogin = false;
            if(tmp == undefined || tmp == null || tmp == 'null' || tmp == '' ||tmp == 'undefined' ) needLogin = true;
            else{
                tmp = JSON.parse(tmp);
                this.loginInfo.phoneno = tmp.phonenumber;
                this.loginInfo.name = tmp.name;
            }
            let param = '{"title":"' + this.language.title + '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true, "needLogin":'+(needLogin ? 'true': 'false') +'}';
            window.postMessage(param, "*");
        }
    }

    sendPostMessage=(param)=>{
        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.sendPostMessage(param);
            }.bind(this), 200);
        }
        else {
            window.postMessage(param, "*");
        }
    }

    loadUser=()=>{
		if(localStorage.getItem('smart-app-id-login') != undefined && localStorage.getItem('smart-app-id-login') != 'undefined'){
			var com = window.localStorage.getItem('smart-app-id-login');
			var login = JSON.parse(com);
			
            this.setState({phoneno : login.phonenumber===undefined?"":login.phonenumber});
            this.setState({name : login.name===undefined?"":login.name});
            this.setState({email : login.email===undefined?"":login.email});
		}
    }

    loadToken=()=>{
        let tkn = localStorage.getItem('smart-app-id-token');
        if(tkn == undefined || tkn == null || tkn == 'null' || tkn == '' || tkn == 'undefined' ){
            let param = '{"code":"need-binding"}';
            this.sendPostMessage(param);

        }else{
            tkn = JSON.parse(tkn);
            this.setState({ token: JSON.stringify(tkn.access_token) });
        }
    }

    loadBinding= async ()=>{
        let tmp = localStorage.getItem('smart-app-id-binding');
        let tmp2 = localStorage.getItem('Modernland-Account');
        if(tmp2 == undefined || tmp2 == null || tmp2 == 'null' || tmp2 == '' || tmp2 == 'undefined' ){
            let param = '{"code":"need-binding"}';
            this.sendPostMessage(param);
        }
        if(tmp == undefined || tmp == null || tmp == 'null' || tmp == '' || tmp == 'undefined' ){
            let param = '{"code":"need-binding"}';
            this.sendPostMessage(param);
        }
        else{
            tmp = JSON.parse(tmp);
            if(tmp.length>0){
                this.loadProggressKeluhan(tmp[0].DebtorAcct);
            }
            
        }
    }

    loadProggressKeluhan = (debtor) =>{


        let getToken = JSON.parse(localStorage.getItem('smart-app-id-token'));
        if(getToken == undefined || getToken == null || getToken == 'null' || getToken == '' || getToken == 'undefined' ){
            let param = '{"code":"need-binding"}';
            this.sendPostMessage(param);
        }

        var config = {
            headers: {'Authorization': 'Bearer '+getToken.access_token}
        };

        this.setState({showLoading:true});

        axios.post(webserviceurl+'/api_modern_proggess_keluhan.php',{ 
            "DebtorAcct": debtor,
            "token": getToken.access_token
        }, config)
        .then((response) => {
            if(response.data.status === "OK"){
                this.setState({ list: response.data.data});
            }
            if(response.data.status === 401){
                confirmAlert({
                    message: (response.data.message),
                    buttons: [{label: "OK"}]
                });
            }
            setTimeout(()=>{
                this.setState({showLoading:false});
            }, 500); 
        })
        .catch(function (error) {
          console.log(error);
        });
}

    createMarkup = (content) => {
        return { __html: content };
    }

    toggle(i) {
        console.log(i);
        this.setState(state => ({ collapse: !state.collapse }));
        if(this.state.collapse === true){
            this.setState({ indexCollapse: i });
        }else{
            this.setState({ indexCollapse: null });
        }
        
    }

    renderStatusKeluhan=(item)=>{
        if(item.Status === 1){
            return(
               <div><span className="color-status-proggres" >Proggress</span></div>
            )
        }
        if(item.Status === 2){
            return(
                <div><span className="color-status-proggres" >Proggress</span></div>
            )
        }
        if(item.Status === 3){
            return(
                <div><span className="color-status-proggres" >Proggress</span></div>
            )
        }
        if(item.Status === 4){
            return(
                <div><span className="color-status-proggres" >Proggress</span></div>
            )
        }
        if(item.Status === 5){
            return(
                <div><span className="color-status-done" >Done</span></div>
            )
        }
    }

    renderDateTarget=(item)=>{
        if(item.TglTarget === null){
            return null
        }else{
            return(
                <div className="tanggal-response">{moment(item.TglTarget).format('llll')}</div>
            )
        }
    }



    renderDetailList=(item)=>{
        if(item.DetailList.length>0){
            return(
                <div>
                    {item.DetailList.map((item, i)=>
                        <div>
                        <CardSubtitle style={{fontSize:11}} >Category Detail:&nbsp;{item.CategoryDetail}</CardSubtitle>
                        <div><span style={{fontSize:11}} >Action:&nbsp;{item.Tindakan}</span></div>
                        <table>
                            <tbody>
                                <tr>
                                    <th style={{fontSize:11, textAlign:'left'}} >Schedule date</th>
                                    <th style={{fontSize:11, textAlign:'right'}} >Target date</th>
                                </tr>
                                <tr>
                                    <td style={{textAlign:'left'}}>
                                        <div className="tanggal-response">{moment(item.TglJadwal).format('llll')}</div>
                                    </td>
                                    <td style={{textAlign:'right'}}>
                                        {this.renderDateTarget(item)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    )} 
                </div>
            )
        }
    }

    renderLoadKeluhan=()=>{
        if(this.state.list.length>0){
            return(
                <div className="feedback-list-section-data">
                    <div>
                        {this.state.list.map((item, i) =>
                            <div className="list-item" key={i}>
                                <div className="descript-container-border">
                                    <div className="tanggal-keluhan">Date of complaint:&nbsp;{moment(item.TglKeluhan).format('llll')}</div>
                                    <div className="description-keluhan">Description:</div>
                                    <div className="desc-text" dangerouslySetInnerHTML={{__html: item.Description}}></div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th style={{fontSize:11}}>Response date</th>
                                                <th style={{fontSize:11, textAlign:'right'}} >Response status</th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="tanggal-response">{moment(item.TglRespon).format('llll')}</div>
                                                </td>
                                                <td style={{textAlign:'right'}}>
                                                    {this.renderStatusKeluhan(item)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th style={{fontSize:11}}>Date of note</th>
                                                <th style={{fontSize:11, textAlign:'right'}} >Note</th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="tanggal-response">{moment(item.TglCatatan).format('llll')}</div>
                                                </td>
                                                <td style={{textAlign:'right'}}>
                                                    <div className="desc-text" dangerouslySetInnerHTML={{__html: item.Catatan}}></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Button color="primary" textAlign="right" onClick={()=>this.toggle(i)} style={{ fontSize:11, marginBottom:6, marginTop:6 }}>List Detail</Button>
                                        <Collapse isOpen={this.state.indexCollapse === i}>
                                            <Card>
                                                <CardBody>
                                                    {this.renderDetailList(item)}                                           	
                                                </CardBody>
                                            </Card>
                                        </Collapse>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        }else{
            return(
                <div className="feedback-list-section-data">
                    <div style={{alignContent:'center', alignItems:'center', textAlign:'center', marginTop:80 }}>
                        <img src={noData} style={{width:180, height:120}} />
                    </div>
                    <div className="no-data" style={{marginTop:-20}}>Feedback not available</div>
                </div>
            )
        }
    }

    goBack=()=>{
        this.props.history.push('/customercare')
    }

    renderLoading=()=>{
        if(this.state.showLoading){
            return (
                <LoadingScreen></LoadingScreen>
            )
        }
    }

    render() {
        return (
            <div className="">
                <SubHeader history={this.props.history} hideSearch={true} title={this.language.title} goBack={this.goBack} /> 
                {this.renderLoadKeluhan()}
                {this.renderLoading()}
            </div>
        )
    }



    
}
export default CustomerCareHistoryPage;