import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
//import registerServiceWorker from './registerServiceWorker';
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
// registerServiceWorker();

// ReactDOM.render(
//   <Router basename="/smart_devel">
//     <App />
//   </Router>,
//   document.getElementById("root")
// );
