import React, { Component } from "react";
//import { Link, Redirect } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Container,
  Col,
  Row,
  Card,
  CardTitle,
  CardBody,
  CardText,
} from "reactstrap";
import axios from "axios";
import "./CommodityMassagesPage.style.css";
import FoodCommodityListItem from "../../Component/FoodCommodityListItem/FoodCommodityListItem";
import CommodityCartItem from "../../Component/CommodityCartItem/CommodityCartItem";
import SubHeaderFood from "../../Component/SubHeaderFood/SubHeaderFood";
import { getLanguage } from "../../../Languages";
import { webserviceurl, activelanguage } from "../../../Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import banner from "../../../images/fastfood.jpg";
import moment from "moment";
import {
  numberFormat,
  convertToRupiah,
  makeid,
  makeidrandom,
} from "../../../Global";
import { confirmAlert } from "react-confirm-alert";
import LocationPicker from "react-location-picker";
import Geosuggest from "react-geosuggest";
import { GoogleComponent } from "react-google-location";
import logoCash from "../../../images/marketplace/logoCash.png";
import LoadingScreen from "../../Component/LoadingScreen/LoadingScreen";
import { getWeekYearWithOptions } from "date-fns/fp";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import { addMonths } from "date-fns";
import { zhCN, enUS } from "date-fns/esm/locale";
registerLocale("enUS", enUS);
moment.locale("id");

const API_KEY = "AIzaSyCSV1AmlA-ArhLJz9u0TXS8zQEXt5wmdAU";

const customStyles = {
  content: {
    top: "50%",
    left: "55%",
    right: "-20%",
    bottom: "-30%",
    transform: "translate(-50%, -50%)",
  },
};

class CommodityMassagesPage extends Component {
  constructor(props) {
    super(props);
    this.language = getLanguage(activelanguage, "foodgardenprofile");
    this.globallang = getLanguage(activelanguage, "global");

    this.paymentList = [
      {
        value: "OVO",
        label: "OVO",
      },
    ];

    this.state = {
      foodgardenId: props.match.params.id,
      total_payment: 0,
      totalPayment: 0,
      merchantData: {},
      currentCommodityCategory: 0,
      merchantData: {},
      currentCommodityCategory: 0,
      counteritem: 0,
      counterprice: 0,
      commodity: [],
      commodityDrink: [],
      commodityOther: [],
      commodityShow: [],
      modal: false,
      currentTab: 0,
      tabs: [
        { id: 0, name: this.language.menu },
        { id: 1, name: this.language.comments },
        { id: 2, name: this.language.info },
      ],
      infoFoods: [],
      infoDrinks: [],
      infoOthers: [],
      counteritemFood: 0,
      counteritemDrink: 0,
      counteritemOther: 0,
      counterpriceFood: 0,
      counterpriceDrink: 0,
      counterpriceOther: 0,
      modalIsOpen: false,
      label: "Items",
      orderid: 0,
      orderdetailfoodid: 0,
      orderdetaildrinkid: 0,
      orderdetailotherid: 0,
      phoneno: "",
      name: "",
      place: "",
      addressdetail: "",
      description: "",
      addresslist: [],
      addresslabel: "",
      address: "",
      status: 0,
      communityid: 22,
      paymentmethod: "",
      currentDateTime: "",
      numberorder: "",
      showLoading: false,
      commodityFoodListItem: [],
      commodityDrinkListItem: [],
      commodityOtherListItem: [],
      datacontoh: [],
      dataTimeOperation: [],
      closeInfo: "",
      dataAddressUser: "",
      datecheckin: new Date(),
      hourin: "08:00:00",
      checkedVoucher: false,
      useVoucherKosmo: 0,
      sisaVoucher: 0,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  componentDidMount = () => {
    this.waitForBridge();
    this.loadUser();
    this.loadData();
    this.loadDataCommodity();
    this.loadAddress();
    this.generateOTP();
    this.getCurrentDateTime();
  };

  generateOTP = () => {
    var digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 8; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }

    this.setState({ numberorder: OTP });
    return OTP;
  };

  paymentMethodChange(e) {
    this.setState({
      paymentmethod: e.target.value,
    });
  }

  loadUser = () => {
    if (
      localStorage.getItem("smart-app-id-login") != undefined &&
      localStorage.getItem("smart-app-id-login") != "undefined"
    ) {
      var com = window.localStorage.getItem("smart-app-id-login");
      var login = JSON.parse(com);

      // alert(JSON.stringify(login));

      this.setState({
        phoneno: login.phonenumber,
        name: login.name,
        email: login.email,
      });
      this.doCheckLocationUser(login.phonenumber);
    } else {
      // this.setState({
      //   phoneno: "082216825612",
      //   name: "Uci Baetulloh",
      //   email: "ucibaetulloh@gmail.com",
      // });
      // this.doCheckLocationUser("082216825612");
    }
  };

  doCheckLocationUser = (phonenumber) => {
    let params = {
      phonenumber: phonenumber,
    };
    axios({
      method: "post",
      url: webserviceurl + "/user_location.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        console.log(result);
        if (result.status === "OK") {
          if (result.records.length > 0) {
            const D = result.records.filter((o) => o.is_default === "1");
            console.log(D);
            this.setState({
              dataAddressUser: D[0],
            });
          }
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  loadData = () => {
    this.setState({ showLoading: true });
    axios({
      method: "post",
      url: webserviceurl + "/merchant_food_commodity.php",
      data: {
        merchantid: this.state.foodgardenId,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        // console.log(result);
        if (result.status === "OK") {
          var now = new Date();
          var dateStringWithTime = moment(now).format("YYYY-MM-DD HH:mm:ss");
          var time = dateStringWithTime.substring(11, 19);
          var dayString = moment(now).format("dddd");
          // console.log(dateStringWithTime);
          // console.log(dayString);
          var dataTimeOperation = result.record.dataTimeOperation;

          if (dataTimeOperation.length > 0) {
            var timeCheck = dataTimeOperation.filter(
              (v) => v.dayText === dayString
            );
            if (timeCheck.length > 0) {
              if (timeCheck[0].dayText !== dayString) {
                this.setState({
                  closeInfo: "CLOSED",
                });
              } else {
                if (time < timeCheck[0].starttime) {
                  this.setState({
                    closeInfo: "CLOSED",
                  });
                } else if (time > timeCheck[0].endtime) {
                  this.setState({
                    closeInfo: "CLOSED",
                  });
                }
              }
            } else {
              this.setState({
                closeInfo: "CLOSED",
              });
            }
          }

          // console.log(this.state.closeInfo === "" ? "Buka" : "Tutup");

          this.setState({
            merchantData: result.record,
            dataTimeOperation: result.record.dataTimeOperation,
          });

          setTimeout(() => {
            this.setState({ showLoading: false });
          }, 500);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  loadDataCommodity = () => {
    axios({
      method: "post",
      url: webserviceurl + "/merchant_food_commoditys.php",
      data: {
        merchantid: this.state.foodgardenId,
        filter: "",
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        if (result.status === "OK") {
          let commodityFood = result.record;
          let storage = JSON.parse(localStorage.getItem("shopping-cart"));
          if (storage == null) storage = [];

          let qty = 0;
          let subtotal = 0;
          for (let i = 0; i < commodityFood.length; i++) {
            commodityFood[i].qty = 0;
            for (let j = 0; j < storage.length; j++) {
              if (commodityFood[i].commodityid === storage[j].commodityid) {
                commodityFood[i].qty = storage[j].qty;
                break;
              }
            }
            qty += commodityFood[i].qty;
            subtotal += commodityFood[i].qty * commodityFood[i].price;
          }

          this.setState({
            commodity: commodityFood,
            counteritem: qty,
            counterprice: subtotal,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  onSearch = (query) => {
    axios({
      method: "post",
      url: webserviceurl + "/merchant_food_commoditys.php",
      data: {
        merchantid: this.state.foodgardenId,
        filter: query,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        if (result.status === "OK") {
          let commodityFood = result.record;
          let storage = JSON.parse(localStorage.getItem("shopping-cart"));
          if (storage == null) storage = [];

          let qty = 0;
          let subtotal = 0;
          for (let i = 0; i < commodityFood.length; i++) {
            commodityFood[i].qty = 0;
            for (let j = 0; j < storage.length; j++) {
              if (commodityFood[i].commodityid === storage[j].commodityid) {
                commodityFood[i].qty = storage[j].qty;
                break;
              }
            }
            qty += commodityFood[i].qty;
            subtotal += commodityFood[i].qty * commodityFood[i].price;
          }

          this.setState({
            commodity: commodityFood,
            counteritem: qty,
            counterprice: subtotal,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getCurrentDateTime = () => {
    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date + " " + time;

    // console.log(dateTime);
    this.setState({
      currentDateTime: dateTime,
    });

    // console.log(this.state.currentDateTime);
  };

  loadAddress = (phonenumber) => {
    axios({
      method: "post",
      url: webserviceurl + "/app_load_address.php",
      data: {
        phoneno: this.state.phoneno,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        // console.log(result);
        if (result.status === "OK") {
          this.setState({ addresslist: result.records });
          this.setState({ address: result.records[0].address });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let param =
        '{"title":"' +
        this.language.title +
        '","canGoBack":true, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
      window.postMessage(param, "*");
    }
  }

  createMarkup = (content) => {
    return { __html: content };
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  goToAbout = () => {
    //ke about
  };

  goBack = () => {
    let cart = JSON.parse(localStorage.getItem("shopping-cart"));
    if (cart == null) cart = [];
    if (cart.length > 0) {
      this.toggle();
    } else {
      this.props.history.goBack();
    }
  };

  goToActivityOrder = () => {
    this.props.history.replace("/orderactivity");
  };

  confirmBack = () => {
    this.toggle();
    localStorage.setItem("shopping-cart", JSON.stringify([]));
    this.props.history.goBack();
  };

  changeCategory = (id) => {
    this.setState({ currentCommodityCategory: id });
    this.loadCommodity(id);
  };

  updateQty = (newValue, commodityid) => {
    let commodity = this.state.commodity;
    // console.log(commodity);
    let thecommodity = {};
    let tempcounter = 0;
    let tempprice = 0;
    let price = 0;
    for (let i = 0; i < commodity.length; i++) {
      if (commodity[i].commodityid === commodityid) {
        thecommodity = commodity[i];
        commodity[i].qty = newValue;
      }
      tempcounter += commodity[i].qty;
      if (commodity[i].commodityIsDiscountId === 1) {
        tempprice +=
          commodity[i].qty *
          (commodity[i].price *
            ((100 - commodity[i].priceDiscountId.replace("%", "")) / 100));
      } else {
        tempprice += commodity[i].qty * commodity[i].price;
      }
    }

    let commodityFoodListItem = [];
    var i = 0;
    commodity.map((idx, val) => {
      if (idx.qty > 0) {
        commodityFoodListItem.push({
          commodityid: idx.commodityid,
          commoditycategoryid: idx.commoditycategoryid,
          commoditycategoryname: idx.commoditycategoryname,
          commodityname: idx.commodityname,
          commoditypic: idx.commoditypic,
          price: idx.price,
          qty: idx.qty,
          tags: idx.tags,
          commodityIsDiscountId: idx.commodityIsDiscountId,
          priceDiscountId: idx.priceDiscountId,
          promo1buyget1Id: idx.promo1buyget1Id,
          recommendedId: idx.recommendedId,
        });
      }
      i++;
    });

    let lbl = this.state.label;
    if (tempcounter == 1) lbl = "Item";

    // console.log(commodityOtherListItem);

    if (tempcounter === 0) {
      this.setState({
        modalIsOpen: false,
      });
    }

    let feeDeliveryMerchant =
      this.state.merchantData.fee_by_merchant === ""
        ? parseInt(0)
        : this.state.merchantData.fee_by_merchant;

    if (this.state.merchantData.deliveryById === 1) {
      if (this.state.merchantData.status_diskon_bykosmo === "1") {
        if (this.state.merchantData.set_ppn === "1") {
          this.setState({
            total_payment:
              tempprice +
              this.state.merchantData.fee_by_kosmo *
                ((100 -
                  this.state.merchantData.feeDiskonByKOSMOID.replace("%", "")) /
                  100) +
              tempprice *
                (this.state.merchantData.price_ppn.replace("%", "") / 100),
          });
        } else {
          this.setState({
            total_payment:
              tempprice +
              this.state.merchantData.fee_by_kosmo *
                ((100 -
                  this.state.merchantData.feeDiskonByKOSMOID.replace("%", "")) /
                  100),
          });
        }
      } else {
        if (this.state.merchantData.set_ppn === "1") {
          this.setState({
            total_payment:
              parseInt(tempprice) +
              parseInt(this.state.merchantData.fee_by_kosmo) +
              parseInt(tempprice) *
                (this.state.merchantData.price_ppn.replace("%", "") / 100),
          });
        } else {
          this.setState({
            total_payment:
              parseInt(tempprice) +
              parseInt(this.state.merchantData.fee_by_kosmo),
          });
        }
      }
    } else {
      if (this.state.merchantData.set_ppn === "1") {
        this.setState({
          total_payment:
            parseInt(tempprice) +
            parseInt(feeDeliveryMerchant) +
            parseInt(tempprice) *
              (this.state.merchantData.price_ppn.replace("%", "") / 100),
        });
      } else {
        this.setState({
          total_payment: parseInt(tempprice) + parseInt(feeDeliveryMerchant),
        });
      }
    }

    this.setState({ commodityFoodListItem: commodityFoodListItem });
    this.setState({ commodity: commodity });
    this.setState({ counteritem: tempcounter });
    this.setState({ counterprice: tempprice });

    //update localstorage shopping-cart
    let isExist = false;
    let cart = JSON.parse(localStorage.getItem("shopping-cart"));
    if (cart == null) cart = [];
    for (let i = 0; i < cart.length; i++) {
      if (cart[i].commodityid === commodityid) {
        isExist = true;
        cart[i].qty = newValue;
        if (newValue === 0) {
          cart.splice(i, 1);
        }
        break;
      }
    }

    if (!isExist) {
      cart.push(thecommodity);
    }
    localStorage.setItem("shopping-cart", JSON.stringify(cart));
  };

  changeTab = (id) => {
    this.setState({ currentTab: id });
  };

  changeAddress = (event) => {
    let eve = event.target.value;
    this.setState({ addresslabel: eve });

    let check = this.state.addresslist;

    for (var i = 0; i < check.length; i++) {
      if (check[i].label == eve) {
        this.setState({ address: check[i].address });
      }
    }
  };

  goToDetail = (merchantData) => {
    window.localStorage.setItem(
      "merchant",
      JSON.stringify(this.state.merchantData)
    );
    // this.props.history.push('/merchantpayment/'+this.state.foodgardenId);
    this.openModal();

    // console.log(this.state.commodity);
    // console.log(this.state.commodityDrink);
    // console.log(this.state.commodityOther);
  };

  handleCopy = () => {
    var copyText = document.getElementById("mail");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    // toast.success(`Hi! Now you can: ctrl+v: ${copyText.value} `)
  };

  handleClickOke = () => {
    // var today = new Date();
    // var date =
    //   today.getFullYear() +
    //   "-" +
    //   (today.getMonth() + 1) +
    //   "-" +
    //   today.getDate();
    // var time =
    //   today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    // var dateTime = date + " " + time;

    var dateStringWithTime = moment(this.state.datecheckin).format(
      "YYYY-MM-DD"
    );
    var dateTime = dateStringWithTime + " " + this.state.hourin;
    var u = dateTime.split(" ")[0];
    var dateZoom = u.replace(/-/g, "");
    var max_paiddate = moment(dateTime)
      .add(1, "d")
      .format("YYYY-MM-DD HH:mm:ss");

    // console.log(dateStringWithTime + " " + this.state.hourin);

    // var now = new Date();
    // var dateString = moment(now).format("YYYY-MM-DD");
    // var dateStringWithTime = moment(now).format("YYYY-MM-DD HH:mm:ss");
    // var d = dateStringWithTime.split(" ")[0];
    // var dateZoom = u.replace(/-/g, "");
    // var max_paiddate = moment(now).add(1, "d").format("YYYY-MM-DD HH:mm:ss");

    var listDataOrderCommodity = this.state.commodityFoodListItem.filter(
      (v) => v.qty > 0
    );

    var dataCommodity = [];
    listDataOrderCommodity.map((v, i) => {
      dataCommodity.push({
        item: v.commodityname,
        qty: v.qty,
      });
    });

    let dataOrder = [];

    dataOrder.push({
      order_detail: dataCommodity,
      note: this.state.description,
    });

    var Instruction = dataCommodity
      .map((o) => "Item: " + o.item + ", " + " QTY:" + o.qty)
      .join(" | ");
    console.log(Instruction + " | " + "Note: " + this.state.description);

    if (this.state.merchantData.deliveryById === 1) {
      let params = {
        orderid: this.state.orderid,
        numberorder: this.state.numberorder,
        currentDateTime: dateTime,
        phoneno: this.state.phoneno,
        communityid: this.state.communityid,
        merchantid: this.state.foodgardenId,
        counterprice: this.state.counterprice,
        counteritem: this.state.counteritem,
        addressdetail: this.state.dataAddressUser.location_detail,
        totalhargaNormal: this.state.counterprice,
        totalPayment: this.state.total_payment,
        deliveryById: this.state.merchantData.deliveryById,
        fee_by_kosmo: this.state.merchantData.fee_by_kosmo,
        fee_by_merchant: this.state.merchantData.fee_by_merchant,
        status_diskon_bykosmo: this.state.merchantData.status_diskon_bykosmo,
        feeDiskonByKOSMOID: this.state.merchantData.feeDiskonByKOSMOID,
        paymentmethod: this.state.paymentmethod.value,
        description: this.state.description,
        commodityFoodListItem: listDataOrderCommodity,
        TransactionDate: dateZoom,
        PickupName: this.state.merchantData.name,
        PickupPhone: this.state.merchantData.contact,
        PickupAddress: this.state.merchantData.location,
        PickupDetail: this.state.merchantData.addres_detail,
        PickupCity: this.state.merchantData.City,
        PickupPostalCode: this.state.merchantData.posttal_code,
        PickupCountry: this.state.merchantData.country,
        PickupLat: this.state.merchantData.Latitude,
        PickupLng: this.state.merchantData.Longitude,
        DeliveryName: this.state.name,
        DeliveryPhone: this.state.phoneno,
        DeliveryAddress: this.state.dataAddressUser.location,
        DeliveryDetail: this.state.dataAddressUser.location_detail,
        DeliveryInstruction:
          Instruction + " | " + "Note: " + this.state.description,
        DeliveryCity: this.state.dataAddressUser.city,
        DeliveryPostalCode: this.state.dataAddressUser.postalcode,
        DeliveryCountry: "Indonesia",
        DeliveryLat: this.state.dataAddressUser.coordinate_lat,
        DeliveryLng: this.state.dataAddressUser.coordinate_long,
        DeliveryCOD: "",
        DeliverySize: "L",
        OrderReferenceId: "",
        Net_weight: "",
        Height: "",
        Length: "",
        Width: "",
        Insurance: "",
        tax: this.state.merchantData.price_ppn,
        max_paiddate: max_paiddate,
        Qty: this.state.counteritem,
        checkedVoucher: this.state.checkedVoucher === false ? 0 : 1,
        useVoucherKosmo: this.state.useVoucherKosmo,
        sisaVoucher: this.state.sisaVoucher,
      };
      console.log(params);
      this.setState({ showLoading: true });
      axios
        .post(webserviceurl + "/marketplace_orderfood.php", params, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        })
        .then((response) => {
          let result = response.data;

          if (result.status === "OK") {
            this.setState({ showLoading: false });
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div>
                    <Card className="card-alert">
                      <CardTitle className="card-title">
                        <span>Information</span>
                      </CardTitle>
                      <CardBody className="card-body">
                        {/* <CardText className="card-text">
                          {this.language.success}
                        </CardText> */}
                        <CardText
                          style={{
                            textAlign: "center",
                            fontSize: 16,
                            fontFamily: "monospace",
                          }}
                        >
                          Please make a payment transfer via your{" "}
                          <span
                            style={{
                              fontFamily: "auto",
                              fontWeight: "bold",
                              color: "#140a8f",
                            }}
                          >
                            OVO
                          </span>{" "}
                          application to the following number:<br></br>
                          <span
                            style={{
                              fontSize: 20,
                              color: "#007bff",
                              fontFamily: "inherit",
                            }}
                          >
                            KOSMO - 083870597388
                          </span>
                          <br></br>
                          <span>Pay Before&nbsp;{max_paiddate} </span>
                          <br></br>
                          <span>
                            Total Payment&nbsp;
                            {convertToRupiah(this.state.total_payment)}
                          </span>
                          <br></br>
                        </CardText>
                        <table>
                          <tr style={{ color: "#fff", textAlign: "right" }}>
                            <td>
                              <a className="link-button" onClick={onClose}>
                                {this.globallang.ok}
                              </a>
                            </td>
                          </tr>
                        </table>
                      </CardBody>
                    </Card>
                  </div>
                );
              },
            });
            // alert(this.language.success);
            this.doNotifToCS(
              this.state.phoneno,
              this.state.name,
              this.state.merchantData.name,
              this.state.total_payment
            );
            // this.doNotifToTenant(this.state.phoneno);
            localStorage.setItem("shopping-cart", JSON.stringify([]));
            this.props.history.push("/orderactivity");
          } else {
            alert(result.message);
          }
        })
        .catch((error) => {
          this.setState({ showLoading: false });
          console.log(error);
        });
    } else {
      let param = {
        orderid: this.state.orderid,
        numberorder: this.state.numberorder,
        currentDateTime: dateTime,
        phoneno: this.state.phoneno,
        communityid: this.state.communityid,
        merchantid: this.state.foodgardenId,
        counterprice: this.state.counterprice,
        counteritem: this.state.counteritem,
        addressdetail: this.state.dataAddressUser.location_detail,
        totalhargaNormal: this.state.counterprice,
        totalPayment: this.state.total_payment,
        deliveryById: this.state.merchantData.deliveryById,
        fee_by_kosmo: this.state.merchantData.fee_by_kosmo,
        fee_by_merchant: this.state.merchantData.fee_by_merchant,
        status_diskon_bykosmo: this.state.merchantData.status_diskon_bykosmo,
        feeDiskonByKOSMOID: this.state.merchantData.feeDiskonByKOSMOID,
        paymentmethod: this.state.paymentmethod.value,
        description: this.state.description,
        commodityFoodListItem: listDataOrderCommodity,
        DeliveryAddress: this.state.dataAddressUser.location,
        DeliveryLat: this.state.dataAddressUser.coordinate_lat,
        DeliveryLng: this.state.dataAddressUser.coordinate_long,
        tax: this.state.merchantData.price_ppn,
        max_paiddate: max_paiddate,
        checkedVoucher: this.state.checkedVoucher === false ? 0 : 1,
        useVoucherKosmo: this.state.useVoucherKosmo,
        sisaVoucher: this.state.sisaVoucher,
      };

      console.log(param);
      this.setState({ showLoading: true });
      axios
        .post(webserviceurl + "/marketplace_orderfood_by_merchant.php", param, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        })
        .then((response) => {
          let result = response.data;

          if (result.status === "OK") {
            this.setState({ showLoading: false });
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div>
                    <Card className="card-alert">
                      <CardTitle className="card-title">
                        <span>Information</span>
                      </CardTitle>
                      <CardBody className="card-body">
                        {/* <CardText className="card-text">
                          {this.language.success}
                        </CardText> */}
                        <CardText
                          style={{
                            textAlign: "center",
                            fontSize: 16,
                            fontFamily: "monospace",
                          }}
                        >
                          Please make a payment transfer via your{" "}
                          <span
                            style={{
                              fontFamily: "auto",
                              fontWeight: "bold",
                              color: "#140a8f",
                            }}
                          >
                            OVO
                          </span>{" "}
                          application to the following number:<br></br>
                          <span
                            style={{
                              fontSize: 20,
                              color: "#007bff",
                              fontFamily: "inherit",
                            }}
                          >
                            KOSMO - 083870597388
                          </span>
                          <br></br>
                          <span>Pay Before&nbsp;{max_paiddate} </span>
                          <br></br>
                          <span>
                            Total Payment&nbsp;
                            {convertToRupiah(this.state.total_payment)}
                          </span>
                          <br></br>
                        </CardText>
                        <table>
                          <tr style={{ color: "#fff", textAlign: "right" }}>
                            <td>
                              <a className="link-button" onClick={onClose}>
                                {this.globallang.ok}
                              </a>
                            </td>
                          </tr>
                        </table>
                      </CardBody>
                    </Card>
                  </div>
                );
              },
            });
            // alert(this.language.success);
            this.doNotifToCS(
              this.state.phoneno,
              this.state.name,
              this.state.merchantData.name,
              this.state.total_payment
            );
            // this.doNotifToTenant(this.state.phoneno);
            localStorage.setItem("shopping-cart", JSON.stringify([]));
            this.props.history.push("/orderactivity");
          } else {
            alert(result.message);
          }
        })
        .catch((error) => {
          this.setState({ showLoading: false });
          console.log(error);
        });
    }
  };

  doNotifToTenant = (phonenumber) => {
    let params = {
      phonenumber: phonenumber,
    };
    axios
      .post(webserviceurl + "/tenant_notif.php", params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        alert(error);
      });
  };

  doNotifToCS = (phonenumber, name, merchant, total) => {
    let params = {
      phoneorder: phonenumber,
      nameorder: name,
      merchant: merchant,
      totalpayment: total,
    };
    axios
      .post(webserviceurl + "/whatsap_cs.php", params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        alert(error);
      });
  };

  doMaps = () => {
    this.props.history.push("/googlemaps");
  };

  checkData = () => {
    this.closeModal();
    const { place } = this.state;
    const { dataAddressUser } = this.state;

    if (dataAddressUser === "") {
      // alert("please fill the description");
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div>
              <Card className="card-alert">
                <CardTitle className="card-title">
                  <span>Warning</span>
                </CardTitle>
                <CardBody className="card-body">
                  <CardText className="card-text">
                    Please add your location.
                  </CardText>
                  <table>
                    <tr style={{ color: "#fff", textAlign: "right" }}>
                      <td>
                        <a
                          className="link-button"
                          onClick={() => {
                            this.doMaps();
                            onClose();
                          }}
                        >
                          {this.globallang.ok}
                        </a>
                      </td>
                    </tr>
                  </table>
                </CardBody>
              </Card>
            </div>
          );
        },
      });
      return false;
    } else if (this.state.paymentmethod === "") {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div>
              <Card className="card-alert">
                <CardTitle className="card-title">
                  <span>Warning</span>
                </CardTitle>
                <CardBody className="card-body">
                  <CardText className="card-text">
                    Please select payment method.
                  </CardText>
                  <table>
                    <tr style={{ color: "#fff", textAlign: "right" }}>
                      <td>
                        <a className="link-button" onClick={onClose}>
                          {this.globallang.ok}
                        </a>
                      </td>
                    </tr>
                  </table>
                </CardBody>
              </Card>
            </div>
          );
        },
      });
      return false;
    } else {
      this.doPay();
    }
  };

  doPay = () => {
    if (this.state.counteritem < 1) {
      // alert (this.language.validation);
      this.closeModal();
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div>
              <Card className="card-alert">
                <CardTitle className="card-title">
                  <span>Information</span>
                </CardTitle>
                <CardBody className="card-body">
                  <CardText className="card-text">
                    {this.language.validation}
                  </CardText>
                  <table>
                    <tr style={{ color: "#fff", textAlign: "right" }}>
                      <td>
                        <a className="link-button" onClick={onClose}>
                          {this.globallang.ok}
                        </a>
                      </td>
                    </tr>
                  </table>
                </CardBody>
              </Card>
            </div>
          );
        },
      });
      return false;
    } else {
      this.closeModal();
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div>
              <Card className="card-alert">
                <CardTitle className="card-title">
                  <span>Confirmation</span>
                </CardTitle>
                <CardBody className="card-body">
                  <CardText className="card-text">
                    {this.language.areyoupay}
                  </CardText>
                  <table>
                    <tr style={{ color: "#fff", textAlign: "center" }}>
                      <td>
                        <a
                          className="link-button"
                          onClick={() => {
                            this.handleClickOke();
                            onClose();
                          }}
                        >
                          {this.globallang.submit}
                        </a>
                      </td>
                      <td>
                        <a className="link-button" onClick={onClose}>
                          {this.globallang.cancel}
                        </a>
                      </td>
                    </tr>
                  </table>
                </CardBody>
              </Card>
            </div>
          );
        },
      });
    }
  };

  renderMenuList = () => {
    if (this.state.commodity !== undefined && this.state.commodity.length > 0) {
      return (
        <>
          <tr>
            <td className="category-column">
              <div
                className="category-item"
                style={{
                  fontFamily: "system-ui",
                  fontSmooth: "",
                  fontStyle: "oblique",
                  fontSize: 16,
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                MENU LIST
              </div>
            </td>
          </tr>
          <tr>
            <td className="menu-column">
              <div className="commodity-list-container">
                {this.state.commodity.map((item, i) => (
                  <FoodCommodityListItem
                    key={i}
                    id={item.commodityid}
                    name={item.commodityname}
                    shortdesc={item.shortdesc}
                    category={item.commoditycategoryname}
                    tags={item.tags}
                    pic={item.commoditypic[0]}
                    price={item.price}
                    qty={item.qty}
                    updateQty={this.updateQty}
                    sold={0}
                    commodityIsDiscountId={item.commodityIsDiscountId}
                    priceDiscountId={item.priceDiscountId}
                    promo1buyget1Id={item.promo1buyget1Id}
                    recommendedId={item.recommendedId}
                    closeInfo={this.state.closeInfo === "" ? "Buka" : "Tutup"}
                  />
                ))}
              </div>
            </td>
          </tr>
        </>
      );
    } else {
      return <div className="no-data-available">No Available</div>;
    }
  };

  renderCartButton = () => {
    let cart = JSON.parse(localStorage.getItem("shopping-cart"));
    if (cart.length > 0) {
      return (
        <div className="shopping-cart-button-container">
          <FontAwesomeIcon icon="shopping-cart" className="list-icon" />
          {this.language.cart}
        </div>
      );
    }
  };

  //Recommended
  //Promo Discount
  //Promo get 1 buy 1

  renderTab = () => {
    if (this.state.currentTab === 0) {
      return (
        <div>
          <div className="menu-section-container" style={{ marginTop: 10 }}>
            <table
              style={{
                minHeight: window.innerHeight - (252 + 45) + "px",
                // maxWidth: window.innerWidth + "px",
                tableLayout: "fixed",
              }}
            >
              <tbody>{this.renderMenuList()}</tbody>
            </table>
          </div>
        </div>
      );
    } else if (this.state.currentTab === 1) {
      return <div className="no-info">{this.language.nocomment}</div>;
    } else {
      let list = [];
      if (
        this.state.merchantData !== undefined &&
        this.state.merchantData.info !== undefined
      )
        list = this.state.merchantData.info;
      if (list.length > 0) {
        return (
          <div className="merchant-info-container">
            <table>
              <tbody>
                {list.map((info, i) => (
                  <tr key={i}>
                    <td style={{ width: "100px" }}>{info.field}</td>
                    <td style={{ width: "10px" }}>:</td>
                    <td>{info.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      } else {
        return <div className="no-info">{this.language.noinfo}</div>;
      }
    }
  };

  renderShoppingChart = () => {
    if (this.state.counteritem > 0) {
      return (
        <div
          className="payment-btn-container"
          onClick={() => this.goToDetail()}
        >
          <table>
            <tr>
              <td>
                <FontAwesomeIcon
                  icon="shopping-cart"
                  style={{
                    width: 25,
                    height: 20,
                    color: "#fff",
                  }}
                />
                &nbsp;&nbsp;
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  {this.state.counteritem} item(s)
                </span>
              </td>
              <td
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >
                Rp. {numberFormat(this.state.counterprice)}
              </td>
            </tr>
          </table>
        </div>
      );
    } else {
      return null;
    }
  };

  renderCart = (item, i) => {
    if (item.qty > 0) {
      return (
        <CommodityCartItem
          key={i}
          id={item.commodityid}
          name={item.commodityname}
          shortdesc={item.shortdesc}
          category={item.commoditycategoryname}
          tags={item.tags}
          pic={item.commoditypic[0]}
          price={item.price}
          qty={item.qty}
          updateQty={this.updateQty}
          sold={0}
          commodityIsDiscountId={item.commodityIsDiscountId}
          priceDiscountId={item.priceDiscountId}
          promo1buyget1Id={item.promo1buyget1Id}
          recommendedId={item.recommendedId}
        />
      );
    }
  };

  renderAddress = () => {
    return (
      <div>
        <div className="field-container">
          <div className="field-label">Mobile Phone:</div>
          <div className="field-value">
            <Input type="text" value={this.state.phoneno} disabled="disabled" />
          </div>
        </div>
        <div className="field-container">
          <div className="field-label">User Order</div>
          <div className="field-value">
            <Input type="text" value={this.state.name} disabled="disabled" />
          </div>
        </div>
        <div className="field-container">
          <div className="field-label">{this.language.address}</div>
          <div className="field-value">
            <textarea
              rows="3"
              value={this.state.address}
              placeholder="Please enter your full address"
              onChange={(event) =>
                this.setState({ address: event.target.value })
              }
            ></textarea>
          </div>
        </div>
      </div>
    );
  };

  renderCommodityCart = () => {
    if (
      this.state.commodityFoodListItem !== undefined &&
      this.state.commodityFoodListItem.length > 0
    ) {
      return (
        <div className="commodity-list-container">
          {this.state.commodityFoodListItem.map((item, i) =>
            this.renderCart(item, i)
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  renderTabCart = () => {
    return (
      <div>
        <div className="menu-section-container">
          <table
            style={{
              minHeight: window.innerHeight - (252 + 45) + "px",
              maxWidth: window.innerWidth + "px",
              tableLayout: "fixed",
            }}
          >
            {/* <table> */}
            <tbody>
              <tr>
                <td className="menu-column">{this.renderCommodityCart()}</td>
              </tr>
              <tr>
                <Container style={{ marginBottom: 20, marginTop: 20 }}>
                  <Row>
                    <Col xs="12">
                      <Input
                        style={{ fontSize: 14 }}
                        type="textarea"
                        rows="3"
                        width="100%"
                        name="description"
                        id="description"
                        placeholder="note"
                        value={this.state.description}
                        onChange={(event) =>
                          this.setState({ description: event.target.value })
                        }
                      />
                    </Col>
                  </Row>
                </Container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  renderDelivery = () => {
    if (this.state.merchantData.deliveryById === 1) {
      return (
        <tr>
          <td>
            <span>Delivery Fee</span>
          </td>
          {this.renderDiscountDeliveryByKOSMO()}
        </tr>
      );
    } else {
      return (
        <tr>
          <td>
            <span>Delivery Fee</span>
          </td>
          <td>
            <span>
              {convertToRupiah(
                this.state.merchantData.fee_by_merchant !== ""
                  ? this.state.merchantData.fee_by_merchant
                  : parseInt(0)
              )}
            </span>
          </td>
        </tr>
      );
    }
  };

  renderDiscountDeliveryByKOSMO = () => {
    if (this.state.merchantData.status_diskon_bykosmo === "1") {
      return (
        <td>
          <span>
            {convertToRupiah(
              this.state.merchantData.fee_by_kosmo *
                ((100 -
                  this.state.merchantData.feeDiskonByKOSMOID.replace("%", "")) /
                  100)
            )}
          </span>
          &nbsp;
          <span
            style={{
              fontFamily: "monospace",
              textDecoration: "line-through",
              color: "#808080",
            }}
          >
            {convertToRupiah(this.state.merchantData.fee_by_kosmo)}
          </span>
        </td>
      );
    } else {
      return (
        <td>
          <span>{convertToRupiah(this.state.merchantData.fee_by_kosmo)}</span>
        </td>
      );
    }
  };

  renderPPN = () => {
    if (this.state.merchantData.set_ppn === "1") {
      return (
        <tr>
          <td>
            <span>Tax</span>&nbsp;
            <span>{this.state.merchantData.price_ppn}</span>
          </td>
          <td>
            <span>
              {convertToRupiah(
                this.state.counterprice *
                  (this.state.merchantData.price_ppn.replace("%", "") / 100)
              )}
            </span>
          </td>
        </tr>
      );
    }
  };

  handleChangePayment = (selectedOption) => {
    console.log(selectedOption);
    this.setState({ paymentmethod: selectedOption });
  };

  updateCheckInDate = (date) => {
    this.setState({ datecheckin: date });
  };

  handleHourInChange = (hourin) => {
    this.setState({
      hourin: hourin.target.value,
    });
  };

  renderModal() {
    if (this.state.counteritem > 0 && this.state.modalIsOpen === true) {
      return (
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
        >
          <div
            style={{ backgroundColor: "#fff" }}
            className="main-container merchant-payment-massages"
          >
            <div className="close-button" onClick={() => this.closeModal()}>
              X
            </div>
            <div className="profile-outer-container">
              <div className="profile-inner-container">
                <div className="profile-name">
                  {this.state.merchantData.name}
                </div>
                <div className="profile-desc">
                  Rp. {numberFormat(this.state.counterprice)}
                </div>
                <div className="profile-items">
                  {this.state.counteritem} {this.state.label}
                </div>
              </div>
            </div>
            <div className="order-summary">
              <span>Order Summary</span>
            </div>
            {this.renderTabCart()}
            <div className="continer-payment-summary">
              <div className="payment-method">
                <span>Select Order Time</span>
              </div>
              <div className="field-container">
                <div className="field-label">Select Date:</div>
                <div className="field-value">
                  <DatePicker
                    selected={this.state.datecheckin}
                    onChange={this.updateCheckInDate}
                    minDate={new Date()}
                    maxDate={addMonths(new Date(), 5)}
                    // dateFormat="dd/MM/yyyy"
                    dateFormat="dd MMMM yyyy"
                    locale="enUS"
                    withPortal
                    disabledKeyboardNavigation
                    showDisabledMonthNavigation
                    popperClassName="some-custom-class"
                    popperPlacement="bottom-end"
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: "5px, 0px",
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: "viewport",
                      },
                    }}
                    className="date-picker"
                  />
                </div>
              </div>
              <div style={{ marginBottom: 30 }} className="field-container">
                <div className="field-label">Time:</div>
                <div className="field-value">
                  <div className="time">
                    <table>
                      <tr>
                        <td>
                          <select
                            onChange={this.handleHourInChange}
                            value={this.state.hourin}
                          >
                            <option value="08:00:00">08:00</option>
                            <option value="08:30:00">08:30</option>
                            <option value="09:00:00">09:00</option>
                            <option value="09:30:00">09:30</option>
                            <option value="10:00:00">10:00</option>
                            <option value="10:30:00">10:30</option>
                            <option value="11:00:00">11:00</option>
                            <option value="11:30:00">11:30</option>
                            <option value="12:00:00">12:00</option>
                            <option value="12:30:00">12:30</option>
                            <option value="13:00:00">13:00</option>
                            <option value="13:30:00">13:30</option>
                            <option value="14:00:00">14:00</option>
                            <option value="14:30:00">14:30</option>
                            <option value="15:00:00">15:00</option>
                            <option value="15:30:00">15:30</option>
                            <option value="16:00:00">16:00</option>
                            <option value="16:30:00">16:30</option>
                            <option value="17:00:00">17:00</option>
                            <option value="17:30:00">17:30</option>
                            <option value="18:00:00">18:00</option>
                            <option value="18:30:00">18:30</option>
                            <option value="19:00:00">19:00</option>
                            <option value="19:30:00">19:30</option>
                          </select>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="continer-detail-address">
              <div className="payment-method">
                <span>Delivery Location</span>
              </div>
              <div>
                <div className="field-container">
                  <div className="field-label">Your Location</div>
                  <div className="field-value">
                    <textarea
                      rows="4"
                      value={this.state.dataAddressUser.location}
                      disabled
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="field-container">
                <div className="field-label">Location Detail</div>
                <div className="field-value">
                  <textarea
                    rows="3"
                    placeholder="Please input your detail address"
                    value={this.state.dataAddressUser.location_detail}
                    onChange={(event) =>
                      this.setState({
                        addressdetail:
                          this.state.dataAddressUser.location_detail,
                      })
                    }
                    disabled
                  ></textarea>
                </div>
              </div>
              <div className="field-container">
                <div
                  style={{ textAlign: "right" }}
                  onClick={() => this.props.history.push("/googlemaps")}
                >
                  <span
                    style={{ fontSize: 15, marginRight: 6, color: "#00ace6" }}
                  >
                    change
                  </span>
                  <FontAwesomeIcon
                    icon="edit"
                    style={{ height: 15, width: 15, color: "#00ace6" }}
                  />
                </div>
              </div>
            </div>
            <div className="continer-payment-summary">
              <div className="payment-method">
                <span>Payment Summary</span>
              </div>
              <div className="payment-select-method">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <span>Sub Total</span>
                      </td>
                      <td>
                        <span>{convertToRupiah(this.state.counterprice)}</span>
                      </td>
                    </tr>
                    {this.renderPPN()}
                    {this.renderDelivery()}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="continer-payment-method">
              <div className="payment-method">
                <span>Payment Method</span>
              </div>
              <div className="payment-select-method">
                <Select
                  value={this.state.paymentmethod}
                  onChange={this.handleChangePayment}
                  options={this.paymentList}
                />
              </div>
            </div>
          </div>
          {/* <div className="payment-button-container">
                  <Button color="success" className="fullbutton" style={{fontWeight:'bold', fontSize:16}} onClick={() => this.doPay()}>Place Order</Button>
              </div> */}
          <div className="submit-button">
            <table>
              <tr>
                <td>
                  <div className="field-label">Total Payment</div>
                </td>
                <td>&nbsp;&nbsp;</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <Input
                    style={{
                      color: "#02ae41",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                    disabled="disabled"
                    value={convertToRupiah(this.state.total_payment)}
                  />
                </td>
                <td>&nbsp;&nbsp;</td>
                <td>
                  <Button
                    color="success"
                    onClick={() => this.checkData()}
                    block
                  >
                    {" "}
                    Order
                  </Button>
                  {/* <Button color="success" block> Order</Button> */}
                </td>
              </tr>
            </table>
          </div>
        </Modal>
      );
    }
  }

  renderLoading = () => {
    if (this.state.showLoading) {
      return <LoadingScreen></LoadingScreen>;
    }
  };

  BottomStyle = () => {
    let showBottom = 60;
    let hideBottom = 0;
    if (this.state.counteritem > 0) {
      return showBottom;
    } else {
      return hideBottom;
    }
  };

  render() {
    if (this.state.redirect) {
      /*if (this.state.page == "newspage") {
            return <Redirect to='/news' />;
        }*/
    }

    let listtags = [];
    if (this.state.merchantData.tags !== undefined)
      listtags = this.state.merchantData.tags;

    let profileimg = require("../../../images/default.png");
    if (
      this.state.merchantData.merchantpic !== undefined &&
      this.state.merchantData.merchantpic !== ""
    )
      profileimg = this.state.merchantData.merchantpic;

    let floatingDiv = "";
    if (this.state.counteritem > 0)
      floatingDiv = (
        <div className="floating-cart" onClick={() => this.goToDetail()}>
          <table className="table-cart">
            <tr>
              <td>
                <FontAwesomeIcon
                  icon="shopping-cart"
                  className="list-icon cart-icon"
                />
                &nbsp;&nbsp; {this.state.counteritem} item(s)
              </td>
              <td className="total-col">
                Rp. {numberFormat(this.state.counterprice)}
              </td>
            </tr>
          </table>
        </div>
      );

    return (
      <>
        <SubHeaderFood
          history={this.props.history}
          hideSearch={true}
          onSearch={this.onSearch}
          showAddButton={true}
          goBack={this.goBack}
          add={this.goToActivityOrder}
        />
        <div
          className="merchant-profile-massages"
          style={{ bottom: this.BottomStyle() }}
        >
          <div className="profile-outer-container">
            <div className="profile-inner-container">
              <div className="profile-name">{this.state.merchantData.name}</div>
              <div
                className="profile-desc"
                dangerouslySetInnerHTML={{
                  __html: this.state.merchantData.shortdesc,
                }}
              ></div>
              <div className="profile-close">{this.state.closeInfo}</div>
              <div className="profile-tags">
                {listtags.map((tag, i) => {
                  if (tag !== "")
                    return (
                      <div key={i} className="tag">
                        {tag}
                      </div>
                    );
                })}
              </div>

              <div className="profile-img">
                <img src={profileimg} alt="img-profile" />
              </div>
            </div>
          </div>
          {this.renderTab()}
        </div>
        {this.renderShoppingChart()}
        {this.renderModal()}
        {this.renderLoading()}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            {this.language.confirmation}
          </ModalHeader>
          <ModalBody>{this.language.areyousure}</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.confirmBack}>
              {this.language.yes}
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              {this.language.no}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default CommodityMassagesPage;
