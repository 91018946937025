import React, { Component } from "react";
//import { Container, Row, Col } from 'reactstrap';
import "./FoodCommodityListItem.style.css";
import { getLanguage } from "../../../Languages";
import { webserviceurl, activelanguage } from "../../../Config";
import { numberFormat } from "../../../Global";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "55%",
    right: "-20%",
    bottom: "-30%",
    transform: "translate(-50%, -50%)",
  },
};

class FoodCommodityListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      name: props.name,
      category: props.category,
      shortDesc: props.shortdesc,
      tags: props.tags === undefined ? [] : props.tags, //array
      pic: props.pic,
      price: props.price,
      qty: props.qty,
      sold: props.sold,
      closeInfo: props.closeInfo,
      commodityIsDiscountId: props.commodityIsDiscountId,
      priceDiscountId: props.priceDiscountId,
      promo1buyget1Id: props.promo1buyget1Id,
      recommendedId: props.recommendedId,
      hidePlusMinus:
        props.hidePlusMinus === undefined ? false : props.hidePlusMinus,
      modalDescriptionIsOpen: false,
    };
    this.language = getLanguage(activelanguage, "merchantprofile");

    this.openModalDescription = this.openModalDescription.bind(this);
    this.closeModalDescription = this.closeModalDescription.bind(this);
  }

  closeModalDescription() {
    this.setState({ modalDescriptionIsOpen: false });
  }

  openModalDescription = () => {
    this.setState({ modalDescriptionIsOpen: true });
  };

  componentDidMount = () => {
    console.log(this.state.closeInfo === "" ? "Buka" : "Tutup");
  };

  componentWillReceiveProps = (props) => {
    this.setState({
      id: props.id,
      name: props.name,
      category: props.category,
      shortDesc: props.shortdesc,
      tags: props.tags === undefined ? [] : props.tags, //array
      pic: props.pic,
      price: props.price,
      qty: props.qty,
      sold: props.sold,
      closeInfo: props.closeInfo,
      commodityIsDiscountId: props.commodityIsDiscountId,
      priceDiscountId: props.priceDiscountId,
      promo1buyget1Id: props.promo1buyget1Id,
      recommendedId: props.recommendedId,
      hidePlusMinus:
        props.hidePlusMinus === undefined ? false : props.hidePlusMinus,
    });
  };

  updateQty = (value) => {
    let tmpQty = this.state.qty;
    if (value > 0) {
      tmpQty += value;
    } else {
      if (tmpQty > 0) {
        tmpQty += value;
      }
    }

    this.props.updateQty(tmpQty, this.state.id);
  };

  renderCategory = () => {
    if (this.state.category !== undefined && this.state.category !== "") {
      return <div className="category">{this.state.category}</div>;
    }
  };

  renderPicture = () => {
    if (this.state.pic !== "") {
      return (
        <div
          className="foodgarden-imgitem-container"
          onClick={() => this.openModalDescription()}
        >
          <img src={this.state.pic} alt={"imgpic"} />
        </div>
      );
    } else {
      return (
        <div
          className="foodgarden-imgitem-container"
          onClick={() => this.openModalDescription()}
        >
          <img
            // src={require("../../../images/noimage.png")}
            src={require("../../../images/noimage.jpg")}
            alt={"imgpic"}
            className="imgdefault"
          />
        </div>
      );
    }
  };

  renderPictureModal = () => {
    if (this.state.pic !== "") {
      return (
        <div
          className="product-image"
          onClick={() => this.openModalDescription()}
        >
          <img src={this.state.pic} />
        </div>
      );
    } else {
      return (
        <div
          className="product-image"
          onClick={() => this.openModalDescription()}
        >
          <img
            // src={require("../../../images/noimage.png")}
            src={require("../../../images/noimage.jpg")}
            alt={"imgpic"}
            className="imgdefault"
          />
        </div>
      );
    }
  };

  renderPlusMinus = () => {
    if (!this.state.hidePlusMinus) {
      return (
        <div className="qty-container">
          <div className="plus-minus-button">
            <div className="plus-button" onClick={() => this.updateQty(1)}>
              +
            </div>
            <div className="qty-value">{this.state.qty}</div>
            {this.renderMinusButton()}
          </div>
        </div>
      );
    }
  };

  renderMinusButton = () => {
    if (this.state.qty > 0) {
      return (
        <div className="minus-button" onClick={() => this.updateQty(-1)}>
          -
        </div>
      );
    } else {
      return (
        <div className="minus-button" onClick={() => this.updateQty(-1)}>
          -
        </div>
      );
    }
  };

  renderModalCloseInfo = () => {
    if (this.state.closeInfo === "Buka") {
      return this.renderPlusMinus();
    }
  };

  renderModalDescription() {
    return (
      <Modal
        isOpen={this.state.modalDescriptionIsOpen}
        onRequestClose={this.closeModalDescription}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
      >
        <div className="main-container merchant-payment">
          <div
            className="close-button"
            onClick={() => this.closeModalDescription()}
          >
            X
          </div>
          <div className="product-content">
            <div style={{ marginTop: 60, textAlign: "center" }}>
              {this.renderPictureModal()}
            </div>
            <div className="product">&nbsp;</div>
            <div className="product-title">{this.state.name}</div>
            <div
              className="product-description"
              dangerouslySetInnerHTML={{ __html: this.state.shortDesc }}
            ></div>
            {this.modalDiscount()}
            <div style={{ textAlign: "center" }}>{this.modalPromo()}</div>
            <div className="relative-container">
              {this.renderModalCloseInfo()}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  modalDiscount = () => {
    if (this.state.commodityIsDiscountId === 1) {
      return (
        <>
          <div className="product-price">
            <span>
              Rp.
              {numberFormat(
                this.state.price *
                  ((100 - this.state.priceDiscountId.replace("%", "")) / 100)
              )}
            </span>
            &nbsp;
            <span
              style={{
                fontFamily: "monospace",
                textDecoration: "line-through",
                color: "#808080",
              }}
            >
              Rp.{numberFormat(this.state.price)}
            </span>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="product-price">
            <span>Rp.{numberFormat(this.state.price)}</span>
          </div>
        </>
      );
    }
  };

  renderDiscount = () => {
    if (this.state.commodityIsDiscountId === 1) {
      return (
        <>
          <span style={{ fontFamily: "monospace" }}>
            Rp.
            {numberFormat(
              this.state.price *
                ((100 - this.state.priceDiscountId.replace("%", "")) / 100)
            )}
          </span>
          &nbsp;
          <span
            style={{
              fontFamily: "monospace",
              textDecoration: "line-through",
              color: "#808080",
            }}
          >
            Rp.{numberFormat(this.state.price)}
          </span>
        </>
      );
    } else {
      return (
        <span style={{ fontFamily: "monospace" }}>
          Rp.{numberFormat(this.state.price)}
        </span>
      );
    }
  };

  modalPromo = () => {
    if (this.state.commodityIsDiscountId === 1) {
      return (
        <div className="promo-modal">
          Promo Disc {this.state.priceDiscountId}
        </div>
      );
    } else if (this.state.promo1buyget1Id === 1) {
      return <div className="promo-modal">Promo Buy 1 Get 1</div>;
    }
  };

  renderPromo = () => {
    if (this.state.commodityIsDiscountId === 1) {
      return (
        <div className="promo">Promo Disc {this.state.priceDiscountId}</div>
      );
    } else if (this.state.promo1buyget1Id === 1) {
      return <div className="promo">Promo Buy 1 Get 1</div>;
    }
  };

  renderCloseInfo = () => {
    if (this.state.closeInfo === "Buka") {
      return this.renderPlusMinus();
    }
  };

  render() {
    return (
      <div className="list-item">
        <div className="relative-container">
          {this.renderModalDescription()}
          {this.renderPicture()}
          <div className="data-container">
            <div
              className="foodgarden-name"
              onClick={() => this.openModalDescription()}
            >
              {/*this.renderCategory()*/}
              {this.state.name}
            </div>
            <div
              className="foodgarden-shortdesc-container"
              style={{ fontSize: 13 }}
              dangerouslySetInnerHTML={{ __html: this.state.shortDesc }}
            ></div>
            <div>{this.renderDiscount()}</div>
            {this.renderPromo()}
          </div>
          {this.renderCloseInfo()}
        </div>
      </div>
    );
  }
}

export default FoodCommodityListItem;
