import React, { Component } from "react";
import { getLanguage } from "../../../Languages";
import { Container, Row, Col } from "reactstrap";
import axios from "axios";
import "./style.css";

import { webserviceurl, activelanguage } from "../../../Config";
import SubHeader from "../../Component/SubHeader/SubHeader";
class PrivacyAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      redirect: false,
    };
  }
  componentDidMount() {
    this.waitForBridge();
  }
  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      //let param = '{"title":"' + this.language.title + '","canGoBack":false, "showCommunityName":true}';
      let param =
        '{"title":"","canGoBack":true, "showCommunityName":false, "hideTopbar":false, "hideFooterMenu":false}';
      window.postMessage(param, "*");
    }
  }
  render() {
    return (
      <div>
        {/* <SubHeader history={this.props.history} /> */}
        <div className="main-container whitebg">
          <div className="privacy-title">
            KOSMO Smart Community Platform Privacy Policy
          </div>
          <div className="privacy-content">
            <p>
              <b>
                Your trust is very important to KOSMO. We are well aware of the
                importance of personal information to you. We will do our best
                to prevent your information from being leaked, damaged, and
                destroyed in accordance with laws and regulations. Lost. We will
                integrate legal, product, design and other factors into the
                concept of privacy protection in all aspects of product or
                service development. I hope you read the "Privacy Policy"
                (hereinafter referred to as "the Policy") carefully to learn
                more about our collection and use of information so that you can
                better understand our services and make appropriate choices.
              </b>
              <br></br>
              <b>1. Information we collect</b>
              <br></br>
              We only collect the information necessary to realize the function
              of the product in accordance with the principles of lawfulness,
              fairness and necessity. Among them, personal information refers to
              a variety of information that can be individually or combined with
              other information to identify the personal identity of a natural
              person, including but not limited to the name, date of birth, ID
              number, personal biometric information, address, Phone number etc.
              <br></br>
              1.1 Information that you proactively provide when using our
              services<br></br>
              1.1.1 The information you filled in when you registered your
              account. For example, the nickname and mobile phone number you
              filled in when you registered your account.
              <br></br>
              1.1.2 Information uploaded when you use the service. For example,
              avatars uploaded when you use software services, photos shared,
              etc.
              <br></br>
              1.1.3 The information you submit through our customer service or
              when participating in an event held by us. For example, the
              questionnaire filled out when you participate in our online
              activities may contain your name, phone number, home address, etc.
              Some of our services may require you to provide specific personal
              sensitive information to achieve specific functions. If you choose
              not to provide such information, you may not be able to use
              certain functions in the service normally, but it will not affect
              your use of other functions in the service. If you take the
              initiative to provide your sensitive personal information, it
              means that you agree that we will process your sensitive personal
              information in accordance with the purposes and methods described
              in this policy (sensitive personal information means that once it
              is leaked, illegally provided or abused, it may endanger personal
              and property safety Personal information that can easily lead to
              personal reputation, physical and mental health damage or
              discriminatory treatment, etc. For example, personal sensitive
              information includes ID number, personal biometric information,
              bank account number, communication content, health and
              physiological information, etc.).
              <br></br>
              1.2 Information we obtain when you use the service<br></br>
              1.2.1 Log information. When you use our services, we may
              automatically collect relevant information and store it as service
              log information.
              <br></br>
              (1) Device Information. For example, information such as device
              model, operating system version, unique device identifier,
              battery, and signal strength.
              <br></br>
              (2) Software information. For example, the version number of the
              software, browser type. In order to ensure the safety of the
              operating environment or provide services required, we will
              collect information about the mobile applications and other
              software you use.
              <br></br>
              (3) IP address. Each device connected to the Internet is assigned
              a number, called an Internet Protocol IP address. These numbers
              are usually assigned based on geographic area. The IP address is
              usually used to identify the location of the device when it is
              connected to the Internet.
              <br></br>
              (4) Service log information. For example, the information you
              searched for and viewed when using our services, service failure
              information, referring URLs and other information.
              <br></br>
              (5) Communication log information. For example, the account,
              communication time and length of communication you have used when
              using our services.
              <br></br>
              1.2.2 location information. When you use location-related
              services, we may record the location information of your device in
              order to provide you with related services.
              <br></br>
              (1) When you use the service, we may obtain your geographic
              location information through IP address, GPS, WiFi, or base
              station;
              <br></br>
              (2) The information provided by you or other users when using the
              service may include information about your geographic location,
              such as the information about your location that may be included
              in the account information you provide, and the geotag information
              in photos shared by you or others;
              <br></br>
              1.2.3 Other relevant information. In order to help you better use
              our products or services, we will collect relevant information.
              For example, we collect friend list, group list information,
              voiceprint feature value information. To ensure that you can
              contact people you know when you use our services, if you choose
              to enable the import address book function, we may encrypt the
              names and phone numbers of your contacts and collect only the
              encrypted information.
              <br></br>
              1.3 The information shared by other users contains your
              information. For example, photos posted or shared videos by other
              users may contain your information.
              <br></br>
              1.4 Information obtained from third-party partners. We may obtain
              information generated or shared when you use third-party partner
              services. For example, when you log in to a third-party partner
              service, we will obtain your login name and login time.
              <br></br>
              <b>Two. How do we use the collected information</b>
              <br></br>
              We strictly abide by laws and regulations and agreements with
              users, and use the collected information for the following
              purposes. If we use your information beyond the following
              purposes, we will explain to you again and obtain your consent.
              <br></br>
              2.1 To provide you with services.<br></br>
              2.2 To meet your individual needs. For example, language settings,
              location settings, personalized help services.
              <br></br>
              2.3 Product development and service optimization. For example,
              when our system fails, we will record and analyze the information
              generated when the system fails to optimize our services.
              <br></br>
              2.4 Security guarantee. For example, we will use your information
              for identity verification, security prevention, anti-fraud
              monitoring, archive backup, and customer security services. For
              example, the security software you download or install will detect
              malicious programs or viruses, or identify fraudulent information
              for you.
              <br></br>
              2.5 Recommend to you advertisements and information that may be of
              interest to you. <br></br>
              2.6 To evaluate and improve the effectiveness of our advertising
              and other promotions and promotional activities. <br></br>
              2.7 Management software. For example, software certification,
              software upgrades, etc. <br></br>
              2.8 You are invited to participate in surveys about our services.
              In order to allow you to have a better experience, improve our
              services or other purposes agreed by you, we may use the
              information collected through certain services for our other
              services, subject to relevant laws and regulations. For example,
              use your information when you use one of our services to show you
              personalized content or advertisements in another service, and use
              it for user research, analysis, and statistics.
              <br></br>
              In order to ensure the security of the service and help us better
              understand the operation of our applications, we may record
              relevant information, such as the frequency of your use of the
              application, failure information, overall usage, performance data,
              and the source of the application. We will not combine the
              information we store in the analysis software with the personally
              identifiable information you provide in the application.
              <br></br>
              <b>3. How do we use cookies and related technologies</b>
              <br></br>
              In order to make your visit experience easier, we may use various
              technologies to collect and store data related to your visit
              during the process of using products or services. We can identify
              you when you visit or visit again, and analyze Data provides you
              with better and more services. Including the use of small data
              files to identify your identity, this is to understand your usage
              habits, to help you save the steps of repeatedly entering account
              information, or to help determine the security of your account.
              These data files may be Cookies, Flash Cookies, or other local
              storage provided by your browser or associated applications
              (collectively referred to as "Cookies").
              <br></br>
              Please understand that some of our services can only be achieved
              through the use of cookies. If your browser or browser additional
              services allow, you can modify the acceptance level of cookies or
              reject cookies, but in some cases, you may not be able to use some
              functions that rely on cookies.
              <br></br>
              <b>Four. Information you shared</b>
              <br></br>
              You can share your relevant information with your friends, family
              and other users through our services. Please note that this may
              contain sensitive information such as your personally identifiable
              information and personal property information. Please carefully
              consider disclosing your relevant personal sensitive information.
              <br></br>
              You can control the scope of your sharing information through the
              privacy settings in our service, and you can delete the
              information you publicly share through the settings in the service
              or the guidelines we provide. However, please note that this
              information may still be stored independently by other users or
              non-associated third parties not under our control.
              <br></br>
              <b>5. How do you manage your own information</b>
              <br></br>
              5.1 You can access, modify and delete the registration information
              and other personal information you provide while using our
              services, and you can also contact us in accordance with the
              notification guidelines. The scope and method of your access,
              modification and deletion of personal information will depend on
              the specific services you use. For example, if you want to stop
              sharing your geographic location information while using
              geographic location-related services, you can stop sharing through
              the mobile location shutdown function, software and hardware
              service providers, and communication service providers' shutdown
              methods. It is recommended that you read the relevant guidelines
              carefully .<br></br>
              5.2 We will collect and use your information only to realize the
              functions of our products or services in accordance with this
              policy. If you find that we have collected and used your personal
              information in violation of laws, administrative regulations, or
              agreed by both parties, you can ask us to delete it. If you find
              that the personal information we collect and store is wrong, you
              can also ask us to correct it. Please contact us through the
              contact information listed in this policy.
              <br></br>
              5.3 When you access, modify and delete related information, we may
              require you to verify your identity to protect the security of
              your account.
              <br></br>
              5.4 Please understand that due to technical limitations, legal or
              regulatory requirements, we may not be able to meet all your
              requirements, and we will respond to your request within a
              reasonable period of time.
              <br></br>
              <b>Six. Information we share</b>
              <br></br>
              We strictly restrict the sharing of information in compliance with
              laws and regulations, such as:
              <br></br>
              6.1 With your prior consent, we may share your personal
              information with third parties. <br></br>
              6.2 For the purpose of external processing only, we may cooperate
              with third-party partners (third-party service providers,
              contractors, agencies, advertising partners, application
              developers, etc., for example, communication services that send
              emails or push notifications on our behalf Providers, map service
              providers that provide us with location services) (they may not be
              located in your jurisdiction) share your personal information, let
              them follow our instructions, privacy policies and other relevant
              confidentiality and security measures to provide us Process the
              above information and use it for the following purposes:
              <br></br>
              6.2.1 To provide you with our services. <br></br>
              6.2.2 To achieve the purpose described in the section "How do we
              use the collected information". <br></br>
              6.2.3 To perform our obligations and exercise our rights in the
              "User Agreement" or this policy.
              <br></br>
              6.2.4 Understand, maintain and improve our services. If we share
              your information with the above-mentioned third parties, we will
              use encryption, anonymization and other means to protect your
              information.
              <br></br>
              6.3 With the continuous development of our business, when mergers,
              acquisitions, asset transfers and other transactions lead to the
              sharing of your personal information with third parties, we will
              inform you of the relevant situation through push notifications,
              announcements, etc., in accordance with laws and regulations and
              no less than The standards required by this policy continue to
              protect or require new managers to continue to protect your
              personal information.
              <br></br>
              6.4 We will use the collected information for big data analysis.
              For example, we use the collected information for analysis to form
              a city heat map or industry insight report that does not contain
              any personal information. We may publicly disclose and share with
              our partners information that does not contain identification
              content after statistical processing, so as to understand how
              users use our services or to let the public understand the general
              usage trends of our services.
              <br></br>
              6.5 We may disclose your personal information for the following
              purposes: <br></br>
              6.5.1 Comply with applicable laws and regulations and other
              relevant regulations.<br></br>
              6.5.2 Comply with court judgments, rulings or other legal
              procedures. <br></br>
              6.5.3 Comply with the requirements of relevant government agencies
              or other legally authorized organizations. <br></br>
              6.5.4 We have reason to believe that we need to comply with
              relevant regulations such as laws and regulations. <br></br>
              6.5.5 It is reasonable and necessary for the purpose of
              implementing relevant service agreements or this policy,
              safeguarding the public interest, and protecting the personal and
              property safety or other legal rights of our customers, us or our
              affiliates, other users or employees.
              <br></br>
              <b>VII. Information we may send to you</b>
              <br></br>
              7.1 Information push. When you use our services, we may send you
              emails, text messages, news or push notifications.
              <br></br>
              7.2 Service-related announcements. We may issue service-related
              announcements to you when necessary (for example, when a certain
              service is suspended due to system maintenance). You may not be
              able to cancel these service-related announcements that are not
              advertisements in nature.
              <br></br>
              <b>8. The location and period of information storage</b>
              <br></br>
              8.1 Where to store information<br></br>
              We abide by laws and regulations and store the personal
              information of users collected in China.
              <br></br>
              8.2 Period of information storage<br></br>
              Generally speaking, we only keep your personal information for the
              shortest time necessary to achieve the purpose. However, in the
              following circumstances, we may change the storage time of
              personal information due to compliance with legal requirements:
              <br></br>
              8.2.1 To comply with applicable laws and regulations and other
              relevant regulations. <br></br>
              8.2.2 To comply with court judgments, rulings or other legal
              procedures. <br></br>
              8.2.3 To comply with the requirements of relevant government
              agencies or legally authorized organizations. <br></br>
              8.2.4 We have reason to believe that we need to comply with
              relevant regulations such as laws and regulations. <br></br>
              8.2.5 Purposes reasonably necessary to implement relevant service
              agreements or this policy, safeguard the public interest, and
              protect the personal and property safety or other legal rights of
              our customers, us or our affiliates, other users or employees
              <br></br>
              When our products or services stop operating, we will notify you
              in the form of push notifications, announcements, etc., and delete
              or anonymize your personal information within a reasonable period
              of time.
              <br></br>
              <b>Nine. Information Security</b>
              <br></br>
              We provide corresponding security guarantees for your information
              to prevent information loss, improper use, unauthorized access or
              disclosure.
              <br></br>
              9.1 We strictly abide by laws and regulations to protect users'
              communication secrets. <br></br>
              9.2 We will use various security protection measures within a
              reasonable security level to ensure the security of information.
              For example, we use encryption technology, anonymization and other
              means to protect your personal information.
              <br></br>
              9.3 We have established a special management system, process and
              organization to ensure information security. For example, we
              strictly limit the scope of people who can access information,
              require them to observe confidentiality obligations and conduct
              reviews.
              <br></br>
              9.4 In the event of a security incident such as personal
              information leakage, we will initiate an emergency plan to prevent
              the expansion of the security incident, and notify you in the form
              of push notifications, announcements, etc.
              <br></br>
              <b>Ten. Advertising</b>
              <br></br>
              We may use your relevant information to provide you with more
              relevant advertisements on relevant websites, apps and other
              channels. In order to ensure a good user experience and minimize
              the intrusion of unrelated ads to you, we will try our best to
              show you ads that may be more interesting and useful for you. In
              the process of showing you ads, we will work hard to ensure data
              security.
              <br></br>
              <b>11. Protection of minors</b>
              <br></br>
              We attach great importance to the protection of personal
              information of minors. According to relevant laws and regulations,
              if you are a minor under the age of 18, you should obtain the
              consent of your parent or legal guardian before using the services
              of KOSMO.
              <br></br>
              <b>12. Scope of application</b>
              <br></br>
              This policy applies to all our services. However, some services
              have their own specific privacy guidelines/statements, and the
              specific privacy guidelines/statements more specifically explain
              how we handle your information in the service. If there is any
              inconsistency between this policy and the privacy
              guidelines/statements of a specific service, please refer to the
              specific privacy guidelines/statements.
              <br></br>
              Please note that this policy does not apply to services provided
              by other companies or individuals. For example, you log in to the
              services provided by other companies or individuals by using the
              account provided by KOSMO.
              <br></br>
              Your use of these third-party services is subject to their privacy
              policies (not this policy), and you need to read their policies
              carefully.
              <br></br>
              <b>13. Contact us</b>
              <br></br>
              If you have any questions, comments or suggestions about our
              privacy protection policy or data processing, you can contact us
              through the customer service hotline (021) 2936 5888, or mail to
              the following address: aritio@modernland.co.id
              <br></br>
              Address: Jalan Gajah Mada 188, Jakarta, 10130<br></br>
              <br></br>
              <b>14. Changes</b>
              <br></br>
              We may revise the content of this policy in due course. If such
              changes will result in a substantial reduction of your rights
              under this policy, we will notify you by prompting in a prominent
              position on the page and sending you an email before the change
              takes effect. In this case, if you continue to use our services,
              you agree to be bound by the revised policy.
              <br></br>
              Update date: 2020-10-28 <br></br>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyAgreement;
