import React, { Component } from "react";
import "./App.css";
import {
  HashRouter,
  Router,
  IndexRoute,
  useRouterHistory,
  Route,
} from "react-router-dom";
import News from "./App/Pages/News/News";
import Home from "./App/Pages/Home/Home";
import HomeBinding from "./App/Pages/HomeBinding/HomeBinding";
import NewsDetail from "./App/Pages/NewsDetail/NewsDetail";
import ParkInformation from "./App/Pages/ParkInformation/ParkInformation";
import ParkEnterprise from "./App/Pages/ParkEnterprise/ParkEnterprise";
import CompanyPage from "./App/Pages/CompanyPage/CompanyPage";
import ProductPage from "./App/Pages/ProductPage/ProductPage";
import ServicePage from "./App/Pages/ServicePage/ServicePage";
import CompanyProfilePage from "./App/Pages/CompanyProfilePage/CompanyProfilePage";
import ProductDetailPage from "./App/Pages/ProductDetailPage/ProductDetailPage";
import ServiceDetailPage from "./App/Pages/ServiceDetailPage/ServiceDetailPage";
import ComingSoonPage from "./App/Pages/ComingSoonPage/ComingSoonPage";
import PersonalInformation from "./App/Pages/PersonalInformation/PersonalInformation";
import ProjectPage from "./App/Pages/ProjectPage/ProjectPage";
import ProjectDetailPage from "./App/Pages/ProjectDetailPage/ProjectDetailPage";
import TalentPage from "./App/Pages/TalentPage/TalentPage";
import TalentDetailPage from "./App/Pages/TalentDetailPage/TalentDetailPage";
import InvestmentPage from "./App/Pages/InvestmentPage/InvestmentPage";
import InvestmentDetailPage from "./App/Pages/InvestmentDetailPage/InvestmentDetailPage";
import MarketplacePage from "./App/Pages/MarketplacePage/MarketplacePage";
import MarketplacePage2 from "./App/Pages/MarketplacePage/MarketplacePage2";
import MerchantListPage from "./App/Pages/MerchantListPage/MerchantListPage";
import MerchantProfilePage from "./App/Pages/MerchantProfilePage/MerchantProfilePage";
import MerchantPayment from "./App/Pages/MerchantPayment/MerchantPayment";
import Moments from "./App/Pages/Moments/Moments";
import MomentsDetail from "./App/Pages/MomentsDetail/MomentsDetail";
import OnlineStorePage from "./App/Pages/OnlineStorePage/OnlineStorePage";
import CoorporateContactPage from "./App/Pages/CoorporateContactPage/CoorporateContactPage";
import EntertainmentPage from "./App/Pages/EntertainmentPage/EntertainmentPage";
import PaymentCenterPage from "./App/Pages/PaymentCenterPage/PaymentCenterPage";
import PaymentForm from "./App/Pages/PaymentCenterDetailPage/PaymentForm/PaymentForm";
import StatusCallBack from "./App/Pages/StatusCallBack/StatusCallBack";
import StatusPaymentServices from "./App/Pages/StatusPayment/StatusPayment";
import StatusPaymentBooking from "./App/Pages/StatusPaymentBooking/StatusPaymentBooking";
import PaymentCenterMultiple from "./App/Pages/PaymentCenterMultiple/PaymentCenterMultiple";
import PaymentMultipleDetailPage from "./App/Pages/PaymentMultipleDetailPage/PaymentMultipleDetailPage";
import PaymentCenterDetailPage from "./App/Pages/PaymentCenterDetailPage/PaymentCenterDetailPage";
import ListDebtor from "./App/Pages/Home/ListDebtor/ListDebtor";
import VisitorAccessPage from "./App/Pages/VisitorAccessPage/VisitorAccessPage";
import VisitorAccessDetailPage from "./App/Pages/VisitorAccessDetailPage/VisitorAccessDetailPage";
import ServiceCenterPage from "./App/Pages/ServiceCenterPage/ServiceCenterPage";
import ServiceCenterDetail from "./App/Pages/ServiceCenterDetail";
import ServiceCenterConfirmation from "./App/Pages/ServiceCenterConfirmation";
import ServiceCenterForm from "./App/Pages/ServiceCenterForm/ServiceCenterForm";
import ServiceCenterCategorySelect from "./App/Pages/ServiceCenterCategorySelect/ServiceCenterCategorySelect";
import SpaceBookings from "./App/Pages/SpaceBookings/SpaceBookings";
import BanquetFacilities from "./App/Pages/BanquetFacilitiesPage/BanquetFacilitiesPage";
import WeddingCategorySelect from "./App/Pages/WeddingCategorySelect/WeddingCategorySelect";
import WeddingReservationForm from "./App/Pages/WeddingReservationForm/WeddingReservationForm";
import WeddingReservationDetail from "./App/Pages/WeddingReservationDetail/WeddingReservationDetail";
import NonWeddingCategorySelect from "./App/Pages/NonWeddingCategorySelect/NonWeddingCategorySelect";
import NonWeddingReservationForm from "./App/Pages/NonWeddingReservationForm/NonWeddingReservationForm";
import NonWeddingReservationDetail from "./App/Pages/NonWeddingReservationDetail/NonWeddingReservationDetail";
import RoomReservationDetail from "./App/Pages/RoomReservationDetail/RoomReservationDetail";
import RoomReservationForm from "./App/Pages/RoomReservationForm/RoomReservationForm";
import RoomCategorySelect from "./App/Pages/RoomCategorySelect/RoomCategorySelect";
import ActivityReservationDetail from "./App/Pages/ActivityReservationDetail/ActivityReservationDetail";
import ActivityReservationForm from "./App/Pages/ActivityReservationForm/ActivityReservationForm";
import ActivityCategorySelect from "./App/Pages/ActivityCategorySelect/ActivityCategorySelect";
import DirectoryCategorySelect from "./App/Pages/DirectoryCategorySelect/DirectoryCategorySelect";
import Directory from "./App/Pages/Directory/Directory";
import DirectoryDetail from "./App/Pages/DirectoryDetail/DirectoryDetail";
import FoodGardenPage from "./App/Pages/FoodGardenPage/FoodGardenPage";
import FoodGardenDetail from "./App/Pages/FoodGardenDetail/FoodGardenDetail";
import MarketPage from "./App/Pages/MarketPage/MarketPage";
import LaundryPage from "./App/Pages/LaundryPage/laundryPage";
import HouseKeepingPage from "./App/Pages/HouseKeepingPage/HouseKeepingPage";
import MaintenancePage from "./App/Pages/MaintenancePage/MaintenancePage";
import MovingPage from "./App/Pages/MovingPage/MovingPage";
import MessagesPage from "./App/Pages/MessagesPage/MessagesPage";
import TrafficCamera from "./App/Pages/TrafficCamera";
import OrderActivity from "./App/Pages/OrderFoodActivityPage/OrderFoodActivityPage";
import OrderActivityDetail from "./App/Pages/OrderFoodActivityDetail.js/OrderFoodActivityDetail";
import HistoryFoodOrder from "./App/Pages/HistoryFoodOrder/HistoryFoodOrder";
import HistoryFoodOrderDetail from "./App/Pages/HistoryFoodOrderDetail/HistoryFoodOrderDetail";
import HistoryServiceOrder from "./App/Pages/HistoryServiceOrder/HistoryServiceOrder";
import HistoryServiceOrderDetail from "./App/Pages/HistoryServiceOrderDetail/HistoryServiceOrderDetail";
import CustomerCare from "./App/Pages/CustomerCarePage/CustomerCarePage";
import RescheduleReservation from "./App/Pages/RescheduleReservation/RescheduleReservation";
import RescheduleReservationOther from "./App/Pages/RescheduleReservationOther/RescheduleReservationOther";
import Login from "./App/Pages/Login/Login";
import StatusPaymentToEmail from "./App/Pages/StatusPaymentToEmail/StatusPaymentToEmail";
import Feedback from "./App/Pages/CustomerCareHistory/CustomerCareHistory";
import ContactTracing from "./App/Pages/HealthDeclarationPage/HealthDeclarationPage";
import HealthFormDailyDeclaration from "./App/Pages/HealthFormDailyDeclaration/HealthFormDailyDeclaration";
import HealthFormReturnTrip from "./App/Pages/HealthFormReturnTrip/HealthFormReturnTrip";
import HealthFormFestivalSpring from "./App/Pages/HealthFormFestivalSpring/HealthFormFestivalSpring";
import HealthDetailDailyDeclaration from "./App/Pages/HealthDetailDailyDeclaration/HealthDetailDailyDeclaration";
import HealthDetailReturnTrip from "./App/Pages/HealthDetailReturnTrip/HealthDetailReturnTrip";
// import HealthDetailFestivalSpring from './App/Pages/HealthDetailFestivalSpring/HealthDetailFestivalSpring';
import Covid19 from "./App/Pages/Covid19Page/Covid19Page";
import NewsCovidDetailDetail from "./App/Pages/CovidNewsDetail/CovidNewsDetail";
import NewsFeedDetail from "./App/Pages/NewsFeedDetail/NewsFeedDetail";
import ComplaintCovidHistory from "./App/Pages/ComplaintHistoryPage/ComplaintHistoryPage";
import NewsWFHDetail from "./App/Pages/WFHDetail/NewsDetail";
import NewsWFH from "./App/Pages/WFH/News";
import PrivacyPolice from "./App/Pages/PrivacyAgreement/PrivacyAgreement";
import QuestionnairePage from "./App/Pages/QuestionnairePage/QuestionnairePage";
import MerchantRegistrationScreen from "./App/Pages/MerchantRegistrationPage/index";
import GoogleMaps from "./App/Pages/GoogleMaps/GoogleMaps";
import CommodityMarketPage from "./App/Pages/CommodityMarketPage/CommodityMarketPage";
import CommodityOnlineStorePage from "./App/Pages/CommodityOnlineStorePage/CommodityOnlineStorePage";
import CommodityHouseKeepingPage from "./App/Pages/CommodityHouseKeepingPage/CommodityHouseKeepingPage";
import CommodityMaintenancePage from "./App/Pages/CommodityMaintenancePage/CommodityMaintenancePage";
import CommodityMovingPage from "./App/Pages/CommodityMovingPage/CommodityMovingPage";
import CommodityMassagesPage from "./App/Pages/CommodityMassagesPage/CommodityMassagesPage";
import { library } from "@fortawesome/fontawesome-svg-core";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faUserCircle,
  faSearch,
  faTint,
  faLightbulb,
  faBusinessTime,
  faPhone,
  faTimesCircle,
  faTimes,
  faCircle,
  faPlus,
  faShoppingCart,
  faCamera,
  faShoppingBasket,
  faShoppingBag,
  faArchive,
  faArrowLeft,
  faArrowCircleLeft,
  faChevronRight,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faChevronLeft,
  faEdit,
  faUserCircle,
  faSearch,
  faTint,
  faLightbulb,
  faBusinessTime,
  faPhone,
  faTimesCircle,
  faTimes,
  faCircle,
  faPlus,
  faShoppingCart,
  faCamera,
  faShoppingBasket,
  faShoppingBag,
  faArchive,
  faArrowLeft,
  faArrowCircleLeft,
  faChevronRight
);

class App extends Component {
  render() {
    return (
      <div>
        {/* <Route exact path="/" component={Login} /> */}
        <Route exact path="/" component={Home} />
        <Route path="/homebinding" component={HomeBinding} />
        <Route path="/comingsoon" component={ComingSoonPage} />
        <Route path="/news" component={News} />
        <Route path="/newsdetail/:idnews" component={NewsDetail} />
        <Route path="/newsfeeddetail/:idnewsfeed" component={NewsFeedDetail} />
        <Route path="/parkinformation" component={ParkInformation} />
        <Route path="/parkenterprise/:communityid" component={ParkEnterprise} />
        <Route path="/company" component={CompanyPage} />
        <Route path="/product/:companyid" component={ProductPage} />
        <Route path="/service/:companyid" component={ServicePage} />
        <Route
          path="/companyprofile/:companyid"
          component={CompanyProfilePage}
        />
        <Route path="/productdetail/:productid" component={ProductDetailPage} />
        <Route path="/servicedetail/:serviceid" component={ServiceDetailPage} />
        <Route path="/project/:companyid" component={ProjectPage} />
        <Route path="/projectdetail/:projectid" component={ProjectDetailPage} />
        <Route path="/talent/:companyid" component={TalentPage} />
        <Route path="/talentdetail/:talentid" component={TalentDetailPage} />
        <Route path="/investment/:companyid" component={InvestmentPage} />
        <Route
          path="/investmentdetail/:investmentid"
          component={InvestmentDetailPage}
        />
        <Route exact path="/marketplace" component={MarketplacePage} />
        <Route exact path="/marketplace2" component={MarketplacePage2} />
        <Route exact path="/statuspayment" component={StatusCallBack} />
        <Route
          exact
          path="/statuspaymentservices"
          component={StatusPaymentServices}
        />
        <Route
          exact
          path="/statuspaymentbooking"
          component={StatusPaymentBooking}
        />
        <Route
          path="/merchantlist/:communityid/:categoryid"
          component={MerchantListPage}
        />
        <Route
          path="/merchantprofile/:merchantid"
          component={MerchantProfilePage}
        />
        <Route
          path="/merchantpayment/:merchantid"
          component={MerchantPayment}
        />
        <Route path="/moments" component={Moments} />
        <Route path="/momentsdetail/:momentid" component={MomentsDetail} />
        <Route
          path="/coorporatecontact/:communityid"
          component={CoorporateContactPage}
        />
        <Route path="/personalinformation" component={PersonalInformation} />
        <Route path="/entertainment" component={EntertainmentPage} />
        <Route path="/onlinestore/:communityid" component={OnlineStorePage} />
        <Route path="/marketpage/:communityid" component={MarketPage} />
        <Route path="/laundrypage/:communityid" component={LaundryPage} />
        <Route
          path="/housekeepingpage/:communityid"
          component={HouseKeepingPage}
        />
        <Route
          path="/maintenancepage/:communityid"
          component={MaintenancePage}
        />
        <Route path="/movingpage/:communityid" component={MovingPage} />
        <Route path="/messagespage/:communityid" component={MessagesPage} />
        <Route path="/paymentcenter" component={PaymentCenterPage} />
        <Route path="/paymentmultiple" component={PaymentCenterMultiple} />
        <Route path="/paymentfrom/:xenditId" component={PaymentForm} />
        <Route path="/listdebtor" component={ListDebtor} />
        {/* <Route path="/paymentcenterdetail/:paymentid/:idx/:unitcode" component={PaymentCenterDetailPage} /> */}
        <Route
          path="/paymentcenterdetail/:paymentid/:idx/:unitcode/:companycode"
          component={PaymentCenterDetailPage}
        />
        <Route
          path="/paymentmultipledetail"
          component={PaymentMultipleDetailPage}
        />
        {/* <Route path="/paymentcenterdetail/:invno/:unitcode" component={PaymentCenterDetailPage} /> */}
        {/* <Route path="/paymentcenterdetail/:invno/:debtoracct/:unitcode" component={PaymentCenterDetailPage} /> */}
        {/* <Route path="/paymentcenterdetail/:invno/:debtoracct/:unitcode" component={PaymentCenterDetailPage} /> */}
        {/* <Route path="/paymentcenterdetail/:invno/:debtoracct" component={PaymentCenterDetailPage} /> */}
        <Route path="/visitoraccess" component={VisitorAccessPage} />
        <Route
          path="/visitoraccessdetail/:uservisitid"
          component={VisitorAccessDetailPage}
        />
        <Route path="/servicecenter" component={ServiceCenterPage} />
        <Route
          path="/servicecenterdetail/:userserviceid"
          component={ServiceCenterDetail}
        />
        <Route
          path="/servicecenterconfirmation/:userserviceid"
          component={ServiceCenterConfirmation}
        />
        <Route
          path="/servicecenterform/:servicecentercategoryid"
          component={ServiceCenterForm}
        />
        <Route
          path="/servicecentercategoryselect"
          component={ServiceCenterCategorySelect}
        />
        <Route path="/spacebookings" component={SpaceBookings} />
        <Route path="/banquetfacilities" component={BanquetFacilities} />
        <Route
          path="/weddingcategoryselect"
          component={WeddingCategorySelect}
        />
        <Route
          path="/weddingreservationform/:weddingcategoryid"
          component={WeddingReservationForm}
        />
        <Route
          path="/weddingreservationdetail/:weddingreservationid"
          component={WeddingReservationDetail}
        />
        <Route
          path="/nonweddingcategoryselect"
          component={NonWeddingCategorySelect}
        />
        <Route
          path="/nonweddingreservationform/:nonweddingcategoryid"
          component={NonWeddingReservationForm}
        />
        <Route
          path="/nonweddingreservationdetail/:nonweddingreservationid"
          component={NonWeddingReservationDetail}
        />
        <Route
          path="/roomreservationdetail/:roomreservationid"
          component={RoomReservationDetail}
        />
        <Route
          path="/roomreservationform/:roomcategoryid"
          component={RoomReservationForm}
        />
        <Route path="/roomcategoryselect" component={RoomCategorySelect} />
        <Route
          path="/activityreservationdetail/:activityreservationid"
          component={ActivityReservationDetail}
        />
        <Route
          path="/activityreservationform/:activitycategoryid"
          component={ActivityReservationForm}
        />
        <Route
          path="/activitycategoryselect"
          component={ActivityCategorySelect}
        />
        <Route
          path="/directorycategoryselect"
          component={DirectoryCategorySelect}
        />
        <Route path="/foodgarden" component={FoodGardenPage} />
        <Route path="/foodgardendetail/:id" component={FoodGardenDetail} />
        <Route path="/directory/:directorycategoryid" component={Directory} />
        <Route
          path="/directorydetail/:directoryid"
          component={DirectoryDetail}
        />
        <Route path="/trafficcamera" component={TrafficCamera} />
        <Route path="/customercare" component={CustomerCare} />
        <Route
          path="/reschedulereservation/:weddingcategoryid/:weddingreservationid"
          component={RescheduleReservation}
        />
        <Route
          path="/reschedulereservationother/:nonweddingcategoryid/:nonweddingreservationid"
          component={RescheduleReservationOther}
        />
        <Route path="/orderactivity" component={OrderActivity} />
        <Route
          path="/orderactivitydetail/:orderid"
          component={OrderActivityDetail}
        />
        <Route path="/historyfoodorder" component={HistoryFoodOrder} />
        <Route
          path="/historyfoodorderdetail/:orderid"
          component={HistoryFoodOrderDetail}
        />
        <Route path="/historyserviceorder" component={HistoryServiceOrder} />
        <Route
          path="/historyserviceorderdetail/:userserviceid"
          component={HistoryServiceOrderDetail}
        />
        <Route path="/statuspaymenttoemail" component={StatusPaymentToEmail} />
        <Route path="/feedback" component={Feedback} />
        <Route path="/healthdeclaration" component={ContactTracing} />
        <Route
          path="/formdailyhealthdeclaration/:phonenumber"
          component={HealthFormDailyDeclaration}
        />
        <Route
          path="/formreturntrip/:phonenumber"
          component={HealthFormReturnTrip}
        />
        <Route
          path="/formfestivalspring/:phonenumber"
          component={HealthFormFestivalSpring}
        />
        <Route
          path="/detaildailydeclaration/:phonenumber&:date"
          component={HealthDetailDailyDeclaration}
        />
        <Route
          path="/detailreturntrip/:phonenumber&:date"
          component={HealthDetailReturnTrip}
        />
        {/* <Route path="/detailfestivalspring/:phonenumber" component={HealthDetailFestivalSpring}/> */}
        <Route path="/covid19" component={Covid19} />
        <Route
          path="/newscoviddetail/:idnewscovid"
          component={NewsCovidDetailDetail}
        />
        <Route path="/historyformcovid" component={ComplaintCovidHistory} />
        <Route path="/wfh" component={NewsWFH} />
        <Route path="/wfhdetail/:idwfh" component={NewsWFHDetail} />
        <Route path="/questionnaire" component={QuestionnairePage} />
        <Route path="/privacypolicy" component={PrivacyPolice} />
        <Route
          path="/merchantregistration"
          component={MerchantRegistrationScreen}
        />
        <Route path="/googlemaps" component={GoogleMaps} />
        <Route path="/commoditymarket/:id" component={CommodityMarketPage} />
        <Route
          path="/commodityonlinestore/:id"
          component={CommodityOnlineStorePage}
        />
        <Route
          path="/commodityhousekeeping/:id"
          component={CommodityHouseKeepingPage}
        />
        <Route
          path="/commoditymaintenance/:id"
          component={CommodityMaintenancePage}
        />
        <Route path="/commoditymoving/:id" component={CommodityMovingPage} />
        <Route
          path="/commoditymassages/:id"
          component={CommodityMassagesPage}
        />
      </div>
    );
  }
}

export default App;
