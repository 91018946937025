import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import Picture from '../../Component/pictureGallery';
import './NewsFeedDetail.style.css';
import { webserviceurl, activelanguage } from '../../../Config';
import axios from 'axios';
import SubHeader from '../../Component/SubHeader/SubHeader';
import SwipeableViews from 'react-swipeable-views';
import LoadingScreen from '../../Component/LoadingScreen/LoadingScreen';
import newsdefault from '../../../images/news_default.png';
import moment from 'moment';
import { zhCN } from 'date-fns/esm/locale'
import 'moment/locale/en-au' 
moment.locale('zhCN');

class NewsFeedDetail extends Component{
    constructor(props) {
        super(props);

        this.language = getLanguage(activelanguage, 'newsfeeddetail');
        
        this.state = {
            navigateTo: '',
            newscovid: {},
            idNewsFeed: props.match.params.idnewsfeed,
            currentIndex:props.match.params.currentIndex,
            listID:[],
            idx:0,
            left:false,
            right:false,
            onload:false,
            newsFeed: {
                list:[],
            },
            community:'',
            id:'',
            type:'',
            showLoading: false
        }
    }
    
    componentDidMount(){
        this.waitForBridge();
        this.loadNewsFeedDetailList();
    }

    componentWillUnmount=()=>{
        localStorage.setItem('covidtabnews', '1');
    }
    

    setIndex=(list)=>{
        var index = list.findIndex(p => p.id == this.state.idNewsFeed)
        console.log(index);
        this.setState({
            currentIndex : index
        })
    }

    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }.bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":true, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
            window.postMessage(param, "*");
        }
    }


    loadNewsFeedDetailList=()=>{
        this.setState({showLoading:true});
        let urlBerita = 'https://newsapi.org/v2/top-headlines?country=id&apiKey=71b0ec76c4c2460d95430ba7bef9c251'
        axios.get(urlBerita)
            .then((response) => {
                console.log(response)
                let result = response.data;
                if (result.status === "ok") {

                    var tmp = result.articles;
                    for(let i=0;i<tmp.length;i++){
                        tmp[i].id = i;
                    }

                    this.setIndex(tmp);
                    this.setState({ newsFeed: {list:tmp} });
                    console.log(this.state.newsFeed);

                    setTimeout(()=>{
                        this.setState({showLoading:false});
                    }, 200);
                }

            })
            .catch((error) => {
                console.log(error);
            });
    }


    renderLoading=()=>{
        if(this.state.showLoading){
            return (
                <LoadingScreen></LoadingScreen>
            )
        }
    }


    renderThumbnail=(item)=>{
        if(item.previewmode === 1){
            return (
                <div className="tree-mode-image">
                    {item.thumbnail.map((img, i) =>
                        <div className="center-cropped">
                            <img src={img.picture} alt={'imgpic'}/>
                        </div>
                    )}
                </div>
            )
        }else{
            return (
                <div className="tree-mode-image">
                    <div className="center-cropped">
                        <img src={newsdefault} alt={'imgpic'}/>
                    </div>
                </div>
            )
        }
    }

    renderSingleImageFeed=(item)=>{
        if(item.urlToImage !== ''){
            return (
                <div className="headerHome">
                    <div className="home-header" >
                        <div className="home-banner-container">
                            <img src={item.urlToImage} className="home-banner" />
                        </div>
                    </div>
                </div>
            )
        }else{
            return (
                <div className="headerHome">
                    <div className="home-header" >
                        <div className="home-banner-container">
                            <img src={newsdefault} className="home-banner" />
                        </div>
                    </div>
                </div>
            )
        }
    }


    renderDesc=(item)=>{
        if(item.content == null){
            return <div className="news-description" dangerouslySetInnerHTML={{__html: item.description}}></div>
        }else{
            return <div className="news-description" dangerouslySetInnerHTML={{__html: item.content}}></div>
        }
    }

    renderPreviewMode=(item)=>{
        return(
            <div className="news-content" key={item.id} >
                <div className="news-title">{item.title}</div>
                    <div className="descriptime" style={{marginBottom:10}} >
                        <div className="timeupdate" >
                            <span>{moment(item.publishedAt).format("llll")} </span>
                        </div>
                        <div className="reading" >
                            <span>{item.author}</span>
                        </div>
                    </div>
                    {this.renderSingleImageFeed(item)}
                    {this.renderDesc(item)}
            </div>
        )
    }
    
    renderNewsFeedList=()=>{
        if (this.state.newsFeed.list.length > 0) {
            return (
                <SwipeableViews index={this.state.currentIndex}>
                {this.state.newsFeed.list.map((item) =>
                    <div className="main-container whitebg">
                        {this.renderPreviewMode(item)}
                    </div>
                )}
            </SwipeableViews>
            )

        } else {
            return (
                <div className="no-data-available"></div>
            )
        }
    }

    render() {
        if (this.state.navigateTo != '') {
            return <Redirect to={this.state.navigateTo} />;
        }
        return (
        
            <div>
            <SubHeader history={this.props.history} hideSearch={true} title={this.language.title} showAddButton={false} goBack={this.goBack}/>
               {this.renderNewsFeedList()}
               {this.renderLoading()}
            </div>
        )
    }
}

export default NewsFeedDetail;