import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import SubHeader from "../../Component/SubHeader/SubHeader";
import { webserviceurl, activelanguage } from "../../../Config";
import axios from "axios";
import LoadingScreen from "../../Component/LoadingScreen/LoadingScreen";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";

// Geocode.setApiKey("AIzaSyBKuo_L0d5LSJmtBNRjsAEiVFdblxg05TU");
// Geocode.enableDebug();

class GoogleMaps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      city: "",
      area: "",
      state: "",
      zoom: 25,
      height: 400,
      mapPosition: {
        lat: -6.1772007,
        lng: 106.9569963,
      },
      markerPosition: {
        lat: -6.1772007,
        lng: 106.9569963,
      },
      phonenumber: "",
      name: "",
      location: "",
      addressdetail: "",
      city: "",
      postalcode: "",
      latitude: "",
      longitude: "",
    };
  }

  componentDidMount = () => {
    this.waitForBridge();
    this.loadUser();
  };

  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let tmp = localStorage.getItem("smart-app-id-login");
      let needLogin = false;
      // let tmp2 = localStorage.getItem('smart-app-id-binding');
      // let needBinding = false;

      if (
        tmp == undefined ||
        tmp == null ||
        tmp == "null" ||
        tmp == "" ||
        tmp == "undefined"
      )
        needLogin = true;
      // else if(tmp2 == undefined || tmp2 == null || tmp2 == 'null' || tmp2 == '' ||tmp2 == 'undefined' ) needBinding = true;
      else {
        tmp = JSON.parse(tmp);
      }
      let param =
        '{"title":"' +
        this.state.title +
        '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true, "needLogin":' +
        (needLogin ? "true" : "false") +
        "}";
      window.postMessage(param, "*");
    }
  }

  loadUser = () => {
    let info = localStorage.getItem("smart-app-id-login");
    if (
      info != null &&
      info !== undefined &&
      info != "null" &&
      info != "" &&
      info != "undefined"
    ) {
      info = JSON.parse(info);
      this.setState({
        phonenumber: info.phonenumber,
        name: info.name,
      });
      this.doCheckLocationUser(info.phonenumber);
    } else {
      // this.setState({
      //   phonenumber: "082240585393",
      //   name: "Hesti Mutia Lestari",
      // });
      // this.doCheckLocationUser("082240585393");
    }
  };

  doCheckLocationUser = (phonenumber) => {
    let params = {
      phonenumber: phonenumber,
    };
    axios({
      method: "post",
      url: webserviceurl + "/user_location.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        console.log(result);
        if (result.status === "OK") {
          if (result.records.length > 0) {
            const D = result.records.filter((o) => o.is_default === "1");
            console.log(D);
            this.setState({
              location: D[0].location,
            });
            this.setState({
              addressdetail: D[0].location_detail,
            });
            this.setState({
              latitude: D[0].coordinate_lat,
            });
            this.setState({
              latitude: D[0].coordinate_long,
            });
            this.setState({
              city: D[0].city,
            });

            this.setState({
              postalcode: D[0].postalcode,
            });
          }
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  createMarkup = (content) => {
    return { __html: content };
  };

  sendPostMessage = (param) => {
    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.sendPostMessage(param);
        }.bind(this),
        200
      );
    } else {
      window.postMessage(param, "*");
    }
  };

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    console.log("Latitude", newLat);
    console.log("Longitude", newLng);

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        console.log(response);
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);
        console.log("address", address);
        console.log("area", area);
        console.log("city", city);
        console.log("state", state);
        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  doCheckData = () => {
    if (
      this.state.location === "" ||
      this.state.addressdetail === "" ||
      this.state.city === "" ||
      this.state.postalcode === ""
    ) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div>
              <Card className="card-alert">
                <CardTitle className="card-title">
                  <span>Warning</span>
                </CardTitle>
                <CardBody className="card-body">
                  <CardText className="card-text">
                    Please fill in your location
                  </CardText>
                  <table>
                    <tr style={{ color: "#fff", textAlign: "right" }}>
                      <td></td>
                      <td>
                        <a className="link-button" onClick={onClose}>
                          OK
                        </a>
                      </td>
                    </tr>
                  </table>
                </CardBody>
              </Card>
            </div>
          );
        },
      });
      return false;
    } else {
      this.doSubmit();
    }
  };

  doSubmit = () => {
    let params = {
      phonenumber: this.state.phonenumber,
      name: this.state.name,
      location: this.state.location,
      addressdetail: this.state.addressdetail,
      city: this.state.city,
      postalcode: this.state.postalcode,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    };
    console.log(params);
    this.setState({ showLoading: true });
    axios
      .post(webserviceurl + "/user_location_insert.php", params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => {
        this.setState({ showLoading: false });
        let result = response.data;
        if (result.status === "OK") {
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        this.setState({ showLoading: false });
        console.log(error);
      });
  };

  render() {
    // const MapWithAMarker = withScriptjs(
    //   withGoogleMap((props) => (
    //     <GoogleMap
    //       defaultZoom={16}
    //       defaultCenter={{
    //         lat: this.state.mapPosition.lat,
    //         lng: this.state.mapPosition.lng,
    //       }}
    //     >
    //       <Marker
    //         draggable={true}
    //         onDragEnd={this.onMarkerDragEnd}
    //         position={{
    //           lat: this.state.markerPosition.lat,
    //           lng: this.state.markerPosition.lng,
    //         }}
    //         // position={{ lat: -6.1772007, lng: 106.9569963 }}
    //       >
    //         <InfoWindow>
    //           <div>Hai Bro</div>
    //         </InfoWindow>
    //       </Marker>
    //     </GoogleMap>
    //   ))
    // );
    return (
      <div>
        <SubHeader
          history={this.props.history}
          hideSearch={true}
          title="Location"
        />
        <div className="directory-all-list-section-data">
          <div className="form-container">
            {/* <div className="field-container">
              <MapWithAMarker
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBKuo_L0d5LSJmtBNRjsAEiVFdblxg05TU&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div> */}
            <div className="field-container">
              <div className="field-label">Street full name</div>
              <div className="field-value">
                <textarea
                  value={this.state.location}
                  style={{ fontSize: 15 }}
                  placeholder="Please enter the full name of your street, based on google maps"
                  onChange={(e) => this.setState({ location: e.target.value })}
                  rows="3"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: 0 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 15 }}>
                Address detail:
              </div>
              <div className="field-value">
                <textarea
                  placeholder="Please enter your address detail"
                  value={this.state.addressdetail}
                  style={{ fontSize: 15 }}
                  onChange={(e) =>
                    this.setState({ addressdetail: e.target.value })
                  }
                  rows="3"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: 0 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 15 }}>
                City:
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 15 }}
                  placeholder="Please enter your city"
                  value={this.state.city}
                  onChange={(e) => this.setState({ city: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="form-container" style={{ marginTop: 0 }}>
            <div className="field-container">
              <div className="field-label" style={{ fontSize: 15 }}>
                Postal Code:
              </div>
              <div className="field-value">
                <input
                  type="text"
                  style={{ fontSize: 15 }}
                  placeholder="Please enter your postal code"
                  value={this.state.postalcode}
                  onChange={(e) =>
                    this.setState({ postalcode: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <Container style={{ marginTop: 20, marginBottom: 40 }}>
            <Row>
              <Col xs="12">
                <Button
                  onClick={() => this.doCheckData()}
                  style={{
                    backgroundColor: "#2196F3",
                    textAlign: "center",
                    width: "100%",
                    fontSize: 16,
                  }}
                >
                  <span>SUBMIT</span>
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default GoogleMaps;
