import React, { Component } from 'react';
import { getLanguage } from '../../../Languages';
import './HomeBinding.css';

import { webserviceurl, activelanguage } from '../../../Config';

class HomeBinding extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: "",
            redirect: false,   
            community: 'dynamax',
            activeIndex:0,
            activeIndex2:0,
            activeIndex3:0,
            modal: false,
			count: 0            
        }
        let search = props.location.search.replace("?", "");
        let params = search.split("&");
        for (let i = 0; i < params.length; i++) {
            let param = params[i].split("=");
            if (param[0].toLowerCase() === "page") {
                this.state.page = param[1];
                this.state.redirect = true;
                break;
            } else if (param[0].toLowerCase() === "community") {
                this.state.community = param[1];
                localStorage.setItem('smart-app-id-binding'+'-community', param[1]);
            }
            console.log(params);
        }

        this.language = getLanguage(activelanguage, 'homebinding');
                
    }

    componentDidMount=()=>{
        this.waitForBridge();
        document.addEventListener("message", this.onMessage);
    }

    componentWillUnmount=()=>{
        document.removeEventListener("message", this.onMessage);
    }

    onMessage=(data)=>{
        //alert('');
        let messContent = null;
        if(data.data)
            messContent = JSON.parse(data.data);
        
        if(messContent){
            if(messContent.code === 'binding'){
                // messContent.param.debtor = messContent.param.DebtorAcct;
                localStorage.setItem('smart-app-id-binding', JSON.stringify(messContent.param));
                window.postMessage('{"code":"receiveloginBinding"}', "*");
            }
        }
    }


    waitForBridge() {

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":false, "showCommunityName":true, "hideTopbar":false, "hideFooterMenu":false}';
            window.postMessage(param, "*");
        }
    }

    render() {
        if (this.state.redirect) {
        }

        return (
            <div>
                <div className="main-container park-enterprise">
                </div>
            </div>
        );
    }
}

export default HomeBinding;