import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import './HistoryServiceOrderDetail.style.css';
import { webserviceurl, activelanguage } from '../../../Config';
import {convertToRupiah, makeid} from '../../../Global';
import SubHeader from '../../Component/SubHeader/SubHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import LoadingScreen from '../../Component/LoadingScreen/LoadingScreen';

class HistoryServiceOrderDetail extends Component {

    constructor(props) {
        super(props);
        this.language = getLanguage(activelanguage, 'servicecenterdetail');
        this.globallang = getLanguage(activelanguage, 'global');

        this.state = {
            page: "",
            redirect: false,
            userserviceid: props.match.params.userserviceid,
            data: {},
            datadetail: [],
            showLoading: false,
        }
        
    }

    componentDidMount=()=>{
        this.waitForBridge();
        this.loadServiceCenter();
        this.loadServiceCenterDetailList();
    }

    loadServiceCenter = () => {
        this.setState({showLoading:true});
        axios.post(webserviceurl + '/app_load_servicecenterdetail.php', {
            id: this.state.userserviceid
        },
        {headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {
                let result = response.data;
                
                if (result.status === "OK") {

                    let bln = "";
                        
                    switch(result.record.requestbln){
                        case 1: bln = this.globallang.january; break;
                        case 2: bln = this.globallang.february; break;
                        case 3: bln = this.globallang.march; break; 
                        case 4: bln = this.globallang.april; break;
                        case 5: bln = this.globallang.may; break;
                        case 6: bln = this.globallang.june; break;
                        case 7: bln = this.globallang.july; break;
                        case 8: bln = this.globallang.august; break;
                        case 9: bln = this.globallang.september; break;
                        case 10: bln = this.globallang.october; break;
                        case 11: bln = this.globallang.november; break;
                        case 12: bln = this.globallang.december; break;
                    }
                    result.record.monthname = bln;

                    this.setState({ data: result.record });

                    setTimeout(()=>{
                        this.setState({showLoading:false});
                    }, 500); 
                }
                
            })
            .catch((error) => {
                console.log(error);
            });
    }

    loadServiceCenterDetailList = ()=>{
        this.setState({showLoading:true});
        axios.post(webserviceurl + '/app_load_userservicedetail_list.php', {
            userserviceid: this.state.userserviceid
        },
        {headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {
                var temp = this.state.datadetail;
				temp = response.data.records;
                this.setState({datadetail : temp});
                
                console.log(temp);

                setTimeout(()=>{
                    this.setState({showLoading:false});
                }, 500); 

                
            })
            .catch((error) => {
                console.log(error);
            });
    }



    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
            window.postMessage(param, "*");
        }
    }

    createMarkup = (content) => {
        return { __html: content };
    }

    renderStatus =()=>{
        if(this.state.data.status === 0){
            return (
                <span className="pending">Pending</span>
            )
        }else{
            return (
                <span className="paid">Paid</span>
            )
        }
    }

    renderStatusServices =()=>{
        if(this.state.data.status_services ===0){
            return (
                <span className="pending" style={{fontWeight:'bold', fontSize:16}} >On Process</span>
            )
        }else{
            return (
                <span className="paid" style={{fontWeight:'bold', fontSize:16}} >Done</span>
            )
        }
    }

    renderStatusPayment =()=>{
        if(this.state.data.status_payment === "PENDING"){
            return (
                <span className="pending" style={{fontWeight:'bold', fontSize:16}} >Pending</span>
            )
        }else{
            return (
                <span className="paid" style={{fontWeight:'bold', fontSize:16}} >Paid</span>
            )
        }
    }

    renderLoading=()=>{
        if(this.state.showLoading){
            return (
                <LoadingScreen></LoadingScreen>
            )
        }
    }


    render() {
        
        return (
            <div className="main-container">
                <SubHeader history={this.props.history} hideSearch={true} title={this.language.title} />
                <div className="history-service-center-detail">
                    <table className="history-service-center-info">
                        <tbody>
                            <tr>
                                <td className="col1">
									{this.language.requestdate}
                                </td>
                                <td className="col2">
                                    {/* {moment(this.state.data.requestdate).format('lll')} */}
                                    {this.state.data.requestdate}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
									{this.language.name}
                                </td>
                                <td className="col2">
                                    {this.state.data.name}
                                </td>
                            </tr>
                            <tr>
                                <td className="col3" colSpan="2">
                                    <div>{this.language.address}</div>
                                    <div className="textarea-note">
                                        {this.state.data.address}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
									Request Service
                                </td>
                                <td className="col2">
                                    {this.state.data.categoryname}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="history-service-center-info">
                        <tbody>
                            <tr>
                                <th style={{paddingLeft:10}} >Service Name </th>
                                <th>Price </th>
                                <th style={{paddingRight:10}} >Qty </th>
                            </tr>     
                            {this.state.datadetail.map((item) =>
                            <tr>
                                <td style={{paddingLeft:10}} > {item.servicecentername} </td>
                                <td> {convertToRupiah(item.price)} </td>
                                <td style={{paddingRight:10}} > {item.quantity} </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    <table className="history-service-center-info">
                        <tbody>
                            <tr>
                                <td className="col1">
									{this.language.price}
                                </td>
                                <td className="col2">
                                    {convertToRupiah(this.state.data.price)}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
									Payment Method
                                </td>
                                <td className="col2">
                                    {this.state.data.payment_method}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
									{this.language.status}
                                </td>
                                <td className="col2" style={{fontWeight:'bold', fontSize:16}} >
                                    {this.renderStatusPayment()}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
									{this.language.status_services}
                                </td>
                                <td className="col2">
                                    {this.renderStatusServices()}
                                </td>
                            </tr>
                            <tr>
                                <td className="col3" colSpan="2">
                                    <div>{this.language.note}</div>
                                    <div className="textarea-note">
                                        {this.state.data.note}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/* <table className="history-service-center-info">
                        <tbody>
                        <tr>
                                <td className="col1">
									Invoice Number
                                </td>
                                <td className="col2">
                                    {this.state.data.externalID}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
									Payment Method
                                </td>
                                <td className="col2">
                                    {this.state.data.payment_method}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
									Payment Channel
                                </td>
                                <td className="col2">
                                    {this.state.data.payment_channel}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
									Paid Amount
                                </td>
                                <td className="col2">
                                    {convertToRupiah(this.state.data.paid_services)}
                                </td>
                            </tr>
                            <tr>
                                <td className="col1">
									{this.language.status}
                                </td>
                                <td className="col2" style={{fontWeight:'bold', fontSize:16}} >
                                    <span className="paid">{this.state.data.status_payment}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
                    {this.renderLoading()}
                </div>
                {/*<div className="next-button" onClick={()=>this.goNext()}>
                    {this.globallang.next}
                </div>*/}
            </div>
        );
    }
}

export default HistoryServiceOrderDetail;