import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import { Container, Row, Col, Card, CardText, CardImg, Label, Input, Button, CardTitle, CardBody } from 'reactstrap';
import Picture from '../../Component/pictureGallery';
import LoadingScreen from '../../Component/LoadingScreen/LoadingScreen';
import NewsListItem from '../../Component/NewsListItem/NewsListItem';
import './Covid19Page.syle.css';
import { webserviceurl, activelanguage, limitList } from '../../../Config';
import axios from 'axios';
import SubHeader from '../../Component/SubHeader/SubHeader';
import SwipeableViews from 'react-swipeable-views';
import Iframe from 'react-iframe';
import noData from '../../../images/kosong2.png'
import DefaultUserImg from '../../../images/user.jpeg';
import newsdefault from '../../../images/news_default.png';
import { confirmAlert } from 'react-confirm-alert'; 
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import { zhCN } from 'date-fns/esm/locale'
import 'moment/locale/en-au' 
moment.locale('zhCN')
registerLocale('zhCN', zhCN);

class NewsCovid extends Component {
    constructor(props) {
        super(props);
        this.language = getLanguage(activelanguage, 'covid19');
        this.globalLang = getLanguage(activelanguage, 'global');

        this.state = { 
            currentTab: parseInt(curr), 
            navigateTo: '', 
            NewsList:{
                list:[],
                listTop:[],
                currentIndex:0,
                showLoadMore: false
            },
            contact: {
                list: [],
                currentIndex: 0
            },
            announcement:[], 
            event:[], 
            redirect:false, 
            community: 22,
            category:[],
            currentIndex: 0,
            limitList: limitList,
            onload:false,
            showLoadMore:true,
			start: 0,
			loadFinished: false,
			filter: '',
            counter: 0,
            showLoading: false,
            complaintid:0,
            phonenumber:'',
            name:'',
            complaintname:'',
            complaintdesc:'',
            date:'',
            reading:0
        };

        this.loginInfo = {
            phoneno: '',
            name: ''
        }
			
		this.listel = null;

        let search = props.location.search.replace("?", "");
        let params = search.split("&");
        for (let i = 0; i < params.length; i++) {
            let param = params[i].split("=");
            if (param[0].toLowerCase() === "page") {
                this.state.navigateTo = param[1];
                this.state.redirect = true;
                break;
            } else if (param[0].toLowerCase() === "community") {
                this.state.community = param[1];
            }
        }

        let curr = localStorage.getItem('covidtab');
        if(curr === null || curr === undefined || curr === '') curr = 0;
    }

    componentDidMount=()=>{
        this.waitForBridge();
        this.loadUser();
        this.loadNewsFeed(this.state.NewsList.currentIndex);
        this.loadContact()

        let curr = localStorage.getItem('covidtab');
        if(curr === null || curr === undefined || curr === '') curr = 0;
        this.setState({currentTab: parseInt(curr)});
		
    }
	
	componentWillUnmount=()=>{
        window.localStorage.removeItem('covidtab');
    }
	
	scrollCheck = ()=>{
        if(this.listel.scrollTop + this.listel.clientHeight >= this.listel.scrollHeight){
			if(this.state.loadFinished == false){
				this.loadNewsFeed(this.state.currentIndex);
			}
        }
    }

    createMarkup = (content) => {
        return { __html: content };
    }

    loadUser=()=>{
        let tmp = localStorage.getItem('smart-app-id-login');
        if(tmp == undefined || tmp == null || tmp == 'null' || tmp == '' || tmp == 'undefined'){
            
            let param = '{"code":"need-login"}';
            //window.postMessage(param, "*");
            this.sendPostMessage(param);
        }else{
            tmp = JSON.parse(tmp);
            this.loginInfo.phoneno = tmp.phonenumber;
            this.loginInfo.name = tmp.name;
            this.setState({
                phonenumber : tmp.phonenumber,
                name: tmp.name
            })
        }
    }

    sendPostMessage=(param)=>{
        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.sendPostMessage(param);
            }.bind(this), 200);
        }
        else {
            window.postMessage(param, "*");
        }
    }

    loadNewsFeed = (currentIndex) => {
        this.state.NewsList.showLoadMore = false;
        this.setState({showLoading:true});
        this.setState({onload:true});
        let params = {
                currentIndex: currentIndex,
                limit: this.state.limitList,
        }

        console.log(params);
        axios({
            method: 'post',
            url: webserviceurl + '/app_load_news_covid19_2.php',
            data: params,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
        })
        .then((response) => {
            this.setState({onload:false});
            let result = response.data;
            console.log(result);
            if (result.status === "OK") {

                let list = this.state.NewsList.list;
                let listTop = this.state.NewsList.listTop

                for (var i = 0; i < result.records.length; i++) {
                    result.records[i].shortdesc = decodeURIComponent(result.records[i].shortdesc);
                    result.records[i].time_ago = moment(result.records[i].time_ago);
                    list.push(result.records[i]);
                }

                listTop.push(result.records[0]);

                let next = this.state.NewsList.currentIndex;
                if(result.records.length > 0)
                    next += 1;

                let show = true;
                if(result.records.length < limitList)
                show = false;   


                this.setState({ NewsList: {list: list, listTop: listTop, currentIndex: next, showLoadMore: show } });
                console.log(this.state.NewsList);
            }  
            setTimeout(()=>{
                this.setState({showLoading:false});
            }, 200); 
        })
        .catch( (error)=> {
            this.state.NewsList.showLoadMore = false;
            this.setState({onload:false});
            console.log(error);
        });
    }

    loadContact = () => {
        this.setState({showLoading:true});
        axios.get(webserviceurl + '/app_load_corporatecontact_covid.php?community='+this.state.community)
            .then((response) => {
                let result = response.data;
                if (result.status === "OK") {
                    this.setState({ contact: { list: result.records } });
                    
                    setTimeout(()=>{
                        this.setState({showLoading:false});
                    }, 500);

                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    checkData = () => {
		const {complaintname, complaintdesc} = this.state;
		
		if(complaintdesc == null || complaintdesc  == "" || complaintdesc == null || complaintdesc  == "" ){
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div>
                            <Card className="card-alert">
                                <CardTitle className="card-title"  >
                                    <span>Information!</span>
                                </CardTitle>
                                <CardBody className="card-body">
                                    <CardText className="card-text" >Please fill in the blank form complaint name and description!</CardText>
                                        <table>
                                            <tr style={{color:'#fff', textAlign:'right'}} >
                                                <td>
                                                </td>
                                                <td>
                                                    <a className="link-button" onClick={onClose}>OK</a>
                                                </td>
                                            </tr>
                                        </table>
                                </CardBody>
                            </Card>
                        </div>
                    )
                }
            })
			return false;
		}
			
		else{
			this.loadDataEntry();
		}
    }

    loadDataEntry=()=>{

        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+' '+time;


        let params = {
            compplaintid:this.state.complaintid,
            currentdatetime: dateTime,
            phonenumber:this.state.phonenumber,
            complaintname:this.state.complaintname,
            complaintdesc:this.state.complaintdesc,

        }

        console.log(params);

        axios.post(webserviceurl + '/app_insert_complaintcovid.php', params,
		  {headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
			  .then((response) => {
				  let result = response.data;
				  if (result.status === "OK") {
					confirmAlert({
						customUI: ({ onClose }) => {
							return (
								<div>
                                    <Card className="card-alert">
                                        <CardTitle className="card-title"  >
                                            <span>Information!</span>
                                        </CardTitle>
                                        <CardBody className="card-body">
                                            <CardText className="card-text" >{this.language.success}</CardText>
                                                <table>
                                                    <tr style={{color:'#fff', textAlign:'right'}} >
                                                        <td>
                                                        </td>
                                                        <td>
                                                            <a className="link-button" onClick={onClose}>OK</a>
                                                        </td>
                                                    </tr>
                                                </table>
                                        </CardBody>
                                    </Card>
                              </div>
							)
						}
					})
                        this.props.history.push('/historyformcovid');
				  }else{
					  alert(result.message);
				  }
			  })
			  .catch((error) => {
				  console.log(error);
			  });
    }
    

    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }.bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
            window.postMessage(param, "*");
        }
    }

    currentActiveTab(idx) {
        if (idx === this.state.currentTab)
            return "tab-item active-tab";
        else
            return "tab-item";
    }

    changeTab(idx) {
        this.setState({ currentTab: idx});
        if(idx == 0){
            // this.loadNewsFeed(0);
            this.renderNewsFeedCovid19Body();
        }
        else if(idx==1){
            this.renderCallCenterBody();
        }
        else if(idx==2) {
            this.renderComplaintFormBody();
        }
        else if(idx==3) {
            this.renderMapsBody();
        }
    }

    renderMapsBody=()=>{
        let url = "https://www.kosmo-modernland.com/maps/maps.html"
        return(
            <div className="iframe-container" >
                <Iframe height="100%" width="100%" position="absolute" url={url}/>
            </div>
        )
    }

    renderWhat=()=>{
		if(this.state.currentTab == 0){
			return(
				<div>
					{this.renderNewsFeedCovid19Body()}
				</div>
			);
		}
		
		else if (this.state.currentTab ==1){
            return(
                <div>
                    {this.renderCallCenterBody()}
                </div>
            )
        }
        else if (this.state.currentTab ==2){
            return(
                <div>
                    {this.renderComplaintFormBody()}
                </div>
            )
        }
        else if (this.state.currentTab ==3){
            return(
                <div>
                    {this.renderMapsBody()}
                </div>
            )
        }
    }

    handleScroll = e => {
        const bottom = Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) - e.target.clientHeight < 50;
        if (bottom) {
          if (this.state.NewsList.showLoadMore === true) {
            this.loadNewsFeed(this.state.NewsList.currentIndex); 
          }
        }
    };

    goToDetail(news) {
        this.countReading(news);
        localStorage.setItem('covidtab', '0');
        this.props.history.push('/newscoviddetail/'+news.id);
    }

    countReading=(item)=>{
        axios.post(webserviceurl + "/master_save_news_covid_reading.php",{
            phoneno: this.state.phonenumber,
            newsid: item.id,
            reading: this.state.reading+1
        },{headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
        .then((response) => {
            let result = response.data;
            console.log(result);
        })
        .catch((error) => {
            console.log(error);
        });
    }


    renderPreviewMode=(item)=>{
        // console.log(item);

        if(item.previewmode === 1){ //tree-image
            return(
                <div className="relative-container">
                    <div className="tree-image-mode">
                        <div className="descriptime" >
                            <div className="name-container"><span className="category-Text" >{item.category}</span>{item.title}</div>
                        </div>
                        {this.renderThumbnail(item)}
                        <div className="descriptime" >
                            <div className="timeupdate" >
                                <span>{moment(item.time_ago).fromNow()} </span>
                            </div>
                            <div className="reading" >
                                <span>{item.reading} Reading</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if(item.previewmode === 2){ //single-image
            return(
                <div className="relative-container">
                    <div className="descript-container-border">
                        {this.renderSingleImage(item)}
                        <div className="descript-container-small">
                            <div className="headline-text-name"><span className="category-Text" >{item.category}</span> {item.title}</div>
                            <div className="sortdesc-headline-text" dangerouslySetInnerHTML={{__html: item.shortdesc}}></div>
                            <div className="descriptime" >
                            <div className="timeupdate" >
                                <span>{moment(item.time_ago).fromNow()} </span>
                            </div>
                            <div className="reading" >
                                <span>{item.reading} Reading</span>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if(item.previewmode === 3){ //text-mode
            return(
                <div className="relative-container">
                    <div className="text-mode">
                        <div className="name-container"><span className="category-Text" >{item.category}</span>{item.title}</div>
                        <div className="descriptime" >
                            <div className="timeupdate" >
                                <span>{moment(item.time_ago).fromNow()} </span>
                            </div>
                            <div className="reading" >
                                <span>{item.reading} Reading</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if(item.previewmode === 4){ //BIG Img Mode
            return(
                <div className="relative-container">
                    <div className="tree-image-mode">
                        {this.renderBigImg(item)}
                        <div className="descriptime" >
                            <div className="name-container"><span className="category-Text" >{item.category}</span>{item.title}</div>
                        </div>
                        <div className="descriptime" >
                            <div className="timeupdate" >
                                <span>{moment(item.time_ago).fromNow()} </span>
                            </div>
                            <div className="reading" >
                                <span>{item.reading} Reading</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if(item.previewmode === 5){ //large-video-mode
            return(
                <div className="relative-container">
                    <div className="tree-image-mode">
                        <div className="descript-video" >
                            <div className="name-container"><span className="category-Text" >{item.category}</span>{item.title}</div>
                        </div>
                        {this.renderVideoLarge(item)}
                        <div className="descriptime" >
                            <div className="timeupdate" >
                                <span>{moment(item.time_ago).fromNow()} </span>
                            </div>
                            <div className="reading" >
                                <span>{item.reading} Reading</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if(item.previewmode === 6){ //large-video-mode
            return (
                <div className="relative-container">
                    <div className="descript-container-border">
                        {this.renderSmallVideo(item)}
                        <div className="descript-container-small">
                            <div className="headline-text-name"><span className="category-Text" >{item.category}</span> {item.title}</div>
                            <div className="sortdesc-headline-text" dangerouslySetInnerHTML={{__html: item.shortdesc}}></div>
                            <div className="descriptime" >
                            <div className="timeupdate" >
                                <span>{moment(item.time_ago).fromNow()} </span>
                            </div>
                            <div className="reading" >
                                <span>{item.reading} Reading</span>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderCategory=(item)=>{
        if(item.category !== undefined && item.category !== ''){
            return (
                <div className="category">{item.category}</div>
            )
        }
    }

    renderBigImg=(item)=>{
        if(item.newspic[0] !== '' && item.previewmode === 4){
            return (
                <div className="image-big-mode">
                    <img  src={item.newspic[0]} alt="" />
                </div>
            )
        }else{
            return (
                <div className="image-big-mode">
                    <img src={newsdefault} alt="" />
                </div>
            )
        }
    }

    renderThumbnail=(item)=>{
        if(item.previewmode === 1){
            return (
                <div className="tree-mode-image">
                    {item.thumbnail.map((img, i) =>
                        <div className="center-cropped">
                            <img src={img.picture} alt={'imgpic'}/>
                        </div>
                    )}
                </div>
            )
        }else{
            return (
                <div className="tree-mode-image">
                    <div className="center-cropped">
                        <img src={newsdefault} alt={'imgpic'}/>
                    </div>
                </div>
            )
        }
    }

    renderSingleImage=(item)=>{
        if(item.newspic[0] !== '' && item.previewmode === 2){
            return (
                <div className="img-cropped">
                    <img src={item.newspic[0]} alt={'imgpic'} className="img-cropped"  />
                </div>
            )
        }else{
            return (
                <div className="img-cropped">
                    <img src={newsdefault} alt={'imgpic'} className="img-cropped"/>
                </div>
            )
        }
    }

    renderVideoLarge=(item)=>{
        if(item.video_url[0] !== "" && item.previewmode === 5){
            return (
                <video width="100%" controls style={{marginTop:10}} >
                    <source src={item.video_url[0]} type="video/mp4" />
                </video>
            )
        }else{
            return (
                <div className="imgitem-container">
                    <img src={newsdefault} alt={'imgpic'} className="imgdefault"/>
                </div>
            )
        }
    }

    renderSmallVideo=(item)=>{
        if(item.video_url[0] !== "" && item.previewmode === 6){
            return (
                <div className="small-video" >
                    <video width="100%" height="90%" controls style={{marginTop:10}} >
                        <source src={item.video_url[0]} type="video/mp4" />
                    </video>
                </div>

            )
        }else{
            return (
                <div className="small-video" >
                    <img src={newsdefault} alt={'imgpic'} />
                </div>
            )
        }
    }

    renderNewsFeedCovid19Body=()=>{
        if(this.state.NewsList.list.length>0){
            return (
                <div className="covidnews-list-section" onScroll={this.handleScroll}>
                    <div>
                        {this.state.NewsList.list.map((item, i) =>
                            <div className="list-item" key={i} onClick={()=>this.goToDetail(item)}>
                                {this.renderPreviewMode(item)}
                            </div>
                        )}
                    </div>
                </div>
            )
        }else{
            return(
                <div className="covidnews-list-section">
                    <div style={{alignContent:'center', alignItems:'center', textAlign:'center', marginTop:80 }}>
                        <img src={noData} style={{width:180, height:120}} />
                    </div>
                    <div className="no-data" style={{marginTop:-20}}>No newsfeed available</div>
                </div>
            )
        }
    }


    renderCallCenterBody=()=>{
        if(this.state.contact.list.length>0){
            return(
            <div className="main-container park-enterprise">
                <div className="body-section">
                    <table className="table-contact">
                        <tbody>
                        {this.state.contact.list.map((item, i) =>
                            <tr key={i}>
                                <td className="column-pic">
                                    <img src={item.image === '' ? DefaultUserImg : item.image } className="contact-pic" />
                                </td>
                                <td>
                                    <div className="contact-name">{item.name}</div>
                                    <div className="contact-position">{item.position}</div>
                                </td>
                                <td className="column-icon">
                                    <a href={"tel:" + item.phone + ""}>
                                        <img src={require("../../../images/btn_phone_n@2x.png")} />
                                    </a>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
            )
        }else{
            return(
                <div className="covidnews-list-section">
                    <div style={{alignContent:'center', alignItems:'center', textAlign:'center', marginTop:80 }}>
                        <img src={noData} style={{width:180, height:120}} />
                    </div>
                    <div className="no-data" style={{marginTop:-20}}>No call center available</div>
                </div>
            )
        }
    }


    renderComplaintFormBody=()=>{
        return(
            <div className="covid-center-form">
                <div className="covid-center-form-title">
                    Request Form COVID-19
                </div>
                <Container style={{marginTop:20}} >
                    <Row style={{marginBottom:10}} >
                        <Col xs="5">
                            <Label style={{fontSize:13}} for="name">Username</Label>
                        </Col>
                        <Col xs="7"> 
                            <Input style={{fontSize:13}} disabled="disabled"  type="text" name="name" id="name"  value={this.state.name} onChange = {(event) => this.setState({ name : event.target.value }) }/>
                        </Col>
                    </Row>
                    <Row >
                        <Col xs="5">
                            <Label style={{fontSize:13}} for="phonenumber">Mobile Phone</Label>
                        </Col>
                        <Col xs="7"> 
                            <Input style={{fontSize:13}} disabled="disabled"  type="phonenumber" name="phonenumber" id="phonenumber"  value={this.state.phonenumber} onChange = {(event) => this.setState({ phonenumber : event.target.value }) }/>
                        </Col>
                    </Row>
                    <Row style={{marginBottom:10}}>
                        <Col xs="12">
                            <Input style={{fontSize:13}} type="text"  width="100%" name="complaintname" id="complaintname" placeholder="Please fill in complaint name" value={this.state.complaintname} onChange = {(event) => this.setState({ complaintname : event.target.value }) }/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Input style={{fontSize:13}} type="textarea" rows="10" width="100%" name="complaintdesc" id="complaintdesc" placeholder="Please fill in description complaint" value={this.state.complaintdesc} onChange = {(event) => this.setState({ complaintdesc : event.target.value }) }/>
                        </Col>
                    </Row>
                    <div style={{marginTop:20, marginBottom:20 }} >
                    <Row>
                        <Col xs="6">
                        </Col>
                        <Col xs="6">
                            <Button  onClick={() => this.checkData()} color="success" style={{width:'100%', fontSize:13 }} >
                                <span>Submit</span>
                            </Button>
                        </Col>
                    </Row>
                    </div>
                    <div style={{marginTop:20, marginBottom:20 }} >
                        <Button color="primary" onClick={()=> this.GotoHistory()} style={{width:'100%', textAlign:'left', fontSize:13}}>History List</Button>
                    </div>
                </Container>
            </div>
        )
    }


    renderLoading=()=>{
        if(this.state.showLoading){
            return (
                <LoadingScreen></LoadingScreen>
            )
        }
    }

    goBack=()=>{
        this.props.history.push('/');
    }

    GotoHistory=()=>{
        this.props.history.push('/historyformcovid');
    }


    render() {
        if (this.state.navigateTo != '') {
            return <Redirect to={this.state.navigateTo} />
        }
        return (
            <div>
                <SubHeader  history={this.props.history} hideSearch={true} title={this.language.title} goBack={this.goBack} />
                <div style={{backgroundColor:'#fff'}}>
                    <div className="tab-section">
                        <Container>
                            <Row>
                                <Col md="3" sm="3" xs="3" className={this.currentActiveTab(0)} onClick={() => this.changeTab(0)}>
                                    {this.language.newsfeed}
                                </Col>
                                <Col md="3" sm="3" xs="3" className={this.currentActiveTab(1)} onClick={() => this.changeTab(1)}>
                                    {this.language.callcenter}
                                </Col>
                                <Col md="3" sm="3" xs="3" className={this.currentActiveTab(2)} onClick={() => this.changeTab(2)}>
                                    Request
                                </Col>
                                <Col md="3" sm="3" xs="3" className={this.currentActiveTab(3)} onClick={() => this.changeTab(3)}>
                                    Maps
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {this.renderWhat()}
                    {this.renderLoading()}
                </div>
            </div>
        );
    }
}

export default NewsCovid;