import React, { Component } from "react";
import "./CommodityCartItem.style.css";
import { getLanguage } from "../../../Languages";
import { webserviceurl, activelanguage } from "../../../Config";
import { numberFormat } from "../../../Global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class CommodityCartItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      name: props.name,
      category: props.category,
      shortDesc: props.shortdesc,
      tags: props.tags === undefined ? [] : props.tags, //array
      pic: props.pic,
      price: props.price,
      qty: props.qty,
      sold: props.sold,
      commodityIsDiscountId: props.commodityIsDiscountId,
      priceDiscountId: props.priceDiscountId,
      promo1buyget1Id: props.promo1buyget1Id,
      recommendedId: props.recommendedId,
      hidePlusMinus:
        props.hidePlusMinus === undefined ? false : props.hidePlusMinus,
    };
    this.language = getLanguage(activelanguage, "merchantprofile");
  }

  componentDidMount = () => {
    this.calculatePrice();
  };

  calculatePrice = () => {
    let tmp;
    tmp = this.state.qty * this.state.price;
    this.setState({ totalprice: tmp });
  };

  removeCart = () => {};

  componentWillReceiveProps = (props) => {
    this.setState({
      id: props.id,
      name: props.name,
      category: props.category,
      shortDesc: props.shortdesc,
      tags: props.tags === undefined ? [] : props.tags, //array
      pic: props.pic,
      price: props.price,
      qty: props.qty,
      sold: props.sold,
      commodityIsDiscountId: props.commodityIsDiscountId,
      priceDiscountId: props.priceDiscountId,
      promo1buyget1Id: props.promo1buyget1Id,
      recommendedId: props.recommendedId,
      hidePlusMinus:
        props.hidePlusMinus === undefined ? false : props.hidePlusMinus,
      totalprice: props.qty * props.price,
    });
  };

  updateQty = (value) => {
    let tmpQty = this.state.qty;
    if (value > 0) {
      tmpQty += value;
    } else {
      if (tmpQty > 0) {
        tmpQty += value;
      }
    }

    this.props.updateQty(tmpQty, this.state.id);
  };

  renderPicture = () => {
    if (this.state.pic !== "") {
      return (
        <div
          className="foodgarden-imgitem-container"
          // onClick={() => this.openModalDescription()}
        >
          <img src={this.state.pic} alt={"imgpic"} />
        </div>
      );
    } else {
      return (
        <div
          className="foodgarden-imgitem-container"
          // onClick={() => this.openModalDescription()}
        >
          <img
            // src={require("../../../images/noimage.png")}
            src={require("../../../images/noimage.jpg")}
            alt={"imgpic"}
            className="imgdefault"
          />
        </div>
      );
    }
  };

  renderPlusMinus = () => {
    if (!this.state.hidePlusMinus) {
      return (
        <div className="qty-container">
          <div className="plus-minus-button">
            <div className="plus-button" onClick={() => this.updateQty(1)}>
              +
            </div>
            <div className="qty-value">{this.state.qty}</div>
            {this.renderMinusButton()}
          </div>
        </div>
      );
    }
  };

  renderMinusButton = () => {
    if (this.state.qty > 0) {
      return (
        <div className="minus-button" onClick={() => this.updateQty(-1)}>
          -
        </div>
      );
    } else {
      return (
        <div className="minus-button" onClick={() => this.updateQty(-1)}>
          -
        </div>
      );
    }
  };

  modalDiscount = () => {
    if (this.state.commodityIsDiscountId === 1) {
      return (
        <>
          <div className="product-price">
            <span>
              Rp.
              {numberFormat(
                this.state.price *
                  ((100 - this.state.priceDiscountId.replace("%", "")) / 100)
              )}
            </span>
            &nbsp;
            <span
              style={{
                fontFamily: "monospace",
                textDecoration: "line-through",
                color: "#808080",
              }}
            >
              Rp.{numberFormat(this.state.price)}
            </span>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="product-price">
            <span>Rp.{numberFormat(this.state.price)}</span>
          </div>
        </>
      );
    }
  };

  renderDiscount = () => {
    if (this.state.commodityIsDiscountId === 1) {
      return (
        <>
          <span style={{ fontFamily: "monospace" }}>
            Rp.
            {numberFormat(
              this.state.price *
                ((100 - this.state.priceDiscountId.replace("%", "")) / 100)
            )}
          </span>
          &nbsp;
          <span
            style={{
              fontFamily: "monospace",
              textDecoration: "line-through",
              color: "#808080",
            }}
          >
            Rp.{numberFormat(this.state.price)}
          </span>
        </>
      );
    } else {
      return (
        <span style={{ fontFamily: "monospace" }}>
          Rp.{numberFormat(this.state.price)}
        </span>
      );
    }
  };

  modalPromo = () => {
    if (this.state.commodityIsDiscountId === 1) {
      return (
        <div className="promo-modal">
          Promo Disc {this.state.priceDiscountId}
        </div>
      );
    } else if (this.state.promo1buyget1Id === 1) {
      return <div className="promo-modal">Promo Buy 1 Get 1</div>;
    }
  };

  renderPromo = () => {
    if (this.state.commodityIsDiscountId === 1) {
      return (
        <div className="promo">Promo Disc {this.state.priceDiscountId}</div>
      );
    } else if (this.state.promo1buyget1Id === 1) {
      return <div className="promo">Promo Buy 1 Get 1</div>;
    }
  };

  render() {
    return (
      <div className="list-item">
        <div className="relative-container">
          {this.renderPicture()}
          <div className="data-container">
            <div className="foodgarden-name">
              {/*this.renderCategory()*/}
              {this.state.name}
            </div>
            <div
              className="foodgarden-shortdesc-container"
              style={{ fontSize: 13 }}
              dangerouslySetInnerHTML={{ __html: this.state.shortDesc }}
            ></div>
            <div>{this.renderDiscount()}</div>
            {this.renderPromo()}
          </div>
          {this.renderPlusMinus()}
        </div>
      </div>
    );
  }
}

export default CommodityCartItem;
