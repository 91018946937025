import React, { Component } from 'react';
//import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import { Container, Row, Col, Input,Button, Card, CardBody, CardText, CardTitle } from 'reactstrap';
import axios from 'axios';
import './CustomerCarePage.style.css';
import { webserviceurl, activelanguage } from '../../../Config';
import SubHeader from '../../Component/SubHeader/SubHeader';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { numberFormat } from '../../../Global';
import {convertToRupiah, makeid} from '../../../Global';
import ServiceCenterList from '../../Component/ServiceCenterList/ServiceCenterList';
import LocationPicker from 'react-location-picker';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; 
import { zhCN, id } from 'date-fns/esm/locale'

class CustomerCarePage extends Component{
    constructor(props){
        super(props);
        this.language = getLanguage(activelanguage, 'customercare');
        this.globallang = getLanguage(activelanguage, 'global');

        this.state={
            page:"",
            redirect: false,
            phoneno:'',
            name:'',
            email:'',
            IncidentDate: new Date(),
            ComplainDate: new Date(),
            communityid: 22,
            description:'',
            UnitNo:'',
            DebtorAcct:'',
            Category:'TTL',
            listUnitCode:[],
            currentUnitCode:''

        }
        this.loginInfo = {
            phoneno: '',
            name: ''
        }
    }

    componentDidMount=()=>{
        this.waitForBridge();
        this.loadUser();
        this.loadToken();
        this.loadBinding();


    }

    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {

            let tmp = localStorage.getItem('smart-app-id-login');
            let needLogin = false;
            // let tmp2 = localStorage.getItem('smart-app-id-binding');
            // let needBinding = false;

            if(tmp == undefined || tmp == null || tmp == 'null' || tmp == '' ||tmp == 'undefined' ) needLogin = true;

            // else if(tmp2 == undefined || tmp2 == null || tmp2 == 'null' || tmp2 == '' ||tmp2 == 'undefined' ) needBinding = true;

            else{
                tmp = JSON.parse(tmp);
                this.loginInfo.phoneno = tmp.phonenumber;
                this.loginInfo.name = tmp.name;
            }

            let param = '{"title":"' + this.language.title + '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true, "needLogin":'+(needLogin ? 'true': 'false') +'}';
            window.postMessage(param, "*");
        }
    }

    sendPostMessage=(param)=>{
        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.sendPostMessage(param);
            }.bind(this), 200);
        }
        else {
            window.postMessage(param, "*");
        }
    }

    loadUser=()=>{
		if(localStorage.getItem('smart-app-id-login') != undefined && localStorage.getItem('smart-app-id-login') != 'undefined'){
			var com = window.localStorage.getItem('smart-app-id-login');
			var login = JSON.parse(com);
			
            this.setState({phoneno : login.phonenumber===undefined?"":login.phonenumber});
            this.setState({name : login.name===undefined?"":login.name});
            this.setState({email : login.email===undefined?"":login.email});
		}
    }

    loadToken=()=>{
        let tkn = localStorage.getItem('smart-app-id-token');
        if(tkn == undefined || tkn == null || tkn == 'null' || tkn == '' || tkn == 'undefined' ){
            let param = '{"code":"need-binding"}';
            this.sendPostMessage(param);

        }else{
            tkn = JSON.parse(tkn);
            // alert(tkn);
            // alert(JSON.stringify(tkn.access_token));
            this.setState({ token: JSON.stringify(tkn.access_token) });
        }
    }


    loadBinding= async ()=>{
        let tmp = localStorage.getItem('smart-app-id-binding');
        let tmp2 = localStorage.getItem('Modernland-Account');
        if(tmp2 == undefined || tmp2 == null || tmp2 == 'null' || tmp2 == '' || tmp2 == 'undefined' ){
            let param = '{"code":"need-binding"}';
            this.sendPostMessage(param);
        }
        if(tmp == undefined || tmp == null || tmp == 'null' || tmp == '' || tmp == 'undefined' ){
            let param = '{"code":"need-binding"}';
            this.sendPostMessage(param);
        }
        else{
            tmp = JSON.parse(tmp);
            let current = '';
            if(tmp.length>0){
                current = tmp[0].DebtorAcct+'|'+tmp[0].LotNo;
                await this.changeUnitCode(tmp[0].DebtorAcct+"|"+tmp[0].LotNo);
            }

            this.setState({listUnitCode: tmp, currentUnitCode: current});
        }
    }
    
    createMarkup = (content) => {
        return { __html: content };
    }

    updateComplainDate = (date) => {
        this.setState({ ComplainDate: date });
    }

    updateIncidentDate = (date) => {
        this.setState({ IncidentDate: date });
    }

    changeUnitCode=(value)=>{
        let arr = value.split("|");
        this.setState({ DebtorAcct : arr[0]})
        this.setState({ UnitNo: arr[1]})
        this.setState({currentUnitCode: value})

        setTimeout(() => {
            // alert(this.state.DebtorAcct + '========' + this.state.UnitNo + '......' + this.state.currentUnitCode);
          }, 3000);
    }


    checkData = () => {
		const {description} = this.state;
		
		if(description == null || description  == "" ){
            // alert("please fill the description");
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div>
                            <Card className="card-alert">
                                <CardTitle className="card-title"  >
                                    <span>Information!</span>
                                </CardTitle>
                                <CardBody className="card-body">
                                    <CardText className="card-text" >{this.language.fieldconfirm}</CardText> 
                                        <table>
                                            <tr style={{color:'#fff', textAlign:'right'}} >
                                                <td>
                                                    <a className="link-button" onClick={onClose}>{this.globallang.ok}</a>
                                                </td>
                                            </tr>
                                        </table>
                                </CardBody>
                            </Card>
                        </div>
                    )
                }
            })
			return false;
		}
			
		else{
			this.onSubmit();
		}
	}

    onSubmit=()=>{

        var incident_et = "";
		var i = new Date(this.state.IncidentDate);
		var yearin = i.getFullYear();
		var monthin = i.getMonth();
        var datein = i.getDate();
        
        incident_et = yearin+"/"+(monthin+1)+"/"+datein;

        var complain_et = "";
		var i = new Date(this.state.ComplainDate);
		var year = i.getFullYear();
		var month = i.getMonth();
        var date = i.getDate();
        
        complain_et = year+"/"+(month+1)+"/"+date;

        let getToken = JSON.parse(localStorage.getItem('smart-app-id-token'));

            if(getToken == undefined || getToken == null || getToken == 'null' || getToken == '' || getToken == 'undefined' ){
                let param = '{"code":"need-binding"}';
                this.sendPostMessage(param);
            }

            var config = {
                headers: {'Authorization': 'Bearer '+getToken.access_token}
            };

        let BodyData = {
            "UnitNo": this.state.UnitNo,
            "DebtorAcct" : this.state.DebtorAcct,
            "ComplainDate" : complain_et,
            "IncidentDate" : incident_et,
            "Category" : this.state.Category,
            "Description" : this.state.description,
            "token": getToken.access_token
        }

        this.setState({showLoading:true});
        axios.post(webserviceurl+'/api_modern_customer_service2.php', BodyData, config)
        .then((response) => {
            // alert(JSON.stringify(response));
            let result = response.data;
            if (result.status === "OK") {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div>
                            <Card className="card-alert">
                                <CardTitle className="card-title"  >
                                    <span>Information!</span>
                                </CardTitle>
                                <CardBody className="card-body">
                                    <CardText className="card-text" >{this.language.success}</CardText> 
                                        <table>
                                            <tr style={{color:'#fff', textAlign:'right'}} >
                                                <td>
                                                    <a className="link-button" onClick={onClose}>{this.globallang.ok}</a>
                                                </td>
                                            </tr>
                                        </table>
                                </CardBody>
                            </Card>
                        </div>
                        )
                    }
                })
                    setTimeout(()=>{
                        this.setState({showLoading:false});
                    }, 500); 
                    this.props.history.push('/');
              }else{
                  alert(result.message);
              }
            
        })
        .catch(function (error) {
          console.log(error);
        });
        //  alert(JSON.stringify(params));
    }


    gotoFeedBack=()=>{
        this.props.history.replace('/feedback');
    }

    goBack=()=>{
        this.props.history.push('/')
    }

    render() {
        return (
            <div className="">
                <SubHeader history={this.props.history} hideSearch={true} title={this.language.title} goBack={this.goBack} /> 
                <div className="customer-center-form">
                    <div className="field-container">
                        <table>
                            {/* <tr>
                                <td>
                                    <div className="field-label">{this.language.fieldusername}</div>
                                </td>
                                <td style={{paddingLeft:10}} >
                                    <div className="field-value">
                                    <Input type="text"  value={this.state.name} disabled="disabled"/></div>
                                </td>
                            </tr> */}
                            <tr>
                                <td>
                                    <div className="field-label">{this.language.debtoracct}</div>
                                </td>
                                <td style={{paddingLeft:10}} >
                                    <div className="field-value" style={{marginBottom:10}} >
                                        <select className="payment-select-unit" value={this.state.currentUnitCode} onChange={(e=>this.changeUnitCode(e.target.value))}>
                                            {
                                                this.state.listUnitCode.map((item, i)=>(
                                                    <option value={item.DebtorAcct+'|'+item.LotNo} >{item.DebtorAcct}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="field-label">{this.language.fieldComplainDate}</div>
                                </td>
                                <td style={{paddingLeft:10}} >
                                    <div className="field-value">
                                        <DatePicker disabled="disabled" selected={this.state.ComplainDate} onChange={this.updateComplainDate} className="date-picker" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="field-label">{this.language.fieldIncidentDate}</div>
                                </td>
                                <td style={{paddingLeft:10}} >
                                    <div className="field-value">
                                        <DatePicker withPortal disabledKeyboardNavigation={true} selected={this.state.IncidentDate} onChange={this.updateIncidentDate} className="date-picker" />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <Container>
                        <Row>
                            <Col xs="12">
                                <Input style={{fontSize:14}} type="textarea" rows="8" width="100%" name="description" id="description" placeholder={this.language.placeholderdesc} value={this.state.description} onChange = {(event) => this.setState({ description : event.target.value }) }/>
                            </Col>
                        </Row>
                    </Container>
                    <Container className="container-button" >
                        <Button  onClick={()=>this.checkData()} style={{backgroundColor:'rgb(119, 7, 85)', color:'#fff', textAlign:'center', width:'50%' }} >
                            <span> {this.language.submit} </span>
                        </Button>
                    </Container>
                    <Container className="button-submit-complain" >
                        <Button  onClick={()=>this.gotoFeedBack()} style={{backgroundColor:'#005ce6', color:'#fff', textAlign:'center', width:'100%' }} >
                            <span>Feedback</span>
                        </Button>
                    </Container>
                </div>
            </div>
        );
    }




}   
export default CustomerCarePage