import React, { Component } from 'react';
//import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import { Container, Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import './HealthDeclarationPage.style.css';
import { webserviceurl, activelanguage } from '../../../Config';
import SubHeader from '../../Component/SubHeader/SubHeader';
import bannerhealth from '../../../images/banner_health-new.png';
import LoadingScreen from '../../Component/LoadingScreen/LoadingScreen';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class HealthDeclarationPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: "",
            redirect: false,
            currentTab: parseInt(curr),
            currentIndex:0,
            phonenumber:'',
            dataDailyDeclaration:[],
            dataReturnTrip:[],
            dataFestivalSpring:[],
            community: props.match.params.communityid,
            showLoading: false
        }

        this.loginInfo = {
            phoneno: '',
            name: ''
        }

        this.language = getLanguage(activelanguage, 'healthpage');
        this.globallang = getLanguage(activelanguage, 'global');

        let curr = localStorage.getItem('health');
        if(curr === null || curr === undefined || curr === '') curr = 0;
        
    }

    componentDidMount=()=>{
        this.waitForBridge();
        this.loadUser();

        // this.loadDataDailyByPhone();
        // this.loadDataReturnTripByPhone();
        
        let curr = localStorage.getItem('health');
        if(curr === null || curr === undefined || curr === '') curr = 0;
        this.setState({currentTab: parseInt(curr)});
    }

    componentWillUnmount=()=>{
       window.localStorage.removeItem('health');
    }

    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let tmp = localStorage.getItem('smart-app-id-login');
            let needLogin = false;

            if(tmp == undefined || tmp == null || tmp == 'null' || tmp == '' ||tmp == 'undefined' ) needLogin = true;
           
            else{
                tmp = JSON.parse(tmp);
                this.loginInfo.phoneno = tmp.phonenumber;
                this.loginInfo.name = tmp.name;
            }
            
            let param = '{"title":"' + this.language.title + '","canGoBack":true, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true, "needLogin":'+(needLogin ? 'true': 'false') +'}';
            window.postMessage(param, "*");
        }
    }

    loadUser=()=>{
        let tmp = localStorage.getItem('smart-app-id-login');
        if(tmp == undefined || tmp == null || tmp == 'null' || tmp == '' || tmp == 'undefined'){
            
            let param = '{"code":"need-login"}';
            //window.postMessage(param, "*");
            this.sendPostMessage(param);
        }else{
            tmp = JSON.parse(tmp);
            this.loginInfo.phoneno = tmp.phonenumber;
            this.loginInfo.name = tmp.name;
            this.setState({
                phonenumber : tmp.phonenumber
            })
            this.loadDataDailyByPhone(tmp.phonenumber);
            this.loadDataReturnTripByPhone(tmp.phonenumber);
        }
    }

    loadDataDailyByPhone = (phonenumber) => {
        this.setState({showLoading:true});
        axios.post(webserviceurl + '/app_load_dailydeclaration_history.php', {
            phonenumber: phonenumber
        },
        {headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {
                console.log(response);
                let result = response.data;
                console.log(result);
                if (result.status === "OK") {
                    this.setState({ 
                        dataDailyDeclaration: result.records,
                    });
                }
                setTimeout(()=>{
                    this.setState({showLoading:false});
                }, 500); 
            })
            .catch((error) => {
                console.log(error);
            });
    }

    loadDataReturnTripByPhone = (phonenumber) => {
        this.setState({showLoading:true});
        axios.post(webserviceurl + '/app_load_returntrip_history.php', {
            phonenumber: phonenumber
        },
        {headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {
                console.log(response);
                let result = response.data;
                console.log(result);
                if (result.status === "OK") {
                    this.setState({ 
                        dataReturnTrip: result.records,
                    });
                    console.log(this.state.dataReturnTrip);
                }
                setTimeout(()=>{
                    this.setState({showLoading:false});
                }, 500); 
            })
            .catch((error) => {
                console.log(error);
            });
    }

    loadDataFestivalSpringByPhone = (phonenumber) => {
        this.setState({showLoading:true});
        axios.post(webserviceurl + '/app_load_springfestival_history.php', {
            phonenumber: phonenumber
        },
        {headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {
                console.log(response);
                let result = response.data;
                console.log(result);
                if (result.status === "OK") {
                    this.setState({ 
                        dataFestivalSpring: result.records,
                    });
                }
                setTimeout(()=>{
                    this.setState({showLoading:false});
                }, 500); 
            })
            .catch((error) => {
                console.log(error);
            });
    }



    sendPostMessage=(param)=>{
        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.sendPostMessage(param);
            }.bind(this), 200);
        }
        else {
            window.postMessage(param, "*");
        }
    }

    createMarkup = (content) => {
        return { __html: content };
    }


    currentActiveTab(idx) {
        if (idx === this.state.currentTab)
            return "tab-item active-tab";
        else
            return "tab-item";
    }

    changeTab(idx) {
        this.setState({ currentTab: idx, currentIndex: 0 });
        if(idx == 0){
            this.renderDailyDeclarationBody();
        }
        else if(idx==1){
            this.renderReturnTripBody();
        }
        else if(idx==2) {
            this.renderSpringFestivalTravelBody();
        }
    }

    renderWhat=()=>{
		if(this.state.currentTab == 0){
			return(
				<div>
					{this.renderDailyDeclarationBody()}
                    {/* {this.renderLoading()} */}
				</div>
			);
		}
		
		else if (this.state.currentTab ==1){
            return(
                <div>
                    {this.renderReturnTripBody()}    
                    {/* {this.renderLoading()}  */}
                </div>
            )
        }
        else if (this.state.currentTab ==2){
            return(
                <div>
                    {this.renderSpringFestivalTravelBody()}    
                    {/* {this.renderLoading()}  */}
                </div>
            )
        }
    }

    
    goToReportJourney=()=>{
        localStorage.setItem('health', '0');
        this.props.history.push('/formdailyhealthdeclaration/'+this.state.phonenumber);
    }


    goToDetailDaily(daily) {
        localStorage.setItem('health', '0');
        this.props.history.push('/detaildailydeclaration/'+daily.phonenumber+'&'+daily.youdate);
    }


    goToReturnTrip=()=>{
        localStorage.setItem('health', '1');
        this.props.history.push('/formreturntrip/'+this.state.phonenumber);
    }
    

    goToDetailReturnTrip=(trip)=>{
        localStorage.setItem('health', '1');
        this.props.history.push('/detailreturntrip/'+trip.phonenumber+'&'+trip.youdate);
    }

    goToFestivalSpring=()=>{
        localStorage.setItem('health', '2');
        this.props.history.push('/formfestivalspring/'+this.state.phonenumber);
    }

    goToDetailFestivalSpring=()=>{
        this.props.history.push('/detailfestivalspring/'+this.state.phonenumber);
    }

    renderDailyDeclarationBody = () => {
        return (
            <div>
                <div className="today-declare-u">
                    <div className="flex-left" >{this.language.today}</div>
                    <div className="flex-right" onClick={()=>this.goToReportJourney()} ><span style={{fontSize:14, marginRight:6, color:'rgb(117, 15, 233)'}} className="completed">{this.language.gotodeclare}</span><FontAwesomeIcon icon="chevron-right" /></div>
                </div>
                {this.renderListDataDailyBody()}
            </div>
        )
    }

    renderListDataDailyBody = () => {
        if(this.state.dataDailyDeclaration.length>0){
            return (
                <div className="">
                    <table>
                        <tbody>
                            {this.state.dataDailyDeclaration.map((item, i) =>
                                <tr key={i} className="health-list2" onClick={()=>this.goToDetailDaily(item)}>
                                    <td style={{marginLeft:12}} className="health-list-col1">
                                        <div style={{color:'#000', fontSize:14}} className="health-checkin">{item.date}</div>
                                        <div style={{color:'#000', fontSize:14}} className="health-checkout">Have declared:</div>
                                    </td>
                                    <td className="health-list-col-price">
                                        <div>
                                            <FontAwesomeIcon icon="chevron-right" />
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )
        }
		else{
            return(
                <div className="body-section rooms-scroll-view" >
                    <div className="no-data">
                        {this.globallang.nodata}
                    </div>        
                </div>
            )
        }
    }



    renderReturnTripBody = () => {
        return (
            <div>
                <div className="today-declare-u">
                    <div className="flex-left" >{this.language.today}</div>
                    <div className="flex-right" onClick={()=>this.goToReturnTrip()} ><span style={{fontSize:14, marginRight:6, color:'rgb(117, 15, 233)'}} className="completed">{this.language.renewal}</span><FontAwesomeIcon icon="chevron-right" /></div>
                </div>
                    {this.renderListReturnTrip()}
            </div>
        )
    }

    renderListReturnTrip = () => {
        if(this.state.dataReturnTrip.length>0){
            return (
                <div>
                    <table>
                        <tbody>
                            {this.state.dataReturnTrip.map((item, i) =>
                                <tr key={i} className="health-list2" onClick={()=>this.goToDetailReturnTrip(item)}>
                                    <td style={{marginLeft:12}} className="health-list-col1">
                                        <div style={{color:'#000', fontSize:14}} className="health-checkin">{item.date}</div>
                                        <div style={{color:'#000', fontSize:14}} className="health-checkout">Have declared:</div>
                                    </td>
                                    <td className="health-list-col-price">
                                        <div>
                                            <FontAwesomeIcon icon="chevron-right" />
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )
        }
		else{
            return(
                <div className="body-section rooms-scroll-view" >
                    <div className="no-data">
                        {this.globallang.nodata}
                    </div>        
                </div>
            )
        }
    }



    renderSpringFestivalTravelBody = () => {
        return (
            <div className="today-declare">
                <div className="flex-left" >{this.language.today}</div>
                <div className="flex-right" onClick={()=>this.goToFestivalSpring()} ><span style={{fontSize:14, marginRight:6, color:'rgb(117, 15, 233)'}} className="completed">{this.language.gotodeclare}</span><FontAwesomeIcon icon="chevron-right" /></div>
            </div>
        )
    }

    goBack=()=>{
        this.props.history.push('/')
    }

    renderLoading=()=>{
        if(this.state.showLoading){
            return (
                <LoadingScreen></LoadingScreen>
            )
        }
    }



    render() {

        return (
            <div>
                <SubHeader history={this.props.history} hideSearch={true} title={this.language.title} goBack={this.goBack} />
                <div className="health-park-introduction">
                    <div className="img-container2">
                        <img src={bannerhealth} alt="img"/>
                    </div>
                    <div className="tab-section">
                        <Container>
                            <Row>
                                <Col className={this.currentActiveTab(0)} onClick={() => this.changeTab(0)}>
                                    {this.language.dailydeclaration}
                                </Col>
                                <Col className={this.currentActiveTab(1)} onClick={() => this.changeTab(1)}>
                                    {this.language.returntrip}
                                </Col>
                                <Col className={this.currentActiveTab(2)} onClick={() => this.changeTab(2)}>
                                    Return Mudik
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {this.renderWhat()}
                    {this.renderLoading()}
                </div>
            </div>
        );
    }



}
export default HealthDeclarationPage;

