import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getLanguage } from "../../../Languages";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Container,
  Col,
  Row,
  Card,
  CardTitle,
  CardBody,
  CardText,
} from "reactstrap";
import axios from "axios";
import "./PaymentCenterPage.style.css";
import { webserviceurl, activelanguage } from "../../../Config";
import SubHeader from "../../Component/SubHeader/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetDetailData } from "../PaymentCenterDetailPage/PaymentCenterDetailPage";
import more from "../../../images/marketplace/more.png";
import moment from "moment";
import { convertToRupiah } from "../../../Global";
import { confirmAlert } from "react-confirm-alert";
import PaymentCenterListItem from "../../Component/PaymentCenterListItem/PaymentCenterListItem";
import LoadingScreen from "../../Component/LoadingScreen/LoadingScreen";
import noData from "../../../images/kosong2.png";
import Select from "react-select";

class PaymentCenterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      redirect: false,
      list: [],
      icons: [],
      all: [{ id: 0, image: more, label: "All" }],
      currentCategory: 0,
      start: 0,
      Debtor: "",
      UnitCode: "",
      CompanyCode: "",
      ProjectCode: "",
      loadPaymentFinished: false,
      listUnitCode: [],
      currentUnitCode: "",
      currentLotNo: "",
      LotNo: "",
      token: "",
      sub_total: "",
      showLoading: false,
      AdsData: [],
      phonenumber: "",
      tenantRequest: "",
      UnitRequset: "",
      invMustPay: "",
    };
    this.loginInfo = {
      phoneno: "",
      name: "",
    };
    this.loginBinding = {
      Debtor: "",
      UnitCode: "",
    };
    this.language = getLanguage(activelanguage, "paymentcenter");
    this.listel = null;

    // alert(this.state.list);
  }

  loadAdsMarquee = () => {
    axios({
      method: "post",
      url: webserviceurl + "/app_load_marquee_run.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        if (result.status === "OK") {
          this.setState({ AdsData: result.records });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  Collectdata = (debtor, unitcode) => {
    let params = {
      debtor_acc: debtor,
      unit_acc: unitcode,
    };
    this.setState({
      UnitRequset: params,
    });
    axios({
      method: "post",
      url: webserviceurl + "/user_request_promo.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        console.log(result);
        if (result.status === "OK") {
          if (result.records.length > 0) {
            let RR = result.records;
            this.setState({
              tenantRequest: RR[0],
            });
          }
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  doJoinPaymentPaid = (debtor, dataPending) => {
    axios({
      method: "post",
      url: webserviceurl + "/app_payment_debtor.php",
      data: {
        Debtor: debtor,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let records1 = response.data.records;
        let records2 = response.data.records_item;

        var newResultDataPaid = records1.concat(records2);

        let resultLast = [];
        var i = 0;
        dataPending.map((val1, i1) => {
          newResultDataPaid.map((val2, i2) => {
            if (val1.InvNo === val2.v_external_id) {
              val1.status = "PAID";
            }
          });
          resultLast.push({
            DebtorAcct: val1.DebtorAcct,
            InvNo: val1.InvNo,
            InvDate: val1.InvDate,
            InvAmt: val1.InvAmt,
            PayNo: val1.PayNo,
            PayDate: val1.PayDate,
            PayAmt: val1.PayAmt,
            Category: val1.Category,
            Periode: val1.Periode,
            Status: val1.status,
          });
          i++;
        });

        console.log(resultLast);

        let UNPAID = resultLast.filter((V) => V.Status !== "PAID");

        console.log("data unpaid", UNPAID[0].InvNo);
        this.setState({
          invMustPay: UNPAID[0].InvNo,
        });

        this.setState({
          list: resultLast,
        });
        let sum = (a) => a.reduce((x, y) => x + y);
        let totalAmount = sum(resultLast.map((x) => Number(x.InvAmt)));
        this.setState({ sub_total: totalAmount });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  loadPayment = (debtor, unitcode, companycode, projectCode) => {
    let getToken = JSON.parse(localStorage.getItem("smart-app-id-token"));
    if (
      getToken == undefined ||
      getToken == null ||
      getToken == "null" ||
      getToken == "" ||
      getToken == "undefined"
    ) {
      let param = '{"code":"need-binding"}';
      this.sendPostMessage(param);
    }

    // this.setState({ token: getToken.access_token});
    // this.state.token = getToken.access_token;

    var config = {
      headers: { Authorization: "Bearer " + getToken.access_token },
    };

    this.setState({ showLoading: true });

    axios
      .post(
        webserviceurl + "/api_modernland.php",
        {
          Debtor: debtor,
          UnitCode: unitcode,
          ProjectCode: projectCode,
          token: getToken.access_token,
        },
        config
      )
      .then((response) => {
        this.setState({ showLoading: false });
        // alert(JSON.stringify(response));
        if (response.data.status === "OK") {
          this.doJoinPaymentPaid(debtor, response.data.data);
          this.Collectdata(debtor, unitcode);
          // this.setState({ list: response.data.data });
          // let sum = (a) => a.reduce((x, y) => x + y);
          // let totalAmount = sum(this.state.list.map((x) => Number(x.InvAmt)));
          // this.setState({ sub_total: totalAmount });
        }
        if (response.data.status === 401) {
          confirmAlert({
            message: response.data.message,
            buttons: [{ label: "OK" }],
          });
          // alert(response.data.message);
          // localStorage.setItem('smart-app-id-binding', null);
          // localStorage.removeItem('smart-app-id-binding');
          // localStorage.setItem('Modernland-Account', null);
          // localStorage.removeItem('Modernland-Account');
          // localStorage.setItem('smart-app-id-token', null);
          // localStorage.removeItem('smart-app-id-token');
          let param = '{"code":"need-binding"}';
          this.sendPostMessage(param);
        }
        // setTimeout(()=>{
        //     this.setState({showLoading:false});
        // }, 500);
      })
      .catch((error) => {
        this.setState({ showLoading: false });
        console.log(error);
      });
  };

  componentWillMount = () => {
    this.loadToken();
  };

  componentDidMount = () => {
    this.waitForBridge();
    // this.loadAdsMarquee();
    // this.doJoinPaymentPaid("DC000206.00001/O", [
    //   {
    //     DebtorAcct: "DC000206.00001/O",
    //     InvNo: "JC1/IS/20020463",
    //     InvDate: "2020-02-01T00:00:00",
    //     InvAmt: 10000.0,
    //     PayNo: null,
    //     PayDate: null,
    //     PayAmt: null,
    //     Category: "I",
    //     Periode: "202002",
    //   },
    //   {
    //     DebtorAcct: "DC000206.00001/O",
    //     InvNo: "JC1/IS/20030489",
    //     InvDate: "2020-03-01T00:00:00",
    //     InvAmt: 10000.0,
    //     PayNo: null,
    //     PayDate: null,
    //     PayAmt: null,
    //     Category: "I",
    //     Periode: "202003",
    //   },
    //   {
    //     DebtorAcct: "DC000206.00001/O",
    //     InvNo: "JC1/IS/20040452",
    //     InvDate: "2020-04-01T00:00:00",
    //     InvAmt: 10000.0,
    //     PayNo: null,
    //     PayDate: null,
    //     PayAmt: null,
    //     Category: "I",
    //     Periode: "202004",
    //   },
    //   {
    //     DebtorAcct: "DC000206.00001/O",
    //     InvNo: "JC1/IS/20050456",
    //     InvDate: "2020-05-01T00:00:00",
    //     InvAmt: 10000.0,
    //     PayNo: null,
    //     PayDate: null,
    //     PayAmt: null,
    //     Category: "I",
    //     Periode: "202005",
    //   },
    //   {
    //     DebtorAcct: "DC000206.00001/O",
    //     InvNo: "JC1/IS/20060457",
    //     InvDate: "2020-06-01T00:00:00",
    //     InvAmt: 10000.0,
    //     PayNo: null,
    //     PayDate: null,
    //     PayAmt: null,
    //     Category: "I",
    //     Periode: "202006",
    //   },
    //   {
    //     DebtorAcct: "DC000206.00001/O",
    //     InvNo: "JC1/IS/20070471",
    //     InvDate: "2020-07-01T00:00:00",
    //     InvAmt: 10000.0,
    //     PayNo: null,
    //     PayDate: null,
    //     PayAmt: null,
    //     Category: "I",
    //     Periode: "202007",
    //   },
    //   {
    //     DebtorAcct: "DC000206.00001/O",
    //     InvNo: "JC1/IS/20080460",
    //     InvDate: "2020-08-01T00:00:00",
    //     InvAmt: 10000.0,
    //     PayNo: null,
    //     PayDate: null,
    //     PayAmt: null,
    //     Category: "I",
    //     Periode: "202008",
    //   },
    // ]);
    // this.Collectdata("DC000206.00001/O", "DUMMY_MSS");
    this.loadToken();
    this.loadBinding();
    //this.loadPayment();
    this.listel = document.getElementById("list-payment");
    this.listel.addEventListener("scroll", (e) => {
      this.scrollCheck();
    });
  };

  loadUser = () => {
    let tmp = localStorage.getItem("smart-app-id-login");
    if (
      tmp == undefined ||
      tmp == null ||
      tmp == "null" ||
      tmp == "" ||
      tmp == "undefined"
    ) {
      let param = '{"code":"need-login"}';
      this.sendPostMessage(param);
    } else {
      tmp = JSON.parse(tmp);
      this.loginInfo.phoneno = tmp.phonenumber;
      this.loginInfo.name = tmp.name;
    }
  };

  loadToken = () => {
    let tkn = localStorage.getItem("smart-app-id-token");
    if (
      tkn == undefined ||
      tkn == null ||
      tkn == "null" ||
      tkn == "" ||
      tkn == "undefined"
    ) {
      let param = '{"code":"need-binding"}';
      this.sendPostMessage(param);
    } else {
      tkn = JSON.parse(tkn);
      // alert(tkn);
      // alert(JSON.stringify(tkn.access_token));
      this.setState({ token: JSON.stringify(tkn.access_token) });
    }
  };

  loadBinding = async () => {
    let tmp = localStorage.getItem("smart-app-id-binding");
    let tmp2 = localStorage.getItem("Modernland-Account");
    if (
      tmp2 == undefined ||
      tmp2 == null ||
      tmp2 == "null" ||
      tmp2 == "" ||
      tmp2 == "undefined"
    ) {
      let param = '{"code":"need-binding"}';
      this.sendPostMessage(param);
    }
    if (
      tmp == undefined ||
      tmp == null ||
      tmp == "null" ||
      tmp == "" ||
      tmp == "undefined"
    ) {
      let param = '{"code":"need-binding"}';
      this.sendPostMessage(param);
    } else {
      tmp = JSON.parse(tmp);
      let currentLotNo = "";
      let current = "";
      if (tmp.length > 0) {
        currentLotNo = tmp[0].LotNo;
        current = tmp[0].DebtorAcct + "|" + tmp[0].LotNo;
        await this.changeUnitCode(
          tmp[0].DebtorAcct +
            "|" +
            tmp[0].LotNo +
            "|" +
            tmp[0].CompanyCode +
            "|" +
            tmp[0].ProjectCode
        );
        this.loadPayment(
          tmp[0].DebtorAcct,
          tmp[0].LotNo,
          tmp[0].CompanyCode,
          tmp[0].ProjectCode
        );

        console.log(this.state.CompanyCode);
        // item.DebtorAcct+'|'+item.LotNo
      }

      this.setState({
        listUnitCode: tmp,
        currentUnitCode: current,
        currentLotNo: currentLotNo,
      });
      //this.state.Debtor = tmp.DebtorAcct;
      //this.state.UnitCode = tmp.LotNo;
    }
  };

  sendPostMessage = (param) => {
    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.sendPostMessage(param);
        }.bind(this),
        200
      );
    } else {
      window.postMessage(param, "*");
    }
  };

  componentWillUnmount = () => {
    this.listel.removeEventListener("scroll", (e) => {
      this.scrollCheck();
    });
  };

  scrollCheck = () => {
    if (
      this.listel.scrollTop + this.listel.clientHeight >=
      this.listel.scrollHeight
    ) {
      if (this.state.loadPaymentFinished == false) {
        this.loadPayment(this.state.currentCategory, this.state.start);
      }
    }
  };

  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let tmp = localStorage.getItem("smart-app-id-login");
      let needLogin = false;
      // let tmp2 = localStorage.getItem('smart-app-id-binding');
      // let needBinding = false;

      if (
        tmp == undefined ||
        tmp == null ||
        tmp == "null" ||
        tmp == "" ||
        tmp == "undefined"
      )
        needLogin = true;
      // else if(tmp2 == undefined || tmp2 == null || tmp2 == 'null' || tmp2 == '' ||tmp2 == 'undefined' ) needBinding = true;
      else {
        tmp = JSON.parse(tmp);
        this.loginInfo.phoneno = tmp.phonenumber;
        this.loginInfo.name = tmp.name;
        this.setState({
          phonenumber: tmp.phonenumber,
        });
        if (tmp.statusbinding === 0) {
          this.props.history.push("/");
          let param = '{"code":"OpenAlert-Binding"}';
          this.sendPostMessage(param);
        }
      }

      let param =
        '{"title":"' +
        this.language.title +
        '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true, "needLogin":' +
        (needLogin ? "true" : "false") +
        "}";
      window.postMessage(param, "*");
    }
  }

  createMarkup = (content) => {
    return { __html: content };
  };

  doMultiplePay = () => {
    this.props.history.push("/paymentmultiple");
  };

  goToDetail = (data, i) => {
    console.log(this.state.invMustPay);
    console.log(data);
    if (data.InvNo !== this.state.invMustPay && data.Status !== "PAID") {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div>
              <Card className="card-alert">
                <CardTitle className="card-title">
                  <span>Information</span>
                </CardTitle>
                <CardBody className="card-body">
                  <CardText className="card-text">
                    Please make bill payments in the previous period.
                  </CardText>
                  <table>
                    <tr style={{ color: "#fff", textAlign: "right" }}>
                      <td>
                        <a
                          className="link-button"
                          onClick={() => {
                            onClose();
                          }}
                        >
                          OK
                        </a>
                      </td>
                    </tr>
                  </table>
                </CardBody>
              </Card>
            </div>
          );
        },
      });
    } else {
      GetDetailData(data);
      this.props.history.push(
        "/paymentcenterdetail/" +
          encodeURIComponent(data.InvNo) +
          "/" +
          encodeURIComponent(data.DebtorAcct) +
          "/" +
          encodeURIComponent(this.state.LotNo) +
          "/" +
          encodeURIComponent(this.state.CompanyCode)
      );
    }
  };

  renderLoading = () => {
    if (this.state.showLoading) {
      return <LoadingScreen></LoadingScreen>;
    }
  };

  renderTab = () => {
    if (this.state.list.length > 0) {
      return (
        <div className="body-section payment-scroll-view2">
          <div className="payment-select-unit">
            <select
              className="payment-select-unit"
              value={this.state.currentUnitCode}
              onChange={(e) => this.changeUnitCode(e.target.value)}
            >
              {this.state.listUnitCode.map((item, i) => (
                <option
                  value={
                    item.DebtorAcct +
                    "|" +
                    item.LotNo +
                    "|" +
                    item.CompanyCode +
                    "|" +
                    item.ProjectCode
                  }
                >
                  Unit Code: {item.LotNo} - Cluster: {item.ClusterName}{" "}
                </option>
              ))}
            </select>
          </div>
          <div>
            <table>
              <tbody>
                <tr>
                  <td
                    style={{
                      textAlign: "left",
                      paddingLeft: 8,
                      borderBottomColor: "#d9d9d9",
                    }}
                  >
                    <span>
                      Total Amount: {convertToRupiah(this.state.sub_total)}
                    </span>
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      paddingRight: 8,
                      borderBottomColor: "#d9d9d9",
                    }}
                  >
                    <Button
                      color="info"
                      onClick={() => this.doMultiplePay()}
                      block
                    >
                      Multi Pay
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div>
                        <text>Total Amount: {convertToRupiah(this.state.sub_total)} </text>
                    </div>
                    <div>
                        <Button color="success" onClick={() => this.doMultiplePay()} block>Multiple Payment</Button>
                    </div> */}
          {this.state.list.map((data, i) => (
            <div key={i} onClick={() => this.goToDetail(data, i)}>
              <PaymentCenterListItem
                key={i}
                DebtorAcct={data.DebtorAcct}
                UnitCode={data.DebtorAcct.substring(0, 7)}
                InvNo={data.InvNo}
                InvAmt={data.InvAmt}
                InvDate={moment(data.InvDate).format("MMMM Y")}
                Periode={data.Periode}
                // Periode={moment(data.Periode).format("MMMM Y")}
                PayAmt={data.PayAmt}
                Category={data.Category}
                Status={data.Status}
                onClick={() => this.goToDetail(data)}
              />
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="body-section payment-scroll-view2">
          <div className="payment-select-unit">
            <select
              className="payment-select-unit"
              value={this.state.currentUnitCode}
              onChange={(e) => this.changeUnitCode(e.target.value)}
            >
              {this.state.listUnitCode.map((item, i) => (
                <option
                  value={
                    item.DebtorAcct +
                    "|" +
                    item.LotNo +
                    "|" +
                    item.CompanyCode +
                    "|" +
                    item.ProjectCode
                  }
                >
                  Unit Code: {item.LotNo} - Cluster: {item.ClusterName}{" "}
                </option>
              ))}
            </select>
          </div>
          <div
            style={{
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginTop: 80,
            }}
          >
            <img src={noData} style={{ width: 180, height: 120 }} />
          </div>
          <div className="no-data-bill" style={{ marginTop: -40 }}>
            <span>Bill not available</span>
          </div>
        </div>
      );
    }
  };

  renderLink = (icon) => {
    if (icon.id === this.state.currentCategory) {
      return (
        <div
          className="payment-link-label payment-link-active"
          onClick={() => this.loadPayment(icon.id)}
        >
          <img src={icon.image} />
          <br />
          {icon.label}{" "}
        </div>
      );
    } else {
      return (
        <div
          className="payment-link-label"
          onClick={() => this.loadPayment(icon.id)}
        >
          <img src={icon.image} />
          <br />
          {icon.label}{" "}
        </div>
      );
    }
  };

  changeUnitCode = (value) => {
    this.setState({
      invMustPay: "",
      list: [],
      tenantRequest: "",
      UnitRequset: "",
    });
    let arr = value.split("|");
    this.setState({ LotNo: arr[1] });
    this.setState({ CompanyCode: arr[2] });
    this.setState({ ProjectCode: arr[3] });
    this.loadPayment(arr[0], arr[1], arr[2], arr[3]);
    this.setState({ currentUnitCode: value });

    setTimeout(() => {
      // alert(this.state.LotNo + '///////' + 'CompanyCode' + this.state.CompanyCode + '......' + this.state.currentUnitCode);
    }, 3000);
  };

  renderMarquee = () => {
    if (this.state.AdsData.length > 0) {
      return (
        <div
          style={{
            position: "absolute",
            bottom: -5,
            left: 0,
            right: 0,
            justifyContent: "center",
            alignContent: "center",
            marginTop: 10,
          }}
        >
          <marquee>
            {this.state.AdsData.map((value, i) => {
              return (
                <span style={{ fontSize: 10, marginRight: 10 }}>
                  {value.name}
                </span>
              );
            })}
          </marquee>
        </div>
      );
    }
  };

  renderButtonRequest = () => {
    if (this.state.tenantRequest === "") {
      return (
        <div className="payment-button-container">
          <Button
            color="info"
            className="fullbuttonMultiple"
            // disabled={!isEnabled}
            onClick={() => this.doDialogRequest()}
          >
            Request a promo
          </Button>
        </div>
      );
    } else if (this.state.tenantRequest.status === 1) {
      return (
        <div className="payment-button-container">
          <Button color="info" className="fullbuttonMultiple" disabled>
            Waiting for approval
          </Button>
        </div>
      );
    } else if (this.state.tenantRequest.status === 2) {
      return (
        <div className="payment-button-container">
          <Button
            color="info"
            className="fullbuttonMultiple"
            disabled
            // onClick={() => this.doUnpromo()}
          >
            Promo request approved
          </Button>
        </div>
      );
    } else if (this.state.tenantRequest.status === 3) {
      return (
        <div className="payment-button-container">
          <Button
            color="info"
            className="fullbuttonMultiple"
            // disabled={!isEnabled}
            onClick={() => this.doDialogRequest()}
          >
            Request denied, try again
          </Button>
        </div>
      );
    }
  };

  dialogUnpromo = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div>
            <Card className="card-alert">
              <CardTitle className="card-title">
                <span>Confirmation</span>
              </CardTitle>
              <CardBody className="card-body">
                <CardText className="card-text">
                  Do you want to request a promo for 11 months free 1 month bill
                  payment? please click "Submit" to make a promo request and
                  click "Cancel" not to.
                </CardText>
                <table>
                  <tr style={{ color: "#fff", textAlign: "center" }}>
                    <td>
                      <a
                        className="link-button"
                        onClick={() => {
                          this.PromoRequest();
                          onClose();
                        }}
                      >
                        Submit
                      </a>
                    </td>
                    <td>
                      <a className="link-button" onClick={onClose}>
                        Cancel
                      </a>
                    </td>
                  </tr>
                </table>
              </CardBody>
            </Card>
          </div>
        );
      },
    });
  };

  doDialogRequest = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div>
            <Card className="card-alert">
              <CardTitle className="card-title">
                <span>Confirmation</span>
              </CardTitle>
              <CardBody className="card-body">
                <CardText className="card-text">
                  Do you confirm to request promo 11 months free 1 month bill
                  payment? please click "Submit" to make a promo request and
                  click "Cancel" not to.
                </CardText>
                <table>
                  <tr style={{ color: "#fff", textAlign: "center" }}>
                    <td>
                      <a
                        className="link-button"
                        onClick={() => {
                          this.PromoRequest();
                          onClose();
                        }}
                      >
                        Submit
                      </a>
                    </td>
                    <td>
                      <a className="link-button" onClick={onClose}>
                        Cancel
                      </a>
                    </td>
                  </tr>
                </table>
              </CardBody>
            </Card>
          </div>
        );
      },
    });
  };

  PromoRequest = () => {
    let params = {
      phonenumber: this.state.phonenumber,
      debtor_acc: this.state.UnitRequset.debtor_acc,
      unit_acc: this.state.UnitRequset.unit_acc,
      status: 1,
    };
    console.log(params);
    this.setState({ showLoading: true });
    axios
      .post(webserviceurl + "/user_request_insert.php", params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => {
        this.setState({ showLoading: false });
        let result = response.data;
        if (result.status === "OK") {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div>
                  <Card className="card-alert">
                    <CardTitle className="card-title">
                      <span>Information</span>
                    </CardTitle>
                    <CardBody className="card-body">
                      <CardText className="card-text">
                        Please wait for approval.
                      </CardText>
                      <table>
                        <tr style={{ color: "#fff", textAlign: "right" }}>
                          <td>
                            <a
                              className="link-button"
                              onClick={() => {
                                onClose();
                              }}
                            >
                              OK
                            </a>
                          </td>
                        </tr>
                      </table>
                    </CardBody>
                  </Card>
                </div>
              );
            },
          });
          this.props.history.push("/");
        }
      })
      .catch((error) => {
        this.setState({ showLoading: false });
        console.log(error);
      });
  };

  render() {
    if (this.state.redirect) {
      /*if (this.state.page == "newspage") {
                return <Redirect to='/news' />;
            }*/
    }

    return (
      <div className="main-container">
        <SubHeader
          history={this.props.history}
          hideSearch={true}
          title={this.language.title}
        />
        <div className="payment-icons" id="list-payment">
          <div className="payment-icons-container">
            <table className="table-icon">
              <tbody>
                <tr>
                  {this.state.all.map((icon, i) => (
                    <td>
                      <div key={i} className="payment-icon-column">
                        {this.renderLink(icon)}
                      </div>
                    </td>
                  ))}
                  {this.state.icons.map((icon, i) => (
                    <td>
                      <div key={i} className="payment-icon-column">
                        {this.renderLink(icon)}
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {this.renderTab()}
        {this.renderButtonRequest()}
        {/* {this.renderMarquee()} */}
        {this.renderLoading()}
        {/* Unicode = this.state.debtor.replace */}
      </div>
    );
  }
}
export { GetDetailData };
export default PaymentCenterPage;
