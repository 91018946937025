import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import { Container, Row, Col, Card } from 'reactstrap';
import LoadingScreen from '../../Component/LoadingScreen/LoadingScreen';
import axios from 'axios';
import './Directory.style.css';
import { webserviceurl, activelanguage } from '../../../Config';
import SubHeader from '../../Component/SubHeader/SubHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import more from '../../../images/marketplace/more.png';
import DirectoryListItem from '../../Component/DirectoryListItem/DirectoryListItem';
import noData from '../../../images/kosong2.png'
import newsdefault from '../../../images/news_default.png';


class Directory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: "",
            redirect: false,
			directorycategoryid: props.match.params.directorycategoryid,
			directorycategoryname: props.match.params.directorycategoryname,
			list: [],
			start: 0,	
            loadDirectoryFinished: false,
            showLoading: false
        }

        this.language = getLanguage(activelanguage, 'directory');
		this.listel = null;
    }
	
	loadDirectoryCategory = () => {
        this.setState({showLoading:true});
		axios.post(webserviceurl + '/app_load_directorycategoryselected.php', {
			directorycategoryid: this.state.directorycategoryid
		},
		{headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) =>{
                this.setState({directorycategoryname: response.data.records[0].directorycategoryname});
                setTimeout(()=>{
                    this.setState({showLoading:false});
                }, 500); 
				
            })
            .catch((error) =>{
                console.log(error);
				alert(error);
            });
	}

	loadDirectory = () =>{
        this.setState({showLoading:true});
		axios.post(webserviceurl + '/app_load_directory.php', {
			start: this.state.start,
			directorycategoryid: this.state.directorycategoryid
		},
		{headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {
                let result = response.data;
                let limitafterload = this.state.start + 10;
                if (result.status === "ok") {
					if(result.records.length == 0){
						this.setState({loadDirectoryFinished: true});
					}
					
					let tmp = this.state.list;
					
					for(var i=0; i<result.records.length;i++){
						tmp.push(result.records[i]);
					}
					
					this.setState({ list: tmp });
					this.setState({ start: limitafterload});
                }
                
                setTimeout(()=>{
                    this.setState({showLoading:false});
                }, 500); 

				
            })
            .catch((error) => {
                console.log(error);
            });
	}

	componentDidMount = () => {
        this.waitForBridge();
		this.loadDirectoryCategory();
		this.loadDirectory();
		this.listel = document.getElementById('list-directory');
        this.listel.addEventListener('scroll', (e)=>{
            this.scrollCheck();
        });
	}
	
	componentWillUnmount=()=>{
        this.listel.removeEventListener('scroll', (e)=>{
            this.scrollCheck();
        });
    }
	
	scrollCheck = ()=>{	
        if(this.listel.scrollTop + this.listel.clientHeight >= this.listel.scrollHeight){
            if(this.state.loadDirectoryFinished == false){
				this.loadDirectory();
			}
        }
    }

    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":false, "showCommunityName":false, "hideTopbar":true, "hideFooterMenu":true}';           
            window.postMessage(param, "*");
        }
    }

    createMarkup = (content) => {
        return { __html: content };
    }

    goToDetail=(item)=>{
        this.props.history.push('/directorydetail/'+item.directoryid);
    }

    renderSingleImageFeed=(item)=>{
        if(item.directorypicture !== '' || item.directorypicture !== null){
            return (
                <div className="img-cropped">
                    <img src={item.directorypicture} alt={'imgpic'} className="img-cropped"  />
                </div>
            )
        }else{
            return (
                <div className="img-cropped">
                    <img src={newsdefault} alt={'imgpic'} className="img-cropped"/>
                </div>
            )
        }
    }

    renderNewsFeedBody=()=>{
        if(this.state.list.length>0){
            return (
                <div className="directory-all-list-section-data">
                    <div>
                        {this.state.list.map((item, i) =>
                            <div className="list-item" key={i} onClick={()=>this.goToDetail(item)}>
                                <div className="relative-container">
                                    <div className="descript-container-border">
                                        {this.renderSingleImageFeed(item)}
                                        <div className="descript-container-small">
                                            <div className="directoryname-text" dangerouslySetInnerHTML={{__html: item.directoryname}}></div>
                                            <div className="phone-address-text-name"> {item.directoryaddress}</div>
                                            <div className="phone-address-text-name"> {item.directoryphone}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        }else{
            return(
                <div className="directory-all-list-section-data">
                    <div style={{alignContent:'center', alignItems:'center', textAlign:'center', marginTop:80 }}>
                        <img src={noData} style={{width:180, height:120}} />
                    </div>
                    <div className="no-data" style={{marginTop:-20}}>No data directory available</div>
                </div>
            )
        }
    }

    renderTab = () => {
        if(this.state.list.length>0){
        return (
                <div className="body-section directory-scroll-view">
                    {this.state.list.map((item, i) =>
                        <Card style={{margin:16}}>
                            <div key={i} onClick={()=>this.goToDetail(item)}>
                                <DirectoryListItem key={i} category={item.directorycategoryname} directoryname={item.directoryname} directoryaddress={item.directoryaddress} directoryphone={item.directoryphone} directorywebsite={item.directorywebsite}  directorypicture={item.directorypicture} onClick={()=>this.goToDetail(item)}/>
                            </div>
                        </Card>
                    )}
                </div>
            )
        }else{
            return(
                <div>
                    <div style={{alignContent:'center', alignItems:'center', textAlign:'center', marginTop:80 }}>
                        <img src={noData} style={{width:180, height:120}} />
                    </div>
                    <div className="no-data" style={{marginTop:-20}}>No data available</div>
                </div>
            )
        }
    }

    renderLoading=()=>{
        if(this.state.showLoading){
            return (
                <LoadingScreen></LoadingScreen>
            )
        }
    }

    render() {
        if (this.state.redirect) {
            /*if (this.state.page == "newspage") {
                return <Redirect to='/news' />;
            }*/
        }

        return (
            <div>
                <SubHeader history={this.props.history} hideSearch={true} title={this.state.directorycategoryname}/>
                <div id="list-directory">
					{this.renderNewsFeedBody()}
                    {this.renderLoading()}
                </div>
            </div>
        );
    }
}

export default Directory;