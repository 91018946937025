import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
  } from 'reactstrap';
//import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import './StatusPaymentBooking.style.css';

import { webserviceurl, activelanguage } from '../../../Config';
import success from '../../../images/payment/payment-succes-3.jpg';
import SubHeader from '../../Component/SubHeader/SubHeader';


class StatusPaymentBooking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: "",
            redirect: false,
            email:'',
            token:'',
            phonenumber:'',
            showLoading: false,
            dataPaymentStatus:[]   
        }

        this.trySendMessage = 0;
        this.language = getLanguage(activelanguage, 'entertainmentpage');
                
    }

    loadUser=()=>{
        if(localStorage.getItem('smart-app-id-login') != undefined && localStorage.getItem('smart-app-id-login') != 'undefined'){
            var user = localStorage.getItem('smart-app-id-login');
            var userID = JSON.parse(user);

            this.setState({email: userID.email});

            // alert(this.state.email);
            // alert(JSON.stringify(userID.email));
        }
        
            // let getToken = JSON.parse(localStorage.getItem('smart-app-id-token'));
            // this.setState({...this.state,
            //                 token: getToken.access_token});
            // alert(this.state.token);
    }
    componentWillMount=()=>{
        this.loadUser();
        
    }

    componentDidMount=()=>{
        this.waitForBridge();
        
    }

    openWindow =()=>{
        //window.open('https://www.netflix.com/id-en/', "_blank");
    }

    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":false, "showCommunityName":true, "hideTopbar":false, "hideFooterMenu":false}';
            window.postMessage(param, "*");
        }
    }

    goToHome=()=>{
        this.setState({showLoading:true});
        // this.props.history.push('/');
        
        let param = '{"code":"need-home"}';
        this.sendPostMessage(param);

        setTimeout(()=>{
            this.setState({showLoading:false});
        }, 200);
    }

    sendPostMessage=(param)=>{
        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.sendPostMessage(param);
            }.bind(this), 200);
        }
        else {
            window.postMessage(param, "*");
        }
    }

    sendMessage=(url)=>{
        this.trySendMessage +=1;
        if (window.postMessage.length !== 1) {
            if(this.trySendMessage < 30){
                setTimeout(function () {
                    this.sendMessage();
                }
                .bind(this), 200);
            }else{
                alert(this.language.errorOnMessage);
                this.trySendMessage = 0;
            }
        }
        else {
            this.trySendMessage = 0;
            let param = '{"code":"openbrowser","url":"'+url+'"}';
            window.postMessage(param, "*");
        }
    }

    createMarkup = (content) => {
        return { __html: content };
    }

    render() {
        const { showLoading } = this.state;
        return (
            <div className="main-container payment-page">
                <div className="park-introduction">
                <Card  body className="text-center">
                    <CardImg top width="100%" src={success} alt="Success" />
                    <CardBody>
                    <CardTitle style={{backgroundColor:'#ffffff', color:'#000'}}  >THANK YOU!</CardTitle>
                    <CardSubtitle>Your payment is successfully done.</CardSubtitle>
                    <CardText></CardText>
                    <Button color="success" className="fullbutton" onClick={() => this.goToHome()}  disabled={showLoading} >
                        {showLoading && (
                            <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                            />
                        )}
                        {showLoading && <span>PLEASE WAIT...</span>}
                        {!showLoading && <span>DONE</span>}
                    </Button>
                    </CardBody>
                </Card>
                </div>
            </div>
        );
    }
}

export default StatusPaymentBooking;