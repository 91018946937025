import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import {Button, Form, FormGroup, Label, Input, Container, Row, Col, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import axios from 'axios';
import './PaymentMultipleDetailPage.css';
import { webserviceurl, activelanguage, PAYMENT_METHODS, NO_PAYMENT_METHOD } from '../../../Config';
import SubHeader from '../../Component/SubHeader/SubHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import ReactModal from 'react-modal';
/* import 'react-confirm-alert/src/react-confirm-alert.css'; */
import Popup from "reactjs-popup";
import {convertToRupiah, makeid} from '../../../Global';
import '../../Component/Modal/Modal.css';
import moment from 'moment';


class PaymentMultipleDetailPage extends Component{
    constructor(props) {
        console.log(props);
        super(props);
        this.language = getLanguage(activelanguage, 'paymentmultipledetail');
        this.globallang = getLanguage(activelanguage, 'global');
        this.toggle = this.toggle.bind(this);
        console.log(this);

        this.state={
            page:"",
            Redirect: false,
            isShowing: false,
            modal: false,
            activeTab: '1',
            email:'',
        }
    }

    toggle() {
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
    }

    loadUser=()=>{
        
		if(localStorage.getItem('smart-app-id-binding') != undefined && localStorage.getItem('smart-app-id-binding') != 'undefined'){
			var com = localStorage.getItem('smart-app-id-binding');
            var binding = JSON.parse(com);
            
            // alert(JSON.stringify(binding));
        
        }
        
         if(localStorage.getItem('smart-app-id-login') != undefined && localStorage.getItem('smart-app-id-binding') != 'undefined'){
            var user = localStorage.getItem('smart-app-id-login');
            var userID = JSON.parse(user);

            this.setState({email: userID.email});

            // alert(this.state.email);
        }
    }
    
    componentDidMount=()=>{
        this.waitForBridge();
        console.log(this.props);
    }

    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":true, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
            window.postMessage(param, "*");
        }
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true,
            modal:false
        });
    }

    closeModalHandler = () => {
        this.setState({
            isShowing: false
        });
    }
    openModalBCA = () => {
        this.setState({
            isShowing2: true,
            modal:false
        });
    }

    closeModalBCA = () => {
        this.setState({
            isShowing2: false
        });
    }

    goFormXendit=()=>{
        const body = JSON.stringify({
            "external_id": this.state.list.InvNo,
            "amount": this.state.list.InvAmt ,
            "payer_email": this.state.email,
            "description": "Invoice "+ this.state.list.Category+"PKL"});

        axios.post(webserviceurl+'/apiXendit/create_invoice.php',body)
        .then((response) => {
            // alert(JSON.stringify(response.data));
            this.setState({ dataXendit: response.data });
            // alert(this.state.dataXendit.id);
            this.props.history.push('/paymentfrom/'+this.state.dataXendit.id);
        });
    }

    renderPayButton=()=>{
        if(this.state.billingstatus !== 1){
            return (
                <div className="payment-button-container">
                    <Button color="primary" className="fullbutton" onClick={() => this.goFormXendit()}>{this.language.pay}</Button>
                </div>
            )
        }
    }

    renderPaidDate=()=>{
        if(this.state.data.billingstatus === 1){
            return (
                <tr>
                    <td className="payment-info-label">{this.language.paiddate} :</td>
                    <td className="payment-info-value paid">{this.state.data.paiddate}</td>
                </tr>
            )
        }
    }

    renderStatus=()=>{
        if (this.state.list.PayAmt === null){
            return(
                <tr>
                    <td className="payment-info-label">{this.language.status} :</td>
                    <td className="payment-info-value pending">{this.language.pending}</td>
                </tr>
            )
        } else if (this.state.list.PayAmt !== null){
            return (
                <tr>
                    <td className="payment-info-label">{this.language.status} :</td>
                    <td className="payment-info-value paid">{this.language.paid}</td>
                </tr>
            )
        }
        
    }

    renderCategory=()=>{
        console.log(this.state.list.Category);
        if (this.state.list.Category === "I"){
            return(
                <tr>
                    { <td className="payment-info-label">{this.language.category}</td>}
                    <td className="payment-info-value pending">{this.language.ipkl} :</td>
                </tr>
            )
        }         
    }

    render() {
        if (this.state.redirect) {
        }

        return (
            <div className="main-container payment-page">
                <SubHeader history={this.props.history} hideSearch={true} title={this.language.title} />
                <div className="park-introduction"> >
                    <div className="payment-title">
                        PAY/MP/ID/{makeid(10)}
                    </div>
                    <div className="payment-amount">
                        {convertToRupiah(this.state.list.InvAmt)}
                        <div className="payment-amount-label">{this.language.amount} :</div>
                    </div>
                    <table className="payment-info-container">
                        <tbody>
                            {/* <tr>
                                <td className="payment-info-label">{this.language.periode} :</td>
                                <td className="payment-info-value">{moment(this.state.list.InvDate).format('MMMM Y')}</td>
                            </tr> */}
                            {/* <tr>
                                <td className="payment-info-label">{this.language.debacct} :</td>
                                <td className="payment-info-value">{this.state.list.DebtorAcct}</td>
                            </tr>
                            <tr>
                                <td className="payment-info-label">{this.language.invno} :</td>
                                <td className="payment-info-value">{this.state.list.InvNo}</td>
                            </tr>
                            {this.renderCategory()}
                            {this.renderStatus()} */}
                        </tbody>
                    </table>
                    {this.renderPayButton()}
                </div>
            </div>
        );
    }
}

export default PaymentMultipleDetailPage;