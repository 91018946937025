import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getLanguage } from "../../../Languages";
//import test from '../images/me_pic_head-bg@2x.png';
import { Container, Row, Col } from "reactstrap";
import { webserviceurl, activelanguage } from "../../../Config";
import axios from "axios";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import "./Home.css";
import newsdefault from "../../../images/news_default.png";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Pagination from "../../Component/PaginationDot/Pagination";
import {
  Player,
  ControlBar,
  PlayToggle,
  VolumeMenuButton,
  ReplayControl,
  BigPlayButton,
  PlaybackRateMenuButton,
  Video,
} from "video-react";
// import "node_modules/video-react/dist/video-react.css";
// import "~video-react/styles/scss/video-react";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

let icons = [];

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      redirect: false,
      community: 22,
      merchantcategoryid: 6,
      activeIndex: 0,
      activeIndex2: 0,
      activeIndex3: 0,
      modal: false,
      count: 0,
      AdvCommunity: [],
      AdvCommunityBottom: [],
      videoAdvTop: [],
      videoAdvBottom: [],
      index: 0,
      index2: 0,
      coronaIndonesia: [],
      coronaPositif: [],
      coronaSembuh: [],
      coronaMeninggal: [],
      LastTime: "",
      platform: "",
    };
    let search = props.location.search.replace("?", "");
    let params = search.split("&");
    for (let i = 0; i < params.length; i++) {
      let param = params[i].split("=");
      if (param[0].toLowerCase() === "page") {
        this.state.page = param[1];
        this.state.redirect = true;
        break;
      } else if (param[0].toLowerCase() === "community") {
        this.state.community = param[1];
        localStorage.setItem("smart-app-id-login" + "-community", param[1]);
      } else if (param[0].toLowerCase() === "community") {
        this.state.community = param[1];
        localStorage.setItem("smart-app-id-binding" + "-community", param[1]);
      } else if (param[0].toLowerCase() === "community") {
        this.state.community = param[1];
        localStorage.setItem("Modernland-Account" + "-community", param[1]);
      } else if (param[0].toLowerCase() === "community") {
        this.state.community = param[1];
        localStorage.setItem("smart-app-id-token" + "-community", param[1]);
      }
      // console.log(params);
    }

    this.language = getLanguage(activelanguage, "home");

    icons = [
      {
        image: require("../../../images/icon_home/News.png"),
        label: this.language.news,
        link: "/news",
        platform: "android",
      },
      {
        image: require("../../../images/icon_home/momments3.png"),
        label: this.language.moments,
        link: "/moments",
        platform: "android",
      },
      {
        image: require("../../../images/icon_home/Emergency3.png"),
        label: this.language.emergency,
        link: "/coorporatecontact/" + this.state.community,
        platform: "android",
      },
      {
        image: require("../../../images/icon_home/Bookings3.png"),
        label: this.language.room,
        link: "/banquetfacilities",
        platform: "android",
      },
      {
        image: require("../../../images/icon_home/customer_service2.png"),
        label: "Complaint",
        link: "/customercare",
        platform: "android",
      },
      {
        image: require("../../../images/icon_home/service_center.png"),
        label: this.language.servicecenter,
        link: "/servicecenter",
        platform: "android",
      },
      {
        image: require("../../../images/icon_home/myBills.png"),
        label: this.language.mybills,
        link: "/paymentcenter",
        platform: "android",
      },
      {
        image: require("../../../images/icon_home/Directory3.png"),
        label: "Directory",
        link: "/directorycategoryselect",
        platform: "android",
      },
      {
        image: require("../../../images/icon_home/covid19.png"),
        label: "COVID-19",
        link: "/covid19",
        platform: "ios",
      },
      {
        image: require("../../../images/icon_home/wfh.png"),
        label: "WFH-SAH",
        link: "/wfh",
        platform: "android",
      },
      {
        image: require("../../../images/icon_home/questionnaire.png"),
        label: "Questionnaire",
        link: "/questionnaire",
        platform: "android",
      },
      {
        image: require("../../../images/icon_home/register_merchant_2.png"),
        label: "Merchant",
        link: "/merchantregistration",
        platform: "android",
      },
      // { image: require("../../../images/icon_home/Products.png"), label: "Product", link: "/directorycategoryselect" },
      // { image: require("../../../images/icon_home/Discount.png"), label: "Promotion", link: "/directorycategoryselect" },
      // { image: require("../../../images/icon_home/TraffightCamera.png"), label: "Traffic Camera", link: "/directorycategoryselect" },
      // { image: require("../../../images/icon_home/FoodGarden.png"), label: "Food Garden", link: "/directorycategoryselect" }
    ];

    let videoAdvTop = JSON.parse(localStorage.getItem("VideoTOP"));
    if (
      videoAdvTop === null ||
      videoAdvTop === undefined ||
      videoAdvTop === [] ||
      videoAdvTop === ""
    )
      videoAdvTop = [];

    let videoAdvBottom = JSON.parse(localStorage.getItem("VideoBOTTOM"));
    if (
      videoAdvBottom === null ||
      videoAdvBottom === undefined ||
      videoAdvBottom === [] ||
      videoAdvBottom === ""
    )
      videoAdvBottom = [];

    let LastTime = JSON.parse(localStorage.getItem("LastTime"));
    if (
      LastTime === null ||
      LastTime === undefined ||
      LastTime === [] ||
      LastTime === ""
    )
      LastTime = [];

    let bannerTop = JSON.parse(localStorage.getItem("TOP"));
    if (
      bannerTop === null ||
      bannerTop === undefined ||
      bannerTop === [] ||
      bannerTop === ""
    )
      bannerTop = [];

    let bannerBottom = JSON.parse(localStorage.getItem("BOTTOM"));
    if (
      bannerBottom === null ||
      bannerBottom === undefined ||
      bannerBottom === [] ||
      bannerBottom === ""
    )
      bannerBottom = [];

    let covidINA = JSON.parse(localStorage.getItem("INA"));
    if (
      covidINA === null ||
      covidINA === undefined ||
      covidINA === [] ||
      covidINA === ""
    )
      covidINA = [];

    let covidPositif = JSON.parse(localStorage.getItem("Positif"));
    if (
      covidPositif === null ||
      covidPositif === undefined ||
      covidPositif === [] ||
      covidPositif === ""
    )
      covidPositif = [];

    let covidRecovered = JSON.parse(localStorage.getItem("Recovered"));
    if (
      covidRecovered === null ||
      covidRecovered === undefined ||
      covidRecovered === [] ||
      covidRecovered === ""
    )
      covidRecovered = [];

    let covidDeath = JSON.parse(localStorage.getItem("Death"));
    if (
      covidDeath === null ||
      covidDeath === undefined ||
      covidDeath === [] ||
      covidDeath === ""
    )
      covidDeath = [];
  }

  getCoronaIndonesia = () => {
    const proxyurl = "https://cors-anywhere.herokuapp.com/";
    const url = "https://api.kawalcorona.com/indonesia/";

    // let headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    // headers.append('Accept', 'application/json');
    // headers.append('Origin','http://localhost:3000');

    fetch(proxyurl + url)
      .then((response) => response.json())
      .then(
        // handle the result
        (result) => {
          var arrbulan = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "Juny",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          var arrhari = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          var date = new Date();
          var millisecond = date.getMilliseconds();
          var detik = date.getSeconds();
          var menit = date.getMinutes();
          var jam = date.getHours();
          var hari = date.getDay();
          var tanggal = date.getDate();
          var bulan = date.getMonth();
          var tahun = date.getFullYear();
          var datetimenow =
            arrhari[hari] +
            ", " +
            tanggal +
            " " +
            arrbulan[bulan] +
            " " +
            tahun +
            " " +
            jam +
            ":" +
            menit +
            ":" +
            detik +
            " WIB";

          localStorage.setItem("LastTime", JSON.stringify(datetimenow));
          localStorage.setItem("INA", JSON.stringify(result));
          // console.log(result);
          this.setState({
            coronaIndonesia: result,
          });
        },

        // Handle error
        (error) => {
          this.setState({
            error,
          });
        }
      );
  };

  getCoronaPositif = () => {
    const proxyurl = "https://cors-anywhere.herokuapp.com/";
    const url = "https://api.kawalcorona.com/positif";

    fetch(proxyurl + url)
      .then((response) => response.json())
      .then(
        // handle the result
        (result) => {
          localStorage.setItem("Positif", JSON.stringify(result));
          // console.log(result);
          this.setState({
            coronaPositif: result,
          });
        },

        // Handle error
        (error) => {
          this.setState({
            error,
          });
        }
      );
  };

  getCoronaSembuh = () => {
    const proxyurl = "https://cors-anywhere.herokuapp.com/";
    const url = "https://api.kawalcorona.com/sembuh";

    fetch(proxyurl + url)
      .then((response) => response.json())
      .then(
        // handle the result
        (result) => {
          localStorage.setItem("Recovered", JSON.stringify(result));
          // console.log(result);
          this.setState({
            coronaSembuh: result,
          });
        },

        // Handle error
        (error) => {
          this.setState({
            error,
          });
        }
      );
  };

  getCoronaMeninggal = () => {
    const proxyurl = "https://cors-anywhere.herokuapp.com/";
    const url = "https://api.kawalcorona.com/meninggal";

    fetch(proxyurl + url)
      .then((response) => response.json())
      .then(
        // handle the result
        (result) => {
          localStorage.setItem("Death", JSON.stringify(result));
          // console.log(result);
          this.setState({
            coronaMeninggal: result,
          });
        },

        // Handle error
        (error) => {
          this.setState({
            error,
          });
        }
      );
  };

  componentDidMount = () => {
    document.addEventListener("message", this.onMessage);
    window.addEventListener("message", this.onMessage);
    if (this.state.community != "") {
      this.getCommunityData();
    }
    this.waitForBridge();
    this.cekplatformdevice();

    let videoAdvTop = JSON.parse(localStorage.getItem("VideoTOP"));
    if (
      videoAdvTop === null ||
      videoAdvTop === undefined ||
      videoAdvTop === [] ||
      videoAdvTop === ""
    )
      videoAdvTop = [];
    this.setState({ videoAdvTop: videoAdvTop });

    let videoAdvBottom = JSON.parse(localStorage.getItem("VideoBOTTOM"));
    if (
      videoAdvBottom === null ||
      videoAdvBottom === undefined ||
      videoAdvBottom === [] ||
      videoAdvBottom === ""
    )
      videoAdvBottom = [];
    this.setState({ videoAdvBottom: videoAdvBottom });

    let LastTime = JSON.parse(localStorage.getItem("LastTime"));
    if (
      LastTime === null ||
      LastTime === undefined ||
      LastTime === [] ||
      LastTime === ""
    )
      LastTime = [];
    // console.log(LastTime);
    this.setState({ LastTime: LastTime });

    let bannerTop = JSON.parse(localStorage.getItem("TOP"));
    if (
      bannerTop === null ||
      bannerTop === undefined ||
      bannerTop === [] ||
      bannerTop === ""
    )
      bannerTop = [];
    this.setState({ AdvCommunity: bannerTop });

    let bannerBottom = JSON.parse(localStorage.getItem("BOTTOM"));
    if (
      bannerBottom === null ||
      bannerBottom === undefined ||
      bannerBottom === [] ||
      bannerBottom === ""
    )
      bannerBottom = [];
    this.setState({ AdvCommunityBottom: bannerBottom });

    // let covidINA = JSON.parse(localStorage.getItem("INA"));
    // if (
    //   covidINA === null ||
    //   covidINA === undefined ||
    //   covidINA === [] ||
    //   covidINA === ""
    // )
    //   covidINA = [];
    // this.setState({ coronaIndonesia: covidINA });

    // let covidPositif = JSON.parse(localStorage.getItem("Positif"));
    // if (
    //   covidPositif === null ||
    //   covidPositif === undefined ||
    //   covidPositif === [] ||
    //   covidPositif === ""
    // )
    //   covidPositif = [];
    // this.setState({ coronaPositif: covidPositif });

    // let covidRecovered = JSON.parse(localStorage.getItem("Recovered"));
    // if (
    //   covidRecovered === null ||
    //   covidRecovered === undefined ||
    //   covidRecovered === [] ||
    //   covidRecovered === ""
    // )
    //   covidRecovered = [];
    // this.setState({ coronaSembuh: covidRecovered });

    // let covidDeath = JSON.parse(localStorage.getItem("Death"));
    // if (
    //   covidDeath === null ||
    //   covidDeath === undefined ||
    //   covidDeath === [] ||
    //   covidDeath === ""
    // )
    //   covidDeath = [];
    // this.setState({ coronaMeninggal: covidDeath });

    // this.getCoronaIndonesia();
    // this.getCoronaPositif();
    // this.getCoronaSembuh();
    // this.getCoronaMeninggal();

    // this.loadMomentCount();

    // if(this.state.community != ''){
    //     this.getCommunityDataBottom();
    // }
  };

  componentWillUnmount = () => {
    document.removeEventListener("message", this.onMessage);
    window.removeEventListener("message", this.onMessage);
  };

  loadUser = (PlatformDevice) => {
    let info = localStorage.getItem("smart-app-id-login");
    if (
      info != null &&
      info !== undefined &&
      info != "null" &&
      info != "" &&
      info != "undefined"
    ) {
      info = JSON.parse(info);
      // this.setState({
      //   phonenumber: info.phonenumber,
      // });
      if (PlatformDevice === '"ios"' || PlatformDevice === "ios") {
        let platformiOS = "IOS";
        this.doUsePlatformUser(info.phonenumber, platformiOS);
      } else if (PlatformDevice === null || PlatformDevice === "") {
        let platformAndroid = "ANDROID";
        this.doUsePlatformUser(info.phonenumber, platformAndroid);
      }
    }
  };

  doUsePlatformUser = (phonenumber, platform) => {
    let params = {
      phoneno: phonenumber,
      platform: platform,
    };
    axios
      .post(webserviceurl + "/app_update_platform.php", params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => {
        let result = response.data;
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  cekplatformdevice = () => {
    let PlatformDevice = localStorage.getItem("PlatformDevice");
    console.log("cek platform", PlatformDevice);
    this.setState({
      platform: PlatformDevice,
    });
    this.loadUser(PlatformDevice);
  };

  getCommunityData = () => {
    axios({
      method: "post",
      url: webserviceurl + "/app_load_communitydata.php",
      data: {
        communityid: this.state.community,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        // console.log(response);
        let result = response.data;
        // console.log(result);
        if (result.status === "OK") {
          let adv = [];
          for (let i = 0; i < result.records.length; i++) {
            for (let idx = 0; idx < result.records[i].bannerpic.length; idx++) {
              adv.push({
                src: result.records[i].bannerpic[idx].banner,
                altText: "slide" + idx,
                caption: "cap" + idx,
              });
            }
          }
          localStorage.setItem("TOP", JSON.stringify(adv));
          this.setState({ AdvCommunity: adv });
          // console.log(this.state.AdvCommunity);

          let advBottom = [];
          for (let i = 0; i < result.records.length; i++) {
            for (
              let idx = 0;
              idx < result.records[i].gallerypic.length;
              idx++
            ) {
              advBottom.push({
                src: result.records[i].gallerypic[idx].gallery,
                altText: "slide" + idx,
                caption: "cap" + idx,
              });
            }
          }
          this.setState({ AdvCommunityBottom: advBottom });
          localStorage.setItem("BOTTOM", JSON.stringify(advBottom));
          // console.log(this.state.AdvCommunityBottom);

          let videoAdvTop = [];
          for (let i = 0; i < result.records.length; i++) {
            for (
              let idx = 0;
              idx < result.records[i].bannerVideoTop.length;
              idx++
            ) {
              videoAdvTop.push({
                src: result.records[i].bannerVideoTop[idx].videoURLTop,
                altText: "slide" + idx,
                caption: "cap" + idx,
              });
            }
          }
          this.setState({ videoAdvTop: videoAdvTop });
          localStorage.setItem("VideoTOP", JSON.stringify(videoAdvTop));
          // console.log(this.state.videoAdvTop);

          let videoAdvBottom = [];
          for (let i = 0; i < result.records.length; i++) {
            for (
              let idx = 0;
              idx < result.records[i].bannerVideoBottom.length;
              idx++
            ) {
              videoAdvBottom.push({
                src: result.records[i].bannerVideoBottom[idx].videoURLBottom,
                poster:
                  result.records[i].bannerVideoBottom[idx].videoURLBottom +
                  "#t=0.1",
                altText: "slide" + idx,
                caption: "cap" + idx,
              });
            }
          }
          this.setState({ videoAdvBottom: videoAdvBottom });
          localStorage.setItem("VideoBOTTOM", JSON.stringify(videoAdvBottom));
          // console.log(this.state.videoAdvBottom);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  loadMomentCount = () => {
    axios({
      method: "post",
      url: webserviceurl + "/app_load_momentscount.php",
      data: {
        filter: "",
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let result = response.data;
        if (result.status === "OK") {
          this.setState({ count: result.records });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  callEmergency = () => {
    this.toggle();
    window.location = "tel:0123";
  };

  onMessage = (data) => {
    //alert('');
    let messContent = null;
    if (data.data) messContent = JSON.parse(data.data);

    if (messContent) {
      if (messContent.code === "DevicePlatform") {
        localStorage.setItem(
          "PlatformDevice",
          JSON.stringify(messContent.param)
        );
        window.postMessage('{"code":"receivePlatform"}', "*");
      } else if (messContent.code === "login") {
        // messContent.param.phoneno = messContent.param.phonenumber;
        localStorage.setItem(
          "smart-app-id-login",
          JSON.stringify(messContent.param)
        );
        window.postMessage('{"code":"receivelogin"}', "*");
      } else if (messContent.code === "binding") {
        // messContent.param.debtor = messContent.param.DebtorAcct;
        localStorage.setItem(
          "smart-app-id-binding",
          JSON.stringify(messContent.param)
        );
        window.postMessage('{"code":"receiveloginBinding"}', "*");
      } else if (messContent.code === "token") {
        localStorage.setItem(
          "smart-app-id-token",
          JSON.stringify(messContent.param)
        );
        window.postMessage('{"code":"receiveloginToken"}', "*");
      } else if (messContent.code === "user") {
        localStorage.setItem(
          "Modernland-Account",
          JSON.stringify(messContent.param)
        );
        window.postMessage('{"code":"receiveloginUser"}', "*");
      } else if (messContent.code === "logout") {
        localStorage.setItem("smart-app-id-login", null);
        localStorage.removeItem("smart-app-id-login");
        localStorage.setItem("smart-app-id-binding", null);
        localStorage.removeItem("smart-app-id-binding");
        localStorage.setItem("Modernland-Account", null);
        localStorage.removeItem("Modernland-Account");
        localStorage.setItem("smart-app-id-token", null);
        localStorage.removeItem("smart-app-id-token");
        window.postMessage('{"code":"receivelogout"}', "*");
      }
    }
  };

  waitForBridge() {
    //the react native postMessage has only 1 parameter
    //while the default one has 2, so check the signature
    //of the function

    if (window.postMessage.length !== 1) {
      setTimeout(
        function () {
          this.waitForBridge();
        }.bind(this),
        200
      );
    } else {
      let param =
        '{"title":"","canGoBack":false, "showCommunityName":true, "hideTopbar":false, "hideFooterMenu":false}';
      window.postMessage(param, "*");
    }
  }

  onClickHandler = (idx) => {
    if (idx === "emergency") {
      this.toggle();
    }
  };

  next = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.AdvCommunity.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.AdvCommunity.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  // <div className="fa-moment"><span class="num">{this.state.count}</span></div>

  renderIcon = (icon) => {
    if (icon.link !== "") {
      if (icon.label == "Moments") {
        return (
          <Link to={icon.link}>
            <div className="link-label">
              <div className="moment-icon">
                <img src={icon.image} />
                <br />
                {icon.label}
              </div>
            </div>
          </Link>
        );
      } else {
        return (
          <Link to={icon.link}>
            <div className="link-label">
              <img src={icon.image} />
              <br />
              {icon.label}
            </div>
          </Link>
        );
      }
    } else {
      return (
        <div
          className="link-label"
          onClick={() => this.onClickHandler(icon.onclick)}
        >
          <img src={icon.image} />
          <br />
          {icon.label}
        </div>
      );
    }
  };

  handleChangeIndex = (index) => {
    this.setState({
      index,
    });
  };

  renderAdvCommunity = () => {
    const { index } = this.state;
    if (
      this.state.AdvCommunity.length > 0 &&
      this.state.videoAdvTop.length > 0
    ) {
      return (
        <div style={styles.root}>
          <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex}>
            {this.state.videoAdvTop.map((item) => (
              <div className="home-header">
                <div className="home-banner-container">
                  <Player
                    poster={item.src}
                    autoPlay
                    fluid={false}
                    width={"100%"}
                    height={"100%"}
                  >
                    <source src={item.src} />
                    <ControlBar autoHide={false} disableDefaultControls={true}>
                      <PlayToggle />
                      <VolumeMenuButton disabled />
                    </ControlBar>
                  </Player>
                </div>
              </div>
            ))}
            {this.state.AdvCommunity.map((item) => (
              <div className="home-header">
                <div className="home-banner-container">
                  <img
                    src={item.src}
                    alt={item.altText}
                    className="home-banner"
                  />
                </div>
              </div>
            ))}
          </SwipeableViews>
          <Pagination
            dots={
              this.state.videoAdvTop.length + this.state.AdvCommunity.length
            }
            index={index}
            onChangeIndex={this.handleChangeIndex}
          />
        </div>
      );
    } else {
      return (
        <div style={styles.root}>
          <AutoPlaySwipeableViews
            index={index}
            onChangeIndex={this.handleChangeIndex}
          >
            {this.state.AdvCommunity.map((item) => (
              <div className="home-header">
                <div className="home-banner-container">
                  <img
                    src={item.src}
                    alt={item.altText}
                    className="home-banner"
                  />
                </div>
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <Pagination
            dots={this.state.AdvCommunity.length}
            index={index}
            onChangeIndex={this.handleChangeIndex}
          />
        </div>
      );
    }
  };

  handleChangeIndexBotom = (index2) => {
    this.setState({
      index2,
    });
  };

  renderAdvCommunityBottom = () => {
    const { index2 } = this.state;
    if (
      this.state.AdvCommunityBottom.length > 0 &&
      this.state.videoAdvBottom.length > 0
    ) {
      return (
        <div style={styles.root}>
          <SwipeableViews
            index={index2}
            onChangeIndex={this.handleChangeIndexBotom}
          >
            {this.state.videoAdvBottom.map((item) => (
              <div className="home-header">
                <div className="home-banner-container">
                  <Player
                    fluid={false}
                    width={"100%"}
                    height={"100%"}
                    aspectRatio={"16:9"}
                  >
                    <source src={item.poster} />
                    <ControlBar autoHide={false} disableDefaultControls={true}>
                      <PlayToggle />
                      <VolumeMenuButton disabled />
                    </ControlBar>
                  </Player>
                </div>
              </div>
            ))}
            {this.state.AdvCommunityBottom.map((item) => (
              <div className="home-header">
                <div className="home-banner-container">
                  <img
                    src={item.src}
                    alt={item.altText}
                    className="home-banner"
                  />
                </div>
              </div>
            ))}
          </SwipeableViews>
          <Pagination
            dots={
              this.state.videoAdvBottom.length +
              this.state.AdvCommunityBottom.length
            }
            index={index2}
            onChangeIndex={this.handleChangeIndexBotom}
          />
        </div>
      );
    } else {
      return (
        <div style={styles.root}>
          <AutoPlaySwipeableViews
            index={index2}
            onChangeIndex={this.handleChangeIndexBotom}
          >
            {this.state.AdvCommunityBottom.map((item) => (
              <div className="home-header">
                <div className="home-banner-container">
                  <img
                    src={item.src}
                    alt={item.altText}
                    className="home-banner"
                  />
                </div>
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <Pagination
            dots={this.state.AdvCommunityBottom.length}
            index={index2}
            onChangeIndex={this.handleChangeIndexBotom}
          />
        </div>
      );
    }
  };

  renderMenuCovid19 = () => {
    if (
      this.state.platform === '"ios"' ||
      this.state.platform === "ios" ||
      this.state.platform === "" ||
      this.state.platform === null
    ) {
      return null;
    } else {
      return (
        <div className="continer-icon-center">
          <Container>
            <Row className="center-icon-row-bottom" xs="12" sm="12" md="12">
              <Col className="content-icon-colom">
                <div onClick={() => this.goToContactTracing()}>
                  <div className="corona-data-container-column">
                    <div className="column-data-corona-container">
                      <span
                        style={{
                          color: "rgb(20, 52, 155)",
                          fontWeight: "bold",
                        }}
                      >
                        UPDATE COVID-19 GLOBAL
                      </span>
                    </div>
                    <Row className="center-icon-row" xs="3" sm="3" md="3">
                      <Col className="content-corona-positif">
                        <div className="content-text-data">
                          <div className="column-text-corona-data">
                            <span
                              style={{
                                fontSize: 13,
                                color: "rgb(211, 137, 0)",
                                fontWeight: "bold",
                              }}
                            >
                              {this.state.coronaPositif.value}
                            </span>
                            {/* <span style={{fontSize:13, color:'rgb(211, 137, 0)', fontWeight:'bold'}} >3,951,905</span> */}
                            <span
                              style={{
                                fontSize: 12,
                                color: "rgb(211, 137, 0)",
                                fontWeight: "bold",
                              }}
                            >
                              Confirmed
                            </span>
                          </div>
                        </div>
                      </Col>

                      <Col className="content-corona-meninggal">
                        <div className="content-text-data">
                          <div className="column-text-corona-data">
                            <span
                              style={{
                                fontSize: 13,
                                color: "rgb(255, 0, 0)",
                                fontWeight: "bold",
                              }}
                            >
                              {this.state.coronaMeninggal.value}
                            </span>
                            {/* <span style={{fontSize:13, color:'rgb(255, 0, 0)', fontWeight:'bold'}} >275,067</span> */}
                            <span
                              style={{
                                fontSize: 12,
                                color: "rgb(255, 0, 0)",
                              }}
                            >
                              Deaths
                            </span>
                          </div>
                        </div>
                      </Col>

                      <Col className="content-corona-sembuh">
                        <div className="content-text-data">
                          <div className="column-text-corona-data">
                            <span
                              style={{
                                fontSize: 13,
                                color: "rgb(0, 160, 0)",
                                fontWeight: "bold",
                              }}
                            >
                              {this.state.coronaSembuh.value}
                            </span>
                            {/* <span style={{fontSize:13, color:'rgb(0, 160, 0)', fontWeight:'bold'}} >1,330,209</span> */}
                            <span
                              style={{
                                fontSize: 12,
                                color: "rgb(0, 160, 0)",
                              }}
                            >
                              Recovered
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="corona-data-container-column"
                    style={{ marginTop: -16 }}
                  >
                    <div className="column-data-corona-container">
                      <span
                        style={{
                          color: "rgb(206, 0, 0)",
                          fontWeight: "bold",
                        }}
                      >
                        UPDATE COVID-19 INDONESIA
                      </span>
                    </div>
                    {this.state.coronaIndonesia.map((item) => (
                      <Row className="center-icon-row" xs="3" sm="3" md="3">
                        <Col className="content-corona-positif">
                          <div className="content-text-data">
                            <div className="column-text-corona-data">
                              <span
                                style={{
                                  fontSize: 13,
                                  color: "rgb(211, 137, 0)",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.positif}
                              </span>
                              {/* <span style={{fontSize:13, color:'rgb(211, 137, 0)', fontWeight:'bold'}} >13,645</span> */}
                              <span
                                style={{
                                  fontSize: 12,
                                  color: "rgb(211, 137, 0)",
                                  fontWeight: "bold",
                                }}
                              >
                                Confirmed
                              </span>
                            </div>
                          </div>
                        </Col>

                        <Col className="content-corona-meninggal">
                          <div className="content-text-data">
                            <div className="column-text-corona-data">
                              <span
                                style={{
                                  fontSize: 13,
                                  color: "rgb(255, 0, 0)",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.meninggal}
                              </span>
                              {/* <span style={{fontSize:13, color:'rgb(255, 0, 0)', fontWeight:'bold'}} >959</span> */}
                              <span
                                style={{
                                  fontSize: 12,
                                  color: "rgb(255, 0, 0)",
                                }}
                              >
                                Deaths
                              </span>
                            </div>
                          </div>
                        </Col>

                        <Col className="content-corona-sembuh">
                          <div className="content-text-data">
                            <div className="column-text-corona-data">
                              <span
                                style={{
                                  fontSize: 13,
                                  color: "rgb(0, 160, 0)",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.sembuh}
                              </span>
                              {/* <span style={{fontSize:13, color:'rgb(0, 160, 0)', fontWeight:'bold'}} >2,607</span> */}
                              <span
                                style={{
                                  fontSize: 12,
                                  color: "rgb(0, 160, 0)",
                                }}
                              >
                                Recovered
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </div>
                  <div className="column-data-corona-container">
                    <span style={{ color: "#000", fontSize: 10 }}>
                      Data Source : Kementerian Kesehatan & JHU.
                    </span>
                  </div>
                  <div className="column-data-corona-container">
                    <span
                      style={{
                        color: "#000",
                        fontSize: 10,
                        marginBottom: 10,
                      }}
                    >
                      Last Update : {this.state.LastTime}
                    </span>
                  </div>
                  <div className="click-tracing-container">
                    Click for JGC Contact Tracing Platform
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  };

  renderMenuIcon = (icon, i) => {
    // console.log(icon);
    if (icon.platform !== "ios") {
      return (
        <div key={i} className="icon-column">
          <Link to={icon.link}>
            <div className="link-label">
              <img src={icon.image} style={{ height: 45, width: 45 }} />
              <br />
              {icon.label}
            </div>
          </Link>
        </div>
      );
    }
  };

  goToContactTracing = () => {
    this.props.history.push("/healthdeclaration");
  };

  render() {
    if (this.state.redirect) {
      if (this.state.page == "newspage") {
        return <Redirect to="/news" />;
      } else if (this.state.page == "mypage") {
        return <Redirect to="/mypage" />;
      } else if (this.state.page == "marketplace") {
        return <Redirect to="/marketplace" />;
      } else if (this.state.page == "entertainment") {
        return <Redirect to="/entertainment" />;
      } else if (this.state.page == "statuspayment") {
        return <Redirect to="/statuspayment" />;
      } else if (this.state.page == "statuspaymentservices") {
        return <Redirect to="/statuspaymentservices" />;
      } else if (this.state.page == "statuspaymentbooking") {
        return <Redirect to="/statuspaymentbooking" />;
      } else if (this.state.page == "statuspaymenttoemail") {
        return <Redirect to="/statuspaymenttoemail" />;
      } else if (this.state.page == "login") {
        return <Redirect to="/login" />;
      } else if (this.state.page == "moments") {
        return <Redirect to="/moments" />;
      } else if (this.state.page == "privacypolicy") {
        return <Redirect to="/privacypolicy" />;
      }
    }

    return (
      <div className="main-container">
        <div className="headerHome">{this.renderAdvCommunity()}</div>
        {this.renderMenuCovid19()}
        <div className="icons">
          <div className="home-icons-container">
            {icons.map((icon, i) => this.renderMenuIcon(icon, i))}
          </div>
        </div>

        <div className="headerHomeBottom">
          {this.renderAdvCommunityBottom()}
        </div>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Emergency</ModalHeader>
          <ModalBody>Calling Emergency?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.callEmergency}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Home;
const styles = {
  root: {
    position: "relative",
  },
  slide: {
    padding: 15,
    minHeight: 100,
    color: "#fff",
  },
  slide1: {
    backgroundColor: "#FEA900",
  },
  slide2: {
    backgroundColor: "#B3DC4A",
  },
  slide3: {
    backgroundColor: "#6AC0FF",
  },
};
