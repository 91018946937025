import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import './VisitorAccessPage.style.css';
import { webserviceurl, activelanguage } from '../../../Config';
import SubHeader from '../../Component/SubHeader/SubHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
} from 'reactstrap';
import comingsoon from '../../../images/comingsoon.jpg';

import VisitorAccessListItem from '../../Component/VisitorAccessListItem/VisitorAccessListItem';


class VisitorAccessPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: "",
            redirect: false,
            list: [],
            voucher:{},
            Amount: "300000",
            fixAmount:''
        }

        this.language = getLanguage(activelanguage, 'visitoraccess');
    }

	loadVisit = () =>{
		axios.post(webserviceurl + '/app_load_uservisit.php', {
		},
		{headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {
                let result = response.data;
                
                if (result.status === "ok") {

					this.setState({ list: result.records });
				}
				
            })
            .catch((error) => {
                console.log(error);
            });
    }
    
    loadVoucher=()=>{
        axios.post(webserviceurl+'/app_load_voucher.php')
        .then((response) => {
            console.log(response.data);
            let data = {};
            if (response&&response.data.records&&response.data.records.length){
              data=response.data.records[0];
            }
            this.setState({ voucher:data });

                let totalAmount     = 0;
                let totalDiscount   = 0;
                let totalPrice      = 0;
                let totalPriceAll   = 0;

                totalAmount     = this.state.Amount;
                totalDiscount   = this.state.voucher.voucherdiscount;
                totalPrice      = totalAmount * totalDiscount/100;
                totalPriceAll   = (totalAmount - totalPrice);
                
                this.setState({fixAmount : totalPriceAll});

                console.log(totalAmount);
                console.log(totalDiscount);
                console.log(totalPrice);
                console.log(totalPriceAll);
                console.log(this.state.fixAmount);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

	componentDidMount = () => {
        this.waitForBridge();
        // this.loadVisit();
        // this.loadVoucher();
	}

    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
            window.postMessage(param, "*");
        }
    }

    createMarkup = (content) => {
        return { __html: content };
    }

    goToDetail=(item)=>{
        this.props.history.push('/visitoraccessdetail/'+item.uservisitid);
    }

    renderTab = () => {
        return (
                <div className="body-section payment-scroll-view">
                    {this.state.list.map((item, i) =>
                        <div key={i} onClick={()=>this.goToDetail(item)}>
                            <VisitorAccessListItem key={i} visitorpic={item.visitorpic} visitorname={item.visitorname} pic={item.pic} visit={item.visit} leave={item.leave} address={item.address} onClick={()=>this.goToDetail(item)}/>
                        </div>
                    )}
                </div>
            )
    }

    render() {
        if (this.state.redirect) {
            /*if (this.state.page == "newspage") {
                return <Redirect to='/news' />;
            }*/
        }

        return (
            <div>
                <SubHeader history={this.props.history} hideSearch={true} title={this.language.title}/>
                {/* {this.renderTab()} */}
                {/* <div className="main-container park-enterprise">
                <Card>
                    <CardImg top width="100%" src={comingsoon} alt="cooming soon" />
                    <CardBody>
                        <CardTitle></CardTitle>
                        <CardSubtitle></CardSubtitle>
                        <CardText></CardText>
                    </CardBody>
                </Card>
                </div> */}
                <div style={{marginTop:150}}>
                    <h1 style={{textAlign:'center', color:'#1273de', fontWeight:'bold', fontfamily: 'sans-serif' }} className="display-7">COMING SOON!</h1>
                </div>

                {/* <div>{this.state.fixAmount}</div> */}

            </div>
        );
    }
}

export default VisitorAccessPage;