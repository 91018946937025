import React, { Component } from 'react';
import { getLanguage } from '../../../Languages';
import { Container, Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import './ComplaintHistoryPage.style.css';
import { webserviceurl, activelanguage } from '../../../Config';
import SubHeader from '../../Component/SubHeader/SubHeader';
import bannerhealth from '../../../images/banner_health.png';
import LoadingScreen from '../../Component/LoadingScreen/LoadingScreen';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import noData from '../../../images/kosong2.png'

class ComplaintHistoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: "",
            redirect: false,
            currentIndex:0,
            phonenumber:'',
            HistoryList:[],
            community: props.match.params.communityid,
            showLoading: false
        }

        this.loginInfo = {
            phoneno: '',
            name: ''
        }

        this.language = getLanguage(activelanguage, 'compliantcovidhistory');
        this.globallang = getLanguage(activelanguage, 'global');
        
    }
    
    componentDidMount=()=>{
        this.waitForBridge();
        this.loadUser();
        // this.loadHistory();
    }

    componentWillUnmount=()=>{
        localStorage.setItem('covidtab', '2');
    }

    waitForBridge() {
        //the react native postMessage has only 1 parameter
        //while the default one has 2, so check the signature
        //of the function

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let tmp = localStorage.getItem('smart-app-id-login');
            let needLogin = false;

            if(tmp == undefined || tmp == null || tmp == 'null' || tmp == '' ||tmp == 'undefined' ) needLogin = true;
           
            else{
                tmp = JSON.parse(tmp);
                this.loginInfo.phoneno = tmp.phonenumber;
                this.loginInfo.name = tmp.name;
            }
            
            let param = '{"title":"' + this.language.title + '","canGoBack":true, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true, "needLogin":'+(needLogin ? 'true': 'false') +'}';
            window.postMessage(param, "*");
        }
    }

    loadUser=()=>{
        let tmp = localStorage.getItem('smart-app-id-login');
        if(tmp == undefined || tmp == null || tmp == 'null' || tmp == '' || tmp == 'undefined'){
            
            let param = '{"code":"need-login"}';
            //window.postMessage(param, "*");
            this.sendPostMessage(param);
        }else{
            tmp = JSON.parse(tmp);
            this.loginInfo.phoneno = tmp.phonenumber;
            this.loginInfo.name = tmp.name;
            this.setState({
                phonenumber : tmp.phonenumber
            })
            this.loadHistory(tmp.phonenumber);
        }
    }

    loadHistory = (phonenumber) => {
        this.setState({showLoading:true});
        axios.post(webserviceurl + '/app_load_historyformcovid.php', {
            phonenumber: phonenumber
        },
        {headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }})
            .then((response) => {
                let result = response.data;
                console.log(result);
                if (result.status === "OK") {
                    this.setState({ 
                        HistoryList: result.records,
                    });
                    console.log(this.state.HistoryList);
                }
                setTimeout(()=>{
                    this.setState({showLoading:false});
                }, 500); 
            })
            .catch((error) => {
                console.log(error);
            });
    }

    sendPostMessage=(param)=>{
        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.sendPostMessage(param);
            }.bind(this), 200);
        }
        else {
            window.postMessage(param, "*");
        }
    }

    createMarkup = (content) => {
        return { __html: content };
    }

    goBack=()=>{
        this.props.history.push('/covid19')
    }

    renderLoading=()=>{
        if(this.state.showLoading){
            return (
                <LoadingScreen></LoadingScreen>
            )
        }
    }

    renderHistoryBody=()=>{
        if(this.state.HistoryList.length>0){
            return(
                <div>
                    {this.state.HistoryList.map((item, i) =>
                        <div className="list-item"  key={i}>
                            <div className="relative-container">
                                <div className="history-desc-container-border">
                                    <div className="history-desc-container-small">
                                        <div className="descriptime" >
                                            <div className="timeupdate" ><span>{item.time}&nbsp;WIB</span></div>
                                            <div className="reading" ><span>{item.name}</span></div>
                                        </div>
                                        <div className="headline-text-name">About Complaint:&nbsp;{item.complaintname}</div>
                                        <div className="sortdesc-headline-text" dangerouslySetInnerHTML={{__html: item.desc}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )
        }else{
            return(
                <div className="history-list-section">
                    <div style={{alignContent:'center', alignItems:'center', textAlign:'center', marginTop:80 }}>
                        <img src={noData} style={{width:180, height:120}} />
                    </div>
                    <div className="no-data" style={{marginTop:-20}}>No newsfeed available</div>
                </div>
            )
        }
    }

    render() {

        return (
            <div>
                <SubHeader history={this.props.history} hideSearch={true} title={this.language.title} goBack={this.goBack} />
                <div className="history-list-section">
                    {this.renderHistoryBody()}
                </div>
                {this.renderLoading()}
            </div>
        );
    }

}
export default ComplaintHistoryPage;