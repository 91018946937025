//export var webserviceurl = "http://120.79.137.189/smartcp/webservices";
//export var activelanguage = "chn";
// export var webserviceurl = "http://54.252.159.41/smartcp/webservices";

/*Prodaction(Live)*/
export var webserviceurl = "https://kosmo-modernland.com/smartcp/webservices";

/*Development(Testing)*/
// export var webserviceurl = "https://kosmo-modernland.com/smartcp_development/webservices";

export var activelanguage = "eng";
export var limitList = 20;
export var appId = "jgc-community";

export const PAYMENT_METHODS = {
  CREDIT_CARD: "credit card",
  DEBIT_CARD: "debit card",
  BANK_TRANSFER: "bank transfer",
};

export const NO_PAYMENT_METHOD = "";
