import React, { Component } from 'react';
//import { Link, Redirect } from 'react-router-dom';
import { getLanguage } from '../../../Languages';
//import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import './HistoryServiceOrder.style.css';
import { webserviceurl, activelanguage } from '../../../Config';
import {convertToRupiah, makeid} from '../../../Global';
import SubHeaderHistory from '../../Component/SubHeaderHistory/SubHeaderHistory';
import SubHeader from '../../Component/SubHeader/SubHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
} from 'reactstrap';
import comingsoon from '../../../images/comingsoon.jpg';
import 'react-confirm-alert/src/react-confirm-alert.css';
import more from '../../../images/marketplace/more.png';
import LoadingScreen from '../../Component/LoadingScreen/LoadingScreen';

class HistoryServiceOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: "",
            redirect: false,
            community: props.match.params.communityid,
            phoneno: '',
            name: '',
            start: 0,
            loadFinished: false,
            showLoading: false,
            services:[]
        }


        this.loginInfo = {
            phoneno: '',
            name: ''
        }

        this.language = getLanguage(activelanguage, 'historyservicecenterorder');
        this.globallang = getLanguage(activelanguage, 'global');
    }

    componentDidMount=()=>{
        this.waitForBridge();
        this.loadUser();
        this.loadHistoryServiceOrder(this.state.phoneno);
    
    }


    loadHistoryServiceOrder = (phoneno) => {
        this.setState({showLoading:true});
        axios.get(webserviceurl + '/app_load_history_servicecenter.php?start=' +this.state.start+ '&phoneno=' +phoneno)
            .then((response) => {
                let result = response.data;
                let limitafterload = this.state.start + 10;
                
                console.log(response);
                console.log(result);

                if (result.status === "OK") {
					if(result.records.length == 0){
						this.setState({loadFinished: true});
					}
					
					let tmp = this.state.services;

                    for(let i=0; i< result.records.length;i++){
                        let bln = "";
                        
                        switch(result.records[i].orderbln){
                            case 1: bln = this.globallang.january; break;
                            case 2: bln = this.globallang.february; break;
                            case 3: bln = this.globallang.march; break; 
                            case 4: bln = this.globallang.april; break;
                            case 5: bln = this.globallang.may; break;
                            case 6: bln = this.globallang.june; break;
                            case 7: bln = this.globallang.july; break;
                            case 8: bln = this.globallang.august; break;
                            case 9: bln = this.globallang.september; break;
                            case 10: bln = this.globallang.october; break;
                            case 11: bln = this.globallang.november; break;
                            case 12: bln = this.globallang.december; break;
                        }
                        result.records[i].monthname = bln;
						
						tmp.push(result.records[i]);
                    }
					
                    this.setState({ services: tmp});
                    this.setState({ start: limitafterload});
                    
                    setTimeout(()=>{
                        this.setState({showLoading:false});
                    }, 500); 
                }

                console.log(this.state.services);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    
    loadUser=()=>{

		if(localStorage.getItem('smart-app-id-login') != undefined && localStorage.getItem('smart-app-id-login') != 'undefined'){
			var com = window.localStorage.getItem('smart-app-id-login');
            var login = JSON.parse(com);
            
            // alert(JSON.stringify(login));

            this.setState({phoneno: login.phonenumber, name: login.name, email: login.email});
            
            // this.loadHistoryServiceOrder(login.phonenumber);
           
		}
    }
   
    waitForBridge() {

        if (window.postMessage.length !== 1) {
            setTimeout(function () {
                this.waitForBridge();
            }
                .bind(this), 200);
        }
        else {
            let param = '{"title":"' + this.language.title + '","canGoBack":false, "showCommunityName":true, "hideTopbar":true, "hideFooterMenu":true}';
            window.postMessage(param, "*");
        }
    }

    goBack=()=>{
        this.props.history.replace('/servicecenter');
    }

    renderDot = (item)=>{
        if(item.status_services ===0){
            return(
                <span className="pending"><FontAwesomeIcon icon="circle" /></span>
            )
        }else{
            return(
                <span className="paid"><FontAwesomeIcon icon="circle" /></span>
            )
        }
    }

    renderStatus = (item)=>{
        if(item.status ===0){
            return(
                <span className="pending">{this.language.proses}</span>
            )
        }else{
            return(
                <span className="paid">{this.language.completed}</span>
            )
        }
    }

    renderStatus_services = (item)=>{
        if(item.status_services ===0){
            return(
                <span className="pending">{this.language.proccess}</span>
            )
        }else{
            return(
                <span className="paid">{this.language.done}</span>
            )
        }
    }

    goToDetail=(userserviceid)=>{
        this.props.history.push('/historyserviceorderdetail/'+userserviceid.userserviceid);
    }




    renderBody = () => {
        if(this.state.services.length>0){
            return (
                <div className="body-section scroll-view">
                    <table>
                        <tbody>
                            {this.state.services.map((item, i) =>
                                <tr key={i} className="historyservice-list" onClick={()=>this.goToDetail(item)}>
                                    <td className="historyservices-list-col-dot">
                                        {this.renderDot(item)}
                                    </td>
                                    <td className="historyservices-list-col1">
                                        <div className="historyservices-name">{item.requestdate}</div>
                                        <div className="historyservices-desc">{item.categoryname}</div>
										<div className="historyservices-desc">  {convertToRupiah(item.price)}</div>
                                    </td>
                                    <td className="historyservices-list-col-qty">
                                        <div className="historyservice-status-label">Status</div>
                                        <div className="historyservice-status-value">
                                            {this.renderStatus_services(item)}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )
        }else{
            return(
                <div className="body-section scroll-view">
                    <div className="no-data">
                        {this.globallang.nodata}
                    </div>        
                </div>
            )
            
        }
        
    }

    renderLoading=()=>{
        if(this.state.showLoading){
            return (
                <LoadingScreen></LoadingScreen>
            )
        }
    }


    render() {
        return(
            <div className="main-container">
                <SubHeader history={this.props.history} hideSearch={true} title={this.language.title}  goBack={this.goBack}/>
                {this.renderBody()}
                {this.renderLoading()}
            </div>
        );
    }


}
export default HistoryServiceOrder;
